import axios from 'axios';
import config from '../config.json';
import { appSession } from '../managers/generalManager';
import logger from "../utils/logging_services";

const service_url = (config.mode === 'dev' ? config.server_url_dev : config.server_url_prod) + '/modules/extdata/image';

export async function processImage(containerName: string, documentName: string, request: string) {
    const url = `${service_url}/${appSession.currentProject}` +
        `?container_name=${encodeURIComponent(containerName)}` +
        `&document_name=${encodeURIComponent(documentName)}` +
        `&request=${encodeURIComponent(request)}`;

    const headers = {
        Authorization: `Bearer ${appSession.accessToken}`,
        Accept: 'text/event-stream',
        'Content-Type': 'application/json',
    };

    try {
        const response = await axios.post(url, {}, { headers });
        if (response.status === 200) {
            logger.info('ProcessImage Service Request Response:', response.data)
            return response.data.response;
        } else {
            logger.error(`'ProcessImage Service Request Unexpected Status: ${response.status}`);
        }
    } catch (error) {
        if (error instanceof Error) {
            logger.error('ProcessImage Service Request Error:', error);
        }
        if (axios.isAxiosError(error) && error.response) {
            console.error('Response app_theme:', error.response.data);
            console.error('Response status:', error.response.status);
        }
    }
}

export async function processLocalImage(prompt: string, imageFile: File): Promise<string> {

    const headers = {
        Authorization: `Bearer ${appSession.accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
    };

    const url = `${service_url}/${appSession.currentProject}/process?request=${encodeURIComponent(prompt)}`;

    const formData = new FormData();
    formData.append('file', imageFile, imageFile.name);

    const params = new URLSearchParams({
        request: prompt
    });

    try {
        const response = await axios.post( url, formData, { headers });
        logger.info("ProcessImage request response:", response.data);
        return response.data.response;
    } catch (error) {
        if (error instanceof Error) {
            logger.error("ProcessImage Request Failed:", error);
        }
        throw error; // Re-throw the error for the caller to handle
    }
}

interface ImageCreationResponse {
    0: "ok";
    1: {
        image_base64: string;
        revised_prompt: string;
    };
}

export async function createImage(prompt: string, size: string, provider?: string): Promise<{
    revised_prompt: any;
    imageUrl: string
}> {
    const headers = {
        Authorization: `Bearer ${appSession.accessToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    const url = `${service_url}/${appSession.currentProject}/create`;

    switch (size) {
        case '1:1':
            size = '1024x1024';
            break;
        case '16:9':
            size = '1792x1024';
            break;
        case '9:16':
            size = '1024x1792';
            break;
    }

    const data = {
        image_prompt: prompt,
        image_size: size,
        provider: provider,
    };

    try {
        const response = await axios.post<ImageCreationResponse>(url, data, { headers });
        logger.info("CreateImage request response:", response.data);

      if (response.data[0] === "ok" && response.data[1] && response.data[1].image_base64) {
            const base64Data = response.data[1].image_base64;
            const revised_prompt = response.data[1].revised_prompt;

            // Convert base64 to Blob
            const byteCharacters = atob(base64Data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'image/png' });

            // Create a temporary URL for the Blob
            const imageUrl = URL.createObjectURL(blob);

            return { imageUrl, revised_prompt };
        } else {
           logger.error('Image creation failed or no image data received');
        }
    } catch (error) {
        if (error instanceof Error) {
            logger.error("CreateImage Request Failed:", error);
        }
    }
   return {"imageUrl": "", "revised_prompt": ""};

}
