import React, {useState} from 'react'
import {Authenticate, AuthenticateResponse} from "../../backend/authentication.services";
import {DraggablePaper} from "../general/Styling.snippets";
import {
    Alert,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    Grid
} from '@mui/material'
import PleaseWaitDialog from "../general/please_wait";
import {appSession, saveSession} from "../../managers/generalManager";


export interface LogInProps {
    isOpen: boolean;
    onSucces: () => void;
    onCancel: () => void;
}

export const LogIn = ({isOpen, onSucces, onCancel}: LogInProps) => {

    const [username, setUsername] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [isProcessing, setIsProcessing] = useState<boolean>(false)

    // Handle button interaction
    const handleSubmit = async () => {
        setErrorMessage("");
        setIsProcessing(true);
        Authenticate(username, password).then((response: AuthenticateResponse) => {
            if (response.status === 200 && response.token !== "") {
                appSession.accessToken= response.token;
                appSession.currentUser = username;
                saveSession();

                // localStorage.setItem('BoostrFoundation_AccessToken', response.token);
                // localStorage.setItem('BoostrFoundation_Username', username);
                setIsProcessing(false);
                onSucces()
            } else {
                if (response.status === 401) {
                    setErrorMessage("Authentication failed. Please check your username and password.")
                } else {
                    setErrorMessage(`Server error ${response.status} received. Contact support for help.`)
                }
                setIsProcessing(false);
            }
        });
    };

    const handleClose = () => {
        onCancel()
    };

    // Handle input field changes
    const handleUsernameChange = (username: string) => {
        setUsername(username)
    }
    const handlePasswordChange = (password: string) => {
        setPassword(password)
    }

    return (
        <>


            <Dialog
                open={isOpen}
                onClose={handleClose}
                PaperComponent={DraggablePaper}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle sx={{cursor: 'move'}} id="draggable-dialog-title">
                    ZAIA Authentication
                </DialogTitle>
                <DialogContentText sx={{mx: 5, my: 3}}>
                    Please enter your username and password and press submit.
                </DialogContentText>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="username"
                                label="username"
                                multiline
                                maxRows={1}
                                onChange={(event) => handleUsernameChange(event.target.value)}
                            />
                        </Grid>
                        <Divider/>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="password"
                                label="password"
                                type="password"
                                onChange={(event) => handlePasswordChange(event.target.value)}
                            />
                        </Grid>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12} sx={{my: 5}}>
                        {errorMessage && (
                            <Alert severity="error">{errorMessage}</Alert>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} autoFocus onClick={handleSubmit}>Submit</Button>
                    <Button variant={"contained"} color={"warning"} onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <PleaseWaitDialog open={isProcessing} title={"Authentication Check"} message={"Connecting and loading initial application app_theme"} onCancel={()=>alert("You need to authenticate before being able to continue ")}/>
        </>

    );
}

