import React, {useEffect, useMemo, useState, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    Backdrop,
    CircularProgress,
    Divider,
    IconButton,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
    addExtDataFile,
    addExtDataRecord,
    KnowledgeBaseItem,
    KnowledgeBaseItemOut,
    getExtDataRecordList,
    getParameter,
    updateExtDataRecord,
    deleteExtDataRecord,
} from "../../backend/extdata.services";
import {SynonymRecord} from "./manage_synonym_list";
import {
    appSession, AppState,
    Option,
} from "../../managers/generalManager";
import AppTable, {TableColumn, TableItem} from "../general/app_table";
import {ManageKnowledgeBaseItem} from "./file_item";
import AppDialog from "../general/app_dialog";
// import {AppState} from "../../store/old/store";
// import {
//     SelectedFile,
//     setSelectedFile,
//     setSelectedFolder,
//     setSelectedPages,
// } from "../../store/old/data-slice";
import {SelectFolder} from "./components/select_folder";
import {zaiaPart} from "../../app/app-styling";
import {getFileIcon} from "./file_services";
import {SelectedFile} from "../../store/types";
import {setSelectedFile, setSelectedFolder, setSelectedPages} from "../../store/dataSlice";
import {RootState} from "../../store/store";

interface FileNavigatorProps {
    selectedCategory: string;
    onFileSelected: (file: KnowledgeBaseItem | undefined) => void;
    onClose: () => void;
}

export interface ObjectItem extends TableItem {
    filename: string;
    source: string;
    chunks: number;
    value?: KnowledgeBaseItem;
}

export const FileNavigator: React.FC<FileNavigatorProps> = ({
                                                                onClose,
                                                                onFileSelected,
                                                                selectedCategory,
                                                            }) => {
    const [recordList, setRecordList] = useState<KnowledgeBaseItem[]>([]);
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [, setSynonyms] = useState<SynonymRecord[]>([]);
    const [selectedFileUUID, setSelectedFileUUID] = useState<string>("");
    const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
    const [recordToDelete, setRecordToDelete] = useState<string>("");
    const [selectedRecordIndex, setSelectedRecordIndex] = useState<number>(-1);
    const [mode, setMode] = useState<string>("");
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(true);

    const dispatch = useDispatch();

    const selectedFile: SelectedFile | undefined = useSelector(
        (state: RootState) => state.data.selectedFile
    );
    const selectedFolder = useSelector(
        (state: RootState) => state.data.selectedFolder
    );

    const menuItem = useMemo(
        () => [
            {label: "Edit", action: "edit"},
            {label: "Delete", action: "delete"},
        ],
        []
    );

    const emptyRecord: KnowledgeBaseItem = useMemo(
        () => ({
            uuid: "",
            title: "",
            original: "",
            processed: "",
            project: "",
            namespace: "",
            keywords: "",
            meta_data: {
                resource: "",
                type: "",
                language: "",
                extra: "",
            },
            related_record: null,
            related_type: null,
            chunks_original: 0,
            chunks_processed: 0,
        }),
        []
    );

    const columns: TableColumn<ObjectItem>[] = useMemo(
        () => [
            {
                title: "Icon",
                dataIndex: "source",
                render: (item: ObjectItem) =>
                    getFileIcon(item.source || "", item.chunks || -1),
            },
            {
                title: "File Name",
                dataIndex: "filename",
                render: (item: ObjectItem) => item.filename,
            },
        ],
        []
    );

    const loadData = useCallback(() => {
        if (!reload || selectedFolder === "") {
            return;
        }

        setIsProcessing(true);
        Promise.all([
            getExtDataRecordList(appSession.currentKnowledgeBaseProject, selectedFolder),
            getParameter(appSession.currentKnowledgeBaseProject, selectedFolder, "synonyms"),
        ])
            .then(([data, synonyms_json]) => {
                setRecordList(data);
                let synonyms: SynonymRecord[] = [];
                if (synonyms_json.startsWith("[{") && synonyms_json.endsWith("}]")) {
                    try {
                        synonyms = JSON.parse(synonyms_json);
                    } catch (e) {
                        console.error("Error parsing synonyms JSON:", e);
                    }
                }
                setSynonyms(synonyms);
                setReload(false);
            })
            .finally(() => setIsProcessing(false));
    }, [selectedFolder, reload]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const truncateLongWords = useCallback((str: string, max_length: number) => {
        console.log("vincent");
        const words = str.split(" ");
        return words
            .map((word) =>
                word.length > max_length
                    ? word.substring(0, max_length) + "..."
                    : word
            )
            .join(" ");
    }, []);

    const sortedAndConvertedRecords: ObjectItem[] = useMemo(() => {
        if (selectedFile !== undefined) {
            const record = recordList.find(
                (record) => record.meta_data.resource === selectedFile.fileName
            );
            if (record) {
                setSelectedFileUUID(record.uuid);
            }
        } else {
            setSelectedFileUUID("");
        }

        return [...recordList]
            .sort((a, b) => a.title.localeCompare(b.title))
            .filter((record) => record.related_record === null)
            .map((record) => ({
                key: record.uuid,
                category: "files",
                filename: truncateLongWords(record.title, 25),
                keywords: record.keywords,
                source: record.meta_data.resource,
                chunks: record.chunks_original,
                value: record,
            }));
    }, [recordList, selectedFile, truncateLongWords]);

    const handleCategoryChange = useCallback(
        (selection: Option) => {
            dispatch(setSelectedFolder(selection.value));
            setReload(true);
        },
        [dispatch]
    );

    const handleRecordClick = useCallback(
        (index: number, selected_mode: string) => {
            setMode(selected_mode);
            setSelectedRecordIndex(index);
            setShowDialog(true);
        },
        []
    );

    // const handleAddUpdate = useCallback(
    //     (app_theme: KnowledgeBaseItemOut, file: File | null | undefined, addToIndex: boolean) => {
    //         setShowDialog(false);
    //         setIsProcessing(true);
    //
    //         const operation =
    //             mode === "add"
    //                 ? file
    //                     ? addExtDataFile(currentKnowledgeBaseProject, app_theme, file, addToIndex)
    //                     : addExtDataRecord(currentKnowledgeBaseProject, app_theme, addToIndex)
    //                 : mode === "edit"
    //                     ? updateExtDataRecord(currentKnowledgeBaseProject, app_theme.uuid || "", app_theme)
    //                     : deleteExtDataRecord(currentKnowledgeBaseProject, app_theme.uuid || "");
    //
    //         operation
    //             .then(() => {
    //                 dispatch(setSelectedFolder(app_theme.namespace));
    //                 setReload(true);
    //             })
    //             .finally(() => setIsProcessing(false));
    //     },
    //     [mode, dispatch]
    // );

    const handleAddUpdateOrDelete = useCallback(
        (data: KnowledgeBaseItemOut, file: File | null | undefined, addToIndex: boolean) => {
            setShowDialog(false);
            setIsProcessing(true);

            let operation;

            switch (mode) {
                case "add":
                    operation = file
                        ? addExtDataFile(appSession.currentKnowledgeBaseProject, data, file, addToIndex)
                        : addExtDataRecord(appSession.currentKnowledgeBaseProject, data, addToIndex);
                    break;
                case "edit":
                    operation = updateExtDataRecord(appSession.currentKnowledgeBaseProject, data.uuid || "", data);
                    break;
                case "delete":
                    operation = deleteExtDataRecord(appSession.currentKnowledgeBaseProject, data.uuid || "");
                    setRecordToDelete("");
                    setShowDeleteConfirm(false);
                    break;
                default:
                    console.error("Invalid mode:", mode);
                    setIsProcessing(false);
                    return;
            }

            operation
                .then(() => {
                    dispatch(setSelectedFolder(data.namespace));
                    setReload(true);
                })
                .catch((error: any) => {
                    console.error("Operation failed:", error);
                    // Handle error (e.g., show an error message to the user)
                })
                .finally(() => setIsProcessing(false));
        },
        [mode, dispatch]
    );

    const handleFileRequest = useCallback(
        (action: string, key: string) => {
            if (action === "delete") {
                setShowDeleteConfirm(true);
                setRecordToDelete(key);
                setMode("delete");
            } else if (action === "edit") {
                const index = recordList.findIndex((record) => record.uuid === key);
                handleRecordClick(index, action);
            } else if (action === "add") {
                handleRecordClick(0, action);
            } else {
                const record = recordList.find((record) => record.uuid === key);
                if (record) {
                    if (selectedFileUUID === record.uuid) {
                        setSelectedFileUUID("");
                        dispatch(setSelectedFile(undefined));
                        dispatch(setSelectedPages([]));
                        onFileSelected(undefined);
                    } else {
                        setSelectedFileUUID(record.uuid || "");
                        if (
                            selectedFile === undefined ||
                            record.meta_data.resource !== selectedFile.fileName
                        ) {
                            const newSelectedFile: SelectedFile = {
                                uuid: record.uuid || "",
                                fileName: record?.meta_data.resource || "",
                                fileType: record?.meta_data.type || "",
                                records: [],
                                columns: [],
                                data: record?.processed || "",
                                fromPage: 0,
                                toPage: 0,
                                metadata: record?.meta_data || {},
                            };
                            dispatch(setSelectedFile(newSelectedFile));
                            dispatch(setSelectedPages([]));
                        }
                        onFileSelected(record);
                    }
                }
            }
        },
        [
            recordList,
            selectedFileUUID,
            selectedFile,
            dispatch,
            onFileSelected,
            handleRecordClick,
        ]
    );

    return (
        <div style={zaiaPart({})}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    overflowY: "auto",
                    // maxWidth: appSettings.maxWidth,
                    // minWidth: appSettings.maxWidth,
                    height: "100%",
                }}
            >
                <div
                    style={{
                        height: "50px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h5" marginLeft={1}>Files</Typography>
                    <IconButton onClick={onClose} size="small">
                        <CloseIcon/>
                    </IconButton>
                </div>
                <Divider/>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        overflow: "auto",
                        flex: 1,
                    }}
                >
                    <AppTable
                        data={sortedAndConvertedRecords}
                        title=""
                        filter=""
                        filterValues={[]}
                        columns={columns}
                        selectColumnsToShow={[]}
                        menu={menuItem}
                        menuTable={[]}
                        onRequest={handleFileRequest}
                        selectMode="select"
                        mode="manage"
                        selected={[selectedFileUUID]}
                        hideHeaders={true}
                        allowDelete={true}
                        allowEdit={true}
                        allowView={true}
                        allowAdd={true}
                        columnStyle={{minWidth: "20px"}}
                    />
                </div>
                <Divider/>
                <SelectFolder onSelection={handleCategoryChange}/>
            </div>

            {showDialog && selectedRecordIndex >= 0 && (
                <ManageKnowledgeBaseItem
                    data={mode === "add" ? emptyRecord : recordList[selectedRecordIndex]}
                    namespace={selectedFolder}
                    onSubmit={handleAddUpdateOrDelete}
                    mode={mode}
                    onClose={(refresh: boolean) => {
                        setShowDialog(false);
                        setReload(refresh);
                    }}
                />
            )}

            {isProcessing && (
                <Backdrop
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={isProcessing}
                >
                    <Typography variant="h3">Processing</Typography>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            )}

            {showDeleteConfirm && (
                <AppDialog
                    open={true}
                    size="sm"
                    title="Delete File"
                    message="Please confirm to delete the selected file"
                    showSubmit={true}
                    showCancel={true}
                    onSubmit={() =>
                        handleAddUpdateOrDelete(
                            {uuid: recordToDelete, namespace: selectedFolder} as KnowledgeBaseItemOut,
                            null,
                            false
                        )
                    }
                    onCancel={() => setShowDeleteConfirm(false)}
                >
                    <Typography variant="body1">
                        Are you sure you want to delete the selected file?
                    </Typography>
                </AppDialog>
            )}
        </div>
    );
};




