import React, {useState, useEffect, useRef, useCallback} from 'react';
import {
    Card,
    CardContent,
    Button,
    Box,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    ToggleButtonGroup,
    ToggleButton,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@mui/icons-material/Save';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import AppDialog from "../general/app_dialog";
import html2canvas from 'html2canvas';
import {appSettings} from "../../managers/generalManager";
import ComponentModalOrEmbedded from "../general/component_modal_embedded";
import {WorkspaceItem} from "../../store/types";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";

interface HTMLViewerProps {
    htmlContent: string;
    itemRef: string;
    title: string;
    onClose: () => void;
}

interface CustomStyle {
    backgroundColor: string;
    color: string;
    fontFamily: string;
    fontSize: string;
    textAlign: 'left' | 'center' | 'right' | 'justify';
}

type ImageSize = 'small' | 'medium' | 'large';

const fonts = [
    "Arial, sans-serif",
    "'Times New Roman', serif",
    "'Courier New', monospace",
    "Georgia, serif",
    "'Palatino Linotype', 'Book Antiqua', Palatino, serif",
    "'Verdana', sans-serif",
    "'Helvetica Neue', Helvetica, Arial, sans-serif",
    "'Trebuchet MS', Helvetica, sans-serif",
];

const lightTheme = {
    backgroundColor: '#ffffff',
    color: '#000000',
};

const darkTheme = {
    backgroundColor: '#282c34',
    color: '#ffffff',
};

const HTMLViewer = ({htmlContent, title, itemRef, onClose}: HTMLViewerProps) => {
    const [copied, setCopied] = useState(false);
    const [customStyle, setCustomStyle] = useState<CustomStyle>(() => {
        const savedStyle = localStorage.getItem('htmlViewerStyle');
        return savedStyle ? JSON.parse(savedStyle) : {
            ...lightTheme,
            fontFamily: 'Arial, sans-serif',
            fontSize: '16px',
            textAlign: 'left' as const,
        };
    });
    const [showStyleOptions, setShowStyleOptions] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const [viewMode, setViewMode] = useState<"modal" | "embedded">("embedded");
    const [imageSize, setImageSize] = useState<ImageSize>('medium');

    const [processedContent, setProcessedContent] = useState(htmlContent);

    const [itemRefFound, setItemRefFound] = useState<WorkspaceItem|undefined>(undefined);
    const workspaceItems: WorkspaceItem[] = useSelector((state: RootState) => state.workspaceItems.items);

    useEffect(() => {
        if (copied) {
            const timer = setTimeout(() => setCopied(false), 2000);
            return () => clearTimeout(timer);
        }
    }, [copied]);

        useEffect(() => {

        if (itemRef === "") return;
        const workspaceItem = workspaceItems.filter(item => item.uuid === itemRef);
        if (workspaceItem && workspaceItem[0]) {
            setItemRefFound(workspaceItem[0]);
        }

    }, [itemRef]);



    useEffect(() => {
        localStorage.setItem('htmlViewerStyle', JSON.stringify(customStyle));
    }, [customStyle]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(htmlContent).then(() => {
            setCopied(true);
        });
    };

    const handleStyleChange = (property: keyof CustomStyle, value: string) => {
        setCustomStyle(prevStyle => ({...prevStyle, [property]: value}));
    };

    const extractStyles = (element: Element): Element => {
        const computedStyle = window.getComputedStyle(element);
        const inlineStyle = element.getAttribute('style') || '';
        const newInlineStyle = inlineStyle + Object.values(computedStyle).reduce((acc, property) => {
            if (computedStyle.getPropertyValue(property)) {
                acc += `${property}:${computedStyle.getPropertyValue(property)};`;
            }
            return acc;
        }, '');
        element.setAttribute('style', newInlineStyle);

        Array.from(element.children).forEach(child => extractStyles(child));
        return element;
    };

    const getStyledHTML = () => {
        if (!contentRef.current) return '';

        const clonedContent = contentRef.current.cloneNode(true) as HTMLElement;
        extractStyles(clonedContent);

        return `
            <html>
            <head>
                <style>
                    body { 
                        background-color: ${customStyle.backgroundColor}; 
                        color: ${customStyle.color}; 
                        font-family: ${customStyle.fontFamily}; 
                        font-size: ${customStyle.fontSize}; 
                        text-align: ${customStyle.textAlign};
                        margin: 0;
                        padding: 20px;
                    }
                </style>
            </head>
            <body>
                ${clonedContent.innerHTML}
            </body>
            </html>
        `;
    };

    const printHTML = () => {
        const printWindow = window.open('', '_blank');
        if (printWindow) {
            printWindow.document.write(getStyledHTML());
            printWindow.document.close();
            printWindow.print();
        }
    };

    const saveAsHTML = () => {
        const blob = new Blob([getStyledHTML()], {type: 'text/html'});
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'content.html';
        link.click();
    };

    const saveAsPNG = async () => {
        if (contentRef.current) {
            const canvas = await html2canvas(contentRef.current, {
                backgroundColor: customStyle.backgroundColor,
                useCORS: true,
                logging: false,
                scale: 2,
            });
            const image = canvas.toDataURL("image/png");
            const link = document.createElement('a');
            link.href = image;
            link.download = 'content.png';
            link.click();
        }
    };

    const toggleStyleOptions = () => {
        setShowStyleOptions(!showStyleOptions);
    };

    const toggleTheme = () => {
        setCustomStyle(prevStyle => ({
            ...prevStyle,
            ...(prevStyle.backgroundColor === lightTheme.backgroundColor ? darkTheme : lightTheme)
        }));
    };

    const handleClose = () => {
        onClose();
    };

    const processImages = useCallback((content: string, size: ImageSize) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const images = doc.getElementsByTagName('img');

        for (let img of Array.from(images)) {
            switch (size) {
                case 'small':
                    img.style.maxWidth = '25%';
                    break;
                case 'medium':
                    img.style.maxWidth = '50%';
                    break;
                case 'large':
                    img.style.maxWidth = '100%';
                    break;
            }
            img.style.height = 'auto';
        }

        return doc.body.innerHTML;
    }, []);

    useEffect(() => {
        const newContent = processImages(htmlContent, imageSize);
        setProcessedContent(newContent);
    }, [htmlContent, imageSize, processImages]);

    const handleImageSizeChange = (newSize: ImageSize) => {
        setImageSize(newSize);
    };

    return (
        <ComponentModalOrEmbedded onClose={handleClose} title={title} mode={viewMode} size={"xl"}
                                  color={itemRefFound ? itemRefFound.selected_color : ""}
                                  maxWidth={appSettings.maxWidth * 2} onModeChange={setViewMode}>
            <>
                {viewMode === 'modal' ? (
                    <Card sx={{margin: 'auto', mt: 4}}>
                        <CardContent>
                            <Grid container spacing={2}>

                                <Grid item xs={2}>
                                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                                        <Button
                                            onClick={copyToClipboard}
                                            variant="outlined"
                                            size="small"
                                            startIcon={copied ? <CheckIcon/> : <ContentCopyIcon/>}
                                        >
                                            {copied ? 'Copied!' : 'Copy'}
                                        </Button>
                                        <Button
                                            onClick={printHTML}
                                            variant="outlined"
                                            size="small"
                                            startIcon={<PrintIcon/>}
                                        >
                                            Print
                                        </Button>
                                        <Button
                                            onClick={toggleTheme}
                                            variant="outlined"
                                            size="small"
                                            startIcon={customStyle.backgroundColor === lightTheme.backgroundColor ?
                                                <Brightness4Icon/> : <Brightness7Icon/>}
                                        >
                                            {customStyle.backgroundColor === lightTheme.backgroundColor ? 'Dark' : 'Light'} Theme
                                        </Button>
                                        <Button
                                            onClick={saveAsHTML}
                                            variant="outlined"
                                            size="small"
                                            startIcon={<SaveIcon/>}
                                        >
                                            Save HTML
                                        </Button>
                                        <Button
                                            onClick={saveAsPNG}
                                            variant="outlined"
                                            size="small"
                                            startIcon={<SaveIcon/>}
                                        >
                                            Save PNG
                                        </Button>
                                        <Button
                                            onClick={toggleStyleOptions}
                                            variant="outlined"
                                            size="small"
                                            startIcon={<ColorLensIcon/>}
                                        >
                                            {showStyleOptions ? 'Hide Styles' : 'Show Styles'}
                                        </Button>
                                        <FormControl fullWidth>
                                            <InputLabel>Image Size</InputLabel>
                                            <Select
                                                value={imageSize}
                                                onChange={(e) => handleImageSizeChange(e.target.value as ImageSize)}
                                                label="Image Size"
                                            >
                                                <MenuItem value="small">Small</MenuItem>
                                                <MenuItem value="medium">Medium</MenuItem>
                                                <MenuItem value="large">Large</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    {showStyleOptions && (
                                        <Box sx={{
                                            mt: 2,
                                            display: 'flex',
                                            gap: 2,
                                            flexWrap: 'wrap',
                                            justifyContent: 'center'
                                        }}>
                                            <TextField
                                                label="Background"
                                                type="color"
                                                value={customStyle.backgroundColor}
                                                onChange={(e) => handleStyleChange('backgroundColor', e.target.value)}
                                                sx={{width: 100}}
                                            />
                                            <TextField
                                                label="Text Color"
                                                type="color"
                                                value={customStyle.color}
                                                onChange={(e) => handleStyleChange('color', e.target.value)}
                                                sx={{width: 100}}
                                            />
                                            <FormControl sx={{minWidth: 120}}>
                                                <InputLabel>Font</InputLabel>
                                                <Select
                                                    value={customStyle.fontFamily}
                                                    onChange={(e) => handleStyleChange('fontFamily', e.target.value)}
                                                    label="Font"
                                                >
                                                    {fonts.map((font, index) => (
                                                        <MenuItem key={index} value={font} style={{fontFamily: font}}>
                                                            {font.split(',')[0].replace(/'/g, '')}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                label="Font Size"
                                                type="number"
                                                value={parseInt(customStyle.fontSize)}
                                                onChange={(e) => handleStyleChange('fontSize', `${e.target.value}px`)}
                                                InputProps={{inputProps: {min: 8, max: 72}}}
                                                sx={{width: 80}}
                                            />
                                            <ToggleButtonGroup
                                                value={customStyle.textAlign}
                                                exclusive
                                                onChange={(e, newAlignment) => newAlignment && handleStyleChange('textAlign', newAlignment)}
                                                aria-label="text alignment"
                                            >
                                                <ToggleButton value="left" aria-label="left aligned">
                                                    <FormatAlignLeftIcon/>
                                                </ToggleButton>
                                                <ToggleButton value="center" aria-label="centered">
                                                    <FormatAlignCenterIcon/>
                                                </ToggleButton>
                                                <ToggleButton value="right" aria-label="right aligned">
                                                    <FormatAlignRightIcon/>
                                                </ToggleButton>
                                                <ToggleButton value="justify" aria-label="justified">
                                                    <FormatAlignJustifyIcon/>
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Box>
                                    )}
                                </Grid>


                                <Grid item xs={viewMode === 'modal' ? 10 : 12}>
                                    <Box
                                        ref={contentRef}
                                        sx={{
                                            p: 2,
                                            border: 1,
                                            borderColor: 'grey.300',
                                            borderRadius: 1,
                                            height: '80vh',
                                            overflow: 'auto',
                                            ...customStyle
                                        }}
                                    >
                                        <Box dangerouslySetInnerHTML={{__html: processedContent}}/>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                ) : (
                    <div
                        style={{
                            height: '80vh',
                            padding: '5px',
                            overflow: 'auto',
                        }}
                    >
                        <Box dangerouslySetInnerHTML={{__html: processedContent}}/>
                    </div>
                )}
            </>
        </ComponentModalOrEmbedded>
    );
};

export default HTMLViewer;