import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SessionState {
    server_token: string;
    user: string;
    initials: string;
}

const initialState: SessionState = {
    server_token: '',
    user: '',
    initials: ''
};



const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setServerToken: (state, action: PayloadAction<string>) => {
            state.server_token = action.payload;
        },
        setUser: (state, action: PayloadAction<string>) => {
            state.user = action.payload;
            state.initials = action.payload.split(' ').map((n: string) => n[0]).join('');
        }
    },
});

export const { setServerToken, setUser } = sessionSlice.actions;
export default sessionSlice.reducer;