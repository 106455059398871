import {Box, Button, Typography} from "@mui/material";
import React from "react";
import colorSchema from "../../../app_theme/theme_support/colorSchema";

interface PdfServiceProps {
    filetype: string;
    chunksOriginal: number;
    handleAddRemoveKnowledge: () => void;
}

export const renderPdfServices = ({
                                      filetype,
                                      chunksOriginal,
                                      handleAddRemoveKnowledge
                                  }: PdfServiceProps) => {
    if (filetype !== 'pdf') return null;

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flex: 1,
            padding: '10px'
        }}>
            <Button variant="contained" size="small" sx={{color: colorSchema.warning.main}}
                    onClick={handleAddRemoveKnowledge}>
                {chunksOriginal > 0 ? "Remove From KB" : "Add To KB"}
            </Button>

            <Typography variant="h6" sx={{color: colorSchema.text.primary}}>
                Chunks in knowledge database: {chunksOriginal}
            </Typography>
        </Box>
    );
};
