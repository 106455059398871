import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Document, Page, pdfjs} from 'react-pdf';
import {
    Button, CircularProgress, Typography, IconButton,
    MenuItem, Menu, ButtonGroup, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import {SaveToWorkspace} from '../workspace/save_to_workspace';

import logger from '../../utils/logging_services';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {ArrowLeft} from "@mui/icons-material";
import GoToPageDialog from "./goto_page_dialog";
import {getSelectedPagesText, runZAIARequest} from "./pdf_utils";
import {fetch_document_as_blob} from "../../backend/zaia_state";
import ComponentModalOrEmbedded from "../general/component_modal_embedded";
import {appSettings} from "../../managers/generalManager";

import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import TransformIcon from '@mui/icons-material/Transform';
import AllInclusiveOutlinedIcon from '@mui/icons-material/AllInclusiveOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import LayersClearOutlinedIcon from '@mui/icons-material/LayersClearOutlined';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import colorSchema from "../../app_theme/theme_support/colorSchema";
import {RootState} from "../../store/store";
import {
    setCurrentPage,
    setParameterSelectPageByPage, setSelectedFile,
    setSelectedFileUrl,
    setSelectedPages
} from "../../store/dataSlice";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import {ImageCarousel} from "./image_carousel";
import ExtractPDFDetails from "./extract_details";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

declare global {
    interface FileSystemWritableFileStream extends WritableStream {
        write(data: BufferSource | Blob | string): Promise<void>;

        seek(position: number): Promise<void>;

        truncate(size: number): Promise<void>;
    }

    interface FileSystemFileHandle {
        createWritable(options?: FileSystemCreateWritableOptions): Promise<FileSystemWritableFileStream>;
    }

    interface FileSystemCreateWritableOptions {
        keepExistingData?: boolean;
    }

    interface Window {
        showSaveFilePicker?: (options?: {
            suggestedName?: string;
            types?: Array<{
                description: string;
                accept: Record<string, string[]>;
            }>;
        }) => Promise<FileSystemFileHandle>;
    }
}

export interface ExtractedImage {
    id: number;
    data: string;
}

interface ShowPdfProps {
    embedded: boolean;
    file: {
        metadata: {
            container_name: string;
            blob_name: string;
        };
        fileName: string;
    };
    selectedGoal: string;
    selectedTemplate: string;
    onClose: () => void;
    onResponse: (request: string) => void;
}

const ShowPdf: React.FC<ShowPdfProps> = ({
                                             embedded,
                                             file,
                                             selectedGoal,
                                             selectedTemplate,
                                             onClose,
                                             onResponse
                                         }) => {
    // State declarations
    const [pdfUrl, setPdfUrl] = useState<string>('');
    const [pdfRequest, setPdfRequest] = useState<string>('');
    const [numPages, setNumPages] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [textToSave, setTextToSave] = useState<string>('');
    const [showSave, setShowSave] = useState<boolean>(false);
    const [showGoToPage, setShowGoToPage] = useState<boolean>(false);
    const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const dispatch = useDispatch();
    const selectedWorkspace = useSelector((state: RootState) => state.data.selectedWorkspace);
    const parameter_select_page_by_page = useSelector((state: RootState) => state.data.parameter_select_page_by_page);
    const app_selected_pages = useSelector((state: RootState) => state.data.selectedPages);
    const selectedFileUrl = useSelector((state: RootState) => state.data.selectedFileUrl);

    const [selectedPagesInCurrentFile, setSelectedPagesInCurrentFile] = useState<number[]>(app_selected_pages || []);
    const [viewMode, setViewMode] = useState<'modal' | 'embedded'>('embedded');

    const [extractingImage, setExtractingImage] = useState(false);
    const [extractedImages, setExtractedImages] = useState<ExtractedImage[]>([]);
    const [showImageDialog, setShowImageDialog] = useState(false);

    const [showImageCarousel, setShowImageCarousel] = useState(false);

    const [extractPageData, setExtractPageData] = useState(false);
    const [pages, setPages] = useState<string[]>([]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      return () => {
        if (pdfUrl) URL.revokeObjectURL(pdfUrl);
        revokeImageUrls();
      };
    }, []);


    useEffect(() => {
        dispatch(setCurrentPage(pageNumber));
    }, [pageNumber]);

    useEffect(() => {
        let isMounted = true;
        let newUrl: string | null = null;

        const fetchAndSetPdf = async () => {
            try {
                logger.info('ShowPDF: ', file);
                const blob = await fetch_document_as_blob(file.metadata.container_name, file.metadata.blob_name);

                if (!isMounted) return;

                // Remove old opened file
                if (selectedFileUrl) {
                    URL.revokeObjectURL(selectedFileUrl);
                }

                newUrl = URL.createObjectURL(blob);
                setPdfUrl(newUrl);
                setPageNumber(1);
                dispatch(setSelectedPages([]));
                dispatch(setSelectedFileUrl(newUrl));
                logger.info('URL: ', newUrl);
            } catch (error: any) {
                logger.error('Error fetching PDF:', error);
            }
        };

        fetchAndSetPdf().then();

        return () => {
            isMounted = false;
            // Cleanup the new URL if the component unmounts before the fetch completes
            if (newUrl) {
                URL.revokeObjectURL(newUrl);
            }
        };
    }, [file, dispatch]);

    const convertPageToImage = useCallback(async (): Promise<void> => {
        revokeImageUrls();
        setExtractingImage(true);
        try {
            const pdf = await pdfjs.getDocument(pdfUrl).promise;
            const page = await pdf.getPage(pageNumber);
            const scale = 1.5;
            const viewport = page.getViewport({scale});
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            if (!context) {
                throw new Error('Unable to create canvas context');
            }
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            await page.render({canvasContext: context, viewport}).promise;

            const blob = await new Promise<Blob | null>(resolve => canvas.toBlob(resolve, 'image/png'));
            if (blob) {
                const url = URL.createObjectURL(blob);
                const newImage: ExtractedImage = {
                    id: extractedImages.length,
                    data: url
                };
                setExtractedImages(prevImages => [...prevImages, newImage]);
                setShowImageCarousel(true);
                logger.info('Page converted to image successfully');
            } else {
                throw new Error('Failed to create image blob');
            }

            // Clean up
            canvas.width = 0;
            canvas.height = 0;
        } catch (error: any) {
            logger.error('Error converting page to image:', error);
        } finally {
            setExtractingImage(false);
        }
    }, [pdfUrl, pageNumber, extractedImages.length]);

    const createImageDataFromBitmap = useCallback(async (imageObj: any): Promise<ImageData | null> => {
        const {width, height, bitmap} = imageObj;

        const canvas = new OffscreenCanvas(width, height);
        const ctx = canvas.getContext('2d') as OffscreenCanvasRenderingContext2D | null;

        if (!ctx) {
            logger.error('Failed to create OffscreenCanvas 2d context');
            return null;
        }

        if (bitmap instanceof ImageBitmap) {
            ctx.drawImage(bitmap, 0, 0);
            bitmap.close(); // Release the ImageBitmap
        } else {
            const imageData = new ImageData(new Uint8ClampedArray(bitmap), width, height);
            ctx.putImageData(imageData, 0, 0);
        }

        const imageData = ctx.getImageData(0, 0, width, height);
        canvas.width = 0; // Release canvas memory
        canvas.height = 0;
        return imageData;
    }, []);

    const extractImagesFromPdf = useCallback(async (): Promise<void> => {
        revokeImageUrls();
        setExtractingImage(true);
        const newExtractedImages: ExtractedImage[] = [];
        try {
            const pdf = await pdfjs.getDocument(pdfUrl).promise;
            const page = await pdf.getPage(pageNumber);
            const operatorList = await page.getOperatorList();

            for (let i = 0; i < operatorList.fnArray.length; i++) {
                if (operatorList.fnArray[i] === pdfjs.OPS.paintImageXObject) {
                    const imageArgs = operatorList.argsArray[i];
                    const imageName = imageArgs[0];
                    if (typeof imageName === 'string') {
                        try {
                            const imageObj = await page.objs.get(imageName);
                            logger.info(`Processing image object: ${imageName}`, imageObj);

                            if (imageObj) {
                                let imgData: ImageData | null = null;

                                if ('bitmap' in imageObj && imageObj.bitmap) {
                                    imgData = await createImageDataFromBitmap(imageObj);
                                } else {
                                    logger.info(`Unexpected image object format for ${imageName}:`, imageObj);
                                    continue;
                                }

                                if (imgData) {
                                    const canvas = document.createElement('canvas');
                                    canvas.width = imgData.width;
                                    canvas.height = imgData.height;
                                    const ctx = canvas.getContext('2d');
                                    if (ctx) {
                                        ctx.putImageData(imgData, 0, 0);
                                        const blob = await new Promise<Blob | null>(resolve => canvas.toBlob(resolve));
                                        if (blob) {
                                            const url = URL.createObjectURL(blob);
                                            newExtractedImages.push({
                                                id: newExtractedImages.length,
                                                data: url
                                            });
                                        }
                                    }
                                    canvas.width = 0; // Release canvas memory
                                    canvas.height = 0;
                                }
                            }
                        } catch (error: any) {
                            logger.error(`Error processing image ${imageName}:`, error);
                        }
                    }
                }
            }

            setExtractedImages(newExtractedImages);
            if (newExtractedImages.length > 0) {
                setShowImageCarousel(true);
            }
            logger.info(`Extracted ${newExtractedImages.length} images`);
        } catch (error: any) {
            logger.error('Error extracting images:', error);
        } finally {
            setExtractingImage(false);
        }
    }, [pdfUrl, pageNumber, createImageDataFromBitmap]);

    // Cleanup function
    // useEffect(() => {
    //     return () => {
    //         // Revoke all object URLs when component unmounts
    //         extractedImages.forEach(img => {
    //             if (img.data.startsWith('blob:')) {
    //                 URL.revokeObjectURL(img.data);
    //             }
    //         });
    //     };
    // }, [extractedImages]);

    const revokeImageUrls = () => {
        extractedImages.forEach(img => {
            if (img.data.startsWith('blob:')) {
                URL.revokeObjectURL(img.data);
            }
        });
        setExtractedImages([]);
    }

    const handleExtractComplete = ( ) => {
        setExtractPageData(false);
        setPages([]);
    }

    const handleSaveImage = async (imageData: string, index: number) => {
        if (window.showSaveFilePicker) {
            try {
                const handle = await window.showSaveFilePicker({
                    suggestedName: `extracted_image_${index}.png`,
                    types: [{
                        description: 'PNG Files',
                        accept: {'image/png': ['.png']},
                    }],
                });
                const writable = await handle.createWritable();
                const blob = await (await fetch(imageData)).blob();
                await writable.write(blob);
                await writable.close();
                logger.info(`Image ${index} saved successfully`);
            } catch (err: any) {
                logger.error(`Error saving image ${index}:`, err);
            }
        } else {
            // Fallback for browsers that don't support showSaveFilePicker
            const link = document.createElement('a');
            link.href = imageData;
            link.download = `extracted_image_${index}.png`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            logger.info(`Image ${index} download initiated`);
        }
    };


    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleResetSelection = useCallback(() => {
        dispatch(setSelectedPages([]));
        setSelectedPagesInCurrentFile([]);
    }, [dispatch]);

    const handleSaveToWorkspace = useCallback(async () => {
        const selectedText = await getSelectedPagesText(pdfUrl, selectedPagesInCurrentFile, file.fileName);
        setTextToSave(selectedText);
        setShowSave(true);
    }, [file.fileName, pdfUrl, selectedPagesInCurrentFile]);

    const handleSelectAllPages = useCallback(() => {
        if (numPages) {
            const allPages = Array.from({length: numPages}, (_, i) => i + 1);
            setSelectedPagesInCurrentFile(allPages);
            // dispatch(setSelectedPages(allPages));
        }
    }, [numPages]);

    const handleGotoPage = useCallback(() => {
        setShowGoToPage(true);
    }, []);

    const handleChangeSelectMode = useCallback(() => {
        dispatch(setParameterSelectPageByPage(!parameter_select_page_by_page));
    }, [dispatch, parameter_select_page_by_page]);

    const menuItems = [
        {label: 'Goto Page', action: handleGotoPage},
        {label: 'Save to workspace', action: handleSaveToWorkspace},
        {label: 'Select All Pages', action: handleSelectAllPages},
        {
            label: parameter_select_page_by_page ? 'Select mode = Page per Page' : 'Select mode = From/To',
            action: handleChangeSelectMode
        },
        {label: 'Reset selection', action: handleResetSelection},
    ];

const handleSelectExtractDetails = useCallback(async () => {
        setIsLoading(true);
        try {
            const selectedTexts: string[] = [];

            // Use Promise.all to wait for all async operations to complete
            await Promise.all(selectedPagesInCurrentFile.map(async (page) => {
                try {
                    const text = await getSelectedPagesText(pdfUrl, [page], file.fileName);
                    selectedTexts.push(text);
                } catch (error: any) {
                    logger.error(`Error getting text for page ${page}:`, error);
                    // Optionally, push an empty string or error message to maintain array length
                    selectedTexts.push(`Error extracting text from page ${page}`);
                }
            }));

            setPages(selectedTexts);
            setExtractPageData(true);
            logger.info(`Extracted text from ${selectedTexts.length} pages`);
        } catch (error: any) {
            logger.error("Error in handleSelectExtractDetails:", error);
            // Handle the error appropriately, maybe set an error state
        } finally {
            setIsLoading(false);
        }
    }, [selectedPagesInCurrentFile, pdfUrl, file.fileName]);

    const handleSendRequest = useCallback(async () => {
        if (pdfRequest.trim() === '' && selectedGoal.trim() === '') return;

        const myRequest = `${pdfRequest.trim()}\n${selectedGoal.trim()}`;
        setShowPleaseWait(true);

        try {
            const contextData = await getSelectedPagesText(pdfUrl, selectedPagesInCurrentFile, file.fileName);
            const instructionToRun = `${myRequest}\n${selectedTemplate ? `<TEMPLATE>${selectedTemplate}</TEMPLATE>\n` : ''}<CONTEXT>\`\`\`${contextData}\`\`\`</CONTEXT>`;
            onResponse(instructionToRun);
        } finally {
            setShowPleaseWait(false);
        }
    }, [pdfRequest, selectedGoal, selectedTemplate, pdfUrl, selectedPagesInCurrentFile, file.fileName, onResponse]);

    useEffect(() => {
        dispatch(setSelectedPages(selectedPagesInCurrentFile));
    }, [dispatch, selectedPagesInCurrentFile]);

    const handleSelectPage = useCallback(() => {

        if (parameter_select_page_by_page) {
            let updated_selectedPages = [...selectedPagesInCurrentFile];
            if (updated_selectedPages.includes(pageNumber)) {
                updated_selectedPages = updated_selectedPages.filter(p => p !== pageNumber);
            } else {
                updated_selectedPages.push(pageNumber);
            }
            setSelectedPagesInCurrentFile(updated_selectedPages);
        } else {
            const firstPageSelected = selectedPagesInCurrentFile[0] || pageNumber;
            const pages = Array.from(
                {length: Math.abs(pageNumber - firstPageSelected) + 1},
                (_, i) => Math.min(firstPageSelected, pageNumber) + i
            );
            setSelectedPagesInCurrentFile(pages);
        }
    }, [parameter_select_page_by_page, pageNumber, selectedPagesInCurrentFile]);

    const handleClose = () => {
        if (pdfUrl) {
            URL.revokeObjectURL(pdfUrl);
        }

        dispatch(setSelectedFile(undefined));
        dispatch(setSelectedPages([]));
        dispatch(setCurrentPage(undefined));
        onClose();
    }

    return (
        <ComponentModalOrEmbedded onClose={handleClose} title={"Pdf viewer"} mode={viewMode}
                                  maxWidth={appSettings.maxWidth * 2} onModeChange={setViewMode}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                position: 'relative',
            }}>
                {pdfUrl && (
                    <div style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        position: 'relative',
                    }}>
                        <Document
                            file={pdfUrl}
                            onLoadSuccess={({numPages}) => setNumPages(numPages)}
                            onLoadError={(error) => logger.error('Error loading PDF:', error)}
                        >
                            <div style={{
                                border: selectedPagesInCurrentFile.includes(pageNumber) ? '2px solid red' : 'none'
                            }}>
                                <Page
                                    pageNumber={pageNumber}
                                    renderTextLayer
                                    onClick={handleSelectPage}
                                    renderAnnotationLayer={false}
                                    height={700}
                                    width={viewMode === 'embedded' ? appSettings.maxWidth : appSettings.maxWidth * 1.5}
                                />
                            </div>
                        </Document>

                        <div style={{margin: '10px'}}>
                            <ButtonGroup>
                                <Tooltip title="Convert page to image">
                                    <Button onClick={convertPageToImage} disabled={extractingImage}>
                                        <PhotoCameraOutlinedIcon/>
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Extract images from current page">
                                    <Button onClick={extractImagesFromPdf} disabled={extractingImage}>
                                        <ImageOutlinedIcon/>
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Goto Page / Show Index">
                                    <Button onClick={handleGotoPage}><ListOutlinedIcon/></Button>
                                </Tooltip>
                                <Tooltip title="Save selected pages to workspace">
                                    <Button onClick={handleSaveToWorkspace}><SaveOutlinedIcon/></Button>
                                </Tooltip>
                                <Tooltip title="Extract Details">
                                    <button
                                        onClick={handleSelectExtractDetails}
                                        disabled={isLoading || selectedPagesInCurrentFile.length === 0}
                                    >
                                        <TransformIcon/>
                                    </button>
                                </Tooltip>
                                <Tooltip title="Reset selected pages">
                                    <Button onClick={handleResetSelection}><RestartAltOutlinedIcon/></Button>
                                </Tooltip>
                                <Tooltip
                                    title={parameter_select_page_by_page ? "Switch to range" : "Switch to page by page"}>
                                    <Button onClick={handleChangeSelectMode}>
                                        {parameter_select_page_by_page ? <LayersClearOutlinedIcon/> :
                                            <LayersOutlinedIcon/>}
                                    </Button>
                                </Tooltip>
                            </ButtonGroup>
                        </div>


                        {/*<div className="page-controls" style={{*/}
                        {/*    position: 'absolute',*/}
                        {/*    bottom: '10px',*/}
                        {/*    left: '50%',*/}
                        {/*    transform: 'translateX(-50%)',*/}
                        {/*    display: 'flex',*/}
                        {/*    alignItems: 'center',*/}
                        {/*    background: 'rgba(255, 255, 255, 0.8)',*/}
                        {/*    padding: '5px',*/}
                        {/*    borderRadius: '5px',*/}
                        {/*    zIndex: 10,*/}
                        {/*}}>*/}
                        <div className="page-controls" style={{
                            bottom: '10px',
                            left: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            background: 'rgba(255, 255, 255, 0.8)',
                            padding: '5px',
                            borderRadius: '5px',
                            zIndex: 10,
                        }}>
                            <IconButton
                                onClick={() => setPageNumber(prev => Math.max(prev - 1, 1))}
                                disabled={pageNumber <= 1}
                            >
                                <ArrowLeft/>
                            </IconButton>
                            <span style={{margin: '0 10px'}}>Page {pageNumber} of {numPages}</span>
                            <IconButton
                                onClick={() => setPageNumber(prev => Math.min(prev + 1, numPages))}
                                disabled={pageNumber >= numPages}
                            >
                                <ArrowRightIcon/>
                            </IconButton>

                            {viewMode === 'modal' && (
                                <>
                                    <IconButton
                                        sx={{padding: '5px'}}
                                        onClick={handleMenuOpen}
                                    >
                                        <MoreVertIcon/>
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleMenuClose}
                                        sx={{zIndex: 9999}}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        {menuItems.map((item, index) => (
                                            <MenuItem
                                                key={`menu-item-${index}`}
                                                onClick={() => {
                                                    item.action();
                                                    handleMenuClose();
                                                }}
                                            >
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Menu>

                                </>
                            )}
                        </div>
                    </div>
                )}

                {extractPageData && (
                    <ExtractPDFDetails pages={pages} onExtractComplete={handleExtractComplete}/>
                )}

                {(viewMode === 'modal' && selectedGoal) && (
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px 20px',
                        marginTop: '10px',
                        backgroundColor: colorSchema.background.default,
                        borderRadius: '8px',
                        width: '100%',
                        boxSizing: 'border-box',
                    }}>
                        <Tooltip title={selectedGoal} placement="top">
                            <Typography variant="body1" style={{
                                color: colorSchema.primary.main,
                                flex: 1,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                                {selectedGoal}
                            </Typography>
                        </Tooltip>
                    </div>
                )}

                {/*{viewMode === 'modal' && (*/}
                {/*    <div style={{*/}
                {/*        display: 'flex',*/}
                {/*        justifyContent: 'center',*/}
                {/*        flex: 1,*/}
                {/*        marginTop: '10px',*/}
                {/*        width: '100%',*/}
                {/*    }}>*/}
                {/*        <div style={{*/}
                {/*            display: 'flex',*/}
                {/*            alignItems: 'center',*/}
                {/*            padding: '10px 20px',*/}
                {/*            width: '100%',*/}
                {/*            backgroundColor: colorSchema.background.default,*/}
                {/*            borderRadius: '8px',*/}
                {/*        }}>*/}
                {/*            <Input*/}
                {/*                placeholder="Type a message..."*/}
                {/*                multiline*/}
                {/*                maxRows={4}*/}
                {/*                fullWidth*/}
                {/*                value={pdfRequest}*/}
                {/*                onChange={(e) => setPdfRequest(e.target.value)}*/}
                {/*                onKeyDown={(e) => {*/}
                {/*                    if (e.key === 'Enter' && !e.shiftKey) {*/}
                {/*                        e.preventDefault();*/}
                {/*                        handleSendRequest().then();*/}
                {/*                    }*/}
                {/*                }}*/}
                {/*                sx={{mr: 1}}*/}
                {/*                disabled={showPleaseWait}*/}
                {/*                aria-label="Message input"*/}
                {/*            />*/}
                {/*            <Button*/}
                {/*                variant="contained"*/}
                {/*                size="small"*/}
                {/*                sx={{backgroundColor: colorSchema.primary.main}}*/}
                {/*                endIcon={<SendIcon/>}*/}
                {/*                onClick={handleSendRequest}*/}
                {/*                disabled={showPleaseWait}*/}
                {/*                aria-label="Send message"*/}
                {/*            >*/}
                {/*                Send*/}
                {/*            </Button>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*)}*/}

                <Dialog open={showImageDialog} onClose={() => setShowImageDialog(false)} maxWidth="md" fullWidth>
                    <DialogTitle>Extracted Images</DialogTitle>
                    <DialogContent>
                        {extractedImages.map((img, index) => (
                            <div key={img.id} style={{marginBottom: '20px'}}>
                                <img src={img.data} alt={`Extracted image ${index}`} style={{maxWidth: '100%'}}/>
                                <Button onClick={() => handleSaveImage(img.data, index)}>Save Image {index + 1}</Button>
                            </div>
                        ))}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowImageDialog(false)}>Close</Button>
                    </DialogActions>
                </Dialog>

                {showPleaseWait && (
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1000,
                    }}>
                        <CircularProgress aria-label="Loading"/>
                    </div>
                )}

                {showSave && selectedWorkspace && (
                    <SaveToWorkspace
                        context_data={textToSave}
                        onCancel={() => setShowSave(false)}
                        workspace={selectedWorkspace}
                        workspaceFolder={selectedWorkspace.folder_list[0]?.name || ""}
                        type="html"
                        onSubmit={() => setShowSave(false)}
                    />
                )}

                {showGoToPage && (
                    <GoToPageDialog
                        pdfUrl={pdfUrl}
                        numPages={numPages}
                        onClose={() => setShowGoToPage(false)}
                        onGoToPage={(page) => {
                            setPageNumber(page);
                            setShowGoToPage(false);
                        }}
                    />
                )}
            </div>

            {showImageCarousel && (
                <ImageCarousel
                    images={extractedImages}
                    onClose={() => setShowImageCarousel(false)}
                />
            )}

            {extractingImage && (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000,
                }}>
                    <CircularProgress aria-label="Extracting image"/>
                </div>
            )}

        </ComponentModalOrEmbedded>
    );
}
export default ShowPdf;




