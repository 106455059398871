import React, { useState, useCallback, useEffect } from 'react';
import { convertTextToStenoPrompt, runZAIARequest } from "./pdf_utils";
import { appSession } from "../../managers/generalManager";
import logger from "../../utils/logging_services";
import { SaveToWorkspace } from "../workspace/save_to_workspace";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Paper,
    CircularProgress,
    IconButton,
    Box
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

interface ExtractPDFDetailsProps {
    pages: string[];
    onExtractComplete: () => void;
}

const ExtractPDFDetails: React.FC<ExtractPDFDetailsProps> = ({ pages, onExtractComplete }) => {
    const [extractedResults, setExtractedResults] = useState<Array<{ actionResult: string; internalResult: string }>>([]);
    const [processingPage, setProcessingPage] = useState<number | null>(null);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isReady, setIsReady] = useState<boolean>(false);
    const [textToSave, setTextToSave] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(0);

    const selectedWorkspace = useSelector((state: RootState) => state.data.selectedWorkspace);

    useEffect(() => {
        handleSelectExtractDetails();
    }, [pages]);

    const handleExtractComplete = useCallback((results: Array<{ actionResult: string; internalResult: string }>) => {
        let text = results.map((result, index) =>
            `<h5>Page ${index + 1}</h5><p>${result.actionResult}</p>`
        ).join('\n');

        setTextToSave(text);
        setIsReady(true);
    }, []);

    const handleOnClose = useCallback(() => {
        logger.info("Closing Extract PDF Details dialog");
        setIsReady(false);
        onExtractComplete();
    }, [onExtractComplete]);

    const handleSelectExtractDetails = useCallback(async () => {
        setIsProcessing(true);
        const results: Array<{ actionResult: string; internalResult: string }> = [];

        for (let i = 0; i < pages.length; i++) {
            const page = pages[i];
            setProcessingPage(i + 1);

            try {
                const result = await runZAIARequest(
                    appSession.currentProject,
                    'Act as an expert stenographer and use best practices',
                    convertTextToStenoPrompt,
                    page
                );

                results.push(result);
                setExtractedResults(prevResults => [...prevResults, result]);
            } catch (error: any) {
                logger.error(`Error processing page ${i + 1}:`, error);
                results.push({ actionResult: `Error processing page ${i + 1}`, internalResult: '' });
            }
        }

        setProcessingPage(null);
        setIsProcessing(false);
        handleExtractComplete(results);
    }, [pages, handleExtractComplete]);

    const handlePrevPage = () => {
        setCurrentPage(prev => (prev > 0 ? prev - 1 : prev));
    };

    const handleNextPage = () => {
        setCurrentPage(prev => (prev < extractedResults.length - 1 ? prev + 1 : prev));
    };

    return (
        <Dialog
            open={true}
            onClose={handleOnClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Extract Page Details</DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" alignItems="center">
                    {isProcessing ? (
                        <Box display="flex" flexDirection="column" alignItems="center" my={4}>
                            <CircularProgress size={60} />
                            <Typography variant="body1" mt={2}>
                                Processing page {processingPage} of {pages.length}
                            </Typography>
                        </Box>
                    ) : extractedResults.length > 0 ? (
                        <Paper elevation={3} sx={{ width: '100%', my: 2, overflow: 'hidden' }}>
                            <Box p={3}>
                                <Typography variant="h6" gutterBottom>
                                    Page {currentPage + 1} of {extractedResults.length}
                                </Typography>
                                <div dangerouslySetInnerHTML={{ __html: extractedResults[currentPage].actionResult }}></div>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" bgcolor="grey.100" px={2} py={1}>
                                <IconButton onClick={handlePrevPage} disabled={currentPage === 0}>
                                    <ChevronLeft />
                                </IconButton>
                                <Typography variant="body2">
                                    {currentPage + 1} / {extractedResults.length}
                                </Typography>
                                <IconButton onClick={handleNextPage} disabled={currentPage === extractedResults.length - 1}>
                                    <ChevronRight />
                                </IconButton>
                            </Box>
                        </Paper>
                    ) : null}

                    {(isReady && selectedWorkspace) && (
                        <Box mt={3} width="100%">
                            <SaveToWorkspace
                                context_data={textToSave}
                                onCancel={handleOnClose}
                                workspace={selectedWorkspace}
                                workspaceFolder={selectedWorkspace.folder_list[0]?.name || ""}
                                type="html"
                                onSubmit={handleOnClose}
                            />
                        </Box>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOnClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExtractPDFDetails;