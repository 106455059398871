import React, {useEffect, useState} from 'react';
import {Box, Paper, Typography, List, ListItem, Input, Button} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import {MessageModel} from "@chatscope/chat-ui-kit-react";
import DOMPurify from "dompurify";
import FeedbackOptions from '../chat/feedbackOptions';
import Tooltip from "@mui/material/Tooltip";
import RestartAltTwoToneIcon from "@mui/icons-material/RestartAltTwoTone";
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import {appSettings} from "../../managers/generalManager";
import colorSchema from '../../app_theme/theme_support/colorSchema';

export interface ChatViewerProps {
    conversation: MessageModel[];
    allowUserInput: boolean;
    feedbackIndex: number;
    onExplainData: () => void;
    onSend: (message: string) => void;
    onReset: () => void;
    goalSelected: boolean;
    onSubmittedFeedback: (commentType: string, comment: string) => void;
    onFeedbackOptionRequest: (request: string, request_data: string, messageList: MessageModel[]) => void;
    onMessagesSelected: (messages: MessageModel[]) => void;
}

export const ChatViewer = ({
                               conversation,
                               allowUserInput,
                               feedbackIndex,
                               goalSelected,
                               onExplainData,
                               onSend,
                               onSubmittedFeedback,
                               onReset,
                               onFeedbackOptionRequest,
                               onMessagesSelected,

                           }: ChatViewerProps) => {
    const [newMessage, setNewMessage] = useState<string>('');
    const [allowUserInputState, setAllowUserInputState] = useState<boolean>(allowUserInput);
    const [selectedMessages, setSelectedMessages] = useState<number[]>([]);

    const handleSend = () => {
        if (newMessage.trim() !== '' || goalSelected) {
            onSend(newMessage);
            setNewMessage('');
        }
    };

    useEffect(() => {
        setAllowUserInputState(allowUserInput);
    }, [allowUserInput]);

    useEffect(() => {
        setSelectedMessages([])
        scrollToBottom();
    }, [conversation]);

    const getMessageStyle = (message: MessageModel, index: number) => ({
        borderRadius: 3,
        padding: '6px 16px',
        margin: '5px',
        maxWidth: '70%',
        boxShadow: message.position === 'first' ? 5 : 3,
        border: message.position === 'first' ? '1px solid primary.main' : 'none',
        backgroundColor: selectedMessages.includes(index) ? colorSchema.background.selected : (message.direction === 'outgoing' ? colorSchema.background.default : colorSchema.background.default),
    });

    const createMarkup = (htmlContent: string | undefined) => {

        const config = {
            FORBID_TAGS: ['script', 'style', 'iframe', 'frame', 'object', 'embed'],
            FORBID_ATTR: ['onerror', 'onload', 'onclick', 'onmouseover'],
            ADD_TAGS: ['img'],  // Ensure img tags are allowed
            ADD_ATTR: ['src'],  // Ensure src attributes are allowed
            ALLOW_DATA_ATTR: true,  // Allow data-* attributes
            ALLOW_UNKNOWN_PROTOCOLS: true,  // Allow URLs with various protocols
        };

        if (htmlContent === undefined || htmlContent === "" || htmlContent.trim() === '<p></p>') {
            return {__html: "<p>...</p>"};
        } else {
             return {__html: DOMPurify.sanitize(htmlContent, config)};
            // return {__html: DOMPurify.sanitize(htmlContent, config)};
        }
    };

    function scrollToBottom() {
        const messageboxContainer = document.getElementById("messagebox");
        if (messageboxContainer === null) return;
        messageboxContainer.scrollTop = messageboxContainer.scrollHeight;
    }

    function handleSelectMessage(index: number) {

        let newSelectedMessages: number[] = [];

        // !!!! TO BE VALIDATE CAUSE IF THE USER USES THE FEEDBACK MENU, THAT MESSAGE IS NOT ADDED TO MESSAGES. !!!!!!!
        // select message request and response.
        // if (selectedMessages.includes(index)) {
        //     newSelectedMessages = selectedMessages.filter((item) => item !== index && item !== index - 1);
        // } else {
        //     newSelectedMessages = [...selectedMessages, index, index - 1];
        // }

        if (selectedMessages.includes(index)) {
            // remove index from array
            newSelectedMessages = selectedMessages.filter((item) => item !== index);

            // setSelectedMessages(selectedMessages.filter((item) => item !== index));
        } else {
            // add index to array
            newSelectedMessages = [...selectedMessages, index];
        }

        setSelectedMessages(prevState => newSelectedMessages);
        onMessagesSelected(conversation.filter((msg, i) => newSelectedMessages.includes(i)));
    }

    function handleReset() {
        setSelectedMessages([])
        onReset();
    }

    function obtainSelectedMessage(message: MessageModel) {
        // return all selected messages + the last message if not already selected
        if (selectedMessages.length > 0) {
            // return all selected messages based on numeric order,  so 1 before 2, ...
            const sorted_message = selectedMessages.sort((a, b) => a - b);
            return sorted_message.map((index) => conversation[index]);
        } else {
            return [message];
        }
    }

    return (

        <>
            {/*------ Header zone with buttons, icons ------*/}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    padding: "10px",
                    backgroundColor: `${appSettings.backgroundColor ? appSettings.backgroundColor : colorSchema.background.default}`,
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                }}>

                <div style={{flexDirection: 'row'}}>
                    <Tooltip title={"Explain Data"}>
                        <EmojiObjectsOutlinedIcon
                            sx={{color: colorSchema.primary.main, marginLeft: "3px"}}
                            onClick={() => onExplainData()}/>
                    </Tooltip>
                    <Tooltip title={"Restart chat"}>
                        <RestartAltTwoToneIcon
                            sx={{color: colorSchema.primary.main, marginLeft: "3px"}}
                            onClick={() => handleReset()}/>
                    </Tooltip>
                </div>
            </div>

            {/*------ Chat-window and dialog input-field -------*/}
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                justifyContent: 'center',
                // backgroundColor: colorSchema.app_background,
                background: `linear-gradient(to bottom, ${appSettings.backgroundColor ? appSettings.backgroundColor : colorSchema.background.default}, ${colorSchema.background.default})`,
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
            }}>
                {/*------ Chat-window -------*/}
                <div id="messagebox" style={{
                    display: 'flex',
                    flex: '1 1 0px',
                    width: '100%',
                    overflowY: 'auto',
                    maxWidth: '1200px',
                    alignSelf: 'center',
                    marginBottom: '20px',
                }}>
                    <List style={{display: 'flex', flexDirection: 'column', flex: '1 1 0px', zIndex: 2}}>
                        {conversation.map((msg, index) => (
                            msg.direction !== 'outgoing' ? null : (
                                <div key={index + "_item"}>
                                    <ListItem
                                        key={index + "_message"}
                                        onClick={() => handleSelectMessage(index)}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: msg.direction === 'outgoing' ? 'flex-end' : 'flex-start',
                                        }}>
                                        <Paper elevation={3} sx={getMessageStyle(msg, index)}
                                               key={index + "_message_bubble"}>
                                            {msg.position === 'first' ? (
                                                <Typography variant="body1"
                                                            sx={{color: msg.direction === 'outgoing' ? colorSchema.text.primary : colorSchema.text.secondary}}>
                                                    {msg.message}
                                                </Typography>
                                            ) : msg.type === 'html' ? (
                                                <Box dangerouslySetInnerHTML={createMarkup(msg.message || '<p></p>')}
                                                     sx={{
                                                         color: msg.direction === 'outgoing' ? colorSchema.text.primary : colorSchema.text.secondary,
                                                     }}/>
                                            ) : (
                                                <Typography variant="body1"
                                                            sx={{
                                                                color: msg.direction === 'outgoing' ? colorSchema.text.primary : colorSchema.text.secondary,
                                                            }}>
                                                    {msg.message}
                                                </Typography>
                                            )}
                                        </Paper>
                                    </ListItem>
                                    {index === conversation.length - 1 && conversation.length > 1 && (
                                        <Box sx={{marginRight: '30px'}}>
                                            <FeedbackOptions onSubmit={onSubmittedFeedback}
                                                             key={index + "_feedback_options"}
                                                             onRequest={(request, request_data) => onFeedbackOptionRequest(
                                                                 request, request_data, obtainSelectedMessage(msg))}
                                            />
                                        </Box>
                                    )}
                                </div>
                            )
                        )).filter(Boolean)}
                    </List>

                </div>


                {/*------ Input field for dialog -------*/}

                {allowUserInputState && (
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        width: '80%',
                        backgroundColor: colorSchema.background.default,
                        alignSelf: 'center', marginBottom: '30px'
                    }} className="border">
                        <Input
                            placeholder="Type a message..."
                            multiline={true}
                            maxRows={4}
                            fullWidth
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            onKeyDown={(e) => {
                                // Check if Enter is pressed without the Shift key
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault(); // Voorkom dat een nieuwe regel wordt gemaakt
                                    handleSend();
                                }
                            }}
                            sx={{mr: 1}}
                        />
                        <Button variant="contained" size={"small"} sx={{backgroundColor: colorSchema.primary.main}}
                                endIcon={<SendIcon/>} onClick={handleSend}/>
                    </div>
                )}
            </div>
        </>
    );
};

// ========================================================================================================

export const ChatViewer2 = ({
                                conversation,
                                allowUserInput,
                                feedbackIndex,
                                goalSelected,
                                onExplainData,
                                onSend,
                                onSubmittedFeedback,
                                onReset,
                                onFeedbackOptionRequest,
                                onMessagesSelected,
                            }: ChatViewerProps) => {
    const [newMessage, setNewMessage] = useState<string>('');
    const [allowUserInputState, setAllowUserInputState] = useState<boolean>(allowUserInput);
    const [selectedMessages, setSelectedMessages] = useState<number[]>([]);

    const handleSend = () => {
        if (newMessage.trim() !== '' || goalSelected) {
            onSend(newMessage);
            setNewMessage('');
        }
    };

    useEffect(() => {
        setAllowUserInputState(allowUserInput);
    }, [allowUserInput]);

    useEffect(() => {
        setSelectedMessages([])
        scrollToBottom();
    }, [conversation]);

    function scrollToBottom() {
        const messageboxContainer = document.getElementById("messagebox");
        if (messageboxContainer === null) return;
        messageboxContainer.scrollTop = messageboxContainer.scrollHeight;
    }

    const getMessageStyle = (message: MessageModel, index: number) => ({
        borderRadius: 3,
        padding: '6px 16px',
        margin: '5px',
        maxWidth: '70%',
        boxShadow: message.position === 'first' ? 5 : 3,
        border: message.position === 'first' ? '1px solid primary.main' : 'none',
        backgroundColor: selectedMessages.includes(index)
            ? colorSchema.action.selected
            : (message.direction === 'outgoing' ? colorSchema.background.default : colorSchema.background.default),
        alignSelf: message.direction === 'outgoing' ? 'flex-end' : 'flex-start',
    });

    function handleSelectMessage(index: number) {

        let newSelectedMessages: number[] = [];
        if (selectedMessages.includes(index)) {
            newSelectedMessages = selectedMessages.filter((item) => item !== index);
        } else {
            newSelectedMessages = [...selectedMessages, index];
        }

        setSelectedMessages(prevState => newSelectedMessages);
        onMessagesSelected(conversation.filter((msg, i) => newSelectedMessages.includes(i)));
    }

    const createMarkup = (htmlContent: string | undefined) => {

        if (htmlContent === undefined || htmlContent === "" || htmlContent.trim() === '<p></p>') {
            return {__html: "<p>...</p>"};
        } else {
            return {__html: DOMPurify.sanitize(htmlContent)};
        }
    };

        function handleReset() {
        setSelectedMessages([])
        onReset();
    }


    function obtainSelectedMessage(message: MessageModel) {
        // return all selected messages + the last message if not already selected
        if (selectedMessages.length > 0) {
            // return all selected messages based on numeric order,  so 1 before 2, ...
            const sorted_message = selectedMessages.sort((a, b) => a - b);
            return sorted_message.map((index) => conversation[index]);
        } else {
            return [message];
        }
    }

    return (
        <>
            {/*------ Header zone with buttons, icons ------*/}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    padding: "10px",
                    backgroundColor: `${appSettings.backgroundColor ? appSettings.backgroundColor : colorSchema.background.default}`,
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                }}>

                <div style={{flexDirection: 'row'}}>
                    <Tooltip title={"Explain Data"}>
                        <EmojiObjectsOutlinedIcon
                            sx={{color: colorSchema.primary.main, marginLeft: "3px"}}
                            onClick={() => onExplainData()}/>
                    </Tooltip>
                    <Tooltip title={"Restart chat"}>
                        <RestartAltTwoToneIcon
                            sx={{color: colorSchema.primary.main, marginLeft: "3px"}}
                            onClick={() => handleReset()}/>
                    </Tooltip>
                </div>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                justifyContent: 'center',
                background: `linear-gradient(to bottom, ${appSettings.backgroundColor ? appSettings.backgroundColor : colorSchema.background.default}, ${colorSchema.background.default})`,
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
            }}>
                <div id="messagebox" style={{
                    display: 'flex',
                    flex: '1 1 0px',
                    width: '100%',
                    overflowY: 'auto',
                    maxWidth: '1200px',
                    alignSelf: 'center',
                    marginBottom: '20px',
                }}>
                    {/*<List style={{display: 'flex', flexDirection: 'column', flex: '1 1 0px', zIndex: 2, width: '100%'}}>*/}
                    {/*    {conversation.map((msg, index) => (*/}
                    {/*        <div key={index + "_item"}>*/}
                    {/*            <ListItem*/}
                    {/*                key={index + "_message"}*/}
                    {/*                onClick={() => handleSelectMessage(index)}*/}
                    {/*                sx={{*/}
                    {/*                    display: 'flex',*/}
                    {/*                    justifyContent: msg.direction === 'outgoing' ? 'flex-end' : 'flex-start',*/}
                    {/*                    padding: 0,*/}
                    {/*                }}>*/}
                    {/*                <Paper elevation={3} sx={getMessageStyle(msg, index)}*/}
                    {/*                       key={index + "_message_bubble"}>*/}
                    {/*                    {msg.position === 'first' ? (*/}
                    {/*                        <Typography variant="body1"*/}
                    {/*                                    sx={{color: msg.direction === 'outgoing' ? colorSchema.outgoing_text : colorSchema.incoming_text}}>*/}
                    {/*                            {msg.message}*/}
                    {/*                        </Typography>*/}
                    {/*                    ) : msg.type === 'html' ? (*/}
                    {/*                        <Box dangerouslySetInnerHTML={createMarkup(msg.message || '<p></p>')}*/}
                    {/*                             sx={{*/}
                    {/*                                 color: msg.direction === 'outgoing' ? colorSchema.outgoing_text : colorSchema.incoming_text,*/}
                    {/*                             }}/>*/}
                    {/*                    ) : (*/}
                    {/*                        <Typography variant="body1"*/}
                    {/*                                    sx={{*/}
                    {/*                                        color: msg.direction === 'outgoing' ? colorSchema.outgoing_text : colorSchema.incoming_text,*/}
                    {/*                                    }}>*/}
                    {/*                            {msg.message}*/}
                    {/*                        </Typography>*/}
                    {/*                    )}*/}
                    {/*                </Paper>*/}
                    {/*            </ListItem>*/}
                    {/*            {index === conversation.length - 1 && conversation.length > 1 && msg.direction === 'outgoing' && (*/}
                    {/*                <Box sx={{marginRight: '30px', alignSelf: 'flex-end'}}>*/}
                    {/*                    <FeedbackOptions onSubmit={onSubmittedFeedback}*/}
                    {/*                                     key={index + "_feedback_options"}*/}
                    {/*                                     onRequest={(request, request_data) => onFeedbackOptionRequest(*/}
                    {/*                                         request, request_data, obtainSelectedMessage(msg))}*/}
                    {/*                    />*/}
                    {/*                </Box>*/}
                    {/*            )}*/}
                    {/*        </div>*/}
                    {/*    ))}*/}
                    {/*</List>*/}
                     <List style={{display: 'flex', flexDirection: 'column', flex: '1 1 0px', zIndex: 2}}>
                        {conversation.map((msg, index) => (
                            msg.direction !== 'outgoing' ? null : (
                                <div key={index + "_item"}>
                                    <ListItem
                                        key={index + "_message"}
                                        onClick={() => handleSelectMessage(index)}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: msg.direction === 'outgoing' ? 'flex-end' : 'flex-start',
                                        }}>
                                        <Paper elevation={3} sx={getMessageStyle(msg, index)}
                                               key={index + "_message_bubble"}>
                                            {msg.position === 'first' ? (
                                                <Typography variant="body1"
                                                            sx={{color: msg.direction === 'outgoing' ? colorSchema.text.primary : colorSchema.text.secondary}}>
                                                    {msg.message}
                                                </Typography>
                                            ) : msg.type === 'html' ? (
                                                <Box dangerouslySetInnerHTML={createMarkup(msg.message || '<p></p>')}
                                                     sx={{
                                                         color: msg.direction === 'outgoing' ? colorSchema.text.primary : colorSchema.text.secondary,
                                                     }}/>
                                            ) : (
                                                <Typography variant="body1"
                                                            sx={{
                                                                color: msg.direction === 'outgoing' ? colorSchema.text.primary : colorSchema.text.secondary,
                                                            }}>
                                                    {msg.message}
                                                </Typography>
                                            )}
                                        </Paper>
                                    </ListItem>
                                    {index === conversation.length - 1 && conversation.length > 1 && (
                                        <Box sx={{marginRight: '30px'}}>
                                            <FeedbackOptions onSubmit={onSubmittedFeedback}
                                                             key={index + "_feedback_options"}
                                                             onRequest={(request, request_data) => onFeedbackOptionRequest(
                                                                 request, request_data, obtainSelectedMessage(msg))}
                                            />
                                        </Box>
                                    )}
                                </div>
                            )
                        )).filter(Boolean)}
                    </List>
                </div>

                {/* Input field remains the same */}
                {/*------ Input field for dialog -------*/}

                {allowUserInputState && (
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        width: '80%',
                        backgroundColor: colorSchema.background.default,
                        alignSelf: 'center', marginBottom: '30px'
                    }} className="border">
                        <Input
                            placeholder="Type a message..."
                            multiline={true}
                            maxRows={4}
                            fullWidth
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            onKeyDown={(e) => {
                                // Check if Enter is pressed without the Shift key
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault(); // Voorkom dat een nieuwe regel wordt gemaakt
                                    handleSend();
                                }
                            }}
                            sx={{mr: 1}}
                        />
                        <Button variant="contained" size={"small"} sx={{backgroundColor: colorSchema.primary.main}}
                                endIcon={<SendIcon/>} onClick={handleSend}/>
                    </div>
                )}
            </div>
        </>
    );
};

