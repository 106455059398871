import React, {useState, useMemo, useCallback, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {CssBaseline, Snackbar, ThemeProvider} from "@mui/material";
import {ZAIATheme} from "../app_theme/theme";
// import {AppState} from "../store/old/store";
// import {WorkspaceItem} from "../store/old/temp-slice";
// import {setFollowupQuestions, resetSelectedWorkspaceItems, setNotification} from "../store/old/temp-slice";
// import {SelectedFile} from "../store/old/data-slice";

import {personaDefinitionList, selectedCategory, selectedPersona} from "../managers/generalManager";
import {getPrompt, PromptSections} from "../managers/promptManager";
import {ActiveGoal, AppMainMenu, MenuFeatures} from "./app_menu";
import {PersonaDefinition} from "../managers/personaManager";

import MyTasksManager from "../components/tasks/my_tasks_manager";
import DynamicCsvTable, {DynamicDataItem} from "../components/csvData/csv_table";
import ShowImage from "../components/image/show_image";
import ShowPdf from "../components/pdf/show_pdf";
import {useAppInitialization} from "./initialize_app";
import {useFileSelection} from "./use_file_selection";
import {LinkedInViewer} from "../components/linkedin/linkedin_viewer";
import {AssistantTemplate} from "../components/assisant/assistant_template";
import {AutoRequest, Chat} from "../components/chat/chat";
import {LogIn} from "../components/authorisation/LoginComponent";
import Config from "../components/config/config";
import PleaseWaitDialog from "../components/general/please_wait";
import ShowAudio from "../components/audio/show_audio";
import logger from "../utils/logging_services";
import FileWorkspaceManager from "./components/file_workspace_manager";
import {Helmet} from "react-helmet";
import useDynamicFontSize from "../app_theme/useDynamicFontSize";
import {useItemSelection} from "./use_item_selection";
import HTMLViewer from "../components/html/html_viewer";
import {DrawMermaid} from "../components/mermaid/draw_mermaid";
import {RootState} from "../store/store";
import {SelectedFile, WorkspaceItem} from "../store/types";
import {setFollowupQuestions, setNotification} from "../store/uiSlice";
import {resetSelectedWorkspaceItems} from "../store/workspaceItemSlice";
import {setSelectedCSVRecords} from "../store/itemSlice";
import {folder} from "jszip";

const App: React.FC = () => {
    const {isLoaded, showLogin, projectId} = useAppInitialization();
    const fileSelection = useFileSelection();
    const itemSelection = useItemSelection();

    const [showAssistentMenu, setShowAssistentMenu] = useState<boolean>(false);
    const [showStopStreaming, setShowStopStreaming] = useState<boolean>(false);
    const [autoRequest, setAutoRequest] = useState<AutoRequest>(({request: "", template: "", persona: ""}));
    const [selectedMode, setSelectedMode] = useState<string>(selectedCategory);
    const [selectedMenu] = useState<any>(undefined);
    const [selectedServiceId, setSelectedServiceId] = useState<string>("");
    const [filterList, setFilterList] = useState<any[]>([]);
    const [selectedWorkspaceItem, setSelectedWorkspaceItem] = useState<WorkspaceItem | undefined>(undefined);
    const [persona, setPersona] = useState<PersonaDefinition>(
        personaDefinitionList.find(p => p.name === selectedPersona) || personaDefinitionList[0]
    );
    const [defaultPrompt, setDefaultPrompt] = useState<PromptSections>(getPrompt(selectedCategory, persona));
    const [activeGoal, setActiveGoal] = useState<ActiveGoal>({goal: "", template: "", goalItem: undefined});
    const [showNotification, setShowNotification] = useState<boolean>(false);

    const [appMenuFeature, setAppMenuFeature] = useState<MenuFeatures>({
        stateChanged: false,
        showAssistant: false,
        showFiles: false,
        showSnippet: false,
        showLinkedIn: false,
        showWorkspace: false,
        showTaskList: false,
        showTemplate: false,
        showConfig: false,
        showAppMenu: false,
        showEditor: false,
    });

    useDynamicFontSize();

    const dispatch = useDispatch();
    const current_workspace = useSelector((state: RootState) => state.data.selectedWorkspace);
    const selectedFile = useSelector((state: RootState) => state.data.selectedFile);
    const notification_to_show = useSelector((state: RootState) => state.ui.notification);

    useEffect(() => {
        const handleResize = () => {
        };

        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Clean up - remove the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const loadSelectedFileData = useCallback((file: SelectedFile) => {
        if (file.fileName.endsWith("csv")) {
            if (file.metadata.container_name && file.metadata.blob_name) {
                fileSelection.fetchDocument(file.metadata.container_name, file.metadata.blob_name, false)
                    .then((data) => {
                        fileSelection.setCsvData(data.data);
                        fileSelection.setSelectedCsvRecordList(file.records);
                        fileSelection.setShowCsvTable(true);
                    });
            }
        } else if (file.fileName.endsWith("pdf")) {
            fileSelection.setSelectedPdf(file);
            fileSelection.setShowSelectedPdf(true);
        } else if (fileSelection.isImageFile(file.fileName)) {
            fileSelection.setSelectedImage(file);
            fileSelection.setShowSelectedImage(true);
        } else if (fileSelection.isAudioFile(file.fileName)) {
            fileSelection.setSelectedAudio(file);
            fileSelection.setShowSelectedAudio(true);
        }
    }, [fileSelection]);

    useEffect(() => {

        if (notification_to_show) {
            setShowNotification(true);
        }

    }, [notification_to_show]);


    useEffect(() => {
        if (selectedFile) {
            loadSelectedFileData(selectedFile);
        } else {
            // Reset file selection state when no file is selected
            fileSelection.setSelectedCsvRecordList([]);
            fileSelection.setCsvData("");
            fileSelection.setShowCsvTable(false);
            fileSelection.setSelectedCsvChatContext([]);
            fileSelection.setSelectedImage(undefined);
            fileSelection.setSelectedPdf(undefined);
            fileSelection.setSelectedAudio(undefined);
            fileSelection.setShowSelectedImage(false);
            fileSelection.setShowSelectedPdf(false);
            fileSelection.setShowSelectedAudio(false);
        }
    }, [selectedFile]);


    const showUserInput = useMemo(() => {
        const goalIsTemplate = activeGoal.goalItem !== undefined && activeGoal.goalItem?.form !== undefined && activeGoal.goalItem?.form !== "";
        return !(goalIsTemplate || selectedServiceId !== "");
    }, [activeGoal, selectedServiceId]);

    const changeAppMenuFeature = useCallback((menuFeatures: Partial<MenuFeatures>) => {
        setAppMenuFeature(prev => ({...prev, ...menuFeatures, stateChanged: true}));
    }, []);

    const updateAppMenuFeature = useCallback((menuFeatures: MenuFeatures) => {
        setAppMenuFeature({...menuFeatures, stateChanged: false});
    }, []);

    const handleLoginSuccess = useCallback(() => {
        // setAccessToken(localStorage.getItem('BoostrFoundation_AccessToken'));
        window.location.reload();
    }, []);

    const handleCloseAssistantTemplate = useCallback((trigger: string) => {
        changeAppMenuFeature({showTemplate: false, showTaskList: false, showAssistant: false});
        setSelectedServiceId("");
        dispatch(setFollowupQuestions([]));

        if (activeGoal.goalItem?.removePreselectedWorkspaceItemsOnClose) {
            dispatch(resetSelectedWorkspaceItems());
        }
    }, [changeAppMenuFeature, activeGoal.goalItem, dispatch]);

    const handleRequestSubmit = useCallback((request: string, template: string = "", personsa: string = "") => {
        const newAutoRequest = {
            request: `${request}?${Math.random()}{language}`,
            template: template,
            persona: personsa,
        };
        setAutoRequest(newAutoRequest);
    }, []);

    const handleShowWorkspaceItem = useCallback((workspaceItem: WorkspaceItem) => {
        setSelectedWorkspaceItem(workspaceItem);
    }, []);

    const handleSelectGoal = useCallback((newGoal: ActiveGoal) => {
        setActiveGoal(newGoal);
    }, []);

    const handleChangeKbCategory = useCallback((category: string) => {
        setSelectedMode(category);
    }, []);

    const handleStreamingState = useCallback((state: boolean) => {
        setShowStopStreaming(state);
        if (!state) {
            logger.info("Streaming stopped, reset auto request");
            const newAutoRequest = {
                request: "",
                template: "",
                persona: "",
            };
            setAutoRequest(newAutoRequest);
        }
    }, []);

    return (

        <>
            <Helmet>
                <link
                    href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&family=Roboto+Mono&display=swap"
                    rel="stylesheet"
                />
            </Helmet>

            <ThemeProvider theme={ZAIATheme}>
                <CssBaseline>
                    <div className="container_top">
                        <div style={{display: "flex", flex: showAssistentMenu ? 2 : 1}}>
                            {showLogin && (
                                <LogIn
                                    isOpen={true}
                                    onCancel={() => {
                                    }}
                                    onSucces={handleLoginSuccess}
                                />
                            )}

                            {!isLoaded && (
                                <PleaseWaitDialog open={true} onCancel={() => {
                                }} title={"Welcome, i am ZAIA"} message={"Please wait. I am loading the application"}/>
                            )}

                            {(showNotification && notification_to_show) && (

                                <Snackbar
                                    open={showNotification}
                                    color={notification_to_show.type === 'info' ? "primary" : "error"}
                                    onClose={() => {
                                        dispatch(setNotification(undefined));
                                        setShowNotification(false);
                                    }}
                                    autoHideDuration={3000}
                                    message={notification_to_show.message}
                                />
                            )}

                            {isLoaded && (
                                <>
                                    {appMenuFeature.showConfig && (
                                        <Config
                                            isOpen={true}
                                            onClose={() => changeAppMenuFeature({showConfig: false})}
                                        />
                                    )}

                                        <div style={{
                                            display: 'flex',
                                            marginBottom: '10px',
                                            flexDirection: 'row',
                                            width: '100%',
                                        }}>
                                            <AppMainMenu
                                                isLoaded={isLoaded}
                                                isStreaming={showStopStreaming}
                                                onChangeKbCategory={handleChangeKbCategory}
                                                onChangeKbFilter={setFilterList}
                                                onChangeDefaultPrompt={setDefaultPrompt}
                                                menuFeatures={appMenuFeature}
                                                onGoalChanged={handleSelectGoal}
                                                onMenuFeaturesChanged={updateAppMenuFeature}
                                                onPersonaChanged={setPersona}
                                            />

                                            {/* RIGHT SIDE */}
                                            <div style={{display: 'flex', flex: 5}}>

                                                {/* TASK VIEW */}
                                                {(activeGoal.goalItem || (appMenuFeature.showAssistant && appMenuFeature.showTemplate && selectedServiceId)) && (
                                                    <AssistantTemplate
                                                        onError={(message: string) => {/* TODO: Implement error handling */
                                                        }}
                                                        onClose={handleCloseAssistantTemplate}
                                                        onHandleRequest={handleRequestSubmit}
                                                        selected_mode={selectedMode}
                                                        selected_menu={selectedMenu}
                                                        selected_service_id={selectedServiceId}
                                                        selected_goal_item={activeGoal.goalItem}
                                                    />
                                                )}

                                                {appMenuFeature.showTaskList && (
                                                    <MyTasksManager
                                                        requestId={'all'}
                                                        handleRequestSubmit={handleRequestSubmit}
                                                        onClose={() => changeAppMenuFeature({showTaskList: false})}
                                                    />
                                                )}


                                                {appMenuFeature.showLinkedIn && (
                                                    <LinkedInViewer
                                                        open={appMenuFeature.showLinkedIn}
                                                        onClose={() => changeAppMenuFeature({showLinkedIn: false})}
                                                        onShowWorkSpaceItem={handleShowWorkspaceItem}
                                                        onSendRequest={handleRequestSubmit}
                                                    />
                                                )}

                                                {(appMenuFeature.showFiles || appMenuFeature.showWorkspace) && (
                                                    <FileWorkspaceManager onClose={() => {
                                                        changeAppMenuFeature({showFiles: false});
                                                        changeAppMenuFeature({showWorkspace: false});
                                                    }}
                                                                          // defines the visibility of the workspace and file manager
                                                                          showFiles={appMenuFeature.showFiles}
                                                                          showWorkspace={appMenuFeature.showWorkspace}

                                                                          // The workspace and folder (category) to use
                                                                          workspace={current_workspace}
                                                                          selectedCategory={selectedCategory}

                                                                          // Callbacks.
                                                                          onShowWorkSpaceItem={handleShowWorkspaceItem}
                                                                          onFileSelected={fileSelection.handleFileSelected}
                                                                          onSendRequest={handleRequestSubmit}
                                                    />

                                                )}


                                                {/*/!* WORKSPACE VIEW *!/*/}
                                                {/*{appMenuFeature.showWorkspace && (*/}
                                                {/*    <WorkspaceViewer*/}
                                                {/*        open={appMenuFeature.showWorkspace}*/}
                                                {/*        onClose={() => changeAppMenuFeature({showWorkspace: false})}*/}
                                                {/*        onShowWorkSpaceItem={handleShowWorkspaceItem}*/}
                                                {/*        onSendRequest={handleRequestSubmit}*/}
                                                {/*        workspace={current_workspace}*/}
                                                {/*    />*/}
                                                {/*)}*/}


                                                {/*{appMenuFeature.showFiles && (*/}
                                                {/*    <FileNavigator*/}
                                                {/*        selectedCategory={selectedCategory}*/}
                                                {/*        onFileSelected={fileSelection.handleFileSelected}*/}
                                                {/*        onClose={() => changeAppMenuFeature({showFiles: false})}*/}
                                                {/*    />*/}
                                                {/*)}*/}

                                                {fileSelection.showCsvTable && (
                                                    <DynamicCsvTable
                                                        viewMode={"embedded"}
                                                        initialData={fileSelection.csvRecords}
                                                        itemRef={""}
                                                        title={"CSV Data"}
                                                        onClose={() => {
                                                            fileSelection.setShowCsvTable(false);
                                                            fileSelection.setSelectedCsvRecordList([]);
                                                        }}
                                                        onSelectRecord={(record: DynamicDataItem | undefined) => {
                                                            if (record !== undefined) {
                                                                fileSelection.setSelectedCsvRecordList([record.key]);
                                                            }
                                                        }}
                                                        onSelection={(selectedRecords: DynamicDataItem[]) => {
                                                            const selectedKeys = selectedRecords.map(record => record.key);
                                                            fileSelection.setSelectedCsvRecordList(selectedKeys);
                                                        }}
                                                        selectedKeys={fileSelection.selectedCsvRecordList}
                                                    />
                                                )}

                                                {itemSelection.showCsvTable && (
                                                    <DynamicCsvTable
                                                        viewMode={"embedded"}
                                                        initialData={itemSelection.csvRecords}
                                                        itemRef={itemSelection.csvItemRef}
                                                        title={itemSelection.csvFolder}
                                                        onClose={() => {
                                                            itemSelection.setShowCsvTable(false);
                                                            itemSelection.setSelectedCsvRecordList([]);
                                                        }}
                                                        onSelectRecord={(record: DynamicDataItem | undefined) => {
                                                            if (record !== undefined) {
                                                                itemSelection.setSelectedCsvRecordList([record.key]);
                                                            }
                                                        }}

                                                        onSelection={(selectedRecords: DynamicDataItem[]) => {
                                                            const selectedKeys = selectedRecords.map(record => record.key);
                                                            itemSelection.setSelectedCsvRecordList(selectedKeys);
                                                        }}
                                                        selectedKeys={itemSelection.selectedCsvRecordList}
                                                    />
                                                )}

                                                {itemSelection.showHtml && (
                                                    <HTMLViewer htmlContent={itemSelection.htmlData}
                                                                itemRef={itemSelection.htmlItemRef}
                                                                title={itemSelection.htmlFolder}
                                                                onClose={() => {
                                                                    itemSelection.setHtmlData("");
                                                                    itemSelection.setShowHtml(false);
                                                                }}/>
                                                )}

                                                {itemSelection.showMermaid && (
                                                    <DrawMermaid markup={itemSelection.mermaidMarkup}
                                                                 onClose={ () => {
                                                                        itemSelection.setMermaidMarkup("");
                                                                        itemSelection.setShowMermaid(false);
                                                                 }}/>
                                                )}

                                                {fileSelection.showSelectedImage && fileSelection.selectedImage && (
                                                    <ShowImage
                                                        file={fileSelection.selectedImage}
                                                        selectedGoal={activeGoal.goal}
                                                        onClose={() => {
                                                            fileSelection.setShowSelectedImage(false)
                                                            fileSelection.setSelectedContextData("");
                                                        }}
                                                        onDataSelected={(response) => fileSelection.setSelectedContextData(response)}
                                                        onResponse={(response) => handleRequestSubmit(response)}
                                                    />
                                                )}


                                                {fileSelection.showSelectedAudio && fileSelection.selectedAudio && (
                                                    <ShowAudio
                                                        file={fileSelection.selectedAudio}
                                                        selectedGoal={activeGoal.goal}
                                                        onClose={() => {
                                                            fileSelection.setShowSelectedAudio(false);
                                                            fileSelection.setSelectedContextData("");
                                                        }}
                                                        onDataSelected={(response) => fileSelection.setSelectedContextData(response)}
                                                    />
                                                )}

                                                {fileSelection.showSelectedPdf && fileSelection.selectedPdf && (
                                                    <ShowPdf
                                                        embedded={false}
                                                        file={fileSelection.selectedPdf}
                                                        selectedGoal={activeGoal.goal}
                                                        selectedTemplate={activeGoal.template}
                                                        onClose={() => fileSelection.setShowSelectedPdf(false)}
                                                        onResponse={(response) => handleRequestSubmit(response)}
                                                    />
                                                )}

                                                {/* CHAT VIEW */}
                                                <div style={{
                                                    display: 'flex',
                                                    flex: 3,
                                                    marginLeft: '10px',
                                                    boxShadow: "inset 0 0 10px rgba(0,0,0,0.2)"
                                                }} className="border">
                                                    <Chat
                                                        isOpen={true}

                                                        // Used to automatically send a request to the llm service
                                                        autoRequest={autoRequest}

                                                        // Determine the knowledge base category and filters
                                                        mode={selectedMode}
                                                        filter={filterList}

                                                        allowUserInput={showUserInput}

                                                        // Used to send the context to the chat window
                                                        selectedWorkspaceItem={selectedWorkspaceItem}

                                                        // All CSV records selected
                                                        selectedCsvChatContext={[
                                                            ...fileSelection.selectedCsvChatContext,
                                                            ...itemSelection.selectedCsvChatContext,
                                                        ]}

                                                        selectedContextData={fileSelection.selectedContextData}

                                                        // Selected Task (goal)
                                                        selectedGoalItem={activeGoal.goalItem}
                                                        // Selected Template
                                                        selectedTemplate={activeGoal.template}

                                                        projectId={projectId}
                                                        showAssistant={false}
                                                        onStreaming={handleStreamingState}
                                                        onAssistantClose={() => setShowAssistentMenu(false)}
                                                        defaultPrompt={defaultPrompt}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                </>
                            )}
                        </div>
                    </div>
                </CssBaseline>
            </ThemeProvider>
        </>
    );
};

export default App;