import React from 'react';
import { Dialog, DialogTitle, DialogContent, CircularProgress, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface PleaseWaitDialogProps {
    open: boolean;
    title?: string;
    message?: string;
    onCancel: () => void;
}

const PleaseWaitDialog: React.FC<PleaseWaitDialogProps> = ({ open, title = "Please Wait", message = "Processing your request...", onCancel }) => {
    return (
        <Dialog open={open} aria-labelledby="please-wait-dialog-title" maxWidth="xs" fullWidth>
            <DialogTitle id="please-wait-dialog-title">
                {title}
                <IconButton
                    aria-label="close"
                    onClick={onCancel}
                    style={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ textAlign: 'center', padding: '20px' }}>
                <CircularProgress />
                <Typography variant="body1" style={{ marginTop: '20px' }}>
                    {message}
                </Typography>
            </DialogContent>
        </Dialog>
    );
};

export default PleaseWaitDialog;

// import React from 'react';
// import { Dialog, DialogTitle, DialogContent, CircularProgress, Typography } from '@mui/material';
//
// interface PleaseWaitDialogProps {
//     open: boolean;
//     title?: string;
//     message?: string;
// }
//
// const PleaseWaitDialog: React.FC<PleaseWaitDialogProps> = ({ open, title = "Please Wait", message = "Processing your request..." }) => {
//     return (
//         <Dialog open={open} aria-labelledby="please-wait-dialog-title" maxWidth="xs" fullWidth>
//             <DialogTitle id="please-wait-dialog-title">{title}</DialogTitle>
//             <DialogContent style={{ textAlign: 'center', padding: '20px' }}>
//                 <CircularProgress />
//                 <Typography variant="body1" style={{ marginTop: '20px' }}>
//                     {message}
//                 </Typography>
//             </DialogContent>
//         </Dialog>
//     );
// };
//
// export default PleaseWaitDialog;
