import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    addSnippet,
    deleteSnippet,
    searchSnippets,
    SnippetData, SnippetDataIn,
    updateSnippet
} from '../backend/snippet.services';
import { appSession } from "../managers/generalManager";
// import {FetchWorkspaceItemsResult, FetchWorkspacesResult} from "./old/data-thunk";
import {RootState} from "./store";

export interface FetchWorkspacesParams {
    user_scope: boolean;
}

export interface FetchWorkspaceItemsParams {
    user_scope: boolean;
    type: string;
    subtype: string;
    currentProject: string;
    accessToken: string;
}

export interface FetchLinkedInItemsParams {
    user_scope: boolean;
    currentProject: string;
    accessToken: string;
}

export interface addWorkspaceItemServiceParams {
    snippet: SnippetData;
    user_scope: boolean;
}

export interface FetchWorkspacesResult {
    user_scope: boolean;
    user_workspace: SnippetDataIn;
    project_workspace: SnippetDataIn;
}

export interface FetchWorkspaceItemsResult {
    user_scope: boolean;
    snippets: SnippetData[];
}


export const fetchWorkspaces = createAsyncThunk<FetchWorkspacesResult, FetchWorkspacesParams, { state: RootState, rejectValue: string }>(
    'workspaces/fetchWorkspaces',
    async ({ user_scope }, { rejectWithValue }) => {
        try {
            const user_data = await searchSnippets(true, 'workspaces', 'configuration', appSession.currentProject, appSession.accessToken);
            const project_data = await searchSnippets(false, 'workspaces', 'configuration', appSession.currentProject, appSession.accessToken);
            return { user_scope, user_workspace: user_data[0] || undefined, project_workspace: project_data[0] || undefined };
        } catch (error) {
            return rejectWithValue('Failed to fetch workspaces');
        }
    }
);

export const updateWorkspaces = createAsyncThunk<void, void, { state: RootState, rejectValue: string }>(
    'workspaces/updateWorkspaces',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState();
            const workspaces = state.workspaces.workspaces;

            // Update user workspaces
            let workspaceSnippet = state.workspaces.userWorkspaceDefinition;
            if (workspaceSnippet === undefined) {
                const snippet: SnippetData = {
                    name: 'Workspaces',
                    type: 'workspaces',
                    subtype: 'configuration',
                    content: JSON.stringify(workspaces.filter(ws => ws.user_scope)),
                    tags: [],
                    reference: ''
                };
                await addSnippet(snippet, appSession.currentProject, appSession.accessToken, true);
            } else {
                const workspaceSnippetCopy = { ...workspaceSnippet };
                workspaceSnippetCopy.content = JSON.stringify(workspaces.filter(ws => ws.user_scope));
                await updateSnippet(workspaceSnippetCopy, appSession.currentProject, appSession.accessToken);
            }

            // Update public workspaces
            workspaceSnippet = state.workspaces.publicWorkspaceDefinition;
            if (workspaceSnippet === undefined) {
                const snippet: SnippetData = {
                    name: 'Workspaces',
                    type: 'workspaces',
                    subtype: 'configuration',
                    content: JSON.stringify(workspaces.filter(ws => !ws.user_scope)),
                    tags: [],
                    reference: ''
                };
                await addSnippet(snippet, appSession.currentProject, appSession.accessToken, false);
            } else {
                const workspaceSnippetCopy = { ...workspaceSnippet };
                workspaceSnippetCopy.content = JSON.stringify(workspaces.filter(ws => !ws.user_scope));
                await updateSnippet(workspaceSnippetCopy, appSession.currentProject, appSession.accessToken);
            }
        } catch (error) {
            return rejectWithValue('Failed to update workspaces');
        }
    }
);

const prepareWorkspaceSnippet = (snippet: SnippetData): {
    originalSnippet: SnippetData,
    modifiedSnippet: SnippetData
} => {
    const originalSnippet = {...snippet};
    const modifiedSnippet = {...snippet, content: JSON.stringify(snippet.content)};

    return {originalSnippet, modifiedSnippet};
}

export const updateWorkspaceItemService = createAsyncThunk<SnippetData, SnippetData, { state: RootState, rejectValue: string }>(
    'workspaceItems/updateWorkspaceItem',
    async (snippet, { rejectWithValue }) => {
        try {
            const { originalSnippet, modifiedSnippet } = prepareWorkspaceSnippet(snippet);
            await updateSnippet(modifiedSnippet, appSession.currentProject, appSession.accessToken);
            return originalSnippet;
        } catch (error) {
            return rejectWithValue('Failed to update workspace item');
        }
    }
);

export const addWorkspaceItemService = createAsyncThunk<SnippetData, addWorkspaceItemServiceParams, { state: RootState, rejectValue: string }>(
    'workspaceItems/addWorkspaceItem',
    async (params, { rejectWithValue }) => {
        try {
            const { user_scope, snippet } = params;
            const { originalSnippet, modifiedSnippet } = prepareWorkspaceSnippet(snippet);
            const response = await addSnippet(modifiedSnippet, appSession.currentProject, appSession.accessToken, user_scope);
            if (response && response.uuid !== undefined) {
                originalSnippet.uuid = response.uuid;
            }
            return originalSnippet;
        } catch (error) {
            return rejectWithValue('Failed to add workspace item');
        }
    }
);

export const deleteWorkspaceItemService = createAsyncThunk<void, string, { state: RootState, rejectValue: string }>(
    'workspaceItems/deleteWorkspaceItem',
    async (uuid, { rejectWithValue }) => {
        try {
            await deleteSnippet(uuid, appSession.currentProject, appSession.accessToken);
        } catch (error) {
            return rejectWithValue('Failed to delete workspace item');
        }
    }
);

export const fetchWorkspaceItems = createAsyncThunk<FetchWorkspaceItemsResult, FetchWorkspaceItemsParams, { state: RootState, rejectValue: string }>(
    'workspaceItems/fetchWorkspaceItems',
    async (params, { rejectWithValue }) => {
        try {
            const { user_scope, type, subtype, currentProject, accessToken } = params;
            const snippets = await searchSnippets(user_scope, "wi_" + type, subtype, currentProject, accessToken);
            return { user_scope, snippets };
        } catch (error) {
            return rejectWithValue('Failed to fetch workspace items');
        }
    }
);

export const fetchLinkedInItems = createAsyncThunk<FetchWorkspaceItemsResult, FetchLinkedInItemsParams, { state: RootState, rejectValue: string }>(
    'linkedInItems/fetchLinkedInItems',
    async (params, { rejectWithValue }) => {
        try {
            const { user_scope, currentProject, accessToken } = params;
            const profileSnippets = await searchSnippets(user_scope, "LinkedIn_Profile", "ZAIA", currentProject, accessToken);
            const jobSnippets = await searchSnippets(user_scope, "LinkedIn_Data", "Job", currentProject, accessToken);
            const snippets = profileSnippets.concat(jobSnippets);
            return { user_scope, snippets };
        } catch (error) {
            return rejectWithValue('Failed to fetch linkedIn items');
        }
    }
);