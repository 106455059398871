import React, {useEffect, useState} from 'react';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from "@mui/material";
import {promptDefinitionList} from "../../managers/generalManager";
import {PromptDefinition} from "../../managers/promptManager";
import logger from "../../utils/logging_services";

type PromptEditorProps = {
};

export const PromptEditor: React.FC<PromptEditorProps> = () => {
    const [selectedPrompt, setSelectedPrompt] = useState<PromptDefinition | null>(null);
    const [inputValue, setInputValue] = useState<string>('');
    const [promptList, setPromptList] = useState<PromptDefinition[]>(promptDefinitionList);

    useEffect(() => {
        setSelectedPrompt(promptList[0]);
        setInputValue(promptList[0] ? (promptList[0].prompt_upd || promptList[0].prompt) : '');
    }, []);

    const handleSelectChange = (event: SelectChangeEvent) => {
        const selected = promptList.find(prompt => prompt.name === event.target.value) || null;
        setSelectedPrompt(selected);
        setInputValue(selected ? (selected.prompt_upd || selected.prompt) : '');
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setInputValue(newValue);
        if (selectedPrompt) {
            selectedPrompt.prompt_upd = newValue;
        }
    };

    const handleSaveSettings = () => {
        // store the promptList in local storage
        localStorage.setItem('BoostrFoundation_PromptDefinitionList', JSON.stringify(promptList));
        window.location.reload()
    }

    const handleDropdownChange = (
        event: SelectChangeEvent,
        optionType: string
    ) => {
        const updatedValue = event.target.value as string;
        if (!selectedPrompt) {
            return;
        }

        setPromptList(currentList => {
            return currentList.map(prompt => {
                if (prompt.name === selectedPrompt.name) {
                    return {
                        ...prompt,
                        [optionType]: updatedValue === '1'
                    };
                }
                return prompt;
            });
        });

        setSelectedPrompt(currentPrompt => {
            if (!currentPrompt) {
                return null;
            }
            return {
                ...currentPrompt,
                [optionType]: updatedValue === '1'
            };
        })

        logger.info("handleDropdownChange", selectedPrompt, optionType, updatedValue);
        logger.info("handleDropdownChange", promptList);

    };

    // formatting: DFT_FORMATTING, source_reference: DFT_SOURCE_REFERENCE, safeguard: DFT_SAFEGUARD

    type DropDownOption = {
        value: keyof PromptDefinition; // Assuming these values are keys of PromptDefinition
        label: string;
    };

    const dropdownOptions: DropDownOption[] = [
        {value: 'formatting', label: 'Formatting'},
        {value: 'source_reference', label: 'Source Reference'},
        {value: 'safeguard', label: 'Safeguard'}
    ]

    return (
        <div style={{
            padding: '10px', flexDirection: "column",
            flex: 1, display: 'flex'
        }}>
            <Select
                value={selectedPrompt?.name || ''}
                variant="outlined"
                size="small"
                style={{marginTop: "10px", marginBottom: "10px"}}
                onChange={handleSelectChange}
            >
                {promptList.map(prompt => (
                    <MenuItem key={prompt.name} value={prompt.name}>
                        {prompt.name}
                    </MenuItem>
                ))}
            </Select>
            {
                selectedPrompt && (
                    <TextField
                        id="outlined-basic"
                        label="Outlined"
                        variant="outlined"
                        value={inputValue}
                        onChange={handleInputChange}
                        multiline
                        minRows={5}
                        style={{marginBottom: "10px"}}
                    />
                )
            }

            <div style={{paddingTop: '10px', flexDirection: 'row', flex: 1, display: 'flex', justifyContent: 'start'}}>
                {dropdownOptions.map(option => {
                    if (!selectedPrompt?.name.startsWith('Default')) {
                        return (
                            <div
                                key={option.value}
                                style={{
                                    flexDirection: 'column',
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    width: '100%'
                                }}
                            >
                                {/*<Typography variant="body1" style={{width: '100%'}}>{option.label}</Typography>*/}
                                <FormControl variant="outlined" size="small" fullWidth>
                                    <InputLabel id={`select-label-${option.value}`}>{option.label}</InputLabel>
                                    <Select
                                        labelId={`select-label-${option.value}`}
                                        label={option.label}
                                        value={selectedPrompt ? (selectedPrompt[option.value] ? '1' : '0') : '0'}
                                        onChange={(event) => handleDropdownChange(event, option.value)}
                                        style={{marginTop: '5px'}}
                                    >
                                        <MenuItem value="0">None</MenuItem>
                                        <MenuItem value="1">Default</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
            <div style={{textAlign: "end", marginTop: '10px'}}>
                <Button
                    variant={"contained"} color={"warning"} onClick={() => handleSaveSettings()}>
                    Save Settings
                </Button>
            </div>
        </div>
    )
        ;
};
