

export function copyToClipboard(message: string) {

    const data = new Blob([message], {type: "text/html"}); // Changed to "text/plain"
    const item = new ClipboardItem({"text/html": data});

    navigator.clipboard.write([item])
        .then(() => {
            console.log("HTML copied to clipboard!");
        })
        .catch((err) => {
            console.error("Could not write to the clipboard", err);
        });
}

export function htmlToPlainText(html: string): string {
  // Create a new div element
  const div = document.createElement("div");
  // Set the HTML content with the provided HTML
  div.innerHTML = html;
  // Use the textContent property to get the plain text
  let text = div.textContent || div.innerText || "";

  // Replace multiple consecutive newlines with a single newline
  // text = text.replace(/\n+/g, '\n');

  return text;
}


export function removeHTMLTags(message: string, replaceNewLineBy: string = '\n' ) {

    message = message.replace(/<br\s*\/?>/gi, replaceNewLineBy);

    // replace new line by replaceNewLineBy if not cr.
    message = message.replace(/[\r\n]+/g, replaceNewLineBy);

    // remove &nbsp and replace by space
    message = message.replace(/&nbsp;/g, ' ');

    // message = message.replace('<br>', replaceNewLineBy);
    return message.replace(/<[^>]*>?/gm, '');
}


type HtmlEntity = '&gt;' | '&lt;' | '&amp;' | '&quot;' | '&#39;';
const entities: Record<HtmlEntity, string> = {
  '&gt;': '>',
  '&lt;': '<',
  '&amp;': '&',
  '&quot;': '"',
  '&#39;': "'"
};

export function decodeHtmlEntities(text: string): string {
  return text.replace(/&[#\w]+;/g, (entity: string): string => {
    return entities[entity as HtmlEntity] || entity;
  });
}

// Define custom types for the File System Access API
interface FilePickerAcceptType {
    description: string;
    accept: { [mimeType: string]: string[] };
}

interface SaveFilePickerOptions {
    suggestedName?: string;
    types?: FilePickerAcceptType[];
}

interface FileSystemWritableFileStream extends WritableStream {
    write(data: string | BufferSource | Blob | ArrayBuffer | DataView): Promise<void>;
    close(): Promise<void>;
}

interface FileSystemFileHandle {
    createWritable(): Promise<FileSystemWritableFileStream>;
}

interface Window {
    showSaveFilePicker(options?: SaveFilePickerOptions): Promise<FileSystemFileHandle>;
}

export const saveContentToDisk = async (fileName: string, content: string): Promise<void> => {
    try {
        // Define file options
        const options = {
            suggestedName: fileName,
            types: [
                {
                    description: 'HTML Files',
                    accept: {
                        'text/html': ['.html'],
                    },
                },
            ],
        };

        // Show save file picker
        const handle: FileSystemFileHandle = await (window as any).showSaveFilePicker(options);

        // Create a writable stream
        const writable = await handle.createWritable();

        // Write the content
        await writable.write(content);

        // Close the file and write the contents to disk
        await writable.close();
    } catch (error) {
        console.error('Failed to save content:', error);
    }
};

export function capitalizeFirstLetter(string: string): string {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function getCurrentDayOfYear(): string {
  const now = new Date();
  const startOfYear = new Date(now.getFullYear(), 0, 0);
  const diff = now.getTime() - startOfYear.getTime();
  const dayOfYear = Math.floor(diff / (1000 * 60 * 60 * 24));
  const year = now.getFullYear() % 100;

  return `${year}_${dayOfYear}`;
}

    // function sendMail() {
    //
    //     const body = mode === "chat+" ? draftToHtml(convertToRaw(editorState.getCurrentContent())) : messages.map((message) => message.message).join("\n");
    //     const subject = "JobBoostr: Chat History";
    //     const recipient = "vincent@plifortigo.com";
    //
    //     window.location.href = `mailto:${recipient}?subject=${encodeURIComponent(
    //         subject
    //     )}&body=${encodeURIComponent(
    //         `Message History:\n${body}\nYour Message:\n`
    //     )}`;
    // }

    // function sendMail() {
    //     let body;
    //     if (mode === "chat+") {
    //         // Presumably, draftToHtml is a function you have that converts your editor state to HTML.
    //         body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    //         handleCopyToClipboard();
    //         // body = new Blob([body], {type: "text/html"})
    //     } else {
    //         body = messages.map((message) => message.message).join("\n");
    //     }
    //
    //     const subject = "ZAIA: Chat History";
    //     const recipient = "";
    //
    //     // Here we assume that `body` already contains HTML content
    //     const htmlBody = `<!DOCTYPE html><html><head><title>${subject}</title></head><body>${body}</body></html>`;
    //
    //     window.location.href = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent("please paste the content of your clipboard here")}`;
    // }
