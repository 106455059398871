import React, {FormEvent, useEffect, useState} from 'react';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select, SelectChangeEvent,
    TextField
} from "@mui/material";
import AppDialog from "../general/app_dialog";
import {SaveToWorkspace} from "../workspace/save_to_workspace";
import {useSelector} from "react-redux";
import logger from "../../utils/logging_services";
import {RootState} from "../../store/store";
import {Workspace} from "../../store/types";
// import {AppState} from "../../store/old/store";
// import {Workspace} from "../../store/old/data-slice";

interface ViewerProps {
    url: string;
    onClose: () => void;
    onRequestNextTask: (name: string, request: string, submit: boolean) => void;
    onRequestDetails: () => void;
}

const Viewer: React.FC<ViewerProps> = ({url, onClose, onRequestNextTask, onRequestDetails}) => {
    const [contentType, setContentType] = useState<string | null>(null);
    const [enlarged,] = useState(false);
    const [requestNextTask, setRequestNextTask] = useState(false);
    const [showAddToLocalStorageStore, setShowAddToLocalStorageStore] = useState(false);
    const [localClipboardData, setLocalClipboardData] = useState<string | null>(null);

    const [prompt, setPrompt] = useState("");
    const [taskName, setTaskName] = useState("");
    const [submit, setSubmit] = useState<boolean>(false);

    const selectedWorkspace: Workspace | undefined = useSelector((state: RootState) => state.data.selectedWorkspace);

    useEffect(() => {
        fetch(url, {method: 'HEAD'})
            .then(response => {
                const contentType = response.headers.get('Content-Type');
                if (contentType) {
                    setContentType(contentType.split(';')[0]); // Get the primary content type
                }
            })
            .catch(console.error);
    }, [url]);


    // Render the content based on content type
    const renderContent = () => {
        if (contentType?.startsWith('image/')) {
            return <img src={url} alt="Content" style={{maxWidth: '100%', height: 'auto'}}/>;
        } else if (contentType === 'text/html' || contentType === 'text/plain') {
            return <iframe src={url} title="Document Viewer" style={{width: '100%', height: '800px'}} frameBorder="0"/>;
        } else if (contentType === 'application/json') {
            // Assuming you have a way to fetch and parse the JSON content
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    // For simplicity, we just directly insert the JSON as pretty-printed text.
                    // In a real application, you might want to render this app_theme more nicely.
                    const prettyJson = JSON.stringify(data, null, 2);
                    const jsonViewer = document.getElementById('jsonViewer');
                    if (jsonViewer) {
                        jsonViewer
                            .textContent = '';
                        jsonViewer
                            .textContent = prettyJson;
                    }
                });
            return <pre id="jsonViewer" style={{whiteSpace: 'pre-wrap', wordBreak: 'break-all'}}></pre>;
        } else {
            return <p>Unsupported content type or unable to determine the content type.</p>;
        }
    };


    const handleRequestNextTask = (event: FormEvent<HTMLFormElement>) => {
        if (prompt !== "") {
            setRequestNextTask(false);
            onRequestNextTask(taskName, prompt, submit);
        } else {
            alert("Please provide a name and a prompt for the next task");
            setRequestNextTask(true);
        }
    }

    const handleShowRequestDetails = () => {
        onRequestDetails();
    }

    // const handleStoreRequest = async () => {
    //     if (contentType === 'text/html') {
    //         try {
    //             const response = await fetch(url);
    //             let app_theme: string = await response.text();
    //             // find app_theme between <body> tags
    //             // const body = app_theme.match(/<body[^>]*>[\s\S]*<\/body>/gi);
    //             // if (body) {
    //             //     app_theme = body[0];
    //             // }
    //             // app_theme = app_theme.replace(/<[^>]*>/g, '');
    //             // app_theme = app_theme.trim();
    //
    //             setLocalClipboardData(app_theme);
    //             setShowAddToLocalStorageStore(true);
    //         } catch (error) {
    //             console.error('Error fetching HTML content:', error);
    //         }
    //     }
    // }

    const handleStoreRequest = async () => {
        try {
            const response = await fetch(url);
            let data_to_store: string;

            switch (contentType) {
                case 'text/html':
                    data_to_store = await response.text();
                    // Optional: Extract content from <body> tag, strip HTML tags, etc.
                    // app_theme = app_theme.match(/<body[^>]*>[\s\S]*<\/body>/gi)?.[0]?.replace(/<[^>]*>/g, '').trim();
                    break;
                case 'application/json':
                    data_to_store = JSON.stringify(await response.json(), null, 2);
                    break;
                case 'text/plain':
                    data_to_store = await response.text();
                    break;
                default:
                    logger.error('Unsupported workspace folder:', contentType || 'null')
                    break;
            }

            // Perform actions with `app_theme` (like setting to clipboard or displaying)
            // For this example, let's assume `setLocalClipboardData` can handle all types of app_theme.
            // @ts-ignore
            setLocalClipboardData(data_to_store);
            setShowAddToLocalStorageStore(true);
        } catch (error) {
            console.error('Error processing content:', error);
        }
    };


    const handleSubmitChange = (event: SelectChangeEvent<boolean>) => {

        const value = event.target.value === 'true';
        setSubmit(value);
    };

    // Dialog styles based on mode
    const dialogStyle: "xs" | "sm" | "md" | "lg" | "xl" = enlarged ? "xl" : "md";

    return (
        <AppDialog size={dialogStyle} title={requestNextTask ? "Submit request" : "Show Document"} open={true}
                   onCancel={onClose} showCancel={true}
                   message={""} onSubmit={() => onClose()}>

            {showAddToLocalStorageStore && selectedWorkspace && (
                // <SaveToWorkspace context_data={localClipboardData || ""}
                //                  onCancel={() => setShowAddToLocalStorageStore(false)}
                //                  onSubmit={() => setShowAddToLocalStorageStore(false)}/>
                <SaveToWorkspace context_data={localClipboardData || ""} onCancel={() => setShowAddToLocalStorageStore(false)}
                                 workspace={selectedWorkspace} workspaceFolder={selectedWorkspace.folder_list[0].name || ""}
                                 type={"html"}
                                 onSubmit={() => setShowAddToLocalStorageStore(false)}/>

                // <LocalDataStore
                //     isOpen={showAddToLocalStorageStore}
                //     mode={"add"}
                //     mode_data={localClipboardData || ""}
                //     filter={""}
                //     onClose={() => setShowAddToLocalStorageStore(false)}
                //     onSelect={(app_theme: string) => {
                //         console.log(app_theme);
                //         setShowAddToLocalStorageStore(false);
                //     }}
                // />
            )}

            <Box sx={{marginTop: '10px'}}>
                {
                    requestNextTask ?
                        (
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                                onSubmit={(e) => {
                                    e.preventDefault(); // Prevent default form submission behavior
                                    handleRequestNextTask(e); // Your custom logic for handling the task request
                                }}
                            >
                                <div>
                                    <TextField
                                        label="Please provide a name to represent the next task"
                                        fullWidth={true}
                                        variant="outlined"
                                        onChange={(e) => setTaskName(e.target.value)}
                                        sx={{marginBottom: '10px'}}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        label="What would you like me to do"
                                        multiline
                                        fullWidth={true}
                                        rows={5}
                                        variant="outlined"
                                        sx={{marginBottom: '10px'}}
                                        onChange={(e) => setPrompt(e.target.value)}
                                    />
                                </div>
                                <FormControl fullWidth sx={{marginBottom: '10px'}}>
                                    <InputLabel id="submit-select-label">Submit</InputLabel>
                                    <Select
                                        labelId="submit-select-label"
                                        id="submit-select"
                                        label=""
                                        value={submit} // Keep the conversion to match the expected string type                                        label="Submit"
                                        onChange={handleSubmitChange}
                                        variant="outlined"
                                    >
                                        {/* Use string values for MenuItem value attributes to match the expected string type of Select */}
                                        <MenuItem value="true">Yes</MenuItem>
                                        <MenuItem value="false">No</MenuItem>
                                    </Select>
                                </FormControl>
                                <Box marginTop={1}>
                                    <Button variant="outlined"
                                            onClick={() => setRequestNextTask(false)}>
                                        Back to document
                                    </Button>
                                    <Button type="submit" variant="contained" color="primary" sx={{marginLeft: '10px'}}>
                                        Submit
                                    </Button>
                                </Box>
                            </Box>

                        ) : (
                            <>
                                {renderContent()}
                                <div id="jsonViewer"></div>
                                <div style={{marginTop: '10px'}}>
                                    <Button
                                        variant={"outlined"}
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{marginLeft: '10px'}}>
                                        Open
                                    </Button>
                                    <Button variant={"outlined"} onClick={handleShowRequestDetails}
                                            style={{marginLeft: '10px'}}>
                                        Details
                                    </Button>
                                    <Button variant={"outlined"} onClick={handleStoreRequest}
                                            style={{marginLeft: '10px'}}>
                                        Store
                                    </Button>
                                    <Button variant={"outlined"} onClick={() => setRequestNextTask(true)}
                                            style={{marginLeft: '10px'}}>
                                        Process results
                                    </Button>
                                </div>
                            </>
                        )
                }
            </Box>

        </AppDialog>
    );
};

export default Viewer;
