import React, {useState, useEffect, useMemo, ReactNode} from 'react';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Paper,
    TextField,
    TablePagination,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Checkbox,
    FormControlLabel,
    FormGroup, styled,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from "@mui/material/Tooltip";
import AppDialog from "./app_dialog";
import logger from "../../utils/logging_services";
import colorSchema from "../../app_theme/theme_support/colorSchema";

interface AppTableProps<T> {
    mode: 'select' | 'manage';
    title: string;
    data: T[];
    recordsPerPage?: number;
    columns: TableColumn<T>[];
    selectColumnsToShow: string[];
    selected: string[];
    filter: string;
    menu: { label: string, action: string }[];
    menuTable: { label: string, action: string }[];
    hideHeaders: boolean;
    filterValues: string[];
    selectMode: string;
    allowAdd: boolean;
    allowEdit: boolean;
    allowDelete: boolean;
    allowView: boolean;
    columnStyle?: React.CSSProperties;
    onRequest: (action: string, key: string, selectedColumns: string[]) => void;
    onFilterSelected?: (filter: string) => void;
}

export interface TableColumn<T> {
    title: string;
    dataIndex: keyof T;
    render?: (item: T) => React.ReactNode;
}

export interface TableItem {
    key: string;
    category?: string;

    [key: string]: any;
}

type SortDirection = 'asc' | 'desc';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: theme.spacing(1),
}));


const AppTable = <T extends TableItem>({
                                           mode,
                                           data,
                                           recordsPerPage,
                                           columns,
                                           selectColumnsToShow,
                                           filter,
                                           filterValues,
                                           selected,
                                           hideHeaders,
                                           menu,
                                           menuTable,
                                           allowAdd,
                                           selectMode,
                                           allowEdit,
                                           allowDelete,
                                           allowView,
                                           onRequest,
                                           onFilterSelected,
                                           columnStyle = {
                                               maxWidth: '400px',
                                               width: 'auto',
                                               whiteSpace: 'nowrap',
                                               overflow: 'hidden',
                                               textOverflow: 'ellipsis'
                                           },
                                       }: AppTableProps<T>) => {
    const [dataList, setDataList] = useState<T[]>([]);
    const [filterCategory, setFilterCategory] = useState<string>(filter);
    const [generalFilter, setGeneralFilter] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(recordsPerPage || 10);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedKey, setSelectedKey] = useState<string | null>(null);
    const open = Boolean(anchorEl);
    const [sortColumn, setSortColumn] = useState<keyof T | null>(null);
    const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
    const [menuOptionSelected, setMenuOptionSelected] = useState(false);
    const [uniqueCategories] = useState<string[]>(filterValues);
    const [filters, setFilters] = useState<{ [key: string]: string }>({});
    const [menuTableLocal, setMenuTabelLocal] = useState<{ label: string, action: string }[]>(menuTable);
    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
    const [showFilterField, setShowFilterField] = useState(false);


    useEffect(() => {

        menuTable.push({label: "Select All", action: "select_all"});
        menuTable.push({label: "Select Fields", action: "set_columns"});
        setMenuTabelLocal(menuTable);

    }, [menuTable])

    useEffect(() => {
        logger.info("Data: ", data)

        // reset page
        setPage(0);

        // clone the app_theme to avoid mutating the original app_theme
        const clonedData = JSON.parse(JSON.stringify(data)) as T[];
        setDataList(clonedData);

        // // add select all and select fields to the menu
        // if (menuTableLocal.length === 0 || !menuTableLocal.find(item => item.action === "select_all"))
        //     menuTableLocal.push({label: "Select All", action: "select_all"});
        // if (menuTableLocal.length === 0 || !menuTableLocal.find(item => item.action === "set_columns"))
        //     menuTableLocal.push({label: "Select Fields", action: "set_columns"});

        // when app_theme changes, reset the column selection
        resetColumnSelection(true);

        // show all or selected columns
        if (selectColumnsToShow.length === 0)
            setSelectedColumns(columns.map(column => column.title));
        else
            setSelectedColumns(JSON.parse(JSON.stringify(selectColumnsToShow)) as string[]);

    }, [data]);

    const handleFilterChange = (dataIndex: string, value: string) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [dataIndex]: value,
        }));
    };

    const handleSort = (column: keyof T) => {
        const isAsc = sortColumn === column && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setSortColumn(column);
    };

    const sortedAndFilteredList = useMemo(() => {
        let newList = [...dataList];

        if (sortColumn) {
            newList.sort((a, b) => {
                if (a[sortColumn] < b[sortColumn]) {
                    return sortDirection === 'asc' ? -1 : 1;
                }
                if (a[sortColumn] > b[sortColumn]) {
                    return sortDirection === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }

        newList = newList.filter((record) =>
            Object.keys(filters).every((key) => {
                const value = filters[key].toLowerCase();
                const recordValue = record[key as keyof T]?.toString().toLowerCase();

                if (value.startsWith('*blank')) {
                    return !recordValue;
                } else if (value.startsWith('*not_blank')) {
                    return recordValue;
                } else if (value.startsWith('*starts_with')) {
                    const searchString = value.replace('*starts_with', '').trim();
                    return recordValue.startsWith(searchString);
                } else if (value.startsWith('*contains')) {
                    const searchString = value.replace('*contains', '').trim();
                    return recordValue.includes(searchString);
                } else {
                    return recordValue.includes(value);
                }
            })
        );

        const filter_on_category = newList.filter(record => filterCategory === '' || record.category === filterCategory);

        if (!generalFilter) return filter_on_category;

        return filter_on_category.filter((item) => {
            const itemValuesString = Object.values(item).join(' ').toLowerCase();
            return itemValuesString.includes(generalFilter.toLowerCase());
        });

    }, [dataList, sortColumn, sortDirection, filters, filterCategory, generalFilter]);

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, key: string) => {
        setAnchorEl(event.currentTarget);
        logger.info("Key: ", key);
        setSelectedKey(key);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedKey(null);
        setMenuOptionSelected(false);
    };

    const handleRequestWrapper = (action: string) => {

        if (action === "select_all") {
            const keys = sortedAndFilteredList.map(record => record.key);
            onRequest(action, keys.join(','), selectedColumns);
            handleMenuClose();
            return;
        }

        if (action === "set_columns") {
            setShowFilterField(true);
            handleMenuClose();
            return;
        }

        if (selectedKey) {
            onRequest(action, selectedKey, selectedColumns);
        }
        handleMenuClose();
    };

    const handleColumnToggle = (column: string) => {
        setSelectedColumns(prevColumns =>
            prevColumns.includes(column)
                ? prevColumns.filter(col => col !== column)
                : [...prevColumns, column]
        );
    };

    const resetColumnSelection = (state: boolean) => {
        // if state = true then select all columns, else deselect all columns
        if (state)
            setSelectedColumns(columns.map(column => column.title));
        else
            setSelectedColumns([]);
    };

    function renderCellValue(value: any): ReactNode {
        const maxLen = 100;
        if (typeof value === 'string') {
            const displayValue = value.length > maxLen ? `${value.substring(0, maxLen)}...` : value;
            return (
                <Tooltip title={value}>
                    <span>{displayValue.replace(/['"]+/g, '')}</span>
                </Tooltip>
            );
        } else if (typeof value === 'number' || typeof value === 'boolean' || React.isValidElement(value)) {
            return value;
        } else if (Array.isArray(value)) {
            const displayValue = value.join(', ').length > maxLen ? `${value.join(', ').substring(0, maxLen)}...` : value.join(', ');
            return (
                <Tooltip title={value.join(', ')}>
                    <span>{displayValue}</span>
                </Tooltip>
            );
        } else if (value !== null && typeof value === 'object') {
            const jsonValue = JSON.stringify(value);
            const displayValue = jsonValue.length > maxLen ? `${jsonValue.substring(0, maxLen)}...` : jsonValue;
            return (
                <Tooltip title={jsonValue}>
                    <span>{displayValue}</span>
                </Tooltip>
            );
        }
        return null;
    }

    const sanitizeTitle = (title: string) => title.replace(/"/g, '');

    const handleFilterOnCategory = (filter: string) => {
        if (filter === filterCategory) {
            filter = "";
        }
        setFilterCategory(filter);
        // Propagate the filter to the parent component
        if (onFilterSelected) {
            onFilterSelected(filter);
        }
    }

    const handleFieldFilterDone = () => {
        setShowFilterField(false);
        onRequest('set_columns', '', selectedColumns);
    }

    return (
        <>
            <div>
                <div style={{display: 'flex', flex: 0, flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        marginBottom: '10px'
                    }}>
                        {uniqueCategories.map((value) => (
                            <Button
                                key={value}
                                variant={filterCategory === value ? "contained" : "outlined"}
                                color="primary"
                                onClick={() => handleFilterOnCategory(value)}
                                style={{marginRight: "8px", marginBottom: "2px"}}
                            >
                                {value}
                            </Button>
                        ))}
                    </div>
                    {mode === 'manage' && allowAdd && (
                        <IconButton onClick={() => onRequest('add', '', [])}>
                            <AddIcon/>
                        </IconButton>
                    )}
                </div>

                <TableContainer component={Paper}>
                    <Table size={"small"}>
                        {!hideHeaders && (
                            <TableHead >
                                <TableRow sx={{backgroundColor: colorSchema.background.default}}>
                                    {columns
                                        .filter(column => selectedColumns.includes(column.title))
                                        .map((column, index) => (
                                            <StyledTableCell key={`header_${index}`} sx={{padding: '0px !important'}}>
                                                <div onClick={() => handleSort(column.dataIndex as keyof T)}
                                                     style={{cursor: 'pointer'}}>
                                                    {sanitizeTitle(column.title)}
                                                    {sortColumn === column.dataIndex && (
                                                        <span>{sortDirection === 'asc' ? ' 🔼' : ' 🔽'}</span>
                                                    )}
                                                </div>
                                                <TextField
                                                    size="small"
                                                    variant="outlined"
                                                    placeholder={`Filter ${column.title}`}
                                                    value={filters[column.dataIndex as string] || ''}
                                                    onChange={(e) =>
                                                        handleFilterChange(column.dataIndex as string, e.target.value)
                                                    }
                                                />
                                            </StyledTableCell>
                                        ))}
                                    {mode === 'manage' && (
                                        <>
                                            <StyledTableCell align="right">
                                                Actions
                                                {menuTableLocal.length > 0 && (
                                                    <div>
                                                        <IconButton
                                                            sx={{padding: '5px'}}
                                                            onClick={(event) => {
                                                                setMenuOptionSelected(true);
                                                                event.stopPropagation();
                                                                handleMenuOpen(event, "**");
                                                            }}
                                                        >
                                                            <MoreVertIcon/>
                                                        </IconButton>
                                                        <Menu
                                                            anchorEl={anchorEl}
                                                            open={open && "**" === selectedKey}
                                                            onClose={handleMenuClose}
                                                            sx={{zIndex: 9999}}
                                                        >
                                                            {menuTableLocal.map((item, index) => (
                                                                <MenuItem key={`global-menu-${index}`}
                                                                          onClick={(event) => {
                                                                              event.stopPropagation();
                                                                              handleRequestWrapper(item.action);
                                                                          }}>
                                                                    {item.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Menu>
                                                    </div>
                                                )}
                                            </StyledTableCell>
                                        </>
                                    )}
                                </TableRow>
                            </TableHead>
                        )}
                        <TableBody>
                            {sortedAndFilteredList
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((record, index) => (
                                    <TableRow
                                        key={`${record.key}-${index}`}
                                        sx={{
                                            backgroundColor: selected.includes(record.key) ? colorSchema.action.selected : 'inherit',
                                            '&:hover': {
                                                backgroundColor: selected.includes(record.key) ? colorSchema.action.hover : colorSchema.action.hover,
                                            },
                                        }}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            if (!menuOptionSelected) onRequest(selectMode, record.key, selectedColumns);
                                        }}
                                    >
                                        {columns
                                            .filter(column => selectedColumns.includes(column.title))
                                            .map((column, colIdx) => (
                                                <StyledTableCell key={`${record.key}-${index}-${colIdx}`}
                                                                 sx={columnStyle}>
                                                    {column.render
                                                        ? column.render(record)
                                                        : renderCellValue(record[column.dataIndex])}
                                                </StyledTableCell>
                                            ))}
                                        {mode === "manage" && (
                                            <StyledTableCell align="right" key={`${record.key}-${index}`}>
                                                <IconButton
                                                    onClick={(event) => {
                                                        setMenuOptionSelected(true);
                                                        event.stopPropagation();
                                                        handleMenuOpen(event, record.key);
                                                    }}
                                                >
                                                    <MoreVertIcon/>
                                                </IconButton>
                                                <Menu
                                                    anchorEl={anchorEl}
                                                    open={open && record.key === selectedKey}
                                                    onClose={handleMenuClose}
                                                    sx={{zIndex: 9999}}
                                                >
                                                    {menu.map((item, index) => (
                                                        <MenuItem key={`${record.key}-menu-${index}`}
                                                                  onClick={(event) => {
                                                                      event.stopPropagation();
                                                                      handleRequestWrapper(item.action);
                                                                  }}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                                </Menu>
                                            </StyledTableCell>
                                        )}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{textAlign: 'left', display: 'flex', flex: 1, justifyContent: 'left'}}>
                    <TablePagination
                        component="div"
                        count={sortedAndFilteredList.length}
                        rowsPerPage={rowsPerPage}
                        labelRowsPerPage={"#"}
                        rowsPerPageOptions={[]}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </div>
            {showFilterField && (
                <AppDialog
                    open={showFilterField}
                    message={""}
                    size="md"
                    showSubmit={true}
                    showCancel={false}
                    title={"Select filter fields"}
                    onSubmit={() => handleFieldFilterDone()}
                    onCancel={() => setShowFilterField(false)}
                >
                    <div style={{display: 'flex', flex: 0, flexDirection: 'column', justifyContent: 'center'}}>

                        <div style={{
                            display: 'flex',
                            flex: 0,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            marginBottom: '10px'
                        }}>
                            <FormGroup row>
                                {columns.map((column) => (
                                    <FormControlLabel
                                        key={column.title}
                                        control={
                                            <Checkbox
                                                checked={selectedColumns.includes(column.title)}
                                                onChange={() => handleColumnToggle(column.title)}
                                            />
                                        }
                                        label={sanitizeTitle(column.title)}
                                    />
                                ))}
                            </FormGroup>

                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-around'}}>
                            <Button variant="contained" onClick={() => resetColumnSelection(true)}
                                    style={{marginLeft: '10px'}}>
                                Select All
                            </Button>
                            <Button variant="contained" onClick={() => resetColumnSelection(false)}
                                    style={{marginLeft: '10px'}}>
                                Deselect All
                            </Button>
                        </div>

                    </div>
                </AppDialog>
            )}
        </>
    );
};

export default AppTable;

