import {execute_task, retrieve_chunks} from "./lexicalia_api";
import {appSession, appSettings, Chunk, languageOptions, ragData, selectedLanguage} from "../managers/generalManager";
import {getDataBoostrData, getTalentBoostrData} from "./extdata.services";
import {action_create_book, action_create_image, action_run_task, ImageRequest} from "./zaia_actions";
import logger from "../utils/logging_services";
import {processImage} from "./special.services";
import {getSnippet} from "./snippet.services";

// ------------------------------------------------------------------------------------------------------
// Run ChatBoostr backend task
// ------------------------------------------------------------------------------------------------------
export async function executeTask(task: string, data: any, project: string = ""): Promise<string> {

    let project_id = appSession.currentProject;

    if ( project !== "") {
        project_id = project;
    }

    const response = await execute_task(project_id, task, JSON.stringify(data));
    logger.info("Execute Task Request Response", response);


    let query: string = response.data || "";
    if (response.error !== null) {
        if (response.error.includes('401')) {
            query = "Error: \"This service is only available for subscribed users.\"";
        } else {
            query = "Error: \"" + response.error + "\"";
        }
    }
    return query;
}

export async function execute_getSkills(value: string): Promise<string> {
    return executeTask('get_skills', {value});
}

export async function execute_getContent(value: string): Promise<string> {
    return executeTask('get_content', value);
}

export async function execute_getNews(keywords: string, time: string, source: string): Promise<string> {
    return executeTask('get_news', {keywords, time, source});
}

export async function execute_query_data(keywords: string, time: string, source: string): Promise<string> {
    return executeTask('query_data', {keywords, time, source});
}

// Request app_theme example.
// {"service_request":"get_data","service_data":"{\"query\":\"test\",\"namespace\":\"HR\",\"file\":\"05b3de26-43a7-4669-beb2-6afd07dcaea0\",\"tags\":\"\",\"requested_chunks\":5}","language":"en"}
export async function execute_databoostr(request: string, namespace: string, file: string, filter: string, chunks?: number): Promise<string> {
    return executeTask('get_data', {query: request, namespace:namespace, file:file, tags:filter, "requested_chunks": chunks || appSettings.requestedChunks});
}

export async function execute_databoostr_record(record_uuid: string): Promise<string> {
    return executeTask('get_record_data', {record_uuid});
}

export async function execute_search_kb(request: string, namespace: string, file: string, filter: string, chunks?: number): Promise<string> {
    return executeTask('get_data', {query: request, namespace:namespace, file:file, tags:filter, "requested_chunks": chunks || appSettings.requestedChunks}, appSession.currentKnowledgeBaseProject);
}

export async function execute_extract_url(url: string): Promise<string> {
    return executeTask('get_url', {url});
}

// ------------------------------------------------------------------------------------------------------
// Retrieve Databoostr chunks
// ------------------------------------------------------------------------------------------------------
export async function execute_retrieve_chunks(record_uuid: string, from_chunk: number, to_chunk: number): Promise<string> {
    const response = await retrieve_chunks(appSession.currentProject, record_uuid, from_chunk, to_chunk);
    logger.info("Execute Retrieve Chunks response:", response);

    let query: string = response.data || "";
    if (response.error !== null) {
        if (response.error.includes('401')) {
            query = "Say: \"This service is only available for subscribed users.\"";
        } else {
            query = "Say: \"" + response.error + "\"";
        }
    }
    return query;
}

export async function extractTaskRequests(data: string): Promise<string> {
    const regex = /\{\*\*([\s\S]*?)\*\*\}/g;
    let match;

    while ((match = regex.exec(data)) !== null) {
        const content = match[1];
        const firstEqualIndex = content.indexOf('=');

        if (firstEqualIndex !== -1) {
            const key = content.slice(0, firstEqualIndex).trim();
            const value = content.slice(firstEqualIndex + 1).trim();
            const valueKV = value.split("||").map(item => item.trim());

            const replacement = await processKeyValue(key, valueKV);
            data = data.replace(match[0], replacement);

            if (replacement === "**STOP**") {
                return replacement;
            }
        }
    }

    logger.info("Extracted Task Requests:", data);
    return data;
}


// ------------------------------------------------------------------------------------------------------
// Process Key Value
// Examples : {**databoostr={question}::HR**}  Request has 2 parts, the instruction and the parameters
//            which are separated by ::
// ------------------------------------------------------------------------------------------------------
async function processKeyValue(key: string, values: string[]): Promise<any> {

    logger.info("processKeyValue", key, values);
    if ( key === 'task' && values.length > 0 ) {

        let context_data: { [key: string]: string } = {}; // TypeScript type annotation for an object with string keys and string values
        for (let i = 1; i < values.length; i++) {
            const parts = values[i].split("::");
            if (parts.length === 2) {
                const [key, value] = parts;
                context_data[key.trim()] = value.trim();
            } else {
                context_data[`value_${i}`] = values[i].trim();
            }
        }
        const language = languageOptions.find(option => option.value === selectedLanguage)?.label || 'English'

        const taskRequest = {
            task: values[0],
            name: context_data.name || values[0] || 'Task',
            context_data: JSON.stringify(context_data),
            context_type: 'json',
            language: language,
            version: appSettings.selectedLLMModelVersion,
            provider: appSettings.selectedLLMModelProvider,
            temperature: appSettings.temperature
        };
        logger.info("Task Request Created:", taskRequest)
        const response = await action_run_task(taskRequest);
        logger.info(`response: ${JSON.stringify(response)}`)
        return "**STOP**"
    }

    if ( key === 'create_book' && values.length === 4) {
        const bookRequest = {
            title: values[0],
            name: values[0],
            number_of_chapters: parseInt(values[2], 10),
            context_data: values[3],
            context_type: 'text',
            language: values[1],
            temperature: appSettings.temperature,
            version: appSettings.selectedLLMModelVersion,
            provider: appSettings.selectedLLMModelProvider
        };
        logger.info("BookRequest created:", bookRequest)
        const response = await action_create_book(bookRequest);
        logger.info(`response: ${JSON.stringify(response)}`)
        return "**STOP**"
    }

    if ( key === 'create_image' && values.length === 4) {
        const prompt = values[0] + "```" + values[3] + "```";
        const imageRequest:ImageRequest = {
            name: values[1],
            image_prompt: prompt,
            image_size: values[2],
            context_data: "",
            context_type: "",
            language: "",
            temperature: appSettings.temperature,
            version: 'standard',
            provider: 'openai'
        };
        logger.info("Image Request Created:", imageRequest)
        const response = await action_create_image(imageRequest);
        logger.info(`response: ${JSON.stringify(response)}`)
        return "**STOP**"
    }

    if ( key === "create_follow_up" ) {
        return "\nAfter your response write @@INTERNAL@@ and continue will 2 relevant follow-up questions based on the subject and the given context information. Separate the questions using ||\n";
    }

    if ( key === 'ws' && values.length > 0) {
        const snippet_data = await getSnippet(values[0], appSession.currentProject, appSession.accessToken);
        if (snippet_data !== undefined) {
            return snippet_data.content;
        } else {
            logger.error(`Snippet ${values[0]} not found`);
            return "Snippet not found";
        }
    }

    if (key === 'news' && values.length === 3) {
        return await execute_getNews(values[0], values[1], values[2]);
    }

    if (key === 'query' && values.length === 3) {
        return await execute_query_data(values[0], values[1], values[2]);
    }

    if (key === 'kb' && values.length === 4) {
        if ( values[2].toLowerCase() === 'all' ) {
            values[2] = '';
        }
        if ( values[3].toLowerCase() === 'all' ) {
            values[3] = '';
        }
        const response = await execute_search_kb(values[0], values[1], values[2], values[3]);
        ragData.chunks = parseResponseToChunks(response);
        ragData.request = values[0];
        logger.info("RAG Data", ragData);
        return response;
    }

    if (key === 'kb' && values.length === 2) {
        const response = await execute_search_kb(values[0], values[1], '', '');
        ragData.chunks = parseResponseToChunks(response);
        ragData.request = values[0];
        logger.info("RAG Data", ragData);
        return response;
    }

    if (key === 'databoostr' && values.length === 4) {
        if ( values[2].toLowerCase() === 'all' ) {
            values[2] = '';
        }
        if ( values[3].toLowerCase() === 'all' ) {
            values[3] = '';
        }
        const response = await execute_databoostr(values[0], values[1], values[2], values[3]);
        ragData.chunks = parseResponseToChunks(response);
        ragData.request = values[0];
        ragData.domain = values[1];
        ragData.filter_type = values[2];
        ragData.filter_value = values[3];
        logger.info("RAG Data", ragData);
        return response;

    }

    if (key === 'databoostr' && values.length === 2) {
        const response = await execute_databoostr(values[0], values[1], '', '');
        ragData.chunks = parseResponseToChunks(response);
        ragData.request = values[0];
        ragData.domain = values[1];
        ragData.filter_value = '';
        ragData.filter_type = '';
        logger.info("RAG Data", ragData);
        return response;
    }

    if (key === 'databoostr_item' && values.length === 1) {
        return await getDataBoostrData(appSession.currentProject, values[0], 0, 0);
    }

    if (key === 'talentboostr_item' && values.length === 2) {
        return await getTalentBoostrData(appSession.currentProject, values[0], values[1]);
    }

    // if (key === 'url' && values.length === 1) {
    //     const article = await execute_extract_url(values[0]);
    //     return article;
    // }

    if (key === 'url' && values.length === 1) {

        const parameter = values[0].trim();

        // Define a regex pattern to validate URLs
        const urlPattern = /^(https?:\/\/)?([\da-z.-]+\.[a-z.]{2,6})(\/[\w.-]*)*\/?$/i;

        // Check if the link matches the URL pattern
        if (!urlPattern.test(parameter)) {
            return parameter;
        }

        const article = await execute_extract_url(parameter);
        return article;
    }


    if (key === 'chunk' && values.length === 3) {

        // convert string to number
        const from: number = parseInt(values[1], 10);
        let to: number = parseInt(values[2], 10);
        if (to < from || to === 0) {
            to = 10;
        }

        return await execute_retrieve_chunks(values[0], from, to);
    }

    if (key === 'get_skills' && values.length === 1) {
        return await execute_getSkills(values[0]);
    }

    if ( key === 'process_image' && values.length === 1) {
        if  (appSettings.selectedFile !== undefined ) {
            return await processImage(appSettings.selectedFile.metadata.container_name, appSettings.selectedFile.metadata.blob_name, values[0]);
        } else {
            logger.info("No image selected");
        }
    }
}

function parseResponseToChunks(response: string): Chunk[] {
    const chunks: Chunk[] = [];
    const pattern = /<SOURCE\/REFERENCE>(.*?)<\/SOURCE\/REFERENCE><DATA>(.*?)<\/DATA>/gs; // Regular expression to match reference and data

    let match;
    while ((match = pattern.exec(response)) !== null) {
        const reference = match[1].trim(); // Extract the reference
        const data = match[2].trim();      // Extract the data
        chunks.push({ reference, data });  // Add to the chunks array
    }

    return chunks;
}

