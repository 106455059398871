// AppDialogs.tsx
import React from 'react';
import { TextField, CircularProgress, Typography, } from '@mui/material';
import AppDialog from '../general/app_dialog';

interface StoreDialogsProps {
    showCreateStoreDialog: boolean;
    showDeleteStoreDialog: boolean;
    showPleaseWait: boolean;
    error: string;
    newStoreName: string;
    setNewStoreName: (name: string) => void;
    handleAddDataStoreSubmit: () => void;
    handleAddDataStoreCancel: () => void;
    handleDeleteCurrentStore: () => void;
    setShowDeleteStoreDialog: (show: boolean) => void;
}

const StoreDialogs: React.FC<StoreDialogsProps> = ({
    showCreateStoreDialog,
    showDeleteStoreDialog,
    showPleaseWait,
    error,
    newStoreName,
    setNewStoreName,
    handleAddDataStoreSubmit,
    handleAddDataStoreCancel,
    handleDeleteCurrentStore,
    setShowDeleteStoreDialog
}) => {
    return (
        <div>
            {showCreateStoreDialog && (
                <AppDialog open={showCreateStoreDialog} size={"xs"} title={"Create new task group"} message={error} showSubmit={true} showCancel={true} onSubmit={handleAddDataStoreSubmit} onCancel={handleAddDataStoreCancel}>
                    <TextField
                        id="store"
                        sx={{ marginTop: '10px' }}
                        fullWidth
                        required={true}
                        value={newStoreName}
                        label="Please provide a name for the new task group"
                        variant="outlined"
                        error={!!error}
                        helperText={error}
                        onChange={event => setNewStoreName(event.target.value)}
                    />
                </AppDialog>
            )}

            {showPleaseWait && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    zIndex: 1000,
                }}>
                    <CircularProgress color="primary" size={50} />
                </div>
            )}

            {showDeleteStoreDialog && (
                <AppDialog open={true} size={"sm"} showSubmit={true} showCancel={true} title={`Delete ${newStoreName}`} message={"Are you sure you want to delete the selected task group?"} onSubmit={handleDeleteCurrentStore} onCancel={() => setShowDeleteStoreDialog(false)}>
                    <div style={{ textAlign: 'center' }}>
                        <Typography variant="h6" sx={{ marginTop: '10px', marginLeft: '10px' }}>
                            You're about to delete the task group {newStoreName}.
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: '10px', marginLeft: '10px' }}>
                            Please be aware that this action is permanent and cannot be undone.
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: '10px', marginLeft: '10px' }}>
                            Deleting the store will permanently remove all data contained within it.
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: '10px', marginLeft: '10px' }}>
                            To confirm, click Confirm.
                        </Typography>
                    </div>
                </AppDialog>
            )}
        </div>
    );
};

export default StoreDialogs;
