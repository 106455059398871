import React, {useState, useEffect} from "react";
import {ExtractedImage} from "./show_pdf";
import {
    Box,
    IconButton,
    Typography,
    Button,
    CircularProgress,
    Divider
} from "@mui/material";
import {KeyboardArrowLeft, KeyboardArrowRight, DescriptionOutlined, Refresh, Save} from "@mui/icons-material";
import AppDialog from "../general/app_dialog";
import colorSchema from "../../app_theme/theme_support/colorSchema";
import logger from "../../utils/logging_services";
import {processLocalImage} from "../../backend/special.services";
import {SaveToWorkspace} from "../workspace/save_to_workspace";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {appSettings, languageOptions} from "../../managers/generalManager";

const imageProcessingPrompt = 'You will be given a detailed description of an image. ' +
    'Your task is to analyze the given image and create a well-structured, visually appealing HTML response that describes the image in detail.\n' +
    'Carefully analyze the provided image. Pay attention to all details, including objects, people, colors, actions, and the overall scene.\n' +
    'Create your response using HTML with inline styling for easy readability. ' +
    'Follow these guidelines:\n' +
    '1. Use a <div> as the main container with a light background color and padding.\n' +
    '2. Use appropriate heading tags (h1, h2, etc.) for different sections of your description.\n' +
    '3. Utilize paragraphs (<p>) for the main content, with a readable font size and line height.\n' +
    '4. If applicable, create an unordered list (<ul>) for listing multiple elements in the image.\n' +
    '5. Use subtle colors to highlight important aspects of the description.\n' +
    '6. The language to create your response in is {language}, write in {language} but do not translate words that are \n' +
    'specific to technical terminology or proper nouns. This ensures clarity and precision in conveying complex concepts.\n' +
    'Your response should include:\n' +
    '1. A brief overview of the entire image\n' +
    '2. Detailed descriptions of the main elements in the image\n' +
    '3. Notes on colors, lighting, and atmosphere\n' +
    '4. Any notable actions or interactions in the image\n' +
    '5. Your interpretation of the mood or theme of the image, if apparent\n' +
    '6. Write in {language}\n' +
    'Remember to use clear, concise language while being descriptive. Organize your response in a logical manner, starting with the most prominent elements and moving to smaller details.\n' +
    'Begin your response between <response></response> elements:\n'

const imageStoryPrompt = 'You are tasked with creating an essay based on an image from a paper or book. ' +
    'Your goal is to extract all the details from the image content provided and craft an engaging essay that represents and expands upon those details.\n' +
    'Carefully analyze the image content provided above. Pay attention to all details, including any text, figures, graphs, or visual elements described. ' +
    'Consider the context, theme, and potential implications of the information presented.\n' +
    'Based on your analysis, create a essay that incorporates and represents the content from the image. Follow these guidelines:\n' +
    '1. Begin by identifying the main theme or subject of the image.\n' +
    '2. Develop characters, settings, or scenarios that relate to the information presented.\n' +
    '3. Incorporate key details from the image into your response.\n' +
    '4. Use your imagination to expand upon the given information, but ensure your content remains consistent with the facts provided.\n' +
    '5. If the image contains technical or scientific information, try to present it in a more accessible, narrative format.\n' +
    '6. Aim for a coherent and engaging result that captures the essence of the image\'s content.\n' +
    '7. The language to create your response in is {language},\n' +
    'Present your response in an easy to read HTML page with inline styling.\n' +
    'Remember to be creative while staying true to the core information presented in the image. Your result should be engaging and informative, helping to illustrate the content in a narrative form.\n' +
    'Begin your response between <response></response> elements:\n'


const imageDescriptionPrompt = 'You will be presented with an image from a paper or book. Your task is to analyze this image thoroughly and create a detailed report based on the information it contains. Follow these steps:\n' +
    '1. Carefully examine the image provided:\n' +
    '2. Analyze the image in detail, paying attention to all visual elements, text, diagrams, charts, or any other information present.\n' +
    '3. Create a comprehensive report with the following structure:\n' +
    '   a. Title: Provide a concise, descriptive title for the report based on the main topic or theme of the image.\n' +
    '   b. Summary: Write a brief overview (2-3 sentences) of the main points or key information presented in the image.\n' +
    '   c. Detailed Analysis: Break down the information into relevant subjects or categories. For each subject:\n' +
    '      - Use a subheading to clearly identify the subject\n' +
    '      - Provide detailed information about that subject\n' +
    '      - Include any relevant data, statistics, or facts presented in the image\n' +
    '      - Explain any relationships or connections between different elements\n' +
    '4. When extracting information:\n' +
    '   - Be thorough and include all relevant details\n' +
    '   - Maintain the original order of information as presented in the image, if applicable\n' +
    '   - Use bullet points or numbered lists for clarity when appropriate\n' +
    '   - Describe any visual elements (charts, graphs, diagrams) in detail\n' +
    '5. Present your final report in the following HTML format with styling:\n' +
    '<html>\n' +
    'Title[Insert your title here]\n' +
    'Summary[Insert your summary here]\n' +
    'Detailed analysis\n' +
    '<subject1>\n' +
    '[Subject 1 name]\n' +
    '[Detailed information about subject 1]\n' +
    '[Subject 2 name]\n' +
    '[Detailed information about subject 2]\n' +
    '[Continue with additional subject tags as needed]\n' +
    '</html>\n' +
    'I want you to create the response in {language} and use HTML with inline formatting.\n' +
    'Do not use backticks and do not mential that the response is in HTML\n' +
    'Remember to be objective and accurate in your analysis, and ensure that all information in your report is directly based on the content of the provided image.'


interface CachedResponse {
    [key: number]: string;
}

const FlipCard: React.FC<{
    frontContent: React.ReactNode;
    backContent: React.ReactNode;
    isFlipped: boolean;
    onFlip: () => void;
}> = ({frontContent, backContent, isFlipped, onFlip}) => {
    return (
        <Box
            sx={{
                perspective: '1000px',
                width: '100%',
                height: '100%',
                cursor: 'pointer',
            }}
            onClick={onFlip}
        >
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    transition: 'transform 0.6s',
                    transformStyle: 'preserve-3d',
                    transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backfaceVisibility: 'hidden',
                    }}
                >
                    {frontContent}
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backfaceVisibility: 'hidden',
                        transform: 'rotateY(180deg)',
                    }}
                >
                    {backContent}
                </Box>
            </Box>
        </Box>
    );
};

export const ImageCarousel: React.FC<{ images: ExtractedImage[], onClose: () => void }> = ({images, onClose}) => {
    const [activeStep, setActiveStep] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [cachedResponses, setCachedResponses] = useState<CachedResponse>({});
    const [isFlipped, setIsFlipped] = useState(false);
    const [showSave, setShowSave] = useState(false);

    const selectedWorkspace = useSelector((state: RootState) => state.data.selectedWorkspace);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
        setIsFlipped(false);
        setError(null);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps);
        setIsFlipped(false);
        setError(null);
    };

    const handleProcessImage = async (e: React.MouseEvent, forceRefresh: boolean = false) => {
        e.stopPropagation();

        if (!forceRefresh && cachedResponses[activeStep]) {
            setIsFlipped(!isFlipped);
            return;
        }

        setIsProcessing(true);
        setError(null);
        try {
            const currentImage = images[activeStep];

            const selectedLanguage = languageOptions.find(option => option.value === appSettings.selectedLanguage)?.label || 'english';
            const imagePrompt = imageDescriptionPrompt.replace(/{language}/g, selectedLanguage);

            const response = await fetch(currentImage.data);
            const blob = await response.blob();
            const imageFile = new File([blob], `image_${activeStep}.png`, {type: 'image/png'});

            let result = await processLocalImage(imagePrompt, imageFile);

            // search <response> and </response> and extract the content between them
            const startTag = '<response>';
            const endTag = '</response>';
            const startIndex = result.indexOf(startTag);
            const endIndex = result.indexOf(endTag);
            if (startIndex !== -1 || endIndex !== -1)
                result = result.substring(startIndex + startTag.length, endIndex);

            setCachedResponses(prev => ({...prev, [activeStep]: result}));
            setIsFlipped(true);
        } catch (err: any) {
            logger.error('Error processing image:', err);
            setError('Failed to process image. Please try again.');
        } finally {
            setIsProcessing(false);
        }
    };


    const handleSave = (e: React.MouseEvent) => {
        e.stopPropagation();
        setShowSave(true);
    };


    const frontContent = (
        <div style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center'
        }}>
            <img
                src={images[activeStep].data}
                alt="Extracted Image"
                style={{
                    maxHeight: '100%',
                    maxWidth: '100%',
                    objectFit: 'contain',
                }}
            />
        </div>
    );

    const backContent = (
        <div style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                height: appSettings.dialogHeight - 50
            }}
                 dangerouslySetInnerHTML={{__html: cachedResponses[activeStep]}}/>

            <Divider/>
            <div style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                justifyItems: 'start',
            }}>

                {/*<Button startIcon={<Save/>} onClick={handleSave}>*/}
                {/*    Save to Workspace*/}
                {/*</Button>*/}
                {/*<Button startIcon={<Refresh/>} onClick={(event) => handleProcessImage(event, true)}>*/}
                {/*    Refresh Description*/}
                {/*</Button>*/}
            </div>
        </div>
    );

    return (
        <>
            <AppDialog
                onCancel={onClose}
                open={true}
                size="xl"
                title="Extracted Images"
                message=""
                showCancel={true}
                showSubmit={false}
                fullHeight={true}
                onSubmit={() => {
                }}
            >
                <div style={{
                    height: '90%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    overflow: 'hidden'
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '70vh',
                            width: '100%'
                        }}
                    >
                        <FlipCard
                            frontContent={frontContent}
                            backContent={backContent}
                            isFlipped={isFlipped}
                            onFlip={() => setIsFlipped(!isFlipped)}
                        />
                    </Box>
                    <Box
                        sx={{
                            padding: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        {cachedResponses[activeStep] && (
                            <Button startIcon={<Save/>}
                                    onClick={handleSave} variant="contained"
                                    color="secondary">
                                Save to Workspace
                            </Button>
                        )}

                        {/* Show image navigation, next + previous image */}
                        <div style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <IconButton onClick={handleBack} disabled={maxSteps === 1}>
                                <KeyboardArrowLeft sx={{color: colorSchema.primary.main}}/>
                            </IconButton>
                            <Typography
                                sx={{color: colorSchema.primary.main}}>{`${activeStep + 1} / ${maxSteps}`}</Typography>
                            <IconButton onClick={handleNext} disabled={maxSteps === 1}>
                                <KeyboardArrowRight sx={{color: colorSchema.primary.main}}/>
                            </IconButton>
                        </div>

                        {/* Button to extract image information or show available information */}
                        <Button
                            onClick={handleProcessImage}
                            disabled={isProcessing}
                            startIcon={isProcessing ? <CircularProgress size={20}/> : <DescriptionOutlined/>}
                            variant="contained"
                            color="primary"
                        >
                            {isProcessing ? 'Processing...' : cachedResponses[activeStep] ? 'Flip card' : 'Explain Image'}
                        </Button>

                    </Box>

                    {error && (
                        <Box sx={{mt: 2, p: 2, bgcolor: 'error.light', borderRadius: 1}}>
                            <Typography color="error">{error}</Typography>
                        </Box>
                    )}
                </div>
            </AppDialog>

            {showSave && selectedWorkspace && (
                <SaveToWorkspace
                    context_data={cachedResponses[activeStep] || ""}
                    onCancel={() => setShowSave(false)}
                    workspace={selectedWorkspace}
                    workspaceFolder={selectedWorkspace.folder_list[0]?.name || ""}
                    type="html"
                    onSubmit={() => setShowSave(false)}
                />
            )}
        </>
    );
};