import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Project} from "../backend/core.services";
import {SelectedFile, Workspace, WorkspaceItem} from "./types";
import {appSettings} from "../managers/generalManager";
import logger from "../utils/logging_services";

interface DataState {
    projects: Project[];
    selectedWorkspace: Workspace | undefined;
    selectedWorkspaceCategory: string;
    selectedWorkspaceItem: WorkspaceItem | undefined;
    selectedWorkspaceItemAction: string | undefined;
    selectedFile: SelectedFile | undefined;
    selectedFileUrl: string | undefined;
    selectedPages: number[];
    currentPage: number | undefined;
    selectedFolder: string;
    activeStore: string;
    compactSnippets: boolean;
    mainMenuLocked: boolean;
    parameter_select_page_by_page: boolean;
    error: string | null;
}

const initialState: DataState = {
    selectedWorkspaceCategory: "",
    activeStore: '',
    projects: [],
    selectedWorkspace: undefined,
    selectedWorkspaceItem: undefined,
    selectedWorkspaceItemAction: undefined,
    selectedFile: undefined,
    selectedFileUrl: undefined,
    selectedPages: [],
    currentPage: undefined,
    selectedFolder: '',
    compactSnippets: false,
    mainMenuLocked: false,
    parameter_select_page_by_page: true,
    error: null
};

const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setSelectedWorkspaceItem: (state, action: PayloadAction<WorkspaceItem | undefined>) => {
            state.selectedWorkspaceItem = action.payload;
        },
        setSelectedWorkspaceItemAction: (state, action: PayloadAction<string | undefined>) => {
            state.selectedWorkspaceItemAction = action.payload;
        },
        setMainMenuLocked: (state, action: PayloadAction<boolean>) => {
            state.mainMenuLocked = action.payload;
        },
        setParameterSelectPageByPage: (state, action: PayloadAction<boolean>) => {
            state.parameter_select_page_by_page = action.payload;
        },
        setSelectedPages: (state, action: PayloadAction<number[]>) => {
            state.selectedPages = action.payload;
        },
        setCurrentPage: (state, action: PayloadAction<number | undefined>) => {
            state.currentPage = action.payload;
        },
        setSelectedFileUrl: (state, action: PayloadAction<string | undefined>) => {
            state.selectedFileUrl = action.payload;
        },
        setActiveStore: (state, action: PayloadAction<string>) => {
            state.activeStore = action.payload;
        },
        setSelectedFile: (state, action: PayloadAction<SelectedFile | undefined>) => {
            state.selectedFile = action.payload;
            appSettings.selectedFile = action.payload;
        },
        setSelectedFolder: (state, action: PayloadAction<string>) => {
            logger.info('setSelectedFolder', action.payload);
            state.selectedFolder = action.payload;
        },
        setSelectedFileRecords: (state, action: PayloadAction<string[]>) => {
            if (state.selectedFile) {
                state.selectedFile.records = action.payload;
            }
        },
        setSelectedFileColumns: (state, action: PayloadAction<string[]>) => {
            if (state.selectedFile) {
                state.selectedFile.columns = action.payload;
            }
        },
        updateProjects: (state, action: PayloadAction<Project[]>) => {
            state.projects = action.payload;
        },
        setSelectedWorkspace: (state, action: PayloadAction<Workspace | undefined>) => {
            state.selectedWorkspace = action.payload;
        },
        setSelectedWorkspaceCategory: (state, action: PayloadAction<string>) => {
            state.selectedWorkspaceCategory = action.payload;
        },
        setCompactSnippets: (state, action: PayloadAction<boolean>) => {
            state.compactSnippets = action.payload;
        }
    }
});

export const {
    setSelectedWorkspaceItem,
    setSelectedWorkspaceItemAction,
    setMainMenuLocked,
    setParameterSelectPageByPage,
    setSelectedPages,
    setCurrentPage,
    setSelectedFileUrl,
    setActiveStore,
    setSelectedFile,
    setSelectedFolder,
    setSelectedFileRecords,
    setSelectedFileColumns,
    updateProjects,
    setSelectedWorkspace,
    setSelectedWorkspaceCategory,
    setCompactSnippets
} = dataSlice.actions;

export default dataSlice.reducer;