
import config from '../config.json';
import logger from "../utils/logging_services";
const service_url = config.mode === 'dev' ? config.server_url_dev : config.server_url_prod;

// HANDLE LogIn Request : Connect to the ChatBoostr server, with the given user and password and retrieve a token.
//------------------------------------------------------------------------------------------------------------------------------------
export interface AuthenticateResponse {
    status: number;
    token: string;
    message: string;
}

export async function Authenticate(username: string, password: string): Promise<AuthenticateResponse> {

    let message: string = "";
    let token: string = "";
    let status: number = 0;

    const url = service_url + `/token`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'accept': 'application/json'
            },
            body: new URLSearchParams({
                'grant_type': '',
                'username': username,
                'password': password,
                'scope': '',
                'client_id': '',
                'client_secret': ''
            })
        });
        status = response.status;
        const data = await response.json();
        token = data.access_token;

    } catch (error) {
        if ( error instanceof Error )
            logger.error("Authenticate Service Failed:" + error );
    }

    return {status, token, message}
}





