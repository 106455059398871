import { promptDefinitionList} from "./generalManager";
import {PersonaDefinition} from "./personaManager";
import logger from "../utils/logging_services";

export interface PromptSections {
    prompt: string,
    instructions: string,
    persona_instructions: string
}

export const getPrompt = (domain: string, persona?: PersonaDefinition): PromptSections => {

    logger.info(`Looking for prompt definition for domain ${domain}`);

    const promptDefinition = promptDefinitionList.find(p => p.reference === domain);
    if (promptDefinition) {
        let prompt = promptDefinition.prompt_upd !== "" ? promptDefinition.prompt_upd : promptDefinition.prompt;
        let instructions = "";

        let persona_instructions = "";
        if ( persona && persona.extraInstructions !== "" ) {
            persona_instructions = `${persona.extraInstructions}`;
        }

        if (promptDefinition.source_reference) {
            const source_reference_Definition = promptDefinitionList.find(p => p.name === "Default source reference");
            const source_reference = source_reference_Definition?.prompt_upd !== "" ? source_reference_Definition?.prompt_upd : source_reference_Definition?.prompt;
            instructions += `\n\n<REFERENCE INSTRUCTIONS>${source_reference}`;
        }
        if (promptDefinition.safeguard) {
            const safeguard_Definition = promptDefinitionList.find(p => p.name === "Default safeguard");
            const safeguard = safeguard_Definition?.prompt_upd !== "" ? safeguard_Definition?.prompt_upd : safeguard_Definition?.prompt;
            instructions += `\n\n<SAFEGUARD INSTRUCTIONS>${safeguard}`;
        }
        if (promptDefinition.formatting) {
            const formatting_Definition = promptDefinitionList.find(p => p.name === "Default formatting");
            const formatting = formatting_Definition?.prompt_upd !== "" ? formatting_Definition?.prompt_upd : formatting_Definition?.prompt;
            instructions += `\n\n<FORMATTING>${formatting}`;
        }

        logger.info(`Prompt definition found for domain ${domain}: ${prompt}`);
        return { prompt, instructions, persona_instructions };
    } else {
        logger.error(`No prompt definition found for domain ${domain}`);
        return getPrompt("chat", persona);
    }
}


export type PromptDefinition = {
    name: string;
    reference?: string;
    prompt: string;
    prompt_upd: string;
    source_reference?: boolean;
    safeguard?: boolean;
    formatting?: boolean;
};



const DFT_SOURCE_REFERENCE = `Provide references, the source you used to answer the question. for example : File = xyz.pdf - Page 1, do not refer to segments `;
const DFT_SOURCE_REFERENCE_UPD = "";

const DFT_SAFEGUARD = `Do not make-up. If you are unsure, ` +
    `say, "I couldn't find any information to answer your question."`;
const DFT_SAFEGUARD_UPD = "";

const DFT_FORMATTING = `Format your response as HTML, using catchy titles, subtitles, ` +
    `and bullet points. Include links and images when relevant. ` +
    `For example <H1>title</H1><H2>Subtitle</H2><p>Line1</p><p>Line2</p><ul><li>list item</li></ul>`;
const DFT_FORMATTING_UPD = "";

const CHAT_PLUS_PROMPT =
    `You are Zaia, my personal assistant. Provide clear answers to general questions about the targeted domain.`;
const CHAT_PLUS_PROMPT_UPD = "";

const HR_PROMPT =
    `You are a Human Resource expert whose task is to provide clear answers to general questions about existing policies. `
const HR_PROMPT_UPD = "";

const FACTS_PROMPT =
    'As a Content Marketing Insights Researcher, your mission is to chart the evolving landscape of content marketing. ' +
    'Uncover trends, generate insights, and forecast the future, guiding strategic decisions.' +
    'Your Directive:Trend Analysis: Identify key trends in content creation, distribution, engagement, and technology.' +
    'Insight Generation: Derive insights on consumer behavior, emerging platforms, and effective content types.' +
    'Comparative Analysis: Contrast current trends with previous years to identify significant shifts.' +
    'Future Forecasting: Project future developments and recommend strategies to stay ahead.' +
    'Expert Opinions: Incorporate insights from industry leaders. ' +
    'Case Studies: Highlight successful strategies and outcomes.Data-Driven ' +
    'Support: Validate findings with recent, credible app_theme.' +
    'Actionable Recommendations: Provide specific strategies for content marketers.'
const FACTS_PROMPT_UPD = "";

const SD_PROMPT =
    `You are a Service Delivery expert whose task is to provide clear answers to general questions about incidents and service request. `
const SD_PROMPT_UPD = "";

const LEGAL_PROMPT =
    `You are a legal expert whose task is to provide clear answers to general questions about existing legal policies. `
const LEGAL_PROMPT_UPD = "";

const CORP_PROMPT =
    `"You are a corporate expert whose task is to provide clear answers to general questions about existing corporate related information. `
const CORP_PROMPT_UPD = "";

const BASE_PROMPT =
    `You are a domain expert whose task is to provide clear answers to general questions about the targeted domain.`
const BASE_PROMPT_UPD = "";

const URL_PROMPT =
    `You are a domain expert whose task is to summarize articles and to provide interesting facts.`
const URL_PROMPT_UPD = "";

const MAIL_PROMPT =
    `You are ZAIA, my personal assistant. I want you to write an email based on the given context. `
const MAIL_PROMPT_UPD = "";


const ACTION_IMPROVEMENT_PROMPT =
    `Please rewrite the following text to improve its clarity and correctness. 
    Ensure that the revised version conveys the original message in a more straightforward and understandable manner. 
    Focus on simplifying complex sentences, correcting any grammatical errors, and rephrasing ambiguous phrases. 
    The goal is to make the text more accessible while preserving its original intent and meaning.`

const ACTION_SUMMARY_PROMPT =
    `Please provide a concise summary of the following text, capturing its key points and main ideas. 
    Focus on the primary themes and essential details, avoiding minor aspects. 
    The summary should be clear and succinct, ideally in a paragraph or bullet points, depending on your preference`

const ACTION_CONVERT_TO_EMAIL_PROMPT =
    `Please transform the following text into an email message. Begin with a subject line that succinctly states the 
    solution or key outcome addressed in the email. In the body of the email, address the recipient directly, 
    indicating that you have a solution or answer for them. Then clearly and concisely present the solution or answer, 
    ensuring the message is well-organized and easy to understand. Use appropriate formatting, 
    such as paragraphs and bullet points, to enhance readability. The tone should be professional and courteous`

export const default_promptDefinitionList: PromptDefinition[] = [
    {reference: "chat+", name: "Editor prompt", prompt: CHAT_PLUS_PROMPT, prompt_upd: CHAT_PLUS_PROMPT_UPD, formatting: true, source_reference: true, safeguard: true},
    {reference: "chat", name: "Chat prompt", prompt: BASE_PROMPT, prompt_upd: BASE_PROMPT_UPD, formatting: true, source_reference: true, safeguard: true},
    {reference: "HR", name: "HR prompt", prompt: HR_PROMPT, prompt_upd: HR_PROMPT_UPD, formatting: true, source_reference: true, safeguard: true},
    {reference: "Service Delivery", name: "Service Delivery prompt", prompt: SD_PROMPT, prompt_upd: SD_PROMPT_UPD, formatting: true, source_reference: true, safeguard: true},
    {reference: "Facts", name: "Facts prompt", prompt: FACTS_PROMPT, prompt_upd: FACTS_PROMPT_UPD, formatting: true, source_reference: true, safeguard: true},
    {reference: "Legal", name: "Legal prompt", prompt: LEGAL_PROMPT, prompt_upd: LEGAL_PROMPT_UPD, formatting: true, source_reference: true, safeguard: true},
    {reference: "Corporate", name: "Corporate prompt", prompt: CORP_PROMPT, prompt_upd: CORP_PROMPT_UPD, formatting: true, source_reference: true, safeguard: true},
    {name: "Url prompt", prompt: URL_PROMPT, prompt_upd: URL_PROMPT_UPD, formatting: true, source_reference: true, safeguard: true},
    {name: "Mail prompt", prompt: MAIL_PROMPT, prompt_upd: MAIL_PROMPT_UPD, formatting: true, source_reference: false, safeguard: true},
    {name: "Default source reference", prompt: DFT_SOURCE_REFERENCE, prompt_upd: DFT_SOURCE_REFERENCE_UPD, formatting: false, source_reference: false, safeguard: false},
    {name: "Default safeguard", prompt: DFT_SAFEGUARD, prompt_upd: DFT_SAFEGUARD_UPD, formatting: false, source_reference: false, safeguard: false},
    {name: "Default formatting", prompt: DFT_FORMATTING, prompt_upd: DFT_FORMATTING_UPD, formatting: false, source_reference: false, safeguard: false},
    {reference: "A_summary", name: "Action - Summary", prompt: ACTION_SUMMARY_PROMPT, prompt_upd: ACTION_SUMMARY_PROMPT, formatting: false, source_reference: false, safeguard: false},
    {reference: "A_improve", name: "Action - Improve", prompt: ACTION_IMPROVEMENT_PROMPT, prompt_upd: ACTION_IMPROVEMENT_PROMPT, formatting: false, source_reference: false, safeguard: false},
    {reference: "A_mail", name: "Action - Convert to Mail", prompt: ACTION_CONVERT_TO_EMAIL_PROMPT, prompt_upd: ACTION_CONVERT_TO_EMAIL_PROMPT, formatting: false, source_reference: false, safeguard: false}
];

