import React, { useState } from "react";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import AppDialog from "../general/app_dialog";
import DropdownSelector from "./dropdownSelector";
import {Option} from "../../managers/generalManager";

interface InteractOnMessageProps {
    onSubmit: (data: any) => void; // This might not be needed anymore, depending on your implementation
    onCancel: () => void;
    context_data: string;
}

const InteractionOptions: Option[] = [
    {value: "toMail", label: "Convert to mail"},
    {value: "rewrite", label: "Rewrite"},
];

export const InteractOnMessage = ({ onCancel, onSubmit, context_data }: InteractOnMessageProps) => {
    const [request, setRequest] = useState<string>("");
    const [interactionMode, setInteractionMode] = useState<string>("toMail");

    const dispatch = useDispatch(); // Use dispatch to send actions to the store


    const handleOnSubmit = () => {
        let user_prompt = "";
        let system_prompt = "";
        if ( interactionMode === 'toMail') {
            system_prompt = 'System: You are an email writing assistant. Your task is to convert the provided text into a well-structured email message following best practices for email communication. The prompt will include the text to be converted and optionally, additional details about the intention or context of the email.';
            user_prompt = 'System: You are an email writing assistant. ' +
                'Your task is to convert the provided text into a well-structured email message following best ' +
                'practices for email communication. The prompt will include the text to be converted and optionally, ' +
                'additional details about the intention or context of the email.';

            user_prompt = 'Please rewrite the given text as an email message, ' +
                'incorporating the additional context or intention if provided. Follow these guidelines:\n' +
                'Start with a clear and concise subject line that summarizes the purpose of the email.\n' +
                'Use a friendly yet professional greeting, addressing the recipient appropriately.\n' +
                'Provide context or background information in the opening paragraph if necessary.\n' +
                'Clearly state the main purpose or request in subsequent paragraphs.\n' +
                'Use straightforward language, avoiding unnecessary jargon or complex sentences.\n' +
                'If any action is required from the recipient, state it clearly and provide relevant details or deadlines.\n' +
                'Close the email with a polite sign-off and your name or signature.\n' +
                'Ensure proper formatting, including line breaks between paragraphs and bullet points or numbered lists if needed.\n' +
                'Incorporate the additional context or intention provided in the comment, if applicable.'
            user_prompt += '<additional_details>' + request + '</additional_details>';
        }
        if ( interactionMode === 'rewrite') {
            system_prompt = "You are a text rewriting assistant. Your task is to rewrite the given text based on the provided instructions and best practices for clear and effective written communication."
            user_prompt = 'Please rewrite the given text according to the additional instructions or ' +
                'context provided in the comment, while following these best practices:\n' +
                'Use clear, concise, and straightforward language.\n' +
                'Ensure proper grammar, spelling, and punctuation.\n' +
                'Organize the content logically and coherently.\n' +
                'Break down complex ideas into simpler, more digestible parts.\n' +
                'Avoid unnecessary jargon or technical terms unless required by the context.\n' +
                'Maintain an appropriate tone and level of formality based on the intended audience.\n' +
                'Incorporate examples, analogies, or visuals if they can aid understanding.\n' +
                'Optimize the structure and formatting for readability (e.g., use headings, bullet points, or numbered lists where appropriate).\n' +
                'Ensure the rewritten text accurately conveys the original meaning and intent while addressing the additional instructions or context provided.';
            user_prompt += '<additional_details>' + request + '</additional_details>';
        }
        onSubmit(user_prompt);
    }

    return (
        <AppDialog
            open={true}
            size={"sm"}
            showSubmit={true}
            showCancel={true}
            title={`Interact on message`}
            message={""}
            onSubmit={handleOnSubmit}
            onCancel={onCancel}
        >
            <div style={{paddingTop: "10px", paddingBottom: "10px"}}>
                <DropdownSelector
                    defaultValue={InteractionOptions.find(option => option.value === interactionMode)?.value}
                    options={InteractionOptions}
                    label="What would you like do?"
                    onChange={setInteractionMode}
                />
            </div>
            <TextField
                id="comment"
                fullWidth
                multiline={true}
                rows={4}
                sx={{my: 1}}
                variant={"outlined"}
                onChange={(event) => setRequest(event.target.value)}
                value={request}
            />

        </AppDialog>
    );
};
