import React, {useState, useEffect, useMemo} from 'react';
import {
    Menu,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    ListItemText,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {DBItem} from "../../utils/IndexedDB.utils";

interface Option {
    value: string;
    label: string;
    tag?: string;
    data?: DBItem;
}

interface DropdownSelectorProps {
    options: Option[];
    label: string;
    defaultValue?: string;
    variant?: "standard" | "outlined" | "filled";
    onChange?: (value: string, data?: DBItem) => void;
    blankLabel?: string;
}

const DropdownSelector: React.FC<DropdownSelectorProps> = ({
                                                               options,
                                                               defaultValue,
                                                               label,
                                                               onChange,
                                                               blankLabel,
                                                               variant = 'outlined'
                                                           }) => {
    const [selectedOption, setSelectedOption] = useState<string>(defaultValue || '');
    const [blankLabelState] = useState<string>(blankLabel || 'all');
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [currentSubMenu, setCurrentSubMenu] = useState<string | null>(null);

    useEffect(() => {
        setSelectedOption(defaultValue || '');
    }, [defaultValue, label, options]);

    const handleChange = (value:string, data?: DBItem) => {
        setSelectedOption(value);
        if (onChange) {
            onChange(value, data);
        }
        handleSubMenuClose();
    };


    const handleSubMenuOpen = (event: React.MouseEvent<HTMLElement>, tag: string) => {
        event.preventDefault();
        setSubMenuAnchorEl(event.currentTarget);
        setCurrentSubMenu(tag);
    };

    const handleSubMenuClose = () => {
        setSubMenuAnchorEl(null);
        setCurrentSubMenu(null);
    };

    const groupedOptions = useMemo(() => {
        const groups: { [key: string]: Option[] } = {'': []};
        options.forEach(option => {
            const key = option.tag || '';
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(option);
        });
        return groups;
    }, [options]);

    return (
        <FormControl fullWidth variant={variant} size="small">
            <InputLabel>{label}</InputLabel>
            <Select
                value={selectedOption}
                label={label}
                onChange = {(event) => {
                    const selectedOption = options.find(option => option.value === event.target.value);
                    if (selectedOption) {
                        handleChange(selectedOption.value, selectedOption.data);
                    }
                }}

                fullWidth={ false  }
                inputProps={{'aria-label': label}}
                IconComponent={ExpandMoreIcon}
                renderValue={(selected) => {
                    const selectedOption = options.find(option => option.value === selected);
                    return selectedOption ? selectedOption.label : blankLabelState;
                }}
                MenuProps={{
                    PaperProps: {
                        onMouseLeave: handleSubMenuClose,
                    },
                }}
            >

                {options.map((option, index) => (
                    <MenuItem key={index} style={{display: option.tag !== undefined && option.tag !== '' ? 'none' : ''}} value={option.value}>
                        <ListItemText primary={option.label || blankLabelState}/>
                    </MenuItem>
                ))}

                {Object.keys(groupedOptions).filter(key => key !== "").map((key, index) => (
                    <MenuItem
                        key={"hidden_"+index}
                        onMouseOver={(e) => handleSubMenuOpen(e, key)}
                    >
                        <ListItemText primary={key}/>
                        <ArrowRightIcon/>
                    </MenuItem>
                ))}


                {Object.keys(groupedOptions).filter(key => key !== "").map((key, index) => {
                    const group = groupedOptions[key];

                    return (
                        <div
                            key={index}
                            onMouseLeave={handleSubMenuClose}
                        >

                            {currentSubMenu === key && (
                                <Menu
                                    anchorEl={subMenuAnchorEl}
                                    open={currentSubMenu === key}
                                    onClose={handleSubMenuClose}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    slotProps={{
                                        paper: {
                                            onMouseLeave: handleSubMenuClose,
                                            sx: {
                                                marginLeft: '-10px',
                                                marginTop: '10px',
                                            },
                                        }
                                    }}

                                >
                                    {group.map((option, idx) => (
                                        <MenuItem
                                            key={`${index}-${idx}`}
                                            value={option.value}
                                            onClick={(event) => {
                                                handleChange(option.value, option.data);
                                            }}
                                        >
                                            <ListItemText primary={option.label}/>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            )}
                        </div>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export default DropdownSelector;
