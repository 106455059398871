
interface Prompt {
  name: string;
  prompt: string;
  [key: string]: any; // for extra parameters
}

class PromptManager {
  private prompts: Prompt[] = [];

  // Function to add a new prompt
  addPrompt(newPrompt: Prompt): void {
    this.prompts.push(newPrompt);
  }

  // Function to get all prompts
  getPrompts(): Prompt[] {
    return this.prompts;
  }

  // Function to find a prompt by name
  findPromptByName(name: string): Prompt {
    let prompt = this.prompts.find(prompt => prompt.name === name);
    if (!prompt) {
      prompt = { name: 'Not found', prompt: `Write a message to indicate that the prompt ${name} was not found` };
    }
    return prompt;
  }

  // Function to remove a prompt by name
  removePromptByName(name: string): void {
    this.prompts = this.prompts.filter(prompt => prompt.name !== name);
  }
}

// Example usage
export const promptManager = new PromptManager();

let instruction = "Your task is to shorten the following text while preserving all important details. \n" +
                "To complete this task, follow these steps:\n" +
                "1. Carefully read and analyze the original text to identify the main ideas and key details.\n" +
                "2. Create a shortened version of the text that:\n" +
                "   - write fluent and comprehensive\n" +
                "   - Retains all essential information\n" +
                "   - Eliminates redundancies and unnecessary words\n" +
                "   - Combines related ideas where possible\n" +
                "   - Uses more concise language and phrasing\n" +
                "3. Ensure that the shortened version:\n" +
                "   - Maintains the original meaning and intent of the text\n" +
                "   - Preserves all important facts, figures, and app_theme points\n" +
                "   - Keeps any crucial context or background information\n" +
                "4. Write the shortened text in a clear, coherent, and professional style.\n" +
                "5. After writing the shortened version, review it to make sure no important details have been lost.\n" +
                "Provide your shortened version of the text. The shortened text should be significantly more concise than the original while retaining all crucial information."
promptManager.addPrompt({ name: 'shorter', prompt: instruction, extraParam: '' });

instruction = 'You are tasked with expanding a given text without adding any details that are not relevant or made up. ' +
                'Your goal is to make the text longer while maintaining its original meaning and context. Here\'s how to approach this task:\n' +
                '1. First, carefully read the original text provided below:\n' +
                '2. To expand the text, follow these steps:\n' +
                '   a. Identify the main ideas and key points in the original text.\n' +
                "   write fluent and comprehensive\n" +
                '   b. Elaborate on these main ideas by providing additional context, explanations, or examples that are directly related to the original content.\n' +
                '   c. Use synonyms and varied sentence structures to rephrase existing information.\n' +
                '   d. Break down complex sentences into multiple simpler sentences if appropriate.\n' +
                '   e. Add transitional phrases to improve flow between sentences and paragraphs.\n' +
                '   f. Include relevant descriptive language to paint a more vivid picture of the concepts presented.\n' +
                '3. Important guidelines to follow:\n' +
                '   - Do not introduce any new facts, details, or information that is not implied or directly related to the original text.\n' +
                '   - Avoid making assumptions or drawing conclusions beyond what is explicitly stated in the original text.\n' +
                '   - Maintain the original tone and style of the text as much as possible.\n' +
                '   - Ensure that the expanded text remains coherent and logically structured.\n' +
                '4. After writing your expanded version, review it to ensure that:\n' +
                '   - The original meaning and intent of the text are preserved.\n' +
                '   - No irrelevant or fabricated information has been added.\n' +
                '   - The text flows naturally and reads well.\n' +
                'Remember, your goal is to enhance and elaborate on the existing content, not to create new information or change the original message.'
promptManager.addPrompt({ name: 'longer', prompt: instruction, extraParam: '' });

instruction = "You are tasked with making a given text more informal. Here is the text you need to work with:\n" +
                "Your goal is to rewrite this text in a more casual, conversational style that sounds less formal and more approachable. To achieve this, follow these guidelines:\n" +
                "1. Use contractions (e.g., \"don't\" instead of \"do not\", \"it's\" instead of \"it is\")\n" +
                "2. Replace formal words with more casual alternatives\n" +
                "3. Simplify complex sentence structures\n" +
                "4. Add some colloquial expressions or idioms where appropriate\n" +
                "5. Use a more personal tone, as if speaking directly to the reader\n" +
                "6. Break up long sentences into shorter ones\n" +
                "7. Use active voice instead of passive voice when possible\n" +
                "Rewrite the text to make it more informal while maintaining the original meaning. Be careful not to change the core message or important details.\n"
promptManager.addPrompt({ name: 'informal', prompt: instruction, extraParam: '' });

 instruction = 'You are tasked with making a given text more formal. Here is the text you need to formalize:\n' +
                'Your goal is to rewrite this text in a more formal style, suitable for professional or academic contexts. To achieve this, follow these guidelines:\n' +
                '1. Use more sophisticated vocabulary, replacing casual words with their formal equivalents.\n' +
                '2. Avoid contractions (e.g., use "do not" instead of "don\'t").\n' +
                '3. Remove colloquialisms, slang, and informal expressions.\n' +
                '4. Use complete sentences with proper grammar and punctuation.\n' +
                '5. Maintain a neutral, objective tone.\n' +
                '6. Avoid personal pronouns when possible, opting for a more impersonal style.\n' +
                '7. Use passive voice where appropriate to increase formality.\n' +
                '8. Ensure clarity and precision in your language.\n' +
                'Rewrite the given text, applying these principles to create a more formal version. Maintain the original meaning and key information while elevating the level of formality.\n'
promptManager.addPrompt({ name: 'formal', prompt: instruction, extraParam: '' });

 instruction = "You are tasked with converting content into nice-looking HTML. Your goal is to create a well-structured, visually appealing HTML document based on the given content and style preferences.\n" +
     "Here is the content you need to convert:\n" +
     "Follow these instructions to convert the content into HTML:\n" +
     "1. Analyze the content structure and identify headings, paragraphs, lists, and any other elements.\n" +
     "2. Create an appropriate HTML structure using semantic tags (e.g., <header>, <main>, <section>, <article>, <footer>).\n" +
     "3. Use heading tags (<h1>, <h2>, etc.) for titles and subtitles, maintaining a logical hierarchy.\n" +
     "4. Wrap paragraphs in <p> tags.\n" +
     "5. Create ordered (<ol>) or unordered (<ul>) lists as appropriate.\n" +
     "6. If there are any links or images in the content, use <a> and <img> tags respectively.\n" +
     "7. Apply the style preferences using inline CSS or by creating a <style> section in the <head> of the document.\n" +
     "Do not override the body tag, use the class zaia instead\n" +
     "8. Ensure the HTML is properly indented and easy to read.\n" +
     "9. Add any additional formatting or structural elements that would enhance the visual appeal and readability of the content.\n"

 promptManager.addPrompt({ name: 'to_html', prompt: instruction, extraParam: '' });

 instruction = "You will be converting content into an HTML table. The content to be converted is provided below:\n" +
     "Your task is to analyze the given content and create an appropriate HTML table structure to represent it. Follow these steps:\n" +
     "1. Examine the content carefully to identify the structure and organization of the information.\n" +
     "2. Determine the number of columns needed for the table based on the content's structure.\n" +
     "3. Identify the headers for each column, if applicable.\n" +
     "4. Create the HTML table structure using the following tags:\n" +
     "   - <table class=\"zaia\"> for the overall table\n" +
     "   - <thead> for the table header (if applicable)\n" +
     "   - <tbody> for the table body\n" +
     "   - <tr> for each row\n" +
     "   - <th> for header cells\n" +
     "   - <td> for app_theme cells\n" +
     " Be sure to add the class zaia to the table tag\n" +
     "5. Format the content within the table, ensuring that:\n" +
     "   - Each piece of information is placed in the appropriate cell\n" +
     "   - The table is properly structured and easy to read\n" +
     "   - Any formatting or styling present in the original content is preserved as much as possible within the HTML structure\n" +
     "6. If the content contains lists or nested information, use appropriate HTML tags (<ul>, <ol>, <li>) within the table cells to maintain the structure.\n" +
     "7. Ensure that the resulting HTML table is valid and properly formatted.\n" +
     "Provide your final output as a complete HTML table structure, including the opening and closing <table> tags." +
     "Remember to focus solely on creating the HTML table structure based on the given content. Do not add any additional styling or CSS to the table."

 promptManager.addPrompt({ name: 'to_table', prompt: instruction, extraParam: '' });


const foundPrompt = promptManager.findPromptByName('Example');
console.log(foundPrompt);

