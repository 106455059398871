import {SelectWithData, ValueLabel} from "../../general/Site.snippets";
import {categoryOptions} from "../../../managers/generalManager";
import {Box} from "@mui/material";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import colorSchema from "../../../app_theme/theme_support/colorSchema";
import {RootState} from "../../../store/store";
import {setSelectedFolder} from "../../../store/dataSlice";
// import {AppState} from "../../../store/old/store";
// import {setSelectedFolder} from "../../../store/old/data-slice";

export interface SelectFolderProps {
    onSelection: (categoryOption: ValueLabel) => void;
}

export const SelectFolder = (props: SelectFolderProps) => {

    const selectedFolder = useSelector((state: RootState) => state.data.selectedFolder);
    const dispatch = useDispatch();

    const handleFolderChanged = (option: ValueLabel) => {
        dispatch(setSelectedFolder(option.value));
        props.onSelection(option);
    }

    return (

        <Box sx={{
            height: '50px',
            textAlign: 'center',
            alignContent: 'center',
            borderRadius: '10px',
            backgroundColor: colorSchema.background.default,
            color: colorSchema.text.primary,
        }}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <SelectWithData data={categoryOptions} id="SelectCategory" onSelect={handleFolderChanged}
                                selected={categoryOptions.findIndex((option) => option.value.toLowerCase() === (selectedFolder ? selectedFolder.toLowerCase() : 'None'))}
                                label={""} isOpen={false}
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flex: 0,
                                    flexGrow: 0,
                                    alignItems: "center",
                                    justifyContent: "start",
                                    mr: 1,
                                }}
                />
            </div>
        </Box>

    )
}