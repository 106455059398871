import React, {useState, useEffect, useCallback} from 'react';
import {fetch_document_as_blob} from "../../backend/zaia_state";
import {Button, CircularProgress, Typography, TextField, Box} from '@mui/material';
import transcription from "../../assets/transcription.png";

import logger from "../../utils/logging_services";
import ComponentModalOrEmbedded from "../general/component_modal_embedded";
import {appSettings, appSession} from "../../managers/generalManager";
import {useDispatch} from "react-redux";
// import {SelectedFile, setSelectedFile} from "../../store/old/data-slice";
// import {AppDispatch} from "../../store/old/store";
import {getServiceInfo} from "../files/file_services";
import {
    getServiceData,
    getServiceRecords, KnowledgeBaseItemOut,
    processResourceService,
    RequestParameters
} from "../../backend/extdata.services";
import colorSchema from "../../app_theme/theme_support/colorSchema";
import {AppDispatch} from "../../store/store";
import {SelectedFile} from "../../store/types";
import {setSelectedFile} from "../../store/dataSlice";

interface ShowAudioProps {
    file: SelectedFile;
    selectedGoal: string;
    onClose: () => void;
    onDataSelected: (request: string) => void;
}

const ShowAudio: React.FC<ShowAudioProps> = ({file, selectedGoal, onClose, onDataSelected}) => {
    const [audioUrl, setAudioUrl] = useState<string>("");
    const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
    const [viewMode] = useState<'modal' | 'embedded'>('embedded');
    const [transcriptionStatus, setTranscriptionStatus] = useState(false);
    const [subtitlesStatus, setSubtitlesStatus] = useState(false);
    const [transcriptionText, setTranscriptionText] = useState<string>("");
    const [subtitlesText, setSubtitlesText] = useState<string>("");
    const [serviceRecords, setServiceRecords] = useState<KnowledgeBaseItemOut[]>([]);
    const [useDataAsContext, setUseDataAsContext] = useState<boolean>(false);


    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        logger.info("ShowAudio: ", file);
        fetch_document_as_blob(file.metadata.container_name, file.metadata.blob_name).then((blob) => {
            const url = URL.createObjectURL(blob);
            setAudioUrl(url);
            logger.info("URL: ", url);
        });
        loadServiceInfo();
    }, [file]);

    const loadServiceInfo = useCallback(async () => {
        if (!file.uuid) return;

        const {
            service_records,
            transcriptionStatus,
            subtitlesStatus
        } = await getServiceInfo(appSession.currentKnowledgeBaseProject, file.uuid, getServiceRecords);
        setServiceRecords(service_records);
        setTranscriptionStatus(transcriptionStatus);
        setSubtitlesStatus(subtitlesStatus);
        logger.info("Transcription status: ", transcriptionStatus);
        logger.info("Subtitles status: ", subtitlesStatus);
    }, [file.metadata.blob_name]);

    const handleOnClose = () => {
        dispatch(setSelectedFile(undefined));

        // clean temp resources
        if ( audioUrl )
            URL.revokeObjectURL(audioUrl);

        onDataSelected("");
        onClose();
    }

    const handleAddRemoveTranscription = async () => {
        setShowPleaseWait(true);
        if (transcriptionStatus || !file.uuid) {
            setTranscriptionStatus(false);
            setTranscriptionText("");
        } else {
            const parameters: RequestParameters = {"parameters": "", "prompt": ""};
            await processResourceService(appSession.currentKnowledgeBaseProject, file.uuid, "transcribe", parameters);
            setTranscriptionStatus(true);
            handleShowServiceData("transcribe");
        }
        setShowPleaseWait(false);
    };

    const handleAddRemoveSubtitle = async () => {
        setShowPleaseWait(true);
        if (subtitlesStatus || !file.uuid) {
            // Remove subtitles logic here
            setSubtitlesStatus(false);
            setSubtitlesText("");
        } else {
            const parameters: RequestParameters = {"parameters": "", prompt: ""};
            await processResourceService(appSession.currentKnowledgeBaseProject, file.uuid, "subtitle", parameters);
            setSubtitlesStatus(true);
            handleShowServiceData("subtitle");
        }
        setShowPleaseWait(false);
    };

    const handleShowServiceData = async (serviceType: string) => {
        if (!file.uuid) return;

        // prevent fetching app_theme if already fetched
        if (serviceType === "transcribe" && transcriptionText !== "")
            return;

        if (serviceType === "subtitle" && subtitlesText !== "")
            return;

        setShowPleaseWait(true);
        try {
            const service_record = serviceRecords.filter(record => record.metadata_type === serviceType)[0];
            if (!service_record) {
                setShowPleaseWait(false);
                return;
            }

            const data = await getServiceData(appSession.currentKnowledgeBaseProject, service_record.uuid);
            if (serviceType === "transcribe") {
                setTranscriptionText(data);
            } else if (serviceType === "subtitle") {
                setSubtitlesText(data);
            }

        } catch (error: any) {
            logger.error(`Error fetching ${serviceType} data:`, error);
        }
        setShowPleaseWait(false);
    };

    const handleSelectData = () => {
        const newState = ! useDataAsContext;
        setUseDataAsContext(newState);
        if ( newState ) {
            onDataSelected(transcriptionText)
        }
    }

    return (
        <ComponentModalOrEmbedded onClose={handleOnClose} title="Show Audio" mode={viewMode}
                                  maxWidth={appSettings.maxWidth * 2}>
            <div style={{
                display: "flex",
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'flex-start',
                position: 'relative',
                flexDirection: 'column',
                padding: '5px',
            }}>
                <div style={{marginBottom: '10px'}}>
                    <Typography variant="h6" style={{color: colorSchema.primary.main}}>{file.fileName}</Typography>
                </div>

                {audioUrl && (
                    <audio
                        controls
                        src={audioUrl}
                        style={{width: '100%', maxWidth: appSettings.maxWidth * 2, marginBottom: '20px'}}
                    >
                        Your browser does not support the audio element.
                    </audio>
                )}

                <Box component="form"
                     sx={{
                         '& .MuiTextField-root': {m: 1},
                     }}
                     noValidate
                     autoComplete="off">

                    {( transcriptionStatus && transcriptionText === '' ) && (
                        <div style={{textAlign: 'center'}}>
                        <Typography variant="h6" style={{color: colorSchema.primary.main}}>Click here to download transcript</Typography>
                        <Button onClick={() => handleShowServiceData("transcribe")}><img src={transcription} style={{
                            width: '20px',
                            height: 'auto'
                        }} alt="Use Data"/></Button>
                        </div>
                    )}
                </Box>

                {transcriptionStatus && transcriptionText && (
                    <>
                    <Typography variant="h6" style={{color: colorSchema.primary.main}}>Transcript</Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={30}
                        variant="outlined"
                        label=""
                        value={transcriptionText}
                        InputProps={{
                            readOnly: true,
                        }}
                        style={{marginTop: '20px', marginBottom: '20px', backgroundColor: useDataAsContext ? colorSchema.action.selected : 'inherit' }}
                    />
                    </>
                )}

                {/*{subtitlesStatus && subtitlesText && (*/}
                {/*    <TextField*/}
                {/*        fullWidth*/}
                {/*        multiline*/}
                {/*        rows={4}*/}
                {/*        variant="outlined"*/}
                {/*        label="Subtitles"*/}
                {/*        value={subtitlesText}*/}
                {/*        InputProps={{*/}
                {/*            readOnly: true,*/}
                {/*        }}*/}
                {/*        style={{marginTop: '20px', marginBottom: '20px'}}*/}
                {/*    />*/}
                {/*)}*/}

                {(transcriptionStatus && transcriptionText) && (
                    <Button variant={"contained"} onClick={() => handleSelectData()}>{ ! useDataAsContext ? 'Select as Context Data' : 'Reset'}</Button>
                )}

            </div>
            {showPleaseWait && (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000,
                }}>
                    <CircularProgress aria-label="Loading"/>
                </div>
            )}
        </ComponentModalOrEmbedded>
    );
};

export default ShowAudio;