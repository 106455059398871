import React from 'react';
import { Box, Typography } from '@mui/material';

interface JsonViewerProps {
  jsonString: string;
}

type JsonValue = string | number | boolean | null | JsonArray | JsonObject;
interface JsonObject { [key: string]: JsonValue }
type JsonArray = JsonValue[];

const JsonViewer: React.FC<JsonViewerProps> = ({ jsonString }) => {
  const [parsedJson, setParsedJson] = React.useState<JsonValue | null>(null);

  React.useEffect(() => {
    if (!jsonString.trim()) {
      setParsedJson(null);
      return;
    }

    try {
      // Function to extract JSON from a string
      const extractJson = (str: string): string => {
        const jsonRegex = /{[\s\S]*}/;
        const match = str.match(jsonRegex);
        return match ? match[0] : '';
      };

      // Extract JSON from the string
      const extractedJsonString = extractJson(jsonString);

      if (!extractedJsonString) {
        console.warn('No valid JSON object found in the string');
        setParsedJson(null);
        return;
      }

      // Parse the extracted JSON
      const parsed: JsonValue = JSON.parse(extractedJsonString);
      setParsedJson(parsed);
    } catch (error) {
      console.error('Failed to parse JSON:', error);
      setParsedJson(null);
    }
  }, [jsonString]);

  const renderValue = (value: JsonValue): React.ReactNode => {
    if (typeof value === 'string') return <span style={{ color: '#008000' }}>"{value}"</span>;
    if (typeof value === 'number') return <span style={{ color: '#0000FF' }}>{value}</span>;
    if (typeof value === 'boolean') return <span style={{ color: '#FFA500' }}>{value.toString()}</span>;
    if (value === null) return <span style={{ color: '#808080' }}>null</span>;
    if (Array.isArray(value)) return renderArray(value);
    if (typeof value === 'object') return renderObject(value);
    return value;
  };

  const renderArray = (arr: JsonArray): React.ReactNode => (
    <Box sx={{ ml: 2 }}>
      [
      {arr.map((item, index) => (
        <Box key={index} sx={{ ml: 2 }}>
          {renderValue(item)}
          {index < arr.length - 1 && ','}
        </Box>
      ))}
      ]
    </Box>
  );

  const renderObject = (obj: JsonObject): React.ReactNode => (
    <Box sx={{ ml: 2 }}>
      {'{'}
      {Object.entries(obj).map(([key, value], index) => (
        <Box key={key} sx={{ ml: 2 }}>
          <Typography component="span" sx={{ color: '#800080' }}>"{key}"</Typography>: {renderValue(value)}
          {index < Object.keys(obj).length - 1 && ','}
        </Box>
      ))}
      {'}'}
    </Box>
  );

  if (!parsedJson) return <Typography color="error">No valid JSON found</Typography>;

  return (
    <Box sx={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap', overflow: 'auto' }}>
      {renderValue(parsedJson)}
    </Box>
  );
};

export default JsonViewer;