import React, {useState, useEffect,} from 'react';
import {
    Typography,
    CircularProgress,
    Box, Dialog, DialogTitle, DialogContent, Button, IconButton, Divider, Checkbox
} from '@mui/material';

import {Table, TableBody, TableCell, TableContainer, TableRow, Paper,} from '@mui/material';

import {
    delete_action_request,
    get_action_request_dates,
    get_action_state_list,
    RequestStateOut
} from "../../backend/zaia_state";
import DialogActions from "@mui/material/DialogActions";
import config from "../../config.json";
import {capitalizeFirstLetter} from "../../utils/promptServices";
import Viewer from "../assisant/external_data_viewer";
import DropdownSelector from "../chat/dropdownSelector";
import Tooltip from "@mui/material/Tooltip";
import {
    appSettings,
    languageOptions, selectedLanguage
} from "../../managers/generalManager";
import {action_run_task} from "../../backend/zaia_actions";
import CloseIcon from "@mui/icons-material/Close";
import FiberNewIcon from '@mui/icons-material/FiberNew';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RestartAltTwoToneIcon from "@mui/icons-material/RestartAltTwoTone";
import DeleteIcon from '@mui/icons-material/Delete';
import TableHead from "@mui/material/TableHead";
import logger from "../../utils/logging_services";
import {zaiaPart} from "../../app/app-styling";
import colorSchema from "../../app_theme/theme_support/colorSchema";

interface MyTasksManagerProps {
    requestId: string; // This prop is to pass the request_id to the get_action_state_list function
    handleRequestSubmit?: (request: string) => void;
    onClose?: () => void;
}

const service_url = config.mode === 'dev' ? config.server_url_dev : config.server_url_prod;

const MyTasksManager: React.FC<MyTasksManagerProps> = ({requestId, onClose, handleRequestSubmit}) => {
    const [actionList, setActionList] = useState<RequestStateOut[]>([]);
    const [selectedRecord, setSelectedRecord] = useState<RequestStateOut | null>(null);
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [showViewer, setShowViewer] = useState<boolean>(false);
    const [currentUrl, setCurrentUrl] = useState<string>('');
    const [uniqueDates, setUniqueDates] = useState<string[]>([])

    const [selectedDate, setSelectedDate] = useState<string>('');
    const [selectedState, setSelectedState] = useState<string>('');
    const [selectedAction, setSelectedAction] = useState<string>('');
    const [refreshList, setRefreshList] = useState<boolean>(false);
    const [mode, setMode] = useState<string>("view");
    const [selectedRecordsToDelete, setSelectedRecordsToDelete] = useState<string[]>([]);
    const [selectAll, setSelectAll] = useState<boolean>(false);

    useEffect(() => {

        if (refreshList) {

            setRefreshList(prevState => false);
            let from_date = "";
            let to_date = "";

            if (requestId === "all") {
                if (selectedDate === "") {
                    get_action_request_dates().then(data => {
                        setUniqueDates(data);
                        if (data.length > 0) setSelectedDate(data[0])
                    })
                    setLoading(false);
                    return;
                } else {
                    from_date = selectedDate;
                    to_date = selectedDate;
                }
            }

            setLoading(true);
            get_action_state_list(requestId, from_date, to_date)
                .then(data => {
                    setActionList(data);
                    setLoading(false);
                })
                .catch(error => {
                    setError(error.message);
                    setLoading(false);
                });
        }

    }, [refreshList]);

    useEffect(() => {
        setRefreshList(prevState => true)
    }, [requestId, selectedDate]);

    const handleSelect = (uuid: string) => {
        const selectedIndex = selectedRecordsToDelete.indexOf(uuid);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRecordsToDelete, uuid);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRecordsToDelete.slice(1));
        } else if (selectedIndex === selectedRecordsToDelete.length - 1) {
            newSelected = newSelected.concat(selectedRecordsToDelete.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRecordsToDelete.slice(0, selectedIndex),
                selectedRecordsToDelete.slice(selectedIndex + 1),
            );
        }

        setSelectedRecordsToDelete(newSelected);
    };


    const deleteSelectedRecords = () => {

        // loop through the selected records and delete them
        selectedRecordsToDelete.forEach((uuid) => {
            delete_action_request(uuid).then(
                () => {
                    logger.info(`Record with uuid: ${uuid} deleted successfully`);
                }
            ).catch(
                (error) => {
                    logger.error("Error during deleteSelectedRecords", error);
                }
            )
        });

    };

    // Show details
    const handleRowClick = (record: RequestStateOut) => {
        setSelectedRecord(prevState => record);

        // check if status is completed and if there is a document and a container_name key
        if (record.status === 'completed' && record.output_data) {
            try {
                const jsonData = JSON.parse(record.output_data as string);

                if ('document' in jsonData && 'container_name' in jsonData) {
                    const link = `${service_url}/modules/zaia_services_state/document/request/${record.uuid}`;
                    handleSetUrl(link);
                    return;
                }
            } catch (error) {
                console.error("Error parsing JSON:", error);
                // Handle the error as per your application's error handling policy
            }
        }
        setShowDetails(true);

    };

    const handleShowDetails = () => {
        setShowDetails(true);
        setShowViewer(false);
    }


    const sortedActionList = [...actionList].sort((a: RequestStateOut, b: RequestStateOut) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB.getTime() - dateA.getTime(); // Descending order
    });

    const filteredActionList: RequestStateOut[] = sortedActionList.filter((item: RequestStateOut) => {
        return (!selectedDate || new Date(item.created_at).toISOString().split('T')[0] === selectedDate) &&
            (!selectedState || item.status === selectedState) &&
            (!selectedAction || item.action === selectedAction);
    });

    const uniqueStates = Array.from(new Set(actionList.map((item) => item.status)));
    uniqueStates.unshift('');
    const uniqueActions = Array.from(new Set(actionList.map((item) => item.action)));
    uniqueActions.unshift('');


    function handleSetUrl(url: string) {
        setCurrentUrl(url);
        setShowViewer(true);
        showDetails && setShowDetails(false);
    }

    function handleClose() {
        if (onClose) {
            onClose();
            setMode(prevState => 'view')
            setSelectedRecordsToDelete([]);
        }
    }

    function handleRefresh() {
        setRefreshList(prevState => true);
        setMode(prevState => 'view')
        setSelectedRecordsToDelete([]);
    }

    function handleSwitchMode() {
        if (mode === 'view') {
            setMode(prevState => 'delete');
        } else {
            setSelectedRecordsToDelete([]);
            setMode(prevState => 'view');
        }
    }

    async function handleOnRequest(name: string, request: string, submit: boolean = true) {
        logger.info("Requesting next task...")
        const context_data = {
            "document": selectedRecord?.uuid,
            "instructions": request,
        }
        const language = languageOptions.find(option => option.value === selectedLanguage)?.label || 'English'

        if (submit) {
            if (name === '') {
                name = 'Perform Request'
            }

            const taskRequest = {
                name: name,
                task: "run prompt",
                context_data: JSON.stringify(context_data),
                context_type: 'json',
                language: language,
                temperature: appSettings.temperature,
                version: appSettings.selectedLLMModelVersion,
                provider: appSettings.selectedLLMModelProvider
            };
            logger.info("handleOnRequest", taskRequest)
            const response = await action_run_task(taskRequest);
            logger.info(`response: ${JSON.stringify(response)}`)
        } else {

            const response = await fetch(currentUrl);
            const html = await response.text();
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, "text/html");
            const bodyContent = doc.body ? doc.body.textContent : null;
            if (bodyContent) {
                const prompt = "Data : " + bodyContent + " \n Instructions : " + request;
                handleRequestSubmit && handleRequestSubmit(prompt);
            }
        }
        setShowViewer(false);
        setRefreshList(true);
    }

    function handleActionStateDetailsClose(refresh: boolean) {
        setShowDetails(false);
        setRefreshList(refresh);
    }

    const getIconForStatus = (state: string) => {
        switch (state) {
            case 'new':
                return (
                    <Tooltip title="New - Registration not yet started">
                        <FiberNewIcon color={"info"}/>
                    </Tooltip>
                );
            case 'initialized':
                return (
                    <Tooltip title="Initialized - Process is running">
                        <PlayCircleFilledIcon color={"primary"}/>
                    </Tooltip>
                );
            case 'error':
                return (
                    <Tooltip title="Error - Process failed">
                        <ErrorIcon color={"warning"}/>
                    </Tooltip>
                );
            case 'completed':
                return (
                    <Tooltip title="Complete - Request processed">
                        <CheckCircleIcon color={"success"}/>
                    </Tooltip>
                );
            default:
                return (
                    <Tooltip title="{state}">
                        <>{state}</>
                    </Tooltip>
                );
        }
    };

    const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelectAll(true);
            const newSelected = filteredActionList.map((n) => n.uuid);
            setSelectedRecordsToDelete(newSelected);
        } else {
            setSelectAll(false);
            setSelectedRecordsToDelete(prevState => []);
        }
    }

    const handleDeleteSelected = () => {
        deleteSelectedRecords();
        handleSwitchMode();
    }

    // --------------------------------------------------------------------------------------------
    // Row component
    // --------------------------------------------------------------------------------------------
    interface TableRowComponentProps {
        requestState: RequestStateOut;
        mode: string;
        isSelected: boolean;
        handleRowClick: (requestState: TableRowComponentProps['requestState']) => void;
        handleSelect: (uuid: string) => void;
    }

    const TableRowComponent = React.memo(({
                                              requestState,
                                              mode,
                                              isSelected,
                                              handleRowClick,
                                              handleSelect
                                          }: TableRowComponentProps) => {
        return (
            <TableRow
                key={requestState.uuid}
                sx={{
                    '&:hover': {
                        backgroundColor: colorSchema.action.hover,
                    },
                }}
                onClick={() => mode === 'view' ? handleRowClick(requestState) : null}
            >
                {mode === 'delete' && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            checked={isSelected}
                            onChange={() => handleSelect(requestState.uuid)}
                        />
                    </TableCell>
                )}
                <TableCell sx={{textAlign: 'start'}}>
                    {requestState.action_name === '' ? capitalizeFirstLetter(requestState.action) : capitalizeFirstLetter(requestState.action_name)}
                </TableCell>
                <TableCell sx={{textAlign: 'end'}}>
                    {getIconForStatus(requestState.status)}
                </TableCell>
            </TableRow>
        );
    });

    // ============================================================================================================
    return (
        <div style={zaiaPart({})}>
            {showDetails && (
                <TaskDetails
                    request={selectedRecord!}
                    onClose={handleActionStateDetailsClose}
                    setUrl={handleSetUrl}
                    showResultWhenReady={false}
                />
            )}


            {showViewer && (
                <Viewer url={currentUrl}
                        onClose={() => setShowViewer(false)}
                        onRequestDetails={handleShowDetails}
                        onRequestNextTask={handleOnRequest}/>
            )}

            <div style={{
                display: 'flex',
                flex: '1 1 0px',
                flexDirection: 'column',
                // maxWidth: appSettings.maxWidth,
                // minWidth: appSettings.maxWidth,
                height: '100%'
            }}>
                {loading ? (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <CircularProgress/>
                    </div>
                ) : error ? (
                    <Typography color="error">{error}</Typography>
                ) : (
                    <div style={{display: 'flex', flexDirection: 'column', flex: '1 1 0px'}}>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <Typography variant={"h5"} sx={{
                                paddingLeft: '10px',
                                marginBottom: '10px',
                                textAlign: 'center',
                                color: colorSchema.text.primary
                            }}>My Tasks</Typography>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                                {/*<IconButton style={{marginRight: '20px'}} onClick={() => handleSwitchMode()}*/}
                                {/*            name="delete" size={"small"}>*/}
                                {/*    <DeleteIcon style={{color: colorSchema.warning}}/>*/}
                                {/*</IconButton>*/}
                                {/*<IconButton onClick={() => handleRefresh()} name="refresh" size={"small"}>*/}
                                {/*    <RestartAltTwoToneIcon style={{color: colorSchema.iconColor}}/>*/}
                                {/*</IconButton>*/}
                                <IconButton onClick={() => handleClose()} name="close" size={"small"}>
                                    <CloseIcon style={{color: colorSchema.primary.main}}/>
                                </IconButton>
                            </div>
                        </div>
                        <Divider sx={{borderColor: colorSchema.grey["400"]}}/>
                        <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <Box style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '10px',
                                marginBottom: '20px',
                                gap: '10px',
                            }}>

                                <DropdownSelector
                                    options={uniqueActions.map(action => ({label: action, value: action}))}
                                    label={'Select an action'}
                                    defaultValue={selectedAction}
                                    blankLabel={'- remove filter -'}
                                    onChange={(value) => setSelectedAction(value)}/>
                                <DropdownSelector
                                    options={uniqueStates.map(state => ({label: state, value: state}))}
                                    label={'Select an state'}
                                    defaultValue={selectedState}
                                    blankLabel={'- remove filter -'}
                                    onChange={(value) => setSelectedState(value)}/>
                                <DropdownSelector
                                    options={uniqueDates.map(date => ({label: date, value: date}))}
                                    label={"Select a date"}
                                    defaultValue={selectedDate}
                                    blankLabel={'- remove filter -'}
                                    onChange={(value) => setSelectedDate(value)}

                                />
                            </Box>

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    overflowY: 'auto',
                                    flex: '1 1 0px'
                                }}>
                                <TableContainer component={Paper}
                                                sx={{backgroundColor: colorSchema.background.default}}>
                                    <Table stickyHeader aria-label="action list table" size={"small"}>
                                        {mode === 'delete' && (
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={selectAll}
                                                            onChange={handleSelectAllChange}
                                                            indeterminate={selectedRecordsToDelete.length > 0 && selectedRecordsToDelete.length < filteredActionList.length}
                                                        />
                                                    </TableCell>
                                                    <TableCell colSpan={2} align={'right'}>
                                                        <Button size={"small"} color={"warning"}
                                                                variant={"outlined"}
                                                                onClick={handleDeleteSelected}>Delete
                                                            Selected</Button>
                                                        <IconButton onClick={() => handleSwitchMode()}
                                                                    name="closeDelete"
                                                                    size={"small"} sx={{marginLeft: '5px'}}>
                                                            <CloseIcon style={{color: colorSchema.primary.main}}/>
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                        )}
                                        <TableBody>
                                            {filteredActionList.map((requestState) => {
                                                const isSelected = selectedRecordsToDelete.includes(requestState.uuid);

                                                return (
                                                    <TableRowComponent
                                                        key={requestState.uuid}
                                                        requestState={requestState}
                                                        mode={mode}
                                                        isSelected={isSelected}
                                                        handleRowClick={handleRowClick}
                                                        handleSelect={handleSelect}
                                                    />
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                )}
                <Box sx={{
                    height: '20px',
                    textAlign: 'center',
                    alignContent: 'center',
                    padding: '20px',
                    borderRadius: '10px',
                    backgroundColor: colorSchema.background.default,
                    color: colorSchema.primary.main,
                }}>
                    <Divider/>
                    <IconButton style={{marginRight: '20px'}} onClick={() => handleSwitchMode()}
                                name="delete" size={"small"}>
                        <DeleteIcon style={{color: colorSchema.warning.main}}/>
                    </IconButton>
                    <IconButton onClick={() => handleRefresh()} name="refresh" size={"small"}>
                        <RestartAltTwoToneIcon style={{color: colorSchema.primary.main}}/>
                    </IconButton>

                </Box>
            </div>
        </div>
    );
};

interface TaskDetailsProps {
    request: RequestStateOut;
    setUrl: (url: string) => void;
    onClose: (refresh: boolean) => void;
    showResultWhenReady?: boolean;
}

const TaskDetails: React.FC<TaskDetailsProps> = ({request, onClose, setUrl, showResultWhenReady}) => {

    const [selectedRecord, setSelectedRecord] = useState<RequestStateOut>(request);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(true);

    const renderOutputData = (field: string, field_type: string) => {

        const isUrl = (value: string) => {
            try {
                new URL(value);
                return true;
            } catch (_) {
                return false;
            }
        };

        const truncate = (str: string, n: number) => {
            return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
        }

        if (field_type === 'json') {
            try {
                const jsonData = JSON.parse(field);
                const keys = Object.keys(jsonData);
                // check if there is a document and a container_name key
                if (keys.includes('document') && keys.includes('container_name')) {
                    const link = service_url + `/modules/zaia_services_state/document/request/${selectedRecord.uuid}`
                    keys.splice(keys.indexOf('document'), 1);
                    keys.splice(keys.indexOf('container_name'), 1);
                    keys.push('document');
                    jsonData['document'] = link;

                    if (showResultWhenReady) {
                        setUrl(link);
                        return (<></>);
                    }
                }

                return (
                    <TableContainer component={Paper}>
                        <Table aria-label="json output data">
                            <TableBody>
                                {keys.map((key) => (
                                    <TableRow key={key}>
                                        <TableCell size={"small"}>{key}</TableCell>
                                        {isUrl(jsonData[key]) ? (
                                            <TableCell>
                                                <Button variant="contained" color="info" size={"small"}
                                                        onClick={() => setUrl(jsonData[key])}>
                                                    Open Link
                                                </Button>
                                            </TableCell>
                                        ) : (
                                            <>
                                                {jsonData[key].length > 250 ? (
                                                    <Tooltip title={jsonData[key]}>
                                                        <TableCell>{truncate(jsonData[key], 250)}</TableCell>
                                                    </Tooltip>
                                                ) : (
                                                    <TableCell>{typeof jsonData[key] === 'string' ? truncate(jsonData[key], 250) : jsonData[key]}</TableCell>
                                                )}
                                            </>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                );
            } catch (error) {
                return <Typography color="error">Error parsing JSON data.</Typography>;
            }
        }
        return null; // Or a message indicating the app_theme is not JSON
    };

    function handleClose() {
        setIsDialogOpen(false);
        if (request.status === selectedRecord.status) {
            onClose(false);
        } else {
            onClose(true);
        }
    }

    function handleRefresh() {
        const from_date = "";
        const to_date = "";

        get_action_state_list(request.uuid, from_date, to_date)
            .then(data => {
                setSelectedRecord(data[0]);
            })
            .catch(error => {
                logger.info(error);
            });
    }

    function handleDelete() {
        delete_action_request(request.uuid).then(
            () => {
                setIsDialogOpen(false);
                onClose(true);
            }
        ).catch(
            (error) => {
                console.log(error);
            }
        )
    }

    return (
        <Dialog
            open={isDialogOpen}
            onClose={() => handleClose()}
            sx={{
                '& .MuiDialog-paper': {
                    width: 700,
                    height: 700,
                    maxWidth: '100%',
                    maxHeight: '100%'
                }
            }}
        >
            <DialogTitle
                variant={"h5"}
                id="title_assistant_dialog"
                align={"center"}
                color={colorSchema.primary.main}
                sx={{
                    backgroundColor: colorSchema.background.default,
                    boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
                }}
            >
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Typography variant={"h5"} sx={{
                        marginBottom: '10px',
                        fontWeight: '700'
                    }}>{capitalizeFirstLetter(selectedRecord.action)}</Typography>
                    <Typography variant={"h5"}
                                sx={{marginBottom: '10px'}}>{capitalizeFirstLetter(selectedRecord.status)}</Typography>
                </Box>

            </DialogTitle>
            <DialogContent>
                {selectedRecord && (
                    <Box>

                        <Table aria-label="simple table" size={"medium"}
                               sx={{marginBottom: '10px'}}>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={{fontWeight: '700'}}>Date</TableCell>
                                    <TableCell>{new Date(selectedRecord.created_at).toLocaleString()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={{fontWeight: '700'}}>Output
                                    </TableCell>
                                    <TableCell>{renderOutputData(
                                        selectedRecord!.output_data,
                                        selectedRecord!.output_type)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={{fontWeight: '700'}}>
                                        Data</TableCell>
                                    <TableCell>{renderOutputData(
                                        JSON.stringify(selectedRecord!.meta_data),
                                        'json')}
                                    </TableCell>
                                </TableRow>
                                {selectedRecord?.log_data !== '' && (
                                    <TableRow key='log_data'>
                                        <TableCell sx={{fontWeight: '700'}}>Error</TableCell>
                                        <TableCell>{selectedRecord?.log_data}</TableCell>
                                    </TableRow>
                                )}

                            </TableBody>
                        </Table>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                {selectedRecord && ['new', 'initialized'].includes(selectedRecord.status) && (
                    <Button
                        variant="contained"
                        sx={{marginBottom: '5px', marginRight: '5px'}}
                        color="info"
                        onClick={() => handleRefresh()}
                    >
                        Refresh state
                    </Button>
                )}

                <Button variant={"contained"} sx={{marginBottom: '5px', marginRight: '5px'}} color={"warning"}
                        onClick={() => handleDelete()}>Delete</Button>

                <Button variant={"contained"} sx={{marginBottom: '5px', marginRight: '5px'}} color={"warning"}
                        onClick={() => handleClose()}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};


export default MyTasksManager;
