import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WorkspaceItem } from './types';
// import { fetchWorkspaceItems, FetchWorkspaceItemsResult, updateWorkspaceItemService, deleteWorkspaceItemService, addWorkspaceItemService } from './old/data-thunk';
import logger from "../utils/logging_services";
import {
    addWorkspaceItemService,
    deleteWorkspaceItemService,
    fetchWorkspaceItems,
    FetchWorkspaceItemsResult,
    updateWorkspaceItemService
} from "./thunks";

interface WorkspaceItemsState {
    items: WorkspaceItem[];
    error: string | null;
}

const initialState: WorkspaceItemsState = {
    items: [],
    error: null,
};

const workspaceItemsSlice = createSlice({
    name: 'workspaceItems',
    initialState,
    reducers: {
        setWorkspaceItems: (state, action: PayloadAction<WorkspaceItem[]>) => {
            state.items = action.payload;
        },
        updateWorkspaceItem: (state, action: PayloadAction<WorkspaceItem>) => {
            const index = state.items.findIndex(item => item.uuid === action.payload.uuid);
            if (index !== -1) {
                state.items[index] = action.payload;
            }
        },
        removeWorkspaceItem: (state, action: PayloadAction<string>) => {
            state.items = state.items.filter(item => item.uuid !== action.payload);
        },
        selectWorkspaceItem: (state, action: PayloadAction<string>) => {
            const item = state.items.find(item => item.uuid === action.payload);
            if (item) {
                item.selected = true;
            }
        },
        deselectWorkspaceItem: (state, action: PayloadAction<WorkspaceItem>) => {
            const index = state.items.findIndex(item => item.uuid === action.payload.uuid);
            if (index !== -1) {
                state.items[index].selected = false;
            }
        },
        resetSelectedWorkspaceItems: (state) => {
            state.items.forEach(item => {
                item.selected = false;
            });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWorkspaceItems.fulfilled, (state, action: PayloadAction<FetchWorkspaceItemsResult>) => {
                logger.info('fetchWorkspaceItems.fulfilled', action.payload);

            if (action.payload.snippets.length === 0) {
                state.items = [];
                return;
            }

            const newItems = action.payload.snippets.map(item => ({
                ...item,
                selected: false,
                user_scope: action.payload.user_scope
            }));

            // Create a map of current items for quick lookup
            const currentItemsMap = new Map(state.items.map(item => [item.uuid, item]));

            newItems.forEach(newItem => {
                const existingItem = currentItemsMap.get(newItem.uuid);
                if (existingItem) {
                    // Update the existing item while preserving the selected state
                    Object.assign(existingItem, newItem, {selected: existingItem.selected, user_scope: true});
                } else {
                    // Add the new item if it doesn't exist
                    state.items.push(newItem);
                }
            });
            })
            .addCase(fetchWorkspaceItems.rejected, (state, action) => {
                state.items = [];
                state.error = action.payload as string;
            })
            .addCase(fetchWorkspaceItems.pending, (state) => {
                state.error = null;
            })
            .addCase(updateWorkspaceItemService.fulfilled, (state, action) => {
                const updatedItem = action.payload as WorkspaceItem;
                const index = state.items.findIndex(item => item.uuid === updatedItem.uuid);
                if (index !== -1) {
                    state.items[index] = updatedItem;
                }
            })
            .addCase(updateWorkspaceItemService.rejected, (state, action) => {
                state.error = action.payload as string;
            })
            .addCase(deleteWorkspaceItemService.fulfilled, (state, action) => {
                // Remove the deleted item from the state
                state.items = state.items.filter(item => item.uuid !== action.payload);
            })
            .addCase(deleteWorkspaceItemService.rejected, (state, action) => {
                state.error = action.payload as string;
            })
            .addCase(addWorkspaceItemService.fulfilled, (state, action) => {
                state.items.push({
                    ...action.payload,
                    selected: false,
                    user_scope: true
                });
            })
            .addCase(addWorkspaceItemService.rejected, (state, action) => {
                state.error = action.payload as string;
            });
    },
});

export const {
    setWorkspaceItems,
    updateWorkspaceItem,
    removeWorkspaceItem,
    selectWorkspaceItem,
    deselectWorkspaceItem,
    resetSelectedWorkspaceItems,
} = workspaceItemsSlice.actions;

export default workspaceItemsSlice.reducer;