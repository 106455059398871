import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import ErrorBoundary from "./utils/error_boundary";
import App from "./app/app";

import './index.css';
import './app/app.css';
import store, {persistor} from "./store/store";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

// window.addEventListener('error', (e) => {
//   if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
//     e.stopImmediatePropagation();
//   }
// });

root.render(
      <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                {/*<ErrorBoundary>*/}
                    {<App/>}
                {/*</ErrorBoundary>*/}
            </PersistGate>
        </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
