import React, {useEffect, useRef, useState} from 'react';
import {
    Divider,
    IconButton,
    Typography,
    Tooltip, Box, Grid, TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useDispatch, useSelector} from "react-redux";

import AppDialog from "../general/app_dialog";
import {EditorViewer} from "../viewers/editor_viewer";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import AddIcon from "@mui/icons-material/Add";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {appSession, AppState} from "../../managers/generalManager";

// import {
//     Workspace
// } from "../../store/old/data-slice";
// import {
//     deleteWorkspaceItemService,
//     fetchLinkedInItems,
//     updateWorkspaceItemService
// } from "../../store/old/data-thunk";
// import {AppDispatch, AppState} from "../../store/old/store";

import draftToHtml from "draftjs-to-html";
import {saveContentToDisk} from "../../utils/common_tools";
import logger from "../../utils/logging_services";
// import {
//     removeLinkedInItem,
//     resetSelectedLinkedInItems,
//     updateLinkedInItem, WorkspaceItem
// } from "../../store/old/temp-slice";
import {LinkedInSnippetViewer} from "./linkedin_snippet_viewer";
import {LinkedInProfile, SnippetData} from "../../backend/snippet.services";
import {getWorkspaceItemData, updateWorkspaceItemData} from "../../utils/snippet_tools";
import {zaiaPart} from "../../app/app-styling";
import colorSchema from "../../app_theme/theme_support/colorSchema";
import {Workspace, WorkspaceItem} from "../../store/types";
import {AppDispatch, RootState} from "../../store/store";
import {deleteWorkspaceItemService, fetchLinkedInItems, updateWorkspaceItemService} from "../../store/thunks";
import {removeLinkedInItem, resetSelectedLinkedInItems, updateLinkedInItem} from "../../store/linkedinItemSlice";

export enum WorkspaceType {
    WORKSPACE = 'workspaces',
    LINKEDIN = 'linkedin'
}

interface LinkedInViewerProps {
    open: boolean;
    onShowWorkSpaceItem: (workspaceItem: WorkspaceItem) => void;

    onSendRequest(request: string): void;

    onClose: () => void;
}


export const LinkedInViewer = ({
                                   open,
                                   onClose,
                                   onShowWorkSpaceItem,
                                   onSendRequest,
                               }: LinkedInViewerProps) => {

    const tooltipTimeoutRef = useRef<number | null>(null);
    const [showWorkspaceItemEditor, setShowWorkspaceItemEditor] = useState(false);
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
    const [addSnippet, setAddSnippet] = useState(false);
    const [selectedSubtype, setSelectedSubtype] = useState<string | null>(null);
    const [, setMenuOptionSelected] = useState(false);
    const [showWorkspaceManager, setShowWorkspaceManager] = useState(false);
    const [snippetData, setSnippetData] = useState<string>("");
    const [selectedWorkspaceItem, setSelectedWorkspaceItem] = useState<WorkspaceItem | undefined>(undefined);
    const [filteredItems, setFilteredItems] = useState<WorkspaceItem[]>([]);
    const [uniqueSubtypes, setUniqueSubtypes] = useState<string[]>([]);
    const [name, setName] = useState<string>("");

    const dispatch = useDispatch<AppDispatch>();
    const selectedWorkspace: Workspace | undefined = useSelector((state: RootState) => state.data.selectedWorkspace);

    const workspaceItems = useSelector((state: RootState) => state.workspaceItems.items);
    const linkedinItems = useSelector((state: RootState) => state.linkedInItems.items);

    // Load the workspace items for the selected workspace
    useEffect(() => {

        if (!appSession.currentHrProject) return;

        dispatch(fetchLinkedInItems({
            user_scope: true,
            currentProject: appSession.currentHrProject,
            accessToken: appSession.accessToken
        }));

    }, [dispatch]);

    useEffect(() => {

        if (!linkedinItems) return;

        const filteredItems = [...linkedinItems]
            .sort((a, b) => a.name.localeCompare(b.name));

        setFilteredItems(filteredItems);
        setUniqueSubtypes(["LinkedIn_Profile", "LinkedIn_Data"]);
        setSelectedSubtype('LinkedIn_Profile');
        return;

    }, [linkedinItems])

    // Load snippet app_theme based on the type of app_theme
    useEffect(() => {

        if (!appSession.currentHrProject) return;

        dispatch(fetchLinkedInItems({
            user_scope: true,
            currentProject: appSession.currentHrProject,
            accessToken : appSession.accessToken
        }));

    }, [selectedWorkspace])


    // // Cleanup the timeout when the component unmounts
    // useEffect(() => {
    //     return () => {
    //         if (tooltipTimeoutRef.current !== null) {
    //             clearTimeout(tooltipTimeoutRef.current);
    //         }
    //     };
    // }, []);

    function deleteWorkspaceItem(id: string) {
        logger.info("Deleting workspace item", id);
        dispatch(removeLinkedInItem(id));
        dispatch(deleteWorkspaceItemService(id));
    }

    function sendWorkspaceItemToChat(id: string) {
        const selectedItem = linkedinItems.find(ws => ws.uuid === id)
        if (selectedItem)
            onShowWorkSpaceItem(selectedItem);
    }


    // Mark the selected item for editing
    const editWorkspaceItem = (id: string) => {
        console.log("Editing workspace item", id)
        const selectedItem = linkedinItems.find(ws => ws.uuid === id);
        if (!selectedItem) return;
            loadEditorState(getWorkspaceItemData(selectedItem));
            setName(selectedItem.name);
            setShowWorkspaceItemEditor(true);
    }

    // Update the selected item
    const handleUpdateWorkspaceItem = () => {
        if (selectedWorkspaceItem) {

            if (name.trim() === '') {
                return;
            }

            const updatedItem = updateWorkspaceItemData(
                selectedWorkspaceItem,
                draftToHtml(convertToRaw(editorState.getCurrentContent()))
                );

            dispatch(updateLinkedInItem(updatedItem));
            dispatch(updateWorkspaceItemService(updatedItem as unknown as SnippetData));
        }
        setName("");
        setShowWorkspaceItemEditor(false);
    }

    // Load the editor state from the HTML content
    const loadEditorState = (value: string) => {
        let {contentBlocks, entityMap} = htmlToDraft(value || "");

        // Create a new content state from the HTML content
        const newContentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        // Create a new EditorState with the new content
        const newEditorState = EditorState.createWithContent(newContentState);

        // Set the new EditorState
        setEditorState(newEditorState);
    }

    function handleEditorStateChange(newState: EditorState) {
        setEditorState(newState);
    }

    function handleAddSnippet() {
        setAddSnippet(true);
    }

    const handleRequestWrapper = (action: string, key: string) => {

        const currentWorkSpaceItem = linkedinItems.find(ws => ws.uuid === key);
        if (!currentWorkSpaceItem) return;

        setSelectedWorkspaceItem(currentWorkSpaceItem);

        if (action === 'delete') {
            deleteWorkspaceItem(key);
        }
        if (action === 'use') {
            sendWorkspaceItemToChat(key);
        }
        if (action === 'edit') {
            editWorkspaceItem(key);
        }
        if (action === 'save') {

            const contentToSave = currentWorkSpaceItem.content as LinkedInProfile;

            saveContentToDisk(`${currentWorkSpaceItem.name}.html`, contentToSave.data)
                .then(() => {
                    logger.info('Content saved successfully.');
                })
                .catch((error) => {
                    console.error('Failed to save content:', error);
                });
        }
    };

    const handleResetSelectedItems = () => {
        dispatch(resetSelectedLinkedInItems());
    }

    return (
        <div style={zaiaPart({})}>
            {open && (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '300px',
                    minWidth: '300px',
                    height: '100%'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: '10px'
                    }}>
                        <Typography variant={"h5"}>{selectedWorkspace?.name}</Typography>
                        <IconButton onClick={onClose} size={"small"}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Divider/>

                    <LinkedInSnippetViewer
                        open={true}
                        onHandleRequest={handleRequestWrapper}
                        itemType={WorkspaceType.LINKEDIN}
                        uniqueCategories={uniqueSubtypes}
                        filteredItems={filteredItems}
                    />

                    <Box sx={{
                        height: '20px',
                        textAlign: 'center',
                        alignContent: 'center',
                        padding: '20px',
                        borderRadius: '10px',
                        backgroundColor: colorSchema.background.default,
                        color: colorSchema.primary.main,
                    }}>

                        <Divider/>

                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>
                                <Tooltip title={"Reset"}>
                                    <IconButton onClick={() => handleResetSelectedItems()}
                                                name="reset selected items"
                                                size={"small"}>
                                        <RestartAltIcon style={{color: colorSchema.warning.main}}/>
                                    </IconButton>
                                </Tooltip>
                            </div>

                            <div>
                                <Tooltip title={"Create (empty/merged)"}>
                                    <IconButton onClick={() => handleAddSnippet()} name="add snippet"
                                                size={"small"}>
                                        <AddIcon style={{color: colorSchema.text.primary}}/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={"Manage Projects"}>
                                    <IconButton onClick={() => setShowWorkspaceManager(true)} name="manage projects"
                                                size={"small"}>
                                        <ManageAccountsIcon style={{color: colorSchema.grey["400"]}}/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </Box>
                </div>
            )}
            {showWorkspaceItemEditor && (

                <AppDialog
                    open={true}
                    size={"xl"}
                    showSubmit={true}
                    showCancel={true}
                    title={`Edit Workspace Item`}
                    message={""}
                    onSubmit={handleUpdateWorkspaceItem}
                    onCancel={() => setShowWorkspaceItemEditor(false)}
                >
                    <Grid container display={"flex"} flexDirection={"row"} spacing={2} marginTop={"5px"}
                          sx={{height: '800px'}} overflow={"hidden"}>


                        <TextField
                            id="workspace_name"
                            label="Workspace Name"
                            fullWidth
                            error={name.trim() === ''}
                            margin="normal"
                            variant="outlined"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />


                        <div style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            height: '100%',
                            backgroundColor: colorSchema.background.default,
                        }}>
                            <EditorViewer editorState={editorState}
                                          showActions={false}
                                          onEditorStateChange={handleEditorStateChange}
                                          onSendRequest={() => {
                                          }}
                                          onUpdateEditorState={setEditorState}/>
                        </div>
                    </Grid>
                </AppDialog>
            )}
        </div>
    );
};