import React from "react";
import AppDialog from "./app_dialog";
import {Divider, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {zaiaPart} from "../../app/app-styling";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import {appSession, appSettings} from "../../managers/generalManager";
import ColorSchema from "../../app_theme/theme_support/colorSchema";

interface ComponentModalOrEmbeddedProps {
    children: React.ReactNode;
    mode: 'modal' | 'embedded';
    onModeChange?: (mode: 'modal' | 'embedded') => void;
    size?: 'sm' | 'md' | 'lg' | 'xl';
    color? : string;
    title: string;
    maxWidth: number;
    onClose: () => void;
}

const ComponentModalOrEmbedded: React.FC<ComponentModalOrEmbeddedProps> = ({
                                                                               children,
                                                                               mode,
                                                                               size = 'md',
                                                                               color = ColorSchema.background.default,
                                                                               title,
                                                                               maxWidth,
                                                                               onClose,
                                                                               onModeChange
                                                                           }: ComponentModalOrEmbeddedProps) => {

    const [currentMode, setCurrentMode] = React.useState<'modal' | 'embedded'>(mode);

    const onHandleSwitchMode = () => {

        const newMode = currentMode === 'modal' ? 'embedded' : 'modal';
        setCurrentMode(newMode);
        onModeChange && onModeChange(newMode);
    };

    if (currentMode === 'modal') {
        return (
            <AppDialog
                onCancel={onHandleSwitchMode}
                open={true}
                size={size}
                title={title}
                message=""
                showCancel={true}
                showSubmit={false}
                fullHeight={true}
                onSubmit={() => {
                }}
            >
                <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start'}}>
                    {children}
                </div>
            </AppDialog>
        );
    } else {
        return (
            <div style={{
                ...zaiaPart({maxWidth: maxWidth}),
                borderRadius: '8px', // Rounded corners for the outer container
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
                padding: '16px', // Padding for inner content spacing
                margin: '10px', // Margin around the component for separation
            }}>

                {/* Title Section with Background Color */}
                <div style={{
                    minHeight: '45px',
                    display: 'flex',
                    flexDirection: 'row',
                    color: '#fff', // White text color for contrast
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: color, // Apply background color to the title section
                    borderTopLeftRadius: '8px', // Rounded corners on top
                    borderTopRightRadius: '8px', // Rounded corners on top
                    padding: '8px 12px', // Padding for content within the title section
                }}>
                    <Typography variant={"h5"} sx={{marginLeft: '5px'}}>{title}</Typography>
                    <div>
                        <IconButton onClick={onHandleSwitchMode} size={"small"}>
                            <OpenInFullIcon/>
                        </IconButton>
                        <IconButton onClick={onClose} size={"small"}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </div>

                {/* Divider */}
                <Divider sx={{marginBottom: '10px'}}/>

                {/* Content Section */}
                <div style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    overflow: 'auto', // Allow scrolling if content exceeds container height
                    padding: '10px' // Padding for content
                }}>
                    {children}
                </div>
            </div>

        );
    }
}

export default ComponentModalOrEmbedded;