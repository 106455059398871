// globalStyles.ts
import { createTheme } from '@mui/material/styles';

const theme = createTheme();

const globalStyles = {
  '@global': {
    html: {
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      boxSizing: 'border-box',
      fontSize: '16px', // Base font size
    },
    '*, *::before, *::after': {
      boxSizing: 'inherit',
    },
    body: {
      margin: 0,
      padding: 0,
      fontFamily: "'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      lineHeight: 1.5,
      color: 'rgba(0, 0, 0, 0.87)',
      backgroundColor: '#fafafa',
    },
    'h1, h2, h3, h4, h5, h6': {
      marginTop: '1em',
      marginBottom: '0.5em',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h1: {
      fontSize: '2rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '2.5rem',
      },
    },
    h2: {
      fontSize: '1.75rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '2rem',
      },
    },
    h3: {
      fontSize: '1.5rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.75rem',
      },
    },
    h4: {
      fontSize: '1.25rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem',
      },
    },
    h5: {
      fontSize: '1.1rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.25rem',
      },
    },
    h6: {
      fontSize: '1rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.1rem',
      },
    },
    'p, ul, ol': {
      marginTop: 0,
      marginBottom: '1rem',
    },
    a: {
      color: '#0055FE',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    'code, pre': {
      fontFamily: "'Roboto Mono', monospace",
      fontSize: '0.875em',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      padding: '0.2em 0.4em',
      borderRadius: '3px',
    },
    'img' : {
        maxWidth: '500px !important',
        height: 'auto',
    }
  },
};

export default globalStyles;