import axios from 'axios';

import config from '../config.json';
import {appSession, appSettings} from "../managers/generalManager";
import logger from "../utils/logging_services";
import {getAccessToken} from "./core.services";

const service_url = config.mode === 'dev' ? config.server_url_dev : config.server_url_prod;
logger.info(`Server to connect : ${service_url}`);

export type ExtDataRecordMeta = {
    resource: string;
    blob_name?: string;
    container_name?: string;
    type: string;
    language: string;
    extra: string;
}

export type KnowledgeBaseItem = {
    uuid: string;
    title: string;
    original: string;
    processed: string;
    project: string;
    keywords: string;
    namespace: string;
    meta_data: ExtDataRecordMeta;
    related_record: string | null;
    related_type: string | null;
    chunks_original: number;
    chunks_processed: number;
}

export type ExtDataPage = {
    uuid: string;
    filename: string;
    page: number;
    original: string;
    processed: string;
    metadata: string;
}

export type RequestParameters = {
    parameters: string;
    prompt: string;
}

export type KnowledgeBaseItemOut = {
    uuid: string;
    title: string;
    original: string;
    processed: string;
    project: string;
    namespace: string;
    keywords: string;
    metadata_resource: string;
    metadata_type: string;
    metadata_language: string;
    metadata_extra: string;
}

export type QueryResult = {
    uuid: string;
    title: string;
    data: string;
    score: number;
    props: string;
}

// Retrieve parameter value from the project.
// ----------------------------------------------------------------------------------------------------------------
export function getParameter(projectId: string, key: string, key_type: string): Promise<string> {
    const accessToken = getAccessToken();
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/extdata/${projectId}/parameter?key=${key}&key_type=${key_type}`;

    return axios.get(url, {headers})
        .then(response => {
            logger.info(response.data);
            return response.data;
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error("Get Parameters Request Failed:", error);
            return "{}";
        });
}

// ----------------------------------------------------------------------------------------------------------------
export function getExtDataRecordList(projectId: string, namespace: string): Promise<KnowledgeBaseItem[]> {
    const accessToken = getAccessToken();
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};

    let url = service_url + `/modules/extdata/${projectId}/record/namespace/${namespace}`;

    return axios.get(url, {headers})
        .then(response => {
            logger.info("getExtDataRecordList request response:", response.data);
            return response.data as KnowledgeBaseItem[];
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error("getExtDataRecordList Request Failed:", error);
            return [] as KnowledgeBaseItem[];
        });
}


// Add new ExtData record to the specified project.
// ----------------------------------------------------------------------------------------------------------------
export function addExtDataRecord(projectId: string, data: KnowledgeBaseItemOut, addToIndex: boolean): any {
    const accessToken = getAccessToken();
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    const url = service_url + `/modules/extdata/${projectId}/record?add_to_knowledge_base=${addToIndex}`;

    return axios.post(url, data, {headers})
        .then(response => {
            logger.info(response.data);
            return response.data;
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error("Add ExtData Record Request Failed:", error);
            return "";
        });
}

export function addExtDataFile(projectId: string, data: KnowledgeBaseItemOut, file?: File | null, addToIndex: boolean = false): any {
    const accessToken = getAccessToken();
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'multipart/form-app_theme'
    };

    const url = service_url + `/modules/extdata/${projectId}/file?title=${encodeURIComponent(data.title)}` +
        `&namespace=${encodeURIComponent(data.namespace)}` +
        `&metadata_type=${encodeURIComponent(data.metadata_type)}` +
        `&metadata_resource=${encodeURIComponent(data.metadata_resource)}` +
        `&metadata_language=${encodeURIComponent(data.metadata_language)}` +
        `&metadata_extra=${encodeURIComponent(data.metadata_extra)}` +
        `&keywords=${encodeURIComponent(data.metadata_extra)}` +
        `&add_to_knowledge_base=${addToIndex}`;

    const formData = new FormData();

    if (file) {
        formData.append('file', file, file.name);
    }

    return axios.post(url, formData, {headers})
        .then(response => {
            logger.info("AddExtDataFile request response:", response.data);
            return response.data;
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error("AddExtDataFile Request Failed:", error);
            return "";
        });
}

export function removeExistingFileFromKnowledgeBase(projectId: string, recordId: string): Promise<any> {
    const url = `${service_url}/modules/extdata/${projectId}/remove_existing_file_from_kb?record_uuid=${recordId}`;
    const accessToken = getAccessToken();
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };

    return axios.delete(url, {headers})
        .then(response => {
            logger.info("Remove Existing File From KB Request Response:", response.data);
            return response.data;
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error("Remove Existing File From KB Request Failed:", error);
            return "";
        });
}

export function addExistingFileToKnowledgeBase(projectId: string, recordId: string, chunkSize: number = 512): Promise<any> {
    const url = `${service_url}/modules/extdata/${projectId}/add_existing_file_to_kb?record_uuid=${recordId}&chunk_size=${chunkSize}`;
    const accessToken = getAccessToken();
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };

    return axios.post(url, {}, {headers})
        .then(response => {
            logger.info("Add Existing File To KB Request Response", response.data);
            return response.data;
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error("Add Existing File To KB Request Failed:", error);
            return "";
        });
}


// Update ExtData record to the specified project.
// ----------------------------------------------------------------------------------------------------------------
export function updateExtDataRecord(projectId: string, recordId: string, data: KnowledgeBaseItemOut): any {
    // const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {
        Authorization: `Bearer ${appSession.accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json' // add Content-Type header
    };
    const url = service_url + `/modules/extdata/${projectId}/record/${recordId}`;

    return axios.post(url, data, {headers}) // pass app_theme and headers to post method
        .then(response => {
            logger.info("Update ExtDataRecord Request Response:", response.data);
            return response.data;
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error("Update ExtDataRecord Request Failed:", error);
            return "";
        });
}

// Execute service request for given record
// ----------------------------------------------------------------------------------------------------------------
export function processResourceService(projectId: string, recordId: string, service: string, data: RequestParameters): any {

    // const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {
        Authorization: `Bearer ${appSession.accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json' // add Content-Type header
    };
    const url = service_url + `/modules/extdata/${projectId}/record/${recordId}/service?service=${service}`;

    return axios.post(url, data, {headers}) // pass app_theme and headers to post method
        .then(response => {
            logger.info("Process resource service request response:", response.data);
            return response.data;
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error("Process resource service request failed:", error);
            return "";
        });
}

export function getServiceData(projectId: string, recordId: string): Promise<string> {

    let url = service_url + `/modules/extdata/${projectId}/record/${recordId}/data`;
    const headers = {Authorization: `Bearer ${appSession.accessToken}`, Accept: 'application/json'};

    return axios.get(url, {headers})
        .then(response => {
            logger.info("getExtDataRecordList request response:", response.data);
            return response.data as string;
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error("getExtDataRecordList Request Failed:", error);
            return "";
        });
}

export function getServiceRecords(projectId: string, recordId: string): Promise<KnowledgeBaseItemOut[]> {

    let url = service_url + `/modules/extdata/${projectId}/record/${recordId}/service`;
    const headers = {Authorization: `Bearer ${appSession.accessToken}`, Accept: 'application/json'};

    return axios.get(url, {headers})
        .then(response => {
            logger.info("getExtDataRecordList request response:", response.data);
            return response.data as KnowledgeBaseItemOut[];
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error("getExtDataRecordList Request Failed:", error);
            return [] as KnowledgeBaseItemOut[];
        });
}


// Delete ExtData record to the specified project.
// ----------------------------------------------------------------------------------------------------------------
export function deleteExtDataRecord(projectId: string, recordId: string): any {
    // const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {
        Authorization: `Bearer ${appSession.accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json' // add Content-Type header
    };
    const url = service_url + `/modules/extdata/${projectId}/record/${recordId}`;

    return axios.delete(url, {headers}) // pass app_theme and headers to post method
        .then(response => {
            logger.info("Delete ExtDataRecord Reques Response:", response.data);
            return response.data;
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error("Delete ExtDataRecord Request Failed:", error);
            return "";
        });
}

// Retrieve records from the project based on their embedding.
// ----------------------------------------------------------------------------------------------------------------
interface RunQueryData {
    query: string;
    score: number;
    max_results: number;
    mode: string;
    filter?: string;
    namespace: string;
}

export function runQuery(projectId: string, query: string, namespace: string, filter?: string): Promise<QueryResult[]> {
    // const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${appSession.accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/extdata/${projectId}/query`;

    const data: RunQueryData = {
        query: query, score: 70, max_results: 6, mode: 'all', filter: filter, namespace: namespace,
    }

    return axios.post(url, data, {headers})
        .then(response => {
            logger.info("RunQuerty Request Response:", response.data);
            return response.data as QueryResult[];
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error("RunQuery Request Failed:", error);
            return [] as QueryResult[];
        });
}

export function AddUpdateParameter(projectId: string, key: string, key_type: string, value: any): Promise<void> {
    const accessToken = getAccessToken();
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    const url = service_url + `/modules/extdata/${projectId}/parameter`;
    const data = {
        key: key,
        key_type: key_type,
        value: value
    };

    return axios.post(url, data, {headers})
        .then(response => {
            logger.info('AddUpdate Parameter Request Response:', response);
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error('AddUpdate Parameter Request Failed:', error);
        });
}


// Retrieve sitemap urls from a given url.
// ----------------------------------------------------------------------------------------------------------------
export function getSiteList(projectId: string, siteUrl: string): Promise<string[]> {
    // const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {Authorization: `Bearer ${appSession.accessToken}`, Accept: 'application/json'};
    const url = service_url + `/modules/extdata/${projectId}/getSitemapUrls?url=${siteUrl}`;

    return axios.get(url, {headers})
        .then(response => {
            logger.info("GetSiteList Request Response", response.data);
            return response.data as string[];
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error("GetSiteList Request Failed:", error);
            return [] as string[];
        });
}

// ----------------------------------------------------------------------------------------------------------------
export async function getExtPageCount(projectId: string, filename: string): Promise<number> {

    // const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {
        Authorization: `Bearer ${appSession.accessToken}`,
        Accept: 'application/json',
    };
    const url = `${service_url}/modules/extdata/${projectId}/getPageCount?filename=${filename}`;

    try {
        const response = await axios.get(url, {headers});
        return response.data.pages;
    } catch (error) {
        if (error instanceof Error)
            logger.error("GetExtPageCount Request Failed:", error);
        return 0;
    }
}

export async function getExtPageData(projectId: string, filename: string, page: number): Promise<ExtDataPage | undefined> {

    // const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {
        Authorization: `Bearer ${appSession.accessToken}`,
        Accept: 'application/json',
    };
    const url = `${service_url}/modules/extdata/${projectId}/page?filename=${filename}&page_number=${page}`;

    try {
        const response = await axios.get<ExtDataPage>(url, {headers});
        return response.data;
    } catch (error) {
        if (error instanceof Error)
            logger.error("GetExtPageData Request Failed:", error);
        return undefined;
    }
}

interface ExtDataPageImage {
    page: number;
    image: Uint8Array;
}

export async function getExtPageImage(
    projectId: string,
    filename: string,
    page: number
): Promise<ExtDataPageImage | undefined> {

    // const accessToken = localStorage.getItem('BoostrFoundation_AccessToken');
    const headers = {
        Authorization: `Bearer ${appSession.accessToken}`,
        Accept: 'application/json',
    };

    const url = `${service_url}/modules/extdata/${projectId}/getImage/${page}?filename=${filename}`;

    try {
        const response = await axios.get<ExtDataPageImage>(url, {headers});
        return response.data;
    } catch (error) {
        if (error instanceof Error)
            logger.error("GetExtPageImage Request Failed:", error);
        return undefined;
    }
}

interface LLMRequestData {
    system_prompt: string;
    user_prompt: string;
    prompt_id: string;
    service_id: string;
    data: string;
    data_json: string;
    url: string;
    temperature: number;
    language: string;
    provider: string;
    version: string;
}

let abortController: AbortController | undefined;

export async function runLLMRequest(
    projectId: string,
    system_prompt: string,
    user_prompt: string,
    prompt_id: string,
    data: string,
    data_json: string,
    data_url: string,
    temperature: number,
    language: string,
    LLM_provider: string,
    LLM_version: string,
    onDataReceived: (chunk: string) => void
): Promise<void> {
    if (abortController) abortController.abort(); // Ensure any existing request is aborted
    abortController = new AbortController(); // Reinitialize AbortController

    const headers: HeadersInit = {
        Authorization: `Bearer ${appSession.accessToken}`,
        Accept: "text/event-stream",
        "Content-Type": "application/json",
    };

    const request_data: LLMRequestData = {
        system_prompt, user_prompt, prompt_id,
        service_id: "", data, data_json, url: data_url, language,
        temperature,
        provider: LLM_provider,
        version: LLM_version
    };

    const url: string = `${service_url}/modules/extdata/${projectId}/llm`;

    try {
        const response = await fetch(url, {
            method: "POST",
            headers,
            body: JSON.stringify(request_data),
            signal: abortController.signal,
        });

        if (!response.body) throw new Error("No response body found.");

        const reader = response.body.getReader();
        appSettings.isStreaming = true;
        appSettings.quitStreaming = false;

        while (true) {
            if (shouldStopStream()) {
                abortController.abort(); // Correctly abort the fetch request here
                break; // Optionally break out of the loop, though the fetch aborting will handle it
            }

            const {done, value} = await reader.read();
            if (done) break; // Exit loop if stream is done

            const chunk: string = new TextDecoder().decode(value);
            onDataReceived(chunk);
        }
    } catch (error) {
        if (error instanceof Error) {
            if (error.name === 'AbortError') {
                logger.info('runLLMRequest Request Response', 'Fetch request aborted by user or code.');
            } else {
                logger.error("runLLMRequest", 'Error during fetch operation:', error);
                throw error; // Rethrow non-abort errors
            }
        }
    } finally {
        appSettings.isStreaming = false;
        appSettings.quitStreaming = false;
    }
}


function shouldStopStream(): boolean {
    if (appSettings.quitStreaming) {
        logger.info('Users request stop streaming:');
        appSettings.quitStreaming = false;
        return true;
    } else {
        return false;
    }
}

export function listDataBoostrItems(projectId: string, namespace: string): Promise<KnowledgeBaseItemOut[]> {
    const url = `${service_url}/modules/chatboostr/${projectId}/list_data/${namespace}`;
    const headers = {
        'Authorization': `Bearer ${appSession.accessToken}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    };

    return axios.get(url, {headers})
        .then(response => {
            // Assuming the response app_theme is the array you want

            if (response.data.length === 0) {
                const empty_record = {uuid: "", type: "dummy", title: "No files found"};
                response.data.push(empty_record);
            }

            return response.data;
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error('ListDataBoostrItems Service Request Failure:', error);

            let errorMessage = "Unknown error occurred.";
            if (error.response) {
                const {status, statusText} = error.response;
                errorMessage = `HTTP error! status: ${status} (${statusText})`;
            } else if (error.message) {
                errorMessage = error.message;
            }
            throw new Error(errorMessage);
            // return [{ error: errorMessage }]; // Adjusted to return an array with an error object
        });
}

export type getListResult = {
    uuid: string;
    type: string;
    title: string;
}

// Service to retrieve a selected job description or candidate from talentBoostr
// ------------------------------------------------------------------------------------------------------
export async function getDataBoostrData(
    projectId: string,
    uuid: string,
    from_chunk: number,
    to_chunk: number,
): Promise<string | undefined> {
    try {

        const url = service_url +
            `/modules/chatboostr/${projectId}/query_data/${uuid}?from_chunk=${from_chunk}&to_chunk=${to_chunk}`;

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${appSession.accessToken}`,
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
        };

        const response = await fetch(url, requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        if (error instanceof Error)
            logger.error('GetDataBoostrData Service Request Failure:', error);
        return undefined;
    }
}

// Service to retrieve list of job descriptions or candidates from talentBoostr
// ------------------------------------------------------------------------------------------------------
export async function listTalentBoostrItems(
    projectId: string,
    type: string,
): Promise<getListResult[] | undefined> {
    try {

        const url = service_url + `/modules/chatboostr/${projectId}/list_talent/${type}`;

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${appSession.accessToken}`,
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
        };

        const response = await fetch(url, requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        if (error instanceof Error)
            logger.error('ListTalentBoostrItems Service Request Failure:', error);
        return undefined;
    }
}

// Service to retrieve a selected job description or candidate from talentBoostr
// ------------------------------------------------------------------------------------------------------
export async function getTalentBoostrData(
    projectId: string,
    type: string, //"job_descriptions" | "candidates",
    uuid: string,
): Promise<string | undefined> {
    try {

        const url = service_url + `/modules/chatboostr/${projectId}/query_talent/${type}/${uuid}`;

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${appSession.accessToken}`,
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
        };

        const response = await fetch(url, requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        if (error instanceof Error)
            logger.error('GetTalentBoostrData Service Request Failure:', error);
        return undefined;
    }
}



