import React, {useEffect, useState} from "react";
import {
    KnowledgeBaseItem,
    KnowledgeBaseItemOut,
    removeExistingFileFromKnowledgeBase,
    addExistingFileToKnowledgeBase,
    processResourceService, RequestParameters, getServiceRecords, getServiceData, deleteExtDataRecord,
} from "../../backend/extdata.services";
import {
    Button,
    TextField,
    Grid,
    Typography, Snackbar, Alert,
} from "@mui/material";
import Box from "@mui/material/Box";
import {SelectWithData} from "../general/Site.snippets";
import {appSession, appSettings, categoryOptions, languageOptions} from "../../managers/generalManager";
import AppDialog from "../general/app_dialog";
import PleaseWaitDialog from "../general/please_wait";
import logger from "../../utils/logging_services";
import {SaveToWorkspace} from "../workspace/save_to_workspace";
import {getFileType, getServiceInfo} from "./file_services";
import {infoServicePrompt, renderImageServices} from "./render_services/render_image_services";
import {renderPdfServices} from "./render_services/render_pdf_services";
import {renderAudioServices} from "./render_services/render_audio_services";
import colorSchema from "../../app_theme/theme_support/colorSchema";

// ---------------------------------------------------------------------------------------------------------------
// ExtDataRecordDetail
// ---------------------------------------------------------------------------------------------------------------
interface ManageKnowledgeBaseItemProps {
    mode: string;
    namespace: string;
    data: KnowledgeBaseItem;
    onSubmit: (updatedData: KnowledgeBaseItemOut, file: File | null | undefined, addToIndex: boolean) => void;
    onClose: (refresh: boolean) => void;
}

export interface Option {
    value: string;
    label: string;
}

const truefalse: Option[] = [
    {value: 'false', label: 'False'},
    {value: 'true', label: 'True'},
];

export function isValidUrl(url: string): boolean {
    try {
        new URL(url);
        return true;
    } catch (error) {
        return false;
    }
}
const MAX_FILE_SIZE = 1000 * 1024 * 1024; // 50MB in bytes

// ----------------------------------------------------------------------------------------------------------------
export function ManageKnowledgeBaseItem(props: ManageKnowledgeBaseItemProps) {
    const [open, setOpen] = useState(true);

    // Initialize state for each field using the external app_theme
    const [uuid] = useState(props.data.uuid);
    const [title, setTitle] = useState(props.data.title);
    const [original, setOriginal] = useState(props.data.original);
    const [processed, setProcessed] = useState(props.data.processed);

    const [metadataResource, setMetadataResource] = useState(props.data.meta_data.resource);
    const [metadataType] = useState(props.data.meta_data.type);
    const [metadataExtra] = useState(props.data.meta_data.extra);
    const [keywords, setkeyWords] = useState(props.data.keywords || "");
    const [metadataLanguage, setMetadataLanguage] = useState<string>(props.data.meta_data.language ||
        appSettings.selectedLanguage || languageOptions[0].value)
    const [namespace, setNamespace] = useState<string>(props.namespace || categoryOptions[0].value)
    const [switch_between_original_and_processed, setSwitch_between_original_and_processed] = useState(true);
    const [file, setFile] = useState<File | null>(null);
    const [dragging, setDragging] = useState(false);
    const [isValidForSubmit, setIsValidForSubmit] = useState(false);
    const [addToIndex, setAddToIndex] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [showSave, setShowSave] = useState(false);
    const [textToSave, setTextToSave] = useState("");


    const [reload, setReload] = useState(true);

    const [filetype, setFiletype] = useState<string>("");
    const [serviceRecords, setServiceRecords] = useState<KnowledgeBaseItemOut[]>([]);
    const [transcriptionStatus, setTranscriptionStatus] = useState(false);
    const [subtitlesStatus, setSubtitlesStatus] = useState(false);
    const [infoStatus, setInfoStatus] = useState(false);

    const [showSizeWarning, setShowSizeWarning] = useState(false);


    useEffect(() => {
        if (!reload) return;

        setReload(false);
        setFiletype(getFileType(metadataResource));
        loadServiceInfo().then();
    }, [reload, metadataResource]);

    const loadServiceInfo = async () => {
        const {
            service_records,
            transcriptionStatus,
            subtitlesStatus,
            infoStatus
        } = await getServiceInfo(appSession.currentKnowledgeBaseProject, uuid, getServiceRecords);

        logger.info("Service records:", service_records);
        logger.info("Transcription status:", transcriptionStatus);
        logger.info("Subtitles status:", subtitlesStatus);

        setServiceRecords(service_records);
        setTranscriptionStatus(transcriptionStatus);
        setSubtitlesStatus(subtitlesStatus);
        setInfoStatus(infoStatus);
    };

    // Validate form fields
    useEffect(() => {

        const check_title_is_required = title.trim().length > 0;
        const check_file_is_required = file !== null;
        const check_original_is_required = original.trim().length > 0;
        const check_url_is_valid = isValidUrl(metadataResource.trim());
        const check_metadata_resource_is_required = metadataResource.trim().length > 0;

        logger.info("Title:", check_title_is_required, "File:", check_file_is_required, "Original:",
            check_original_is_required, "URL:", check_url_is_valid, "Resource:", check_metadata_resource_is_required);

        const is_valid = check_title_is_required &&
            (check_file_is_required || check_original_is_required || check_url_is_valid || props.mode !== 'add') &&
            check_metadata_resource_is_required;

        setIsValidForSubmit(is_valid);

    }, [title, original, processed, metadataResource, metadataType, keywords, metadataExtra, namespace, file]);

    function handleSubmit() {

        if (props.mode === 'view') {
            setOpen(false);
            props.onClose(false);
        } else {

            // Create a new ExtDataRecord object with the updated values
            const updatedData: KnowledgeBaseItemOut = {
                uuid,
                title: title,
                original: original,
                processed: processed,
                namespace: namespace,
                project: props.data.project,
                keywords: keywords,
                metadata_resource: metadataResource,
                metadata_language: metadataLanguage,
                metadata_type: metadataType,
                metadata_extra: metadataExtra,

            };
            props.onSubmit(updatedData, file !== null ? file : undefined, addToIndex);
        }
    }

    const handleLanguageSelected = (selected: Option) => {
        setMetadataLanguage(selected.value);
    };

    // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const fileList = event.target.files;
    //
    //     if (fileList && fileList.length > 0) {
    //         const file = fileList[0];
    //         let filename = file.name.split(".")[0]; // Extract filename without extension
    //         const fullPath = event.target.value;
    //         logger.info("File selected: ", fullPath);
    //         setFile(file);
    //
    //         // remove _ in filename
    //         filename = filename.replace(/_/g, " ");
    //
    //         setTitle(filename);
    //         setMetadataResource(file.name);
    //     }
    // };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;

        if (fileList && fileList.length > 0) {
            const selectedFile = fileList[0];
            if (selectedFile.size > MAX_FILE_SIZE) {
                setShowSizeWarning(true);
                return;
            }

            let filename = selectedFile.name.split(".")[0];
            const fullPath = event.target.value;
            logger.info("File selected: ", fullPath);
            setFile(selectedFile);

            filename = filename.replace(/_/g, " ");

            setTitle(filename);
            setMetadataResource(selectedFile.name);
        }
    };



    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragging(false);
    };

    // const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    //     event.preventDefault();
    //     setDragging(false);
    //     const fileList = event.dataTransfer.files;
    //     if (fileList && fileList.length > 0) {
    //         setFile(fileList[0]);
    //     }
    // };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragging(false);
        const fileList = event.dataTransfer.files;
        if (fileList && fileList.length > 0) {
            const droppedFile = fileList[0];
            if (droppedFile.size > MAX_FILE_SIZE) {
                setShowSizeWarning(true);
                return;
            }
            setFile(droppedFile);
            let filename = droppedFile.name.split(".")[0];
            filename = filename.replace(/_/g, " ");
            setTitle(filename);
            setMetadataResource(droppedFile.name);
        }
    };

    const handleNamespaceSelected = (selection: Option) => {
        console.log(selection);
        setNamespace(selection.value);
    }

    const handleAddToIndex = (selection: Option) => {
        setAddToIndex(() => selection.value === 'true');
    }

    const handleAddRemoveTranscription = () => {
        setIsProcessing(true);
        if (transcriptionStatus) {
            handleRemoveServiceRecord("transcribe");
        } else {
            const parameters: RequestParameters = {"parameters": "", prompt: ""};
            processResourceService(appSession.currentKnowledgeBaseProject, uuid, "transcribe", parameters).then(() => {
                setIsProcessing(false);
                setReload(true);
            });
        }
    };

    const handleAddRemoveSubtitle = () => {
        setIsProcessing(true);
        if (subtitlesStatus) {
            handleRemoveServiceRecord("subtitle");
        } else {
            const parameters: RequestParameters = {"parameters": "", "prompt": ""};
            processResourceService(appSession.currentKnowledgeBaseProject, uuid, "subtitle", parameters).then(() => {
                setIsProcessing(false);
                setReload(true);
            });
        }
    };

    const handleAddRemoveInfo = () => {
        setIsProcessing(true);
        if (infoStatus) {
            handleRemoveServiceRecord("info");
        } else {
            const parameters: RequestParameters = {"parameters": "", "prompt": infoServicePrompt};
            processResourceService(appSession.currentKnowledgeBaseProject, uuid, "info", parameters).then(() => {
                setIsProcessing(false);
                setReload(true);
            });
        }
    }

    const handleAddRemoveKnowledge = () => {
        if (props.data.chunks_original > 0) {
            console.log("Remove from knowledge base");
            handleRemoveFileFromKnowledgeDatabase();
        } else {
            console.log("Add to knowledge base");
            handleAddFileToKnowledgeDatabase();
        }
    }

    function handleRemoveFileFromKnowledgeDatabase() {
        setIsProcessing(true);
        removeExistingFileFromKnowledgeBase(appSession.currentKnowledgeBaseProject, uuid).then(() => {
            setIsProcessing(false);
            setOpen(false);
            props.onClose(true);
        })
    }

    function handleAddFileToKnowledgeDatabase() {
        setIsProcessing(true);
        addExistingFileToKnowledgeBase(appSession.currentKnowledgeBaseProject, uuid).then(() => {
            setIsProcessing(false);
            setOpen(false);
            props.onClose(true);
        })
    }

    function handleShowServiceData(serviceType: string) {

        const service_record = serviceRecords.filter(record => record.metadata_type === serviceType)[0];
        if (service_record) {
            getServiceData(appSession.currentKnowledgeBaseProject, service_record.uuid).then((data) => {
                setTextToSave(data);
                setShowSave(true);
            });
        }
    }

    function handleRemoveServiceRecord(serviceType: string) {
        setIsProcessing(true)
        const service_record = serviceRecords.filter(record => record.metadata_type === serviceType)[0];
        if (service_record) {
            deleteExtDataRecord(appSession.currentKnowledgeBaseProject, service_record.uuid).then(() => {
                setIsProcessing(false);
                setReload(true);
            })
        }
    }

    return (

        <AppDialog
            open={open}
            size={"md"}
            onCancel={() => props.onClose(false)}
            title={props.mode.charAt(0).toUpperCase() + props.mode.slice(1) + " - File"}
            message={""}
            onSubmit={handleSubmit}
            showSubmit={props.mode !== 'view' && isValidForSubmit}
            showCancel={true}
            aria-labelledby="draggable-dialog-title"
        >

            <Box component="form"
                 sx={{
                     '& .MuiTextField-root': {m: 1},
                 }}
                 noValidate
                 autoComplete="off">
                <TextField
                    fullWidth
                    label="title"
                    id="title"
                    value={title}
                    disabled={props.mode === 'view'}
                    onChange={(event) => setTitle(event.target.value)}
                />

                {/* If item is a record instead of a file */}
                {props.data.meta_data.type !== 'file' && (
                    <>
                        {props.mode !== 'add' && (
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: '5px',
                                marginBottom: '5px'
                            }}>
                                <Button
                                    variant="contained" color={"success"}
                                    onClick={() => {
                                        setSwitch_between_original_and_processed(!switch_between_original_and_processed);
                                    }}
                                >
                                    {switch_between_original_and_processed ? 'Processed' : 'Original'}
                                </Button>
                            </Box>
                        )}
                        {switch_between_original_and_processed && (
                            <TextField
                                fullWidth
                                multiline={true}
                                rows={10}
                                label="orginal"
                                id="original"
                                value={original}
                                disabled={props.mode === 'view' || props.mode === 'edit'}
                                onChange={(event) => setOriginal(event.target.value)}
                            />
                        )}
                        {!switch_between_original_and_processed && (
                            <TextField
                                fullWidth
                                multiline={true}
                                rows={10}
                                label="processed"
                                id="processed"
                                value={processed}
                                disabled={props.mode === 'view'}
                                onChange={(event) => setProcessed(event.target.value)}
                            />
                        )}
                    </>
                )}


                <Typography variant="h6" gutterBottom component="div"
                            sx={{marginTop: '10px', color: colorSchema.text.primary}}>
                    Metadata
                </Typography>

                <Grid container gap={1}>
                    {props.mode === 'add' ? (
                        <>
                            <Grid item>
                                <SelectWithData data={truefalse} id="SelectAddToIndex" onSelect={handleAddToIndex}
                                                selected={0} label={"Add to knowledge domain"} isOpen={false}
                                                sx={{m: 1}}/>
                            </Grid>
                            <Grid item>
                                <SelectWithData data={languageOptions} id="SelectLanguage"
                                                onSelect={handleLanguageSelected}
                                                selected={languageOptions.findIndex((option) => option.value === metadataLanguage)}
                                                label={"Language"} isOpen={false} sx={{m: 1}}/>
                            </Grid>
                            <Grid item>
                                <SelectWithData data={categoryOptions} id="SelectCategory"
                                                onSelect={handleNamespaceSelected}
                                                selected={categoryOptions.findIndex((option) => option.value.toLowerCase() === namespace.toLowerCase()) || 0}
                                                label={"Category"} isOpen={false} sx={{m: 1}}/>
                            </Grid>
                        </>
                    ) : (
                        <>

                        </>
                    )}
                </Grid>

                <TextField
                    fullWidth
                    label="Link or Reference to the resource"
                    id="metadataResource"
                    value={metadataResource}
                    disabled={props.mode !== 'add'}
                    onChange={(event) => setMetadataResource(event.target.value)}
                />

                {/*<TextField*/}
                {/*    fullWidth*/}
                {/*    label="Type of information"*/}
                {/*    id="metadataType"*/}
                {/*    disabled={props.mode !== 'add'}*/}
                {/*    value={metadataType}*/}
                {/*    onChange={(event) => setMetadataType(event.target.value)}*/}
                {/*/>*/}

                <TextField
                    fullWidth
                    label="Keywords"
                    id="keywords"
                    disabled={filetype === 'pdf' && props.mode !== 'add'}
                    value={keywords}
                    onChange={(event) => setkeyWords(event.target.value)}
                />

                <Typography variant="h6" gutterBottom component="div"
                            sx={{marginTop: '10px', color: colorSchema.text.primary}}>
                    Services
                </Typography>

                {/* Show services based on the type of file */}
                {renderPdfServices({
                    filetype,
                    chunksOriginal: props.data.chunks_original,
                    handleAddRemoveKnowledge
                })}

                {renderAudioServices({
                    filetype,
                    transcriptionStatus,
                    subtitlesStatus,
                    handleAddRemoveTranscription,
                    handleAddRemoveSubtitle,
                    handleShowServiceData
                })}

                {renderImageServices({
                    filetype,
                    infoStatus,
                    handleAddRemoveInfo,
                    handleShowServiceData
                })}

            </Box>

            {/* SHOW FILE UPLOAD OPTION WHEN THE USER CREATES A NEW RESOURCE */}
            {
                props.mode === 'add' && (
                    <Grid item>
                        <div
                            onDragEnter={handleDragEnter}
                            onDragOver={handleDragEnter}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                            style={{
                                border: `2px dashed ${
                                    dragging ? '#00bcd4' : 'rgba(0, 0, 0, 0.23)'
                                }`,
                                padding: '1rem',
                                marginTop: '1rem',
                                textAlign: 'center',
                            }}
                        >
                            {/* upload file */}
                            <input
                                required
                                id="file"
                                name="file"
                                type="file"
                                accept=".pdf,.doc,.docx,.png,.gif,.jpg,.jpeg,.csv,.webp, .mp3, .m4a, .wav, .ogg, .mp4"
                                onChange={handleFileChange}
                                style={{display: 'none'}}
                            />
                            <label htmlFor="file">
                                <Button
                                    variant="contained"
                                    component="span"
                                    style={{marginRight: '1rem'}}
                                >
                                    Select file
                                </Button>
                                {file ? file.name : 'Drag and drop file here'}
                            </label>
                        </div>
                    </Grid>
                )
            }

             <Snackbar open={showSizeWarning} autoHideDuration={6000} onClose={() => setShowSizeWarning(false)}>
                <Alert onClose={() => setShowSizeWarning(false)} severity="error" sx={{ width: '100%' }}>
                    File size exceeds 15MB limit. Please choose a smaller file.
                </Alert>
            </Snackbar>


            {/* SHOW PLEASE WAIT WHEN PROCESSING */}
            {
                isProcessing && (
                    <PleaseWaitDialog open={isProcessing} title={"Processing"}
                                      message={"Please wait while the file is being processed."}
                                      onCancel={() => setIsProcessing(false)}/>
                )
            }

            {showSave && (
                <SaveToWorkspace context_data={textToSave} onCancel={() => setShowSave(false)}
                                 type={"html"}
                                 onSubmit={() => setShowSave(false)}/>
            )}
        </AppDialog>
    );
}

// // ---------------------------------------------------------------------------------------------------------------
// // SearchExtData
// // ---------------------------------------------------------------------------------------------------------------
// interface SearchExtDataProps {
//     projectId: string;
//     onClose: () => void;
// }
//
// function SearchExtData(props: SearchExtDataProps) {
//     const [open, setOpen] = useState(true);
//     const [query, setQuery] = useState("");
//     const [prompt, setPrompt] = useState("");
//     const [filter, setFilter] = useState("");
//     const [queryResultList, setQueryResultList] = useState<QueryResult[]>([]);
//     const [showActionResult, setShowActionResult] = useState(false);
//     const [actionResult, setActionResult] = useState<string[]>([]);
//     const [actionResultComplete, setActionResultComplete] = useState(false);
//     const [namespace, setNamespace] = useState<string>(categoryOptions[0].value);
//     const [addToIndex, setAddToIndex] = useState<boolean>(false);
//     const [selectedLanguage, setSelectedLanguage] = useState<string>(
//         localStorage.getItem('settings_language') || languageOptions[0].value
//     );
//
//     function handleSearchClick() {
//         setQueryResultList(prevState => []);
//         runQuery(props.projectId, query, namespace, filter).then((app_theme: QueryResult[]) => {
//             setQueryResultList(app_theme);
//         })
//     }
//
//     function handlePromptClick() {
//         setActionResult(prevState => []);
//
//         let prompt_to_use = prompt;
//         if (prompt_to_use === "") {
//             prompt_to_use = query;
//         }
//
//         if (queryResultList.length === 0) {
//             setQueryResultList(prevState => []);
//             runQuery(props.projectId, query, namespace, filter).then((app_theme: QueryResult[]) => {
//                 setQueryResultList(app_theme);
//                 const context_data = queryResultList.map((result) => result.app_theme).join("\n");
//                 setActionResult(prevState => [""])
//                 // runGPTRequest(props.projectId, "", selectedLanguage, context_data, prompt_to_use, onDataReceived).then(() => {
//                 //     setActionResultComplete(true)
//                 // })
//                 setShowActionResult(true);
//             })
//         } else {
//             const context_data = queryResultList.map((result) => result.app_theme).join("\n");
//             setActionResult(prevState => [""])
//             // runGPTRequest(props.projectId, "", selectedLanguage, context_data, prompt_to_use, onDataReceived).then(() => {
//             //     setActionResultComplete(true)
//             // })
//             setShowActionResult(true);
//         }
//     }
//
//     const onDataReceived = (word: string) => {
//         if (word === '\n') {
//             // If the received word is a newline character, add a new empty line to the result
//             setActionResult((prevResult) => [...prevResult, ""]);
//         } else {
//             // Otherwise, append the received word to the last line of the action result
//             setActionResult((prevResult) => {
//                 if (prevResult.length === 0) {
//                     // If actionResult is empty, initialize it with an empty string
//                     return [word];
//                 } else {
//                     const newResult = [...prevResult];
//                     newResult[newResult.length - 1] = newResult[newResult.length - 1] + word;
//                     return newResult;
//                 }
//             });
//         }
//     };
//
//     const handleNamespaceSelected = (selection: Option) => {
//         setNamespace(selection.value);
//     }
//
//     const handleAddToIndex = (selection: Option) => {
//         setAddToIndex(prevState => selection.value === 'true');
//     }
//
//     const handleLanguageSelected = (selection: Option) => {
//         setSelectedLanguage(selection.value);
//         localStorage.setItem('settings_language', selection.value);
//     }
//
//     return (
//         <Dialog
//             open={open}
//             onClose={props.onClose}
//             PaperComponent={DraggablePaper}
//             aria-labelledby="draggable-dialog-title"
//         >
//             <DialogTitle sx={{cursor: 'move', textAlign: 'center'}} id="draggable-dialog-title">
//                 <Typography variant={"h4"} sx={{textAlign: 'center', color: colorSchema.color2}}>Search for external
//                     app_theme</Typography>
//             </DialogTitle>
//
//             <DialogContentText sx={{mx: 1, my: 1}}>
//             </DialogContentText>
//             <DialogContent>
//                 <Box component="form"
//                      sx={{
//                          '& .MuiTextField-root': {m: 1},
//                      }}
//                      noValidate
//                      autoComplete="off">
//                     <TextField
//                         fullWidth
//                         label="query"
//                         id="query"
//                         value={query}
//                         rows={3}
//                         multiline={true}
//                         onChange={(event) => setQuery(event.target.value)}
//                     />
//                     <TextField
//                         fullWidth
//                         label="prompt"
//                         id="prompt"
//                         value={prompt}
//                         rows={3}
//                         multiline={true}
//                         onChange={(event) => setPrompt(event.target.value)}
//                     />
//                     <TextField
//                         fullWidth
//                         label="filter"
//                         id="filter"
//                         value={filter}
//                         onChange={(event) => setFilter(event.target.value)}
//                     />
//
//                     <Grid container>
//
//                         <Grid item>
//                             <SelectWithData app_theme={truefalse} id="SelectAddToIndex" onSelect={handleAddToIndex}
//                                             selected={0} label={"Add to knowledge domain"} isOpen={false} sx={{m: 1}}/>
//                         </Grid>
//
//                         <Grid item>
//                             <SelectWithData app_theme={categoryOptions} id="SelectCategory"
//                                             onSelect={handleNamespaceSelected}
//                                             selected={0} label={"Category"} isOpen={false} sx={{m: 1}}/>
//                         </Grid>
//                         <Grid item>
//                             <SelectWithData app_theme={languageOptions} id="SelectLanguage"
//                                             onSelect={handleLanguageSelected}
//                                             selected={languageOptions.findIndex((option) => option.value === selectedLanguage)}
//                                             label={"Language"} isOpen={false} sx={{m: 1}}/>
//
//                         </Grid>
//                     </Grid>
//                 </Box>
//                 <Box>
//                     <ShowQueryResults queryResultList={queryResultList}/>
//                 </Box>
//             </DialogContent>
//             <DialogActions>
//                 <Button variant={"contained"} color={"warning"} onClick={props.onClose}>
//                     Return
//                 </Button>
//                 <Button variant={"contained"} autoFocus onClick={handleSearchClick} disabled={query.trim().length == 0}>
//                     Search
//                 </Button>
//                 <Button variant={"contained"} autoFocus
//                         disabled={query.trim().length === 0 && prompt.trim().length === 0} onClick={handlePromptClick}>
//                     Run Prompt
//                 </Button>
//             </DialogActions>
//
//             {/* -----------------------------------------------------------------------------  */}
//             {/* Show the chatGPT request feedback                                              */}
//             {/* -----------------------------------------------------------------------------  */}
//             {showActionResult && (
//                 <Dialog
//                     open={showActionResult}
//                     onClose={() => {
//                         setShowActionResult(false)
//                     }}
//                     PaperComponent={DraggablePaper}
//                     sx={{
//                         left: '100px',
//                         top: '10px',
//                     }}
//                     aria-labelledby="alert-dialog-title"
//                     aria-describedby="alert-dialog-description"
//                 >
//                     <DialogTitle variant={"h4"} id="alert-dialog-title"
//                                  sx={{
//                                      borderBottom: "1px solid black",
//                                      marginBottom: "20px",
//                                      color: colorSchema.color3
//                                  }}>
//                         Accessing the knowledge base to obtain information
//                     </DialogTitle>
//                     <DialogContent>
//                         {actionResult.map((line, index) => {
//                             if (line.trim() === '') {
//                                 return <br key={index}/>;
//                             }
//                             return (
//                                 <Typography sx={{marginBottom: '2px', textAlign: 'justify'}} key={index}>
//                                     {line}
//                                 </Typography>
//                             );
//                         })}
//                     </DialogContent>
//                     <DialogActions>
//                         {actionResultComplete && (
//                             <Button color={"warning"} variant={"contained"} onClick={() => {
//                                 setShowActionResult(false)
//                             }}>Return</Button>
//                         )}
//                     </DialogActions>
//                 </Dialog>
//             )
//             }
//         </Dialog>
//     )
// }
//
// // ---------------------------------------------------------------------------------------------------------------
// // SearchExtData
// // ---------------------------------------------------------------------------------------------------------------
// interface ShowQueryResultsProps {
//     queryResultList: QueryResult[];
// }
//
// function ShowQueryResults(props: ShowQueryResultsProps) {
//     const [expanded, setExpanded] = React.useState<string | false>(false);
//
//     const handleChange =
//         (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
//             setExpanded(isExpanded ? panel : false);
//         };
//
//     return (
//         <div>
//             {props.queryResultList.length !== 0 && (
//                 <Typography variant={"h4"} sx={{textAlign: 'center', color: colorSchema.color1}}>Matching
//                     Chunks</Typography>
//             )}
//
//             {props.queryResultList.map((result, index) => (
//                 <Accordion key={index} expanded={expanded === 'r' + index} onChange={handleChange('r' + index)}>
//                     <AccordionSummary
//                         expandIcon={<ExpandMoreIcon/>}
//                         aria-controls={"result_" + index}
//                         id={"result_" + index}
//                     >
//                         <Typography>{result.title}</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails>
//                         <Typography>
//                             {result.app_theme}
//                         </Typography>
//                     </AccordionDetails>
//                 </Accordion>
//             ))}
//         </div>
//     )
// }
//
// interface SiteListProps {
//     projectId: string;
//     onClose: () => void;
//     onImport: (namespace: string, siteMap: string[], addToIndex: boolean) => void;
//     open: boolean; // Add the open prop
// }

// function SiteList(props: SiteListProps) {
//     const {projectId, onClose, open: initialOpen} = props; // Destructure the props
//     const [open, setOpen] = useState(initialOpen);
//     const [url, setUrl] = useState('');
//     const [siteMap, setSiteMap] = useState<string[]>([]);
//     const [selectedItems, setSelectedItems] = useState<number[]>([]);
//     const [showSiteMap, setShowSiteMap] = useState(false);
//     const [namespace, setNamespace] = useState(categoryOptions[0].value);
//     const [isLoading, setIsLoading] = useState(false);
//     const [itemsLoaded, setItemsLoaded] = useState(false);
//     const [userAlert, setUserAlert] = useState("");
//     const [addToIndex, setAddToIndex] = useState<boolean>(false);
//
//     const handleLoadSiteMapClick = () => {
//
//         // Reset the state
//         setUserAlert("");
//         setIsLoading(true);
//         setItemsLoaded(false);
//         setShowSiteMap(false);
//
//         let sitelist_url = url;
//         const urlPattern = /^https?:\/\//i; // Regular expression pattern for "http://" or "https://"
//
//         if (!urlPattern.test(url)) {
//             sitelist_url = "https://" + url; // Add "https://" prefix if not present
//         }
//
//         // Get SiteList
//         getSiteList(projectId, sitelist_url).then((result) => {
//
//             setSiteMap(result);
//             setIsLoading(false);
//
//             if (result.length > 0) {
//                 setItemsLoaded(true);
//                 setShowSiteMap(true);
//             } else {
//                 setUserAlert("No site map found for this URL. Please try another URL.")
//             }
//         })
//     }
//
//     const handleImportClick = () => {
//
//         if (namespace !== '') {
//
//             if (selectedItems.length === 0) {
//                 setUserAlert("Please select at least one item to import.")
//                 return;
//             }
//
//             props.onImport(namespace, siteMap.filter((_, index) => selectedItems.includes(index)), addToIndex);
//         }
//     }
//
//     const handleSelectionChange = (selectedItems: number[]) => {
//         setSelectedItems(selectedItems);
//         console.log("Selected items:", selectedItems);
//     };
//
//
//     const handleNamespaceSelected = (selection: Option) => {
//         console.log(selection);
//         setNamespace(selection.value);
//     }
//
//     const handleAddToIndex = (selection: Option) => {
//         setAddToIndex(prevState => selection.value === 'true');
//     }
//
//
//     return (
//         <Dialog
//             open={open}
//             onClose={onClose}
//             PaperComponent={DraggablePaper}
//             aria-labelledby="draggable-dialog-title"
//         >
//             <DialogTitle sx={{cursor: 'move', textAlign: 'center'}} id="draggable-dialog-title">
//                 <Typography variant={"h4"} color={colorSchema.color2}>Load SiteMap</Typography>
//             </DialogTitle>
//
//             <DialogContentText sx={{mx: 0, my: 0}}></DialogContentText>
//             <DialogContent>
//                 <Grid container>
//                     <Grid item xs={3}>
//                         <SelectWithData app_theme={truefalse} id="SelectAddToIndex" onSelect={handleAddToIndex}
//                                         selected={0} label={"Add to knowledge domain"} isOpen={false}
//                                         sx={{mr: 1, mb: 1}}
//                         />
//
//                     </Grid>
//                     <Grid item xs={9}>
//                         <SelectWithData app_theme={categoryOptions} id="SelectCategory" onSelect={handleNamespaceSelected}
//                                         selected={0} label={"Category"} isOpen={false} sx={{mb: 1}}/>
//                     </Grid>
//                     <Grid item xs={12}>
//                         <Typography variant="h6">Enter the URL of the site map</Typography>
//                         <TextField
//                             fullWidth
//                             label="URL"
//                             id="url"
//                             value={url}
//                             onChange={(event) => setUrl(event.target.value)}
//                         />
//                     </Grid>
//
//
//                 </Grid>
//                 <Box>
//                     {showSiteMap && <ShowList list={siteMap} onSelectionChange={handleSelectionChange}/>}
//                 </Box>
//
//                 {userAlert !== "" && (
//                     <Alert onClose={() => setUserAlert("")}>{userAlert}</Alert>
//                 )}
//
//             </DialogContent>
//             <DialogActions>
//
//                 {!isLoading ? (
//                     <Button variant="contained" autoFocus onClick={handleLoadSiteMapClick}>
//                         Load SiteMap
//                     </Button>
//                 ) : (
//                     <Button variant="contained" disabled>
//                         Loading....
//                     </Button>
//                 )}
//
//                 <Button variant="contained" autoFocus onClick={handleImportClick} disabled={!itemsLoaded}>
//                     Import
//                 </Button>
//                 <Button variant="contained" color={"warning"} autoFocus onClick={onClose}>
//                     Cancel
//                 </Button>
//             </DialogActions>
//         </Dialog>
//     );
// }


// // ---------------------------------------------------------------------------------------------------------------
// // SearchExtData
// // ---------------------------------------------------------------------------------------------------------------
// interface ShowListProps {
//     list: string[];
//     onSelectionChange: (selectedItems: number[]) => void;
// }
//
// function ShowList(props: ShowListProps) {
//     const [recordList, setRecordList] = React.useState<string[]>(props.list);
//     const [checked, setChecked] = React.useState<number[]>([]);
//
//     const handleToggle = (value: number) => () => {
//         const currentIndex = checked.indexOf(value);
//         const newChecked = [...checked];
//
//         if (currentIndex === -1) {
//             newChecked.push(value);
//         } else {
//             newChecked.splice(currentIndex, 1);
//         }
//
//         setChecked(newChecked);
//         props.onSelectionChange(newChecked);
//     };
//
//     return (
//         <List sx={{width: '100%', bgcolor: 'background.paper'}}>
//             {recordList.map((item, index) => (
//                 <ListItem key={"item_" + index} disablePadding>
//                     <ListItemButton role={undefined} onClick={handleToggle(index)} dense>
//                         <ListItemIcon>
//                             <Checkbox
//                                 edge="start"
//                                 checked={checked.indexOf(index) !== -1}
//                                 tabIndex={-1}
//                                 disableRipple
//                                 inputProps={{'aria-labelledby': `checkbox-list-label-${index}`}}
//                             />
//                         </ListItemIcon>
//                         <ListItemText id={`checkbox-list-label-${index}`} primary={item}/>
//                     </ListItemButton>
//                 </ListItem>
//             ))}
//         </List>
//     );
// }


// ---------------------------------------------------------------------------------------------------------------
// ExtDataRecordList
// ---------------------------------------------------------------------------------------------------------------
// export interface ListExtDataRecordsProps {
//     projectId: string;
//     namespace: string;
//     onRecordAdded: (app_theme: KnowledgeBaseItemOut, file: File | null | undefined, addToIndex: boolean) => void;
//     onRecordDeleted: (record_uuid: string, namespace: string) => void;
//     onRecordUpdate: (app_theme: KnowledgeBaseItemOut, file: File | null | undefined) => void;
//     onUrlImport: (namespace: string, urls: string[], addToIndex: boolean) => void;
// }
//
// // ---------------------------------------------------------------------------------------------------------------
// export function ExtDataRecordList(props: ListExtDataRecordsProps) {
//     const [recordList, setRecordList] = useState<KnowledgeBaseItem[]>([]);
//     const [showDialog, setShowDialog] = useState(false);
//     const [showSiteDialog, setShowSiteDialog] = useState(false);
//     const [showPagesDialog, setShowPagesDialog] = useState(false);
//     const [selectedFile, setSelectedFile] = useState<string>("");
//     const [searchDialog, setSearchDialog] = useState(false);
//     const [selectedRecordIndex, setSelectedRecordIndex] = useState(-1); // Initialize with -1
//     const [namespace, setNamespace] = useState<string>(props.namespace || categoryOptions[0].value);
//     const [isAscending, setIsAscending] = useState(true);
//     const [showSynonyms, setShowSynonyms] = useState(false);
//     const [synonyms, setSynonyms] = useState<SynonymRecord[]>([]);
//
//
//     const [mode, setMode] = useState("view");
//
//     const [rowsPerPage, setRowsPerPage] = useState(10);
//     const [page, setPage] = useState(0);
//
//     // Handle pagination
//     const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
//         setPage(newPage);
//     };
//
//     const handleRowsPerPage = (event: any, rows: React.SetStateAction<number>) => {
//         setRowsPerPage(rows);
//     };
//
//     function sortRecordList(selectedField: keyof KnowledgeBaseItem): void {
//         setRecordList((prevList) => {
//             const newList = [...prevList];
//
//             newList.sort((a, b) => {
//                 // Ensure the values are strings and convert them to the same case
//                 const valueA = String(a[selectedField]).toLowerCase();
//                 const valueB = String(b[selectedField]).toLowerCase();
//
//                 if (isAscending) {
//                     return valueA < valueB ? -1 : (valueA > valueB ? 1 : 0);
//                 } else {
//                     return valueA > valueB ? -1 : (valueA < valueB ? 1 : 0);
//                 }
//             });
//
//             setIsAscending(!isAscending);
//             return newList;
//         });
//     }
//
//     // Perform actions on the selected record (view, edit or delete).
//     function handleRecordClick(index: number, selected_mode: string) {
//
//         if (selected_mode === "pages") {
//             logger.info("Page Mode", recordList[index]);
//             const filename = recordList[index].meta_data.resource;
//             if (filename.endsWith("pdf")) {
//                 setSelectedFile(filename);
//                 setShowPagesDialog(true);
//             }
//         } else {
//             setMode(selected_mode);
//             setSelectedRecordIndex(index); // Store the selected index
//             setShowDialog(true);
//         }
//     }
//
//     // ------------- BUTTON HANDLERS -----------------
//     // Trigger load external site dialog
//     function handleAddSiteClick() {
//         setShowSiteDialog(true);
//     }
//
//     function handleAddUpdate(app_theme: KnowledgeBaseItemOut, file: File | null | undefined, addToIndex: boolean) {
//         setShowDialog(false);
//         if (mode === 'add') {
//             props.onRecordAdded(app_theme, file, addToIndex);
//         } else {
//             props.onRecordUpdate(app_theme, file);
//         }
//     }
//
//     // Trigger search dialog
//     function handleSearchClick() {
//         setSearchDialog(true);
//     }
//
//     function handleSynonymsClick() {
//         logger.info("Synonyms clicked");
//         setShowSynonyms(true);
//     }
//
//     function handleSynomynsSave(records: SynonymRecord[]) {
//         let items_json = JSON.stringify(records);
//         AddUpdateParameter(props.projectId, namespace, "synonyms", items_json).then(() => {
//             logger.info("Synonyms saved", records);
//             setShowSynonyms(false);
//         });
//     }
//
//     // ------------- IMPORT DATA -----------------
//     // Load external app_theme from selected project
//     useEffect(() => {
//         getExtDataRecordList(props.projectId, namespace).then(app_theme => setRecordList(app_theme));
//         getParameter(props.projectId, namespace, "synonyms").then((synonyms_json: string) => {
//             let synonyms: SynonymRecord[] = [];
//             if (synonyms_json.startsWith("[{") && synonyms_json.endsWith("}]")) {
//                 try {
//                     synonyms = JSON.parse(synonyms_json);
//                     setSynonyms(synonyms);
//                 } catch (e) {
//                     console.error("Error parsing synonyms JSON:", e);
//                 }
//             }
//             setSynonyms(synonyms);
//         });
//
//     }, [namespace])
//
//
//     // ------------- DIALOG HANDLERS -----------------
//     function handleDialogClose() {
//         setSearchDialog(false);
//         setShowDialog(false);
//         setSelectedRecordIndex(-1); // Reset the selected index
//     }
//
//     // Import selected URLs
//     const importSelectedUrls = (namespace: string, urls: string[], add_to_index: boolean) => {
//         setShowSiteDialog(false);
//         props.onUrlImport(namespace, urls, add_to_index);
//     }
//
//     const handleNamespaceSelected = (selection: Option) => {
//         logger.info("handleNamespaceSelected", selection);
//         setNamespace(selection.value);
//     }
//
//     // JSX.element
//     return (
//         <Grid container sx={{margin: '2px'}}>
//             <Grid item xs={3}>
//                 <SelectWithData app_theme={categoryOptions} id="SelectCategory" onSelect={handleNamespaceSelected}
//                                 selected={categoryOptions.findIndex((option) => option.value.toLowerCase() === namespace.toLowerCase())}
//                                 label={"Category"} isOpen={false} sx={{
//                     display: "flex",
//                     flexDirection: "row",
//                     alignItems: "center",
//                     justifyContent: "start",
//                     m: 1,
//                 }}/>
//             </Grid>
//             <Grid item xs={9} sx={{textAlign: "end", alignSelf: "center"}}>
//                 <ButtonGroup sx={{marginBottom: '0px'}} size="small" variant="contained">
//                     <Button onClick={() => handleRecordClick(-1, 'add')}>
//                         Add
//                     </Button>
//                     <Button onClick={handleAddSiteClick}>
//                         Add Site
//                     </Button>
//                     <Button onClick={() => handleSearchClick()}>
//                         Search
//                     </Button>
//                     <Button onClick={() => handleSynonymsClick()}>
//                         Synonyms
//                     </Button>
//                 </ButtonGroup>
//             </Grid>
//
//             <Grid item xs={12}>
//                 <TableContainer component={Paper}>
//                     <Table sx={{}} aria-label="ExtDataRecordList">
//                         <TableHead>
//                             <TableRow sx={{backgroundColor: colorSchema.color4, color: 'white'}}>
//                                 <TableCell sx={{color: 'inherit'}}
//                                            onClick={() => sortRecordList('title')}>Title</TableCell>
//                                 <TableCell sx={{color: 'inherit'}}
//                                            onClick={() => sortRecordList('namespace')}>Category</TableCell>
//                                 <TableCell sx={{color: 'inherit'}} align="right">
//                                     Actions
//                                 </TableCell>
//                             </TableRow>
//                         </TableHead>
//                         <TableBody>
//                             {recordList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((record, index) => (
//                                 <TableRow key={index} sx={{
//                                     cursor: 'pointer',
//                                     '&:hover': {
//                                         backgroundColor: '#f5f5f5' // Replace with your desired hover color
//                                     }
//                                 }}>
//                                     <TableCell component="th" scope="row"
//                                                onClick={() => handleRecordClick(index + (page * rowsPerPage), "view")}
//
//                                     >
//                                         {record.title}
//                                     </TableCell>
//                                     <TableCell component="th" scope="row">
//                                         {record.namespace}
//                                     </TableCell>
//                                     <TableCell align="right">
//                                         <EditIcon
//                                             onClick={() => handleRecordClick(index + (page * rowsPerPage), "edit")}/>
//                                         <FindInPageIcon sx={{color: 'green'}}
//                                                         onClick={() => handleRecordClick(index + (page * rowsPerPage), "pages")}/>
//                                         <DeleteIcon sx={{color: 'red'}}
//                                                     onClick={() => props.onRecordDeleted(record.uuid, namespace)}/>
//                                     </TableCell>
//                                 </TableRow>
//                             ))}
//                         </TableBody>
//                     </Table>
//                 </TableContainer>
//                 <TablePagination
//                     rowsPerPageOptions={[10, 25, 100]}
//                     component="div"
//                     count={recordList.length}
//                     rowsPerPage={rowsPerPage}
//                     page={page}
//                     onPageChange={handleChangePage}
//                     onRowsPerPageChange={event => {
//                         setRowsPerPage(parseInt(event.target.value, 10))
//                         setPage(0)
//                     }}
//                 />
//
//                 {showSynonyms && (
//                     <ManageSynonymList open={showSynonyms}
//                                        synonyms={synonyms}
//                                        onSave={handleSynomynsSave}
//                                        onCancel={() => {
//                                            setShowSynonyms(false)
//                                        }}
//                     />
//                 )}
//
//                 {showDialog && (
//                     <ManageKnowledgeBaseItem
//                         app_theme={selectedRecordIndex >= 0 ? recordList[selectedRecordIndex] : emptyRecord}
//                         namespace={namespace}
//                         onSubmit={handleAddUpdate}
//                         mode={mode}
//                         onClose={handleDialogClose}
//                     />
//                 )}
//
//                 {/*{showPagesDialog && (*/}
//                 {/*    <ExtDataPageViewer projectId={props.projectId}*/}
//                 {/*                       filename={selectedFile} onClose={() => setShowPagesDialog(false)}/>*/}
//                 {/*)}*/}
//
//                 {showSiteDialog && (
//                     <SiteList projectId={props.projectId} open={showSiteDialog} onClose={() => {
//                         setShowSiteDialog(false)
//                     }} onImport={importSelectedUrls}/>
//                 )}
//
//                 {searchDialog && <SearchExtData projectId={props.projectId} onClose={handleDialogClose}/>}
//             </Grid>
//         </Grid>
//
//     )
// }





