import {KnowledgeBaseItemOut} from "../../backend/extdata.services";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import AudiotrackOutlinedIcon from "@mui/icons-material/AudiotrackOutlined";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import React from "react";
import colorSchema from "../../app_theme/theme_support/colorSchema";
import {WorkspaceItem} from "../../store/types";
// import {WorkspaceItem} from "../../store/old/temp-slice";

export const getFileType = (filename: string): string => {
    const extension = filename.toLowerCase().split('.').pop();
    switch (extension) {
        case 'pdf':
            return "pdf";
        case 'doc':
        case 'docx':
            return "word";
        case 'png':
        case 'gif':
        case 'jpg':
        case 'jpeg':
        case 'webp':
            return "image";
        case 'csv':
            return "csv";
        case 'mp3':
        case 'wav':
        case 'ogg':
        case 'm4a':
        case 'mp4':
            return "audio";
        default:
            return "other";
    }
};

export const getFileIcon = (filename: string, chunks: number) => {
    const extension = filename.split('.').pop();
    switch (extension) {
        case 'jpg':
        case 'jpeg':
        case 'webp':
        case 'png':
            return <ImageOutlinedIcon/>;
        case 'pdf':
            return <ArticleOutlinedIcon sx={{color: chunks !== -1 ? colorSchema.success.main : 'inherit'}}/>;
        case 'csv':
            return <InsertChartOutlinedOutlinedIcon sx={{maxWidth: '40px'}}/>;
        case 'mp3':
        case 'm4a':
        case 'mp4':
        case 'wav':
        case 'ogg':
            return <AudiotrackOutlinedIcon/>;
        default:
            return <StickyNote2OutlinedIcon/>;
    }
};

export const getItemIcon = (item: WorkspaceItem) => {

    switch (item.tags[0]) {
        case 'html':
            return <ArticleOutlinedIcon/>;
        case 'csv':
           return <InsertChartOutlinedOutlinedIcon sx={{maxWidth: '40px'}}/>;
        case 'mermaid':
            return <AutoGraphIcon/>;
        default:
            return <StickyNote2OutlinedIcon/>;
    }
}

// Return the service info for the given file
// Use in the file viewer and the workspace viewer
export const getServiceInfo = async (
    currentKnowledgeBaseProject: string,
    uuid: string,
    getServiceRecords: (project: string, uuid: string) => Promise<KnowledgeBaseItemOut[]>
) => {
    const service_records = await getServiceRecords(currentKnowledgeBaseProject, uuid);
    const transcriptionStatus = service_records.some(record => record.metadata_type === 'transcribe');
    const subtitlesStatus = service_records.some(record => record.metadata_type === 'subtitle');
    const infoStatus: boolean = service_records.some(record => record.metadata_type === 'info');
    return {service_records, transcriptionStatus, subtitlesStatus, infoStatus};
};