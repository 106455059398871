// LocalDataStore.tsx
import React, {useEffect, useMemo, useState} from 'react';
import StoreDialogs from './store_dialogs';
import GroupManager from "./components/group_manager";
import GroupItemManager from "./components/group_item_manager";
import AppDialog from "../general/app_dialog";
import {
    addDBStore, DBItem,
    deleteDBStore,
    exportDBToJson,
    getObjectStoresInDatabase,
    importDBFromJson,
} from "../../utils/IndexedDB.utils";

import {useDispatch, useSelector} from "react-redux";
// import {AppState} from "../../store/old/store";
// import {setActiveStore} from "../../store/old/data-slice";
import {appSettings, DefaultCategories} from "../../managers/generalManager";
import {fetchData} from "pdfjs-dist";
import logger from "../../utils/logging_services";
import {RootState} from "../../store/store";
import {setActiveStore} from "../../store/dataSlice";

export interface LocalDataStoreProps {
    isOpen: boolean;
    filter: string;
    mode: "select" | "manage" | "add";
    mode_data: string;
    dbName: string;
    onClose: () => void;
    onSelect: (data: string) => void;
}


// Task group management component
// General entry point for managing app_theme stores and task items ( Goals, Prompts, etc.)
// The current implemenation uses indexedDB for app_theme storage
//
// The component is divided into two main sections:
// 1. StoreList: Displays a list of available app_theme stores and provides options to add, delete, save and load app_theme stores
// 2. StoreData: Displays the app_theme items within the selected app_theme store. The app_theme items are filtered based on the selected category
// --------------------------------------------------------------------------------------------
export const TaskGroupsManager: React.FC<LocalDataStoreProps> = (props) => {
    const [showCreateStoreDialog, setShowCreateStoreDialog] = useState<boolean>(false);
    const [showDeleteStoreDialog, setShowDeleteStoreDialog] = useState<boolean>(false);
    const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [newStoreName, setNewStoreName] = useState<string>("");
    const [storeList, setStoreList] = useState<string[]>([])
    const [uniqueCategories, setUniqueCategories] = useState<string[]>([]);

    const dispatch = useDispatch();
    const selectedStore = useSelector((state: RootState) => state.data.activeStore);

    useEffect(() => {

            const initializeData = async () => {
                const stores = await getObjectStoresInDatabase(props.dbName);
                if (stores.length > 0) {
                    setStoreList(stores);
                    const newSelectedStore = stores.includes(selectedStore) ? selectedStore : stores[0];
                    dispatch(setActiveStore(newSelectedStore))
                    fetchData(stores);
                }
            };

            let categories = null;
            categories = Object.values(DefaultCategories);
            setUniqueCategories(Array.from(categories));

            initializeData();
        }, []
    )

    const handleAddDataStoreSubmit = () => {

        if (!newStoreName.trim()) {
            logger.info("Store name is required")
            setError('Store name is required');
            setShowCreateStoreDialog(true);
            return false;
        }

        setShowPleaseWait(true);
        addDBStore(props.dbName, newStoreName).then(() => {
            setShowCreateStoreDialog(false);
            setStoreList([...storeList, newStoreName]);
            setShowPleaseWait(false);
            dispatch(setActiveStore(newStoreName))
            setNewStoreName("")
        });
    }

    const handleAddDataStoreCancel = () => {
        setShowCreateStoreDialog(false);
        setNewStoreName("");
    };

    const handleDeleteCurrentStore = () => {
        setShowPleaseWait(true);
        deleteDBStore(props.dbName, selectedStore).then(() => {
            setShowDeleteStoreDialog(false);
            const newStoreList = storeList.filter(store => store !== selectedStore);
            setStoreList(prevState => newStoreList);
            if (newStoreList.length > 0) {
                dispatch(setActiveStore(newStoreList[0]))
            } else {
                dispatch(setActiveStore(""));
            }
            setShowPleaseWait(false);
        });
    }

    const handleShowCreateStoreDialog = () => {
        setShowCreateStoreDialog(true);
    };

    const handleShowConfirmDeleteStore = () => {
        setShowDeleteStoreDialog(true);
    };

    const handleSaveStore = () => {
        exportDBToJson(props.dbName, selectedStore).then((result) => {
            logger.info("Exported app_theme", result);
        })
    }

    const handleLoadStore = () => {
        importDBFromJson(props.dbName).then((result) => {
            logger.info("Imported app_theme", result);
        })
    }

    return (
        <AppDialog open={true} size={"xl"} title={"Task Group Management"} message={error} showSubmit={true}
                   showCancel={false} onSubmit={props.onClose} onCancel={props.onClose}>
            <div style={{display: 'flex', flexDirection: 'row', flex: 1, minHeight: appSettings.dialogHeight}}>
                <GroupManager
                    storeList={storeList}
                    onAddStore={handleShowCreateStoreDialog}
                    onDeleteStore={handleShowConfirmDeleteStore}
                    onSaveStore={handleSaveStore}
                    onLoadStore={handleLoadStore}
                />
                <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                    <GroupItemManager dbName={props.dbName} defaultFilter={'Goals'} uniqueCategories={uniqueCategories}/>
                    <StoreDialogs
                        showCreateStoreDialog={showCreateStoreDialog}
                        showDeleteStoreDialog={showDeleteStoreDialog}
                        showPleaseWait={showPleaseWait}
                        error={error}
                        newStoreName={newStoreName}
                        setNewStoreName={(storeName) => setNewStoreName(storeName.charAt(0).toUpperCase() + storeName.slice(1))}
                        handleAddDataStoreSubmit={handleAddDataStoreSubmit}
                        handleAddDataStoreCancel={handleAddDataStoreCancel}
                        handleDeleteCurrentStore={handleDeleteCurrentStore}
                        setShowDeleteStoreDialog={setShowDeleteStoreDialog}
                    />
                </div>
            </div>
        </AppDialog>
    );
};

export default TaskGroupsManager;


// import React, {useState, useEffect, useMemo} from 'react';
// import {
//     getAllFromIndexedDB,
//     getObjectStoresInDatabase,
//     addDBStore,
//     deleteDBStore,
//     exportDBToJson,
//     importDBFromJson,
//     addItemToStore, deleteItemFromStore, DBItem, IndexedDBRecord
// } from "../../utils/IndexedDB.utils";
// import {
//     Button,
//     CircularProgress,
//     Dialog,
//     DialogContent,
//     DialogContentText,
//     DialogTitle, Divider,
//     Grid,
//     IconButton, List, ListItem, ListItemText, MenuItem, Select,
//     TextField, Typography
// } from "@mui/material";
// import AppDialog from "../general/app_dialog";
// import {colorSchema} from "../../app_theme/theme";
// import CloseIcon from '@mui/icons-material/Close';
// import {appSettings, DefaultCategories, selectedCategory} from "../../managers/generalManager";
// import DeleteIcon from '@mui/icons-material/Delete';
// import StorageIcon from '@mui/icons-material/Storage';
// import AddIcon from '@mui/icons-material/Add';
// import {EditorViewer} from "../viewers/editor_viewer";
// import {ContentState, convertToRaw, EditorState, Modifier} from "draft-js";
// import htmlToDraft from "html-to-draftjs";
// import draftToHtml from "draftjs-to-html";
//
// import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import AppDrawer from "../general/app_drawer";
// import AppTable, {TableColumn, TableItem} from "../general/app_table";
// import {useDispatch, useSelector} from "react-redux";
// import {AppState} from "../../store/store";
// import {setActiveStore} from "../../store/app_theme-slice";
// import formImage from '../../assets/form.png';
// import promptImage from '../../assets/prompt.png';
// import dropdownImage from '../../assets/dropdown.png';
// import exampleImage from '../../assets/example.png';
// import headerImage from '../../assets/header.png';
// import textImage from '../../assets/text.png';
// import inputImage from '../../assets/input.png';
//
//
// export interface LocalDataStoreProps {
//     isOpen: boolean;
//     filter: string;
//     mode: "select" | "manage" | "add";
//     mode_data: string;
//     onClose: () => void;
//     onSelect: (app_theme: string) => void;
// }
//
// interface ObjectItem extends TableItem {
//     key: string;
//     name: string;
//     description: string;
//     item?: DBItem;
// }
//
// export const LocalDataStore = (props: LocalDataStoreProps): React.JSX.Element => {
//     const [record, setRecord] = useState<IndexedDBRecord[]>([]);
//
//     const [isOpen, setIsOpen] = useState<boolean>(props.isOpen);
//     const [isLoaded, setIsLoaded] = useState<boolean>(false);
//     const [filter, setFilter] = useState<string>(props.filter);
//
//     const [dialogMode, setDialogMode] = useState<string>("");
//     const [mode, setMode] = useState<"select" | "manage">("select");
//
//     const [selectedKey, setSelectedKey] = useState<string>("");
//
//     // database record structure
//     const [category, setCategory] = useState<string>("");
//     const [name, setName] = useState<string>("");
//     const [tag, setTag] = useState<string>("");
//     const [value, setValue] = useState<string>("");
//     const [template, setTemplate] = useState<string>("");
//     const [description, setDescription] = useState<string>("");
//
//     const [uniqueCategories, setUniqueCategories] = useState<string[]>([]);
//     const [storeList, setStoreList] = useState<string[]>([])
//     // const [selectedStore, setSelectedStore] = useState<string>(appSettings.task_groups)
//     const [dbName] = useState<string>(appSettings.database)
//
//     const [showCreateStoreDialog, setShowCreateStoreDialog] = useState<boolean>(false);
//     const [newStoreName, setNewStoreName] = useState<string>("");
//     const [showDeleteStoreDialog, setShowDeleteStoreDialog] = useState<boolean>(false);
//     const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
//     const [error, setError] = useState<string>("");
//
//     const [editorStateValue, setEditorStateValue] = useState<EditorState>(EditorState.createEmpty());
//     const [editorStateTemplate, setEditorStateTemplate] = useState<EditorState>(EditorState.createEmpty());
//
//     const dispatch = useDispatch();
//     const selectedStore = useSelector((state: AppState) => state.app_theme.activeStore);
//
//     const [categoryType, setCategoryType] = useState<string>("prompt");
//
//     const [columns, setColumns] = useState<TableColumn<ObjectItem>[]>([]);
//     const [showDeleteItemDialog, setShowDeleteItemDialog] = useState<boolean>(false);
//
//     const sortedRecords: ObjectItem[] = useMemo(() => {
//         console.log("In LocalDataStore - useMemo, category: ");
//         return [...record]
//             .sort((a, b) => a.value.name.localeCompare(b.value.name))
//             .map((record) => ({
//                 key: record.key,
//                 category: record.value.category,
//                 name: record.value.name,
//                 description: record.value.description,
//                 item: record.value
//             }));
//     }, [record]);
//
//     useEffect(() => {
//         let categories = null;
//         categories = Object.values(DefaultCategories);
//         setUniqueCategories(Array.from(categories));
//     }, []);
//
//
//     useEffect(() => {
//         let isSubscribed = true; // To avoid state updates on unmounted components
//
//         if (selectedStore === "") {
//             return;
//         }
//
//         console.log("In LocalDataStore - useEffect, selectedStore: ", selectedStore)
//         const fetchData = async (stores: string[]) => {
//
//             try {
//                 if (!stores.includes(selectedStore)) {
//                     console.log("Store not found: ", selectedStore)
//                     return;
//                 }
//
//                 const app_theme = await getAllFromIndexedDB(dbName, selectedStore);
//                 if (isSubscribed) {
//                     setRecord(app_theme);
//                 }
//             } catch (error) {
//                 console.error("Error during app_theme fetch", error);
//                 if (isSubscribed) {
//                     setRecord([]);  // Clear the list
//                 }
//             } finally {
//                 if (isSubscribed) {
//                     setIsLoaded(true);
//                 }
//             }
//         };
//
//         const initializeData = async () => {
//             const stores = await getObjectStoresInDatabase(dbName);
//             if (stores.length > 0) {
//                 setStoreList(stores);
//                 // const newSelectedStore = stores.includes(appSettings.task_groups) ? appSettings.task_groups : stores[0];
//                 const newSelectedStore = stores.includes(selectedStore) ? selectedStore : stores[0];
//                 dispatch(setActiveStore(newSelectedStore))
//                 // appSettings.task_groups = newSelectedStore;
//                 fetchData(stores);
//             }
//         };
//
//         initializeData();
//
//         const columns: TableColumn<ObjectItem>[] = [
//             {
//                 title: "Name",
//                 dataIndex: "name",
//                 render: (item: ObjectItem) => item.name
//             },
//             {
//                 title: "Tag",
//                 dataIndex: "Tag",
//                 render: (item: ObjectItem) => item.item ? item.item.tag || "" : ""
//             },
//             {
//                 title: "Description",
//                 dataIndex: "description",
//                 render: (item: ObjectItem) => item.description
//             },
//         ];
//         setColumns(columns);
//
//         return () => {
//             isSubscribed = false; // Cleanup function to prevent state updates on unmounted component
//         };
//     }, [selectedStore]); // Add other dependencies as required
//
//     useEffect(() => {
//         setIsLoaded(false);
//
//         if (props.mode === 'add') {
//             setMode('manage');  // Directly set 'manage', no need for a function
//         } else {
//             setMode(props.mode);
//         }
//
//         // setIsLoaded(true); Moved to a separate useEffect for accurate timing
//     }, [props.mode, props.mode_data]);
//
//
//     useEffect(() => {
//         if (mode === 'manage' && props.mode === 'add') {
//             loadEditorState(props.mode_data, "");
//             let defaultRecord: IndexedDBRecord = {
//                 key: "",
//                 value: {id: 0, category: selectedCategory, name: "", description: "", value: props.mode_data, tag: ''}
//             };
//             handleTableRequest('add', '', defaultRecord);
//         }
//         setIsLoaded(true);
//     }, [mode, props.mode]);
//
//
//     // Menu items can be passed as props or defined outside the component if they are static
//     const menuItem = useMemo(() => [
//         {label: "Delete", action: "delete"},
//     ], []);
//
//     const initDialogFields = useMemo(() => () => {
//         setSelectedKey("");
//         setCategory("");
//         setName("");
//         setValue("");
//         setTemplate("");
//         setDialogMode("");
//     }, []);
//
//     const handleAddUpdateItem = async () => {
//
//         // validate input fields
//         if (name === "") {
//             console.log("Name is required");
//             setError('Name is required');
//             return;
//         }
//         console.log("Add/Update item", name, value, description, category)
//
//         try {
//             let updatedRecords = [...record];
//
//             // remove record based on key. This to avoid duplicates
//             if (selectedKey !== "") {
//                 updatedRecords = updatedRecords.filter(r => r.key !== selectedKey);
//             }
//
//             // New key
//             const key = `${category}_${name}`;
//
//             // Add new record
//             updatedRecords.push({
//                 key, value: {id: Date.now(), name, value, description, category, template, tag}
//             });
//
//             // Update IndexedDB
//             addItemToStore(dbName, selectedStore, key, {name, description, value, category, template, tag}).then(
//                 () => {
//                     console.log(updatedRecords);
//                 });
//
//             setRecord(updatedRecords);
//
//         } catch (error) {
//             console.log("Error during add/update", error);
//         }
//     }
//
//     const handleUpdateItem = async () => {
//
//         if (selectedKey === "") {
//             console.log("No item selected for update");
//             return;
//         }
//
//         await handleRemoveItem();
//         await handleAddUpdateItem();
//     };
//
//     const handleRemoveItem = async () => {
//         if (selectedKey === "") {
//             console.log("No item selected for update");
//         }
//         setShowDeleteItemDialog(false);
//
//         await deleteItemFromStore(dbName, selectedStore, selectedKey);
//         setRecord(record.filter((item) => item.key !== selectedKey));
//     };
//
//
//     const loadEditorState = (value: string, template: string) => {
//
//         function convertToEditorState(value: string) {
//             let {contentBlocks, entityMap} = htmlToDraft(value || "");
//
//             // Create a new content state from the HTML content
//             const newContentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
//
//             // Create a new EditorState with the new content
//             return EditorState.createWithContent(newContentState);
//         }
//
//         setEditorStateValue(convertToEditorState(value));
//         setEditorStateTemplate(convertToEditorState(template));
//
//         // let {contentBlocks, entityMap} = htmlToDraft(value || "");
//         //
//         // // Create a new content state from the HTML content
//         // const newContentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
//         //
//         // // Create a new EditorState with the new content
//         // const newEditorState = EditorState.createWithContent(newContentState);
//         //
//         // // Set the new EditorState
//         // setEditorStateValue(newEditorState);
//
//
//     }
//
//     function handleEditorStateChange(newState: EditorState, type: string) {
//
//         if (type === 'prompt') {
//             setEditorStateValue(newState);
//             setValue(draftToHtml(convertToRaw(newState.getCurrentContent())));
//         } else {
//             setEditorStateTemplate(newState);
//             setTemplate(draftToHtml(convertToRaw(newState.getCurrentContent())));
//         }
//         // setEditorStateValue(newState);
//         // setValue(draftToHtml(convertToRaw(newState.getCurrentContent())));
//     }
//
//     const handleRequest = (action: string, key: string, selectedColumns: string[]) => {
//         handleTableRequest(action, key)
//     }
//
//     const handleTableRequest = (action: string, key: string, defaultRecord: IndexedDBRecord | undefined = undefined) => {
//
//         console.log("Table request: ", action, key, defaultRecord)
//
//         // Convert default action at select towards edit.
//         if (action === 'view') {
//             action = 'edit'
//         }
//
//         let selectedRecord: IndexedDBRecord = {
//             key: "",
//             value: {id: 0, category: "", name: "", description: "", value: "", tag: ""}
//         };
//         record.find((item) => {
//             if (item.key === key) selectedRecord = item;
//         });
//
//         if (mode === 'select') {
//             setIsOpen(false);
//             props.onSelect(selectedRecord.value.value);
//             return;
//         }
//
//         setDialogMode(prevState => action);
//         setCategoryType("prompt");
//         setSelectedKey(key);
//         setName(selectedRecord.value.name);
//         setDescription(selectedRecord.value.description);
//         setTag(selectedRecord.value.tag || "")
//
//         if (action === 'add') {
//             setCategory(filter);
//         } else {
//             setCategory(selectedRecord.value.category);
//         }
//
//         if (props.mode === 'add' && defaultRecord !== undefined) {
//             setValue(defaultRecord.value.value);
//             setDescription(defaultRecord.value.description || "");
//             loadEditorState(defaultRecord.value.value, defaultRecord.value.template || "");
//         } else {
//             setValue(selectedRecord.value.value);
//             setTemplate(selectedRecord.value.template || "")
//             loadEditorState(selectedRecord.value.value, selectedRecord.value.template || "");
//         }
//
//         if (action === 'delete') {
//             setDialogMode('');
//             setShowDeleteItemDialog(true);
//         }
//     }
//
//     const handleDialogRequest = () => {
//
//         console.log("Dialog request: ", dialogMode)
//
//         if (dialogMode === 'add') {
//             handleAddUpdateItem();
//         } else if (dialogMode === 'edit') {
//             handleUpdateItem();
//         } else if (dialogMode === 'delete') {
//             handleRemoveItem()
//         }
//         initDialogFields();
//     }
//
//
//     const handleClose = () => {
//         setIsOpen(false);
//         props.onClose();
//     }
//
//     const handleListItemClick = (store: string) => {
//         dispatch(setActiveStore(store))
//         // appSettings.task_groups = store;
//     };
//
//     const handleShowCreateStoreDialog = () => {
//         setShowCreateStoreDialog(prevState => true);
//     }
//
//     const handleAddDataStoreSubmit = () => {
//
//         if (!newStoreName.trim()) {
//             console.log("Store name is required")
//             setError('Store name is required');
//             setShowCreateStoreDialog(true);
//             return false;
//         }
//
//         setShowPleaseWait(true);
//         addDBStore(dbName, newStoreName).then(() => {
//             setShowCreateStoreDialog(false);
//             setStoreList([...storeList, newStoreName]);
//             setShowPleaseWait(false);
//             dispatch(setActiveStore(newStoreName))
//             setNewStoreName("")
//         });
//     }
//
//     const handleAddDataStoreCancel = () => {
//         setShowCreateStoreDialog(false);
//         setNewStoreName("");
//     }
//
//     const handleShowConfirmDeleteStore = () => {
//         setShowDeleteStoreDialog(true);
//     }
//
//     const handleFilterSelected = (filter: string) => {
//         setFilter(filter);
//         console.log("Filter selected: ", filter);
//     }
//
//     const handleDeleteCurrentStore = () => {
//         setShowPleaseWait(true);
//         deleteDBStore(dbName, selectedStore).then(() => {
//             setShowDeleteStoreDialog(false);
//             const newStoreList = storeList.filter(store => store !== selectedStore);
//             setStoreList(prevState => newStoreList);
//             if (newStoreList.length > 0) {
//                 dispatch(setActiveStore(newStoreList[0]))
//             } else {
//                 dispatch(setActiveStore(""));
//             }
//             setShowPleaseWait(false);
//         });
//     }
//
//     const handleSaveStore = () => {
//         exportDBToJson(dbName).then((result) => {
//             console.log("Exported app_theme", result);
//         })
//     }
//
//     const handleLoadStore = () => {
//         importDBFromJson(dbName).then((result) => {
//             console.log("Imported app_theme", result);
//         })
//     }
//
//     const handleInsert = (type: string): void => {
//         let textToInsert = "";
//         if (type === 'header') {
//             textToInsert = "Define header title ({type:header})";
//         }
//         if (type === 'text') {
//             textToInsert = "Define text here ({type:body})";
//         }
//         if (type === 'field') {
//             textToInsert = "[field_name({rows:1})]";
//         }
//         if (type === 'choice') {
//             textToInsert = "Select one of the given values [field_name({choice:value1|value2|value3|value4})]";
//         }
//         if (type === 'example') {
//             textToInsert = "Define example ({type:example})\n";
//         }
//
//         const currentContent = editorStateTemplate.getCurrentContent();
//         const currentBlockMap = currentContent.getBlockMap();
//         const lastBlock = currentBlockMap.last();
//         const endKey = lastBlock.getKey();
//         const endLength = lastBlock.getLength();
//
//         // Move selection to the end of the last block
//         const selection = editorStateTemplate.getSelection().merge({
//             anchorKey: endKey,
//             anchorOffset: endLength,
//             focusKey: endKey,
//             focusOffset: endLength,
//         });
//
//         // Insert a new line and then the new text
//         const newContentState = Modifier.insertText(
//             currentContent,
//             selection,
//             '\n' + textToInsert
//         );
//
//         const newEditorState = EditorState.push(
//             editorStateTemplate,
//             newContentState,
//             'insert-characters'
//         );
//
//         setEditorStateTemplate(newEditorState);
//     }
//
//     return (
//         <div>
//             {isLoaded && (
//
//                 <Dialog
//                     open={isOpen}
//                     onClose={handleClose}
//                     fullWidth={true}
//                     maxWidth={"xl"}
//                     PaperProps={{style: {minHeight: '800px'}}}
//                     aria-labelledby="localdata-list"
//                 >
//                     <DialogTitle
//                         variant={"h5"}
//                         id="dialog_title"
//                         align={"center"}
//                         sx={{
//                             backgroundImage: `linear-gradient(to right,${colorSchema.assistant_topics_background} , ${colorSchema.color1})`,
//                             padding: '5px',
//                             letterSpacing: '1px'
//                         }}
//                         color={colorSchema.light}
//                     >
//                         Task Group Management
//                         <IconButton
//                             aria-label="close"
//                             onClick={handleClose}
//                             sx={{
//                                 position: 'absolute',
//                                 right: 8,
//                                 top: -3,
//                                 color: colorSchema.light,
//                             }}
//                         >
//                             <CloseIcon/>
//                         </IconButton>
//                     </DialogTitle>
//                     <Divider/>
//                     <DialogContentText>
//                     </DialogContentText>
//                     <DialogContent sx={{display: 'flex', flex: 1}}>
//                         <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
//                             {/* LEFT SIDE - STORES */}
//                             <AppDrawer title={selectedStore} state={false}>
//                                 <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
//
//                                     <div style={{
//                                         display: 'flex',
//                                         flexDirection: 'row',
//                                         justifyContent: 'space-between',
//                                         alignContent: 'space-between'
//                                     }}>
//                                         <div style={{display: 'flex'}}>
//                                             <Typography variant="subtitle1"
//                                                         sx={{marginTop: '10px', marginLeft: '10px'}}>
//                                                 <StorageIcon sx={{marginRight: '5px', color: colorSchema.color1}}/>
//                                             </Typography>
//                                         </div>
//                                         <div style={{
//                                             minHeight: '50px',
//                                             justifyContent: 'end',
//                                             alignItems: 'center',
//                                             display: 'flex'
//                                         }}>
//                                             <IconButton size={"small"} onClick={() => handleShowCreateStoreDialog()}
//                                                         name="add">
//                                                 <AddIcon/>
//                                             </IconButton>
//                                             <IconButton onClick={() => handleShowConfirmDeleteStore()} name="delete"
//                                                         size={"small"}>
//                                                 <DeleteIcon style={{color: colorSchema.warning}}/>
//                                             </IconButton>
//                                             <IconButton onClick={() => handleSaveStore()} name="save_data"
//                                                         size={"small"} sx={{ml: '5px'}}>
//                                                 <CloudDownloadIcon style={{color: colorSchema.primary}}/>
//                                             </IconButton>
//                                             <IconButton onClick={() => handleLoadStore()} name="load_data"
//                                                         size={"small"}>
//                                                 <CloudUploadIcon style={{color: colorSchema.primary}}/>
//                                             </IconButton>
//                                         </div>
//                                     </div>
//                                     <Divider/>
//                                     <div style={{
//                                         display: 'flex',
//                                         flexDirection: 'column',
//                                         flex: "1, 0, 0px",
//                                         overflowY: 'auto',
//                                         marginTop: '5px'
//                                     }}>
//                                         {storeList.length > 0 && (
//                                             <List component="nav" aria-label="store list" sx={{flex: 1, paddingX: 2}}>
//                                                 {storeList.map((store, index) => (
//                                                     <ListItem
//                                                         key={index}
//                                                         onClick={() => handleListItemClick(store)}
//                                                         sx={{
//                                                             borderRadius: '5px',
//                                                             height: '40px',
//                                                             textAlign: 'center',
//                                                             marginBottom: '5px',
//                                                             backgroundColor: selectedStore === store ? colorSchema.gray6 : "inherit",
//                                                             color: selectedStore === store ? 'white' : "inherit",
//                                                             fontWeight: selectedStore === store ? 'bold' : "inherit",
//                                                             '&:hover': {
//                                                                 backgroundColor: colorSchema.color3,
//                                                             },
//                                                         }}
//                                                     >
//                                                         <ListItemText primary={store}/>
//                                                     </ListItem>
//                                                 ))}
//                                             </List>
//                                         )}
//                                     </div>
//
//                                 </div>
//                             </AppDrawer>
//                             {/* RIGHT SIDE - STORES */}
//
//                             <AppTable
//                                 app_theme={sortedRecords}
//                                 title={""}
//                                 filter={filter}
//                                 filterValues={uniqueCategories}
//                                 columns={columns}
//                                 selectColumnsToShow={[]}
//                                 hideHeaders={false}
//                                 menu={menuItem}
//                                 menuTable={[]}
//                                 selected={[]}
//                                 allowAdd={true}
//                                 onRequest={handleRequest}
//                                 mode={mode === "select" || mode === "manage" ? mode : "select"}
//                                 selectMode={"edit"}
//                                 allowDelete={true}
//                                 allowEdit={true}
//                                 allowView={true}
//                                 onFilterSelected={handleFilterSelected}
//                             />
//                         </div>
//                     </DialogContent>
//                 </Dialog>
//             )}
//
//             {/* CREATE NEW STORE DIALOG */}
//             {showCreateStoreDialog && (
//                 <AppDialog open={showCreateStoreDialog}
//                            size={"xs"} title={"Create new task group"}
//                            message={error}
//                            showSubmit={true} showCancel={true}
//                            onSubmit={handleAddDataStoreSubmit} onCancel={handleAddDataStoreCancel}>
//                     <TextField id="store"
//                                sx={{marginTop: '10px'}}
//                                fullWidth
//                                required={true}
//                                value={newStoreName}
//                                label="Please provide a name for the new task group"
//                                variant="outlined"
//                                error={!!error} // Show error styling if there's an error
//                                helperText={error} // Display error message
//                                onChange={event => setNewStoreName(event.target.value)}/>
//                 </AppDialog>
//             )}
//
//             {/* SHOW PLEASE WAIT */}
//             {showPleaseWait && (
//                 <div style={{
//                     position: 'fixed', // Fixed position to cover the whole screen
//                     top: 0,
//                     left: 0,
//                     width: '100%', // Full width
//                     height: '100%', // Full height
//                     display: 'flex',
//                     justifyContent: 'center', // Center horizontally
//                     alignItems: 'center', // Center vertically
//                     backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent background
//                     zIndex: 1000, // High z-index to overlay on top of other content
//                 }}>
//                     <CircularProgress color="primary" size={50}/>
//                 </div>
//             )}
//
//             {/* SHOW DELETE STORE DIALOG */}
//             {
//                 showDeleteStoreDialog && (
//                     <AppDialog
//                         open={true}
//                         size={"sm"}
//                         showSubmit={true}
//                         showCancel={true}
//                         title={`Delete ${selectedStore} `}
//                         message={"Are you sure you want to delete the selected task group?"}
//                         onSubmit={() => handleDeleteCurrentStore()}
//                         onCancel={() => setShowDeleteStoreDialog(false)}
//                     >
//                         <div style={{textAlign: 'center'}}>
//                             <Typography variant="h6" sx={{marginTop: '10px', marginLeft: '10px'}}>
//                                 You're about to delete the task group {selectedStore}.
//                             </Typography>
//                             <Typography variant="body1" sx={{marginTop: '10px', marginLeft: '10px'}}>
//                                 Please be aware that this action is permanent and cannot be undone.
//                             </Typography>
//                             <Typography variant="body1" sx={{marginTop: '10px', marginLeft: '10px'}}>
//                                 Deleting the store will permanently remove all app_theme contained within it.
//                             </Typography>
//                             <Typography variant="body1" sx={{marginTop: '10px', marginLeft: '10px'}}>
//                                 To confirm, click Confirm.
//                             </Typography>
//                         </div>
//                     </AppDialog>
//                 )}
//
//             {/* SHOW DELETE STORE DIALOG */}
//             {
//                 showDeleteItemDialog && (
//                     <AppDialog
//                         open={true}
//                         size={"sm"}
//                         showSubmit={true}
//                         showCancel={true}
//                         title={`Delete item ${name} `}
//                         message={"Are you sure you want to delete the selected item?"}
//                         onSubmit={() => handleRemoveItem()}
//                         onCancel={() => setShowDeleteItemDialog(false)}
//                     >
//                         <div style={{textAlign: 'center'}}>
//                             <Typography variant="h6" sx={{marginTop: '10px', marginLeft: '10px'}}>
//                                 You're about to delete the {category} item : {name}.
//                             </Typography>
//                             <Typography variant="body1" sx={{marginTop: '10px', marginLeft: '10px'}}>
//                                 Please be aware that this action is permanent and cannot be undone.
//                             </Typography>
//                             <Typography variant="body1" sx={{marginTop: '10px', marginLeft: '10px'}}>
//                                 To confirm, click Confirm.
//                             </Typography>
//                         </div>
//                     </AppDialog>
//                 )}
//
//             {/* SHOW EDIT/VIEW/ADD NEW ITEM DIALOG  */}
//             {dialogMode !== "" && (
//                 <AppDialog
//                     open={true}
//                     size={"xl"}
//                     showSubmit={dialogMode !== 'view'}
//                     showCancel={true}
//                     title={`${dialogMode} item`}
//                     height={"80vh"}
//                     message={"Please enter the name of the new item"}
//                     onSubmit={handleDialogRequest}
//                     onCancel={() => setDialogMode("")}
//                 >
//                     <Grid container spacing={1}>
//
//                         {/* In EDIT or ADD mode show category, description and other fields */}
//                         {dialogMode !== 'view' && (
//                             <Grid container style={{paddingBottom: '5px', borderBottom: '1px solid #ccc'}} spacing={1}>
//                                 <Grid item={true} xs={5} sx={{margin: '0px'}}>
//                                     <Typography variant="subtitle2" marginRight={"10px"}>Category</Typography>
//                                     <Select
//                                         fullWidth={true}
//                                         value={category}
//                                         label={"Category"}
//                                         title={"Category"}
//                                         variant={"outlined"}
//                                         onChange={event => setCategory(event.target.value)}
//                                     >
//                                         {uniqueCategories.map((value) => {
//                                             return (
//                                                 <MenuItem key={value} value={value}
//                                                           id={value}>{value}</MenuItem>
//                                             )
//                                         })}
//
//                                     </Select>
//                                 </Grid>
//                                 <Grid item={true} xs={5} sx={{margin: '0px', padding: '0px'}}>
//                                     <TextField id="name"
//                                                fullWidth
//                                                value={name}
//                                                sx={{marginTop: '16px'}}
//                                                disabled={dialogMode === 'delete' || dialogMode === 'view'}
//                                                label="Name"
//                                                variant="outlined"
//                                                onChange={event => setName(event.target.value)}/>
//                                 </Grid>
//                                 <Grid item={true} xs={2} sx={{margin: '0px', padding: '0px'}}>
//                                 </Grid>
//                                 <Grid item={true} xs={4} sx={{margin: '0px', padding: '0px'}}>
//                                     <TextField id="tag"
//                                                fullWidth
//                                                value={tag}
//                                                disabled={dialogMode === 'delete' || dialogMode === 'view'}
//                                                label="Tag"
//                                                variant="outlined"
//                                                onChange={event => setTag(event.target.value)}/>
//                                 </Grid>
//                                 <Grid item={true} xs={8} sx={{margin: '0px', padding: '0px'}}>
//                                     <TextField id="description"
//                                                fullWidth
//                                                value={description}
//                                                disabled={dialogMode === 'delete' || dialogMode === 'view'}
//                                                label="Description"
//                                                variant="outlined"
//                                                onChange={event => setDescription(event.target.value)}/>
//                                 </Grid>
//                             </Grid>
//                         )}
//                         {category === 'Goals' && (
//                             <Grid item={true} xs={1} sx={{
//                                 marginTop: '0px', padding: '0px', borderRight: '1px solid #ccc',
//                                 textAlign: 'center'
//                                 // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
//                             }}>
//                                 <Button onClick={() => setCategoryType("template")}>
//                                     <div style={{display: 'flex', flexDirection: 'column'}}>
//                                         <img src={formImage} style={{width: '40px', height: 'auto'}} alt="form"/>
//                                         <Typography variant="subtitle2" marginTop={"2px"} sx={{
//                                             backgroundColor: categoryType === 'template' ? colorSchema.options_content_selected : "inherit",
//                                             minWidth: 'auto'
//                                         }}>Template</Typography>
//                                     </div>
//                                 </Button>
//                                 <Button onClick={() => setCategoryType("prompt")}>
//                                     <div style={{display: 'flex', flexDirection: 'column'}}>
//                                         <img src={promptImage} style={{width: '40px', height: 'auto'}} alt="Prompt"/>
//                                         <Typography variant="subtitle2" marginTop={"2px"} sx={{
//                                             backgroundColor: categoryType === 'prompt' ? colorSchema.options_content_selected : "inherit",
//                                             minWidth: 'auto'
//                                         }}>Prompt</Typography>
//                                     </div>
//                                 </Button>
//                                 {categoryType === 'template' && (
//                                     <>
//                                         <Divider/>
//                                         <Typography variant="body1" marginTop={"10px"} sx={{
//                                             textAlign: 'center',
//                                             backgroundColor: colorSchema.options_content_selected,
//                                             marginX: '5px'
//                                         }}>ASSIST</Typography>
//                                         <div style={{display: 'flex', flexDirection: 'column'}}>
//                                             <Button sx={{marginTop: '5px'}} onClick={() => handleInsert("header")}>
//                                                 <img src={headerImage}
//                                                      style={{width: '20px', height: 'auto', alignItems: 'center'}}
//                                                      alt="Header"/>
//                                             </Button>
//                                             <Button sx={{marginTop: '3px'}} onClick={() => handleInsert("text")}>
//                                                 <img src={textImage}
//                                                      style={{width: '20px', height: 'auto', alignItems: 'center'}}
//                                                      alt="Text"/>
//                                             </Button>
//                                             <Button sx={{marginTop: '3px'}} onClick={() => handleInsert("field")}>
//                                                 <img src={inputImage}
//                                                      style={{width: '20px', height: 'auto', alignItems: 'center'}}
//                                                      alt="Field"/>
//                                             </Button>
//                                             <Button sx={{marginTop: '3px'}} onClick={() => handleInsert("choice")}>
//                                                 <img src={dropdownImage}
//                                                      style={{width: '20px', height: 'auto', alignItems: 'center'}}
//                                                      alt="Choice"/>
//                                             </Button>
//                                             <Button sx={{marginTop: '3px'}} onClick={() => handleInsert("example")}>
//                                                 <img src={exampleImage}
//                                                      style={{width: '20px', height: 'auto', alignItems: 'center'}}
//                                                      alt="Example"/>
//                                             </Button>
//                                         </div>
//                                     </>
//                                 )}
//                             </Grid>
//                         )}
//                         <Grid item={true} xs={category === 'Goals' ? 11 : 12} sx={{margin: '0px', padding: '0px'}}>
//
//                             {categoryType === 'prompt' ? (
//                                 <EditorViewer editorState={editorStateValue}
//                                               showActions={false}
//                                               onEditorStateChange={state => handleEditorStateChange(state, "prompt")}
//                                               onSendRequest={() => {
//                                               }}
//                                               onUpdateEditorState={setEditorStateValue}/>
//
//                             ) : (
//                                 <EditorViewer editorState={editorStateTemplate}
//                                               showActions={false}
//                                               onEditorStateChange={state => handleEditorStateChange(state, "template")}
//                                               onSendRequest={() => {
//                                               }}
//                                               onUpdateEditorState={setEditorStateTemplate}/>
//
//                             )}
//                         </Grid>
//
//                     </Grid>
//                 </AppDialog>
//             )}
//         </div>
//     )
// }
