import React, {useState} from 'react';
import {Document, Outline} from 'react-pdf';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Box,
    styled
} from '@mui/material';

// ... previous styled components ...

const StyledOutlineContainer = styled('div')({
    maxHeight: '300px',
    overflow: 'auto',
    '& ul': {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
    },
    '& li': {
        margin: '8px 0',
        padding: '8px',
        backgroundColor: '#f0f0f0',
        borderRadius: '4px',
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    '& a': {
        color: '#1976d2',
        textDecoration: 'none',
        display: 'block',
        fontWeight: 'bold',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

interface GoToPageDialogProps {
    pdfUrl: string;
    numPages: number | undefined;
    onClose: () => void;
    onGoToPage: (page: number) => void;
}

const GoToPageDialog: React.FC<GoToPageDialogProps> = ({pdfUrl, numPages, onClose, onGoToPage}) => {
    const [pageInput, setPageInput] = useState<string>("");

    const handleGoToPage = () => {
        const page = parseInt(pageInput, 10);
        if (numPages && page >= 1 && page <= numPages) {
            onGoToPage(page);
            onClose();
        }
    };

    const handleOutlineItemClick = ({pageNumber}: { pageNumber: number }) => {
        onGoToPage(pageNumber);
        onClose();
    };

    return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Go to Page</DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" gap={2}>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Page Number"
                        type="number"
                        fullWidth
                        variant="outlined"
                        value={pageInput}
                        onChange={(e) => setPageInput(e.target.value)}
                        InputProps={{
                            inputProps: {min: 1, max: numPages}
                        }}
                    />
                    <StyledOutlineContainer>
                        <Document
                            file={pdfUrl}
                            onLoadError={(error) => console.error("Error loading PDF document:", error)}
                            options={{workerSrc: "/pdf.worker.js"}}
                        >
                            <Outline
                                onItemClick={handleOutlineItemClick}
                            />
                        </Document>
                    </StyledOutlineContainer>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={handleGoToPage}
                    disabled={!(pageInput && parseInt(pageInput, 10) >= 1 && numPages && parseInt(pageInput, 10) <= numPages)}
                    color="primary"
                    variant="contained"
                >
                    Go
                </Button>
        </DialogActions>
</Dialog>);
};

export default GoToPageDialog;