import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UIState {
    selectedTemplate: string;
    selectedPersona: string;
    followupQuestions: string[];
    notification: { message: string; type: string } | undefined;
}

const initialState: UIState = {
    selectedTemplate: '',
    selectedPersona: '',
    followupQuestions: [],
    notification: undefined,
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setSelectedTemplate: (state, action: PayloadAction<string>) => {
            state.selectedTemplate = action.payload;
        },
        setFollowupQuestions: (state, action: PayloadAction<string[]>) => {
            state.followupQuestions = action.payload;
        },
        setNotification: (state, action: PayloadAction<{ message: string; type: string } | undefined>) => {
            state.notification = action.payload;
        },
    },
});

export const {
    setSelectedTemplate,
    setFollowupQuestions,
    setNotification,
} = uiSlice.actions;

export default uiSlice.reducer;