import {colorSchema} from "../app_theme/theme_support/colorSchema";
import {
    appSession, appSettings, categoryOptions, DefaultCategories, extractName,
    languageOptions, personaDefinitionList, saveSettings,
    selectedLanguage, selectedPersona,
    setQuitStreaming, setSelectedPersona,
    showAssistantParameter, showLinkedInParameter, updateSelectedLanguage
} from "../managers/generalManager";
import logo from "../Logo_Zaia1.png";
import {Button, Divider, IconButton, keyframes} from "@mui/material";
import logger from "../utils/logging_services";
import DropdownSelector from "../components/chat/dropdownSelector";
import FilterManager, {Filter} from "../components/filter/filter_component";
import {convertPersonaIntoOptionList, PersonaDefinition} from "../managers/personaManager";
import Tooltip from "@mui/material/Tooltip";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import Avatar from "@mui/material/Avatar";
import AppDrawer from "../components/general/app_drawer";
import React, {useEffect, useMemo, useState} from "react";
import {getPrompt, PromptSections} from "../managers/promptManager";
import {DBItem, getItemsByCategory, getObjectStoresInDatabase} from "../utils/IndexedDB.utils";
import {getExtDataRecordList, KnowledgeBaseItem} from "../backend/extdata.services";
import {useDispatch, useSelector} from "react-redux";
// import {AppState} from "../store/old/store";
// import {resetSelectedWorkspaceItems} from "../store/old/temp-slice";
// import {setActiveStore, setSelectedWorkspace, setSelectedWorkspaceCategory} from "../store/old/data-slice";
import AppMenu from "../components/general/application.menu";
import {RootState} from "../store/store";
import {resetSelectedWorkspaceItems} from "../store/workspaceItemSlice";
import {setActiveStore, setSelectedWorkspace, setSelectedWorkspaceCategory} from "../store/dataSlice";

// animation for the stop button
const pulseAnimation = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
`;

export interface MenuFeatures {
    stateChanged: boolean,
    showAssistant: boolean,
    showFiles: boolean,
    showTaskList: boolean,
    showSnippet: boolean,
    showLinkedIn: boolean,
    showWorkspace: boolean,
    showConfig: boolean,
    showTemplate: boolean,
    showAppMenu: boolean,
    showEditor: boolean,
}

export interface ActiveGoal {
    goal: string;
    template: string;
    goalItem: DBItem | undefined;
}

interface AppMainMenuProps {
    isStreaming: boolean;
    isLoaded: boolean;
    menuFeatures: MenuFeatures;
    onMenuFeaturesChanged: (features: MenuFeatures) => void;
    onGoalChanged: (activeGoal: ActiveGoal) => void;
    onChangeDefaultPrompt: (prompt: PromptSections) => void;
    onChangeKbCategory: (category: string) => void;
    onChangeKbFilter: (filter: Filter[]) => void;
    onPersonaChanged: (persona: PersonaDefinition) => void;
}

const no_persona: PersonaDefinition = {
    name: "None",
    extraInstructions: "",
    image: "",
    about: "",
};

export const AppMainMenu = (props: AppMainMenuProps) => {

    const [showAssistentMenu, setShowAssistentMenu] = useState<boolean>(false);
    const [showFilesButton, setShowFilesButton] = useState<boolean>(false);
    const [showLinkedInButton, setShowLinkedInButton] = useState<boolean>(false);
    const [showSnippetButton, setShowSnippetButton] = useState<boolean>(false);
    const [showTasksButton, setShowTasksButton] = useState<boolean>(false);

    const [showStopStreaming, setShowStopStreaming] = useState<boolean>(false);
    const [fileList, setFileList] = useState<{ value: string, label: string }[]>([]);
    const [, setRecordList] = useState<KnowledgeBaseItem[]>([]);

    const [selectedMode] = useState<string>("chat");
    const [selectedCategory, setSelectedCategory] = useState<string>("chat");
    const [anchorRef] = useState(React.createRef<HTMLButtonElement>());

    const [menuFeatures, setMenuFeatures] = useState<MenuFeatures>(props.menuFeatures);

    const [initials, setInitials] = useState<string>("Z");
    const [firstName, setFirstName] = useState<string>("Zaia");
    const [lastName, setLastName] = useState<string>("Assistant");

    const dispatch = useDispatch(); // Use dispatch to send actions to the store
    const workspaces = useSelector((state: RootState) => state.workspaces.workspaces);

    const [persona, setPersona] = useState<PersonaDefinition>(no_persona);

    const [filterList, setFilterList] = useState<Filter[]>([]);
    const [available_goals, setAvailableGoals] = useState<DBItem[]>([]);
    const [available_templates, setAvailableTemplates] = useState<DBItem[]>([]);
    const [available_stores, setAvailableStores] = useState<string[]>([]);

    const [activeGoal, setActiveGoal] = useState<ActiveGoal>({goal: "", template: "", goalItem: undefined});

    const [dbName] = useState<string>(appSettings.database)

    const selectedStore = useSelector((state: RootState) => state.data.activeStore);

    useEffect(() => {

        handlePersonaChange(selectedPersona)

        const userName = appSession.currentUser || "Unknown"
        const {firstName, lastName, initials} = extractName(userName);
        setFirstName(firstName);
        setLastName(lastName);
        setInitials(initials);

        return () => {
            // Cleanup code if necessary
        };

    }, [])

    // propagate changes to the parent component
    useEffect(() => {
        props.onGoalChanged(activeGoal);
    }, [activeGoal])

    useEffect(() => {
        props.onPersonaChanged(persona);
    }, [persona])

    useEffect(() => {
            props.onChangeKbFilter(filterList);
        }
        , [filterList])


    useEffect(() => {
        setShowFilesButton(true)
        setShowTasksButton(true)
        setShowSnippetButton(true)
        setShowLinkedInButton(showLinkedInParameter)
    }, [showAssistantParameter, showLinkedInParameter])

    // Provide a stop button to stop the current streaming service
    useEffect(() => {
        setShowStopStreaming(props.isStreaming);
    }, [props.isStreaming])


    // menu feature determine the state of the menu, what menus and buttons to show. This is the main state of the menu
    useEffect(() => {

        if (!props.menuFeatures.stateChanged) {
            return;
        }

        if (!props.menuFeatures.showAssistant && menuFeatures.showAssistant) {
            resetGoalAndTemplate();
        }

        const newMenuFeatures = {...props.menuFeatures};
        newMenuFeatures.stateChanged = false;
        setMenuFeatures(newMenuFeatures)
    }, [props.menuFeatures])

    useEffect(() => {

        if (!menuFeatures.stateChanged) {
            return;
        }

        props.onMenuFeaturesChanged(menuFeatures);

    }, [menuFeatures])

    const changeAppMenuFeature = (menuFeatures: MenuFeatures) => {
        menuFeatures.stateChanged = true;
        setMenuFeatures(menuFeatures);
    }

    // load goals and templates when the user changes the category (goal) or store (task group)
    useEffect(() => {
        if (props.isLoaded) {
            props.onChangeDefaultPrompt(getPrompt(selectedCategory, persona));
            // setDefaultPrompt(getPrompt(selectedCategory, persona));

            setFilterList([]);
            resetGoalAndTemplate();

            // load goals and templates
            getItemsByCategory(DefaultCategories.GOALS, selectedStore).then(setAvailableGoals);
            getItemsByCategory(DefaultCategories.TEMPLATES, selectedStore).then(setAvailableTemplates);
            getObjectStoresInDatabase(dbName).then(setAvailableStores);

            if (selectedCategory === 'chat') {
                return;
            }

            setFileList([{value: "None", label: "Loading please wait ....."}]);

            getExtDataRecordList(appSession.currentKnowledgeBaseProject, selectedCategory).then(data => {
                setRecordList(data);
                const newFileList = data
                    .filter(record => record?.meta_data && record.meta_data.resource?.endsWith("pdf"))
                    .filter(record => record.chunks_original > 0)
                    .map((record, index) => ({
                        value: record.uuid,
                        label: record.title.substring(0, 50) + (record.meta_data.resource.length > 50 ? "..." : ""),
                    }))

                newFileList.unshift({value: "All", label: "All"});
                setFileList(newFileList);
            })

        }
    }, [props.isLoaded, selectedCategory, selectedStore])

    // available options for dropdown menus used.
    const select_availableStoreOptions = useMemo(() => [
        {value: "None", label: "None"},
        ...available_stores
            .map(req => ({value: req, label: req, tag: ""}))
            .sort((a, b) => a.label.localeCompare(b.label))
    ], [available_stores]);


    const select_goalOptions = useMemo(() => [
        {value: "None", label: "None", tag: '', template: ''},
        ...available_goals
            .map(req => ({
                value: req.value,
                label: req.name,
                data: req,
                tag: req.tag ?? ''  // Use nullish coalescing to provide a default empty string
            }))
            .sort((a, b) => {
                // First, compare by tag, treating undefined as an empty string
                const tagComparison = (a.tag ?? '').localeCompare(b.tag ?? '');

                // If tags are the same, compare by name (label)
                if (tagComparison === 0) {
                    return a.label.localeCompare(b.label);
                }

                return tagComparison;
            })
    ], [available_goals]);

    const select_templateOptions = useMemo(() => [
        {value: "None", label: "None"},
        ...available_templates
            .map(req => ({value: req.value, label: req.name, tag: req.tag}))
            .sort((a, b) => a.label.localeCompare(b.label))
    ], [available_templates]);


    const resetGoalAndTemplate = () => {
        const newActiveGoal = {goal: "", template: "", goalItem: undefined};
        setActiveGoal(newActiveGoal);
        setMenuFeatures({...menuFeatures, showWorkspace: false});
    }

    const handleLanguageChange = (value: string) => {
        updateSelectedLanguage(value);
        saveSettings();
        logger.info(`Selected language: ${value}`)
    }

    const handleKbCategoryChange = (value: string) => {
        setSelectedCategory(value);
        saveSettings();
        props.onChangeKbCategory(value);
    }

    const handlePersonaChange = (name: string) => {
        const persona = personaDefinitionList.find(persona => persona.name === name) || no_persona;
        setPersona(persona);
        setSelectedPersona(persona.name);
        saveSettings();
        props.onChangeDefaultPrompt(getPrompt(selectedMode, persona))
    }

    // Handle the selection of a goal
    const handleSelectGoal = (value: string, data?: DBItem) => {
        logger.info("handleSelectGoal", value, data);
        let template = "";

        if (data) {
            const template_value = available_templates.filter(template => template.name === data.template).map(template => template.value);
            if (template_value.length > 0) {
                template = template_value[0];
            }

            if (data.removePreselectedWorkspaceItems !== undefined && data.removePreselectedWorkspaceItems) {
                dispatch(resetSelectedWorkspaceItems());
            }

            setMenuFeatures({...menuFeatures, showWorkspace: false})
            if (data.workspacePersonal !== undefined && data.workspaceShared !== undefined) {
                const workspaceNameToShow = data.workspacePersonal !== 'None' ? data.workspacePersonal : data.workspaceShared;
                if (workspaceNameToShow !== 'None') {
                    const workspace = workspaces.find(workspace => workspace.name === workspaceNameToShow);
                    if (workspace) {
                        dispatch(setSelectedWorkspace(workspace));
                        if (data.workspaceContentType !== undefined && data.workspaceContentType !== "None") {
                            dispatch(setSelectedWorkspaceCategory(data.workspaceContentType));
                        }
                        setMenuFeatures({...menuFeatures, showWorkspace: true})
                    }
                }
            }
        }

        if (data && data.form !== undefined && data.form !== "") {
            const newActiveGoal = {goal: value, template: template, goalItem: data};
            setActiveGoal(newActiveGoal);
        } else {
            const newActiveGoal = {goal: value, template: template, goalItem: undefined};
            setActiveGoal(newActiveGoal);
        }
        changeAppMenuFeature({...menuFeatures, showAssistant: true});
    }

    // Handle the selection of a store
    const handleSelectStore = (value: string) => {
        dispatch(setActiveStore(value));
    }

    const handleSelectTemplate = (value: string) => {
        setActiveGoal({...activeGoal, template: value});
    }

    const handleTaskListState = () => {
        setShowAssistentMenu(prevState => false);
        changeAppMenuFeature({
            ...menuFeatures,
            showTaskList: !menuFeatures.showTaskList,
            showWorkspace: false,
            showFiles: false
        });
    }

    const handleWorkSpaceViewState = () => {
        changeAppMenuFeature({
            ...menuFeatures,
            showWorkspace: !menuFeatures.showWorkspace,
            showLinkedIn: false,
            showTaskList: false,
            showFiles: false
        });
        // setShowWorkspace(prevState => !prevState);
        // setShowLinkedIn(false);
        // setShowTaskList(false);
        // setShowFiles(false);
    }

    const handleEditorViewState = () => {
        changeAppMenuFeature({...menuFeatures, showEditor: !menuFeatures.showEditor});
    }

    const handleLinkedInViewState = () => {
        changeAppMenuFeature({
            ...menuFeatures,
            showLinkedIn: !menuFeatures.showLinkedIn,
            showWorkspace: false,
            showTaskList: false,
            showFiles: false
        });
    }

    const handleShowFilesState = () => {
        changeAppMenuFeature({
            ...menuFeatures,
            showFiles: !menuFeatures.showFiles,
            showWorkspace: false,
            showLinkedIn: false,
            showTaskList: false
        });
    }

    // User menu procesing = menu when clicking the user name below
    // ------------------------------------------------------------------------------------------------------
    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        changeAppMenuFeature({...menuFeatures, showAppMenu: true});
        // setShowAppMenu(true);
    };

    const handleMenuClose = () => {
        changeAppMenuFeature({...menuFeatures, showAppMenu: false});
        // setShowAppMenu(false);
    };

    const handleOnMenuSelection = (item: string) => {

        if (item === "settings") {
            changeAppMenuFeature({...menuFeatures, showConfig: true});
        } else {
            changeAppMenuFeature({...menuFeatures, showAppMenu: false});
        }

        if (item === "task_group_management") {
            getItemsByCategory(DefaultCategories.GOALS, selectedStore).then(setAvailableGoals);
            getItemsByCategory(DefaultCategories.TEMPLATES, selectedStore).then(setAvailableTemplates);
        }
    }

    return (

        <AppDrawer title={"ZAIA"} initialState={true}>

            {/* LEFT SIDE */}
            {/* ---------------------------------*/}
            <div style={{display: 'flex', flex: 1}}>

                <div style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    borderRadius: "10px",
                    border: `1px solid ${colorSchema.grey[200]}`,
                    minHeight: '100%',
                    marginRight: '10px',
                    maxWidth: appSettings.maxWidth,
                    backgroundColor: colorSchema.background.default,
                }}>

                    {/* Logo */}
                    <div style={{textAlign: 'center'}}>
                        <img src={logo} alt="Logo"
                             style={{
                                 width: '60%',
                                 marginBottom: '10px',
                                 backgroundColor: colorSchema.background.default,
                                 borderRadius: '10px 10px 0 0',
                                 pointerEvents: 'none', // Disable pointer events
                             }}
                             onDragStart={(e) => e.preventDefault()} // Prevent dragging
                        />
                    </div>

                    {/* STOP LLM REQUEST selector */}
                    <div style={{marginBottom: "10px", textAlign: 'center'}}
                         id="stop_button">

                        {showStopStreaming ? (
                            <Button variant={"contained"}
                                    color={"error"}
                                    sx={{
                                        animation: `${pulseAnimation} 2s infinite`, // Apply the animation
                                        '&:hover': {
                                            animation: 'none', // Stop animation on hover (optional)
                                        }
                                    }}
                                    onClick={(event: any) => {
                                        logger.info("Stop streaming");
                                        setQuitStreaming(true);
                                        setShowStopStreaming(false);
                                    }}>STOP</Button>
                        ) : (
                            <>

                            </>
                        )}
                    </div>

                    {/* MIDDLE PART = Application options */}
                    <div id="target-options"
                         style={{
                             flex: 1,
                             display: 'flex',
                             flexDirection: 'column',
                             paddingLeft: '10px',
                             paddingRight: '10px',
                         }}>

                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <div style={{paddingTop: "10px", paddingBottom: "10px"}}>
                                <DropdownSelector
                                    defaultValue={languageOptions.find(option => option.value === selectedLanguage)?.value}
                                    options={languageOptions}
                                    label="Output Language"
                                    onChange={handleLanguageChange}
                                />
                            </div>

                            {/* TO BE FIXED */}
                            {showAssistantParameter && (
                                <>
                                    <div
                                        style={{paddingTop: "10px", paddingBottom: "10px"}}>
                                        {/* MODE */}
                                        <DropdownSelector
                                            variant={"filled"}
                                            defaultValue={categoryOptions.find(option => option.value === selectedCategory)?.value}
                                            options={categoryOptions}
                                            label="Knowledge Domain"
                                            onChange={handleKbCategoryChange}
                                        />
                                    </div>
                                    {selectedCategory !== "chat" && (
                                        <div style={{marginBottom: '5px'}}>
                                            <FilterManager fileList={fileList}
                                                           onFilterUpdate={setFilterList}/>
                                            <Divider
                                                sx={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px'
                                                }}/>
                                        </div>
                                    )}
                                </>
                            )}
                            {!showAssistentMenu && (

                                <>
                                    <div
                                        style={{
                                            paddingTop: "10px",
                                            paddingBottom: "10px"
                                        }}>
                                        {/* Project SELECTOR */}
                                        <DropdownSelector
                                            variant={"filled"}
                                            options={select_availableStoreOptions}
                                            onChange={handleSelectStore}
                                            defaultValue={select_availableStoreOptions.find((option) => option.value === selectedStore)?.value ?? select_availableStoreOptions[0].value}
                                            label="Task Group"
                                        />
                                    </div>


                                    <div
                                        style={{
                                            paddingTop: "10px",
                                            paddingBottom: "10px"
                                        }}>
                                        {/* GOAL SELECTOR */}
                                        <DropdownSelector
                                            options={select_goalOptions}
                                            onChange={handleSelectGoal}
                                            defaultValue={
                                                (select_goalOptions.find((option) => option.value === activeGoal.goal) || select_goalOptions[0]).value
                                            } label="Task"
                                        />
                                    </div>

                                    <div
                                        style={{
                                            paddingTop: "10px",
                                            paddingBottom: "10px"
                                        }}>
                                        {/* TEMPLATE SELECTOR */}
                                        <DropdownSelector
                                            options={select_templateOptions}
                                            onChange={handleSelectTemplate}
                                            defaultValue={
                                                (select_templateOptions.find((option) => option.value === activeGoal.template) || select_templateOptions[0]).value}
                                            label="Template"
                                        />
                                    </div>
                                    <div
                                        style={{paddingTop: "10px", paddingBottom: "10px"}}>
                                        {/* PERSONA SELECTOR */}
                                        <DropdownSelector
                                            defaultValue={convertPersonaIntoOptionList().find(option => option.value === persona.name)?.value || "None"}
                                            options={convertPersonaIntoOptionList()}
                                            label="Persona"
                                            onChange={handlePersonaChange}
                                        />
                                    </div>
                                </>
                            )}

                            {/* MIDDLE PART = MENU --- INJECTION ZONE */}
                            <div id="assistant_menu_selector"
                                 style={{
                                     display: 'flex',
                                     paddingTop: "10px",
                                     marginTop: "10px", paddingBottom: "10px"
                                 }}
                            />
                        </div>
                    </div>


                    {/* OPTION ZONE */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        paddingBottom: '10px'
                    }}>
                        {/* DEPRECATED AND REMOVE FROM CURRENT VERSION */}
                        {/*{showAssistantButton && (*/}
                        {/*    <>*/}
                        {/*        <Tooltip title="Show/Hide assistant templates">*/}
                        {/*            <IconButton onClick={handleAssistantMenuState}*/}
                        {/*                        name="Assistant"*/}
                        {/*                        sx={{}}*/}
                        {/*                        size={"medium"}>*/}
                        {/*                <AssistantIcon*/}
                        {/*                    style={{color: showAssistentMenu ? colorSchema.options_content_selected : colorSchema.options_content_unselected}}/>*/}
                        {/*            </IconButton>*/}
                        {/*        </Tooltip>*/}
                        {/*    </>*/}
                        {/*)}*/}

                        {showTasksButton && (
                            <Tooltip title="Show/Hide requested tasks">
                                <IconButton onClick={handleTaskListState} name="Assistant"
                                            sx={{}}
                                            size={"medium"}>
                                    <AssignmentIcon
                                        style={{color: menuFeatures.showTaskList ? colorSchema.action.selected : colorSchema.primary.main}}/>
                                </IconButton>
                            </Tooltip>
                        )}

                        {showSnippetButton && (
                            <>
                                <Tooltip title="Manage workspace snippets">

                                    <IconButton onClick={handleWorkSpaceViewState}
                                                name="Assistant"
                                                sx={{}}
                                                size={"medium"}>
                                        <DeviceHubIcon
                                            style={{color: menuFeatures.showWorkspace ? colorSchema.action.selected : colorSchema.primary.main}}/>
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}

                        {showLinkedInButton && (
                            <Tooltip title={"LinkedIn"}>
                                <IconButton
                                    onClick={handleLinkedInViewState}
                                    name="LinkedIn"
                                    size={"small"}>
                                    <LinkedInIcon
                                        style={{color: menuFeatures.showLinkedIn ? colorSchema.action.selected : colorSchema.primary.main}}/>
                                </IconButton>
                            </Tooltip>
                        )}


                        {showFilesButton && (
                            <Tooltip title="Manage knowledge base files ">
                                <IconButton onClick={handleShowFilesState}
                                            name="Files" sx={{}}
                                            size={"small"}>
                                    <FolderOpenIcon
                                        style={{color: menuFeatures.showFiles ? colorSchema.action.selected : colorSchema.primary.main}}/>
                                </IconButton>
                            </Tooltip>
                        )}

                        {/*{allowEditor && (*/}

                        {/*    <div style={{*/}
                        {/*        display: 'flex',*/}
                        {/*        flexDirection: 'row',*/}
                        {/*        justifyContent: 'center'*/}
                        {/*    }}>*/}
                        {/*        {selectedMode !== "chat+" ? (*/}
                        {/*            <Tooltip title="Switch to editor mode">*/}
                        {/*                <IconButton*/}
                        {/*                    onClick={() => setSelectedMode(prevState => "chat+")}*/}
                        {/*                    name="Assistant"*/}
                        {/*                    sx={{}}*/}
                        {/*                    size={"medium"}>*/}
                        {/*                    <TextSnippetTwoToneIcon*/}
                        {/*                        style={{color: colorSchema.options_content_unselected}}/>*/}
                        {/*                </IconButton>*/}
                        {/*            </Tooltip>*/}
                        {/*        ) : (*/}
                        {/*            <Tooltip title="Switch to chat mode">*/}
                        {/*                <IconButton*/}
                        {/*                    onClick={() => setShowEditor(true)}*/}
                        {/*                    name="Assistant"*/}
                        {/*                    sx={{}}*/}
                        {/*                    size={"medium"}>*/}
                        {/*                    <ChatBubbleTwoToneIcon*/}
                        {/*                        style={{color: colorSchema.options_content_unselected}}/>*/}
                        {/*                </IconButton>*/}
                        {/*            </Tooltip>*/}
                        {/*        )}*/}
                        {/*    </div>*/}
                        {/*)}*/}
                    </div>

                    <Divider sx={{marginTop: '10px', marginBottom: '10px'}}/>
                    <div style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        alignItems: "center",
                        alignSelf: "center",
                        display: 'flex',
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}>
                        {/* User + settings */}
                        <Avatar>{initials}</Avatar>
                        <Tooltip title="Settings">
                            <Button variant="text" ref={anchorRef} onClick={handleMenuOpen}>
                                {firstName.toLowerCase() + ' ' + lastName.toLowerCase()}
                            </Button>
                        </Tooltip>
                        {menuFeatures.showAppMenu && anchorRef && (
                            <AppMenu
                                anchorEl={anchorRef.current}
                                user={firstName.toLowerCase() + ' ' + lastName.toLowerCase()}
                                initials={initials}
                                open={menuFeatures.showAppMenu}
                                onClose={handleMenuClose}
                                onSelection={handleOnMenuSelection}
                            />
                        )}
                    </div>
                </div>
            </div>
        </AppDrawer>
    )
}