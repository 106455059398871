import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Workspace } from './types';
import logger from "../utils/logging_services";
import {SnippetData} from "../backend/snippet.services";
import {fetchWorkspaces, FetchWorkspacesResult} from "./thunks";
// import {FetchWorkspacesResult} from "./old/data-thunk";

interface WorkspacesState {
    workspaces: Workspace[];
    userWorkspaceDefinition: SnippetData | undefined;
    publicWorkspaceDefinition: SnippetData | undefined;
    error: string | null;
}

const initialState: WorkspacesState = {
    workspaces: [],
    userWorkspaceDefinition: undefined,
    publicWorkspaceDefinition: undefined,
    error: null
};

const workspacesSlice = createSlice({
    name: 'workspaces',
    initialState,
    reducers: {
        setWorkspaces: (state, action: PayloadAction<Workspace[]>) => {
            logger.info('setWorkspaces', action.payload);
            state.workspaces = action.payload;
        },
        updateWorkspace: (state, action: PayloadAction<Workspace>) => {
            const index = state.workspaces.findIndex(ws => ws.uuid === action.payload.uuid);
            if (index !== -1) {
                state.workspaces[index] = action.payload;
            }
        },
        addWorkspace: (state, action: PayloadAction<Workspace>) => {
            state.workspaces.push(action.payload);
        },
        removeWorkspace: (state, action: PayloadAction<string>) => {
            state.workspaces = state.workspaces.filter(ws => ws.uuid !== action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWorkspaces.fulfilled, (state, action: PayloadAction<FetchWorkspacesResult>) => {
                state.userWorkspaceDefinition = action.payload.user_workspace;
                state.publicWorkspaceDefinition = action.payload.project_workspace;

                const workspaceList: Workspace[] = [];

                function addToWorkspaceList(workspace: Workspace[]) {
                    const newWorkSpace = workspace.map(item => ({
                        ...item,
                        user_scope: item.user_scope,
                    }));
                    workspaceList.push(...newWorkSpace);
                }

                if (action.payload.user_workspace && action.payload.user_workspace.content.length > 0) {
                    addToWorkspaceList(action.payload.user_workspace.content as unknown as Workspace[]);
                }
                if (action.payload.project_workspace && action.payload.project_workspace.content.length > 0) {
                    addToWorkspaceList(action.payload.project_workspace.content as unknown as Workspace[]);
                }

                state.workspaces = workspaceList;
            })
            .addCase(fetchWorkspaces.rejected, (state, action) => {
                state.userWorkspaceDefinition = undefined;
                state.publicWorkspaceDefinition = undefined;
                state.workspaces = [];
                state.error = action.payload as string;
            })
            .addCase(fetchWorkspaces.pending, (state) => {
                state.error = null;
            });
    }
});

export const {
    setWorkspaces,
    updateWorkspace,
    addWorkspace,
    removeWorkspace,
} = workspacesSlice.actions;

export default workspacesSlice.reducer;