
export interface BackgroundColors {
    default: string;
    gradient: string;
    hover: string;
    selected: string;
    header: string;
    paper: string;
    [key: `selected${number}`]: string;
}

function generateMatchingColors(baseColor: string, count: number): { [key: string]: string } {
    // Convert hex to RGB
    const hexToRgb = (hex: string): [number, number, number] => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        return [r, g, b];
    };

    // Convert RGB to hex
    const rgbToHex = (r: number, g: number, b: number): string =>
        '#' + [r, g, b].map(x => {
            const hex = x.toString(16);
            return hex.length === 1 ? '0' + hex : hex;
        }).join('');

    // Generate a slightly different color
    const adjustColor = (rgb: [number, number, number], amount: number): [number, number, number] => {
        return rgb.map(channel => {
            const adjustment = Math.floor(Math.random() * amount * 2) - amount;
            return Math.max(0, Math.min(255, channel + adjustment));
        }) as [number, number, number];
    };

    const baseRgb = hexToRgb(baseColor);
    const colors: { [key: string]: string } = { selected: baseColor };

    for (let i = 1; i <= count; i++) {
        const adjustedRgb = adjustColor(baseRgb, 20); // Adjust by up to 20 in either direction
        colors[`selected${i}`] = rgbToHex(...adjustedRgb);
    }

    return colors;
}


// colorSchema.ts
export const colorSchema = {
  primary: {
    main: "#3E4D5C",
    light: "#506070",
    dark: "#203040",
    contrastText: "#fafafa"
  },
  secondary: {
    main: "#E7E8D1",
    light: "#F2F3E6",
    dark: "#C5C6B2",
    contrastText: "#3E4D5C"
  },
  info: {
    main: "#0055FE",
    light: "#3377FE",
    dark: "#0044CB",
    contrastText: "#fafafa"
  },
  success: {
    main: "#32E0A1",
    light: "#5BE6B5",
    dark: "#28B381",
    contrastText: "#3E4D5C"
  },
  warning: {
    main: "#FF5722",
    light: "#FF7A50",
    dark: "#CC461B",
    contrastText: "#fafafa"
  },
  error: {
    main: "#E0324A",
    light: "#E65A6E",
    dark: "#B3283B",
    contrastText: "#fafafa"
  },
  grey: {
    50: "#fafafa",
    100: "#E0E0E0",
    200: "#C0D0E0",
    300: "#A0B0C0",
    400: "#8090A0",
    500: "#607080",
    600: "#405060",
    700: "#304050",
    800: "#203040",
    900: "#102030",
  },
  text: {
    primary: "rgba(0, 0, 0, 0.87)",
    secondary: "rgba(0, 0, 0, 0.6)",
    disabled: "rgba(0, 0, 0, 0.38)",
  },
  background : {
    default: "#fafafa",
    gradient : "linear-gradient(to right,#fafafa , #9abadd)",
    hover: "#d6e7f8",
    selected: "#9abadd",
    header: "#3E4D5C",
    paper: "#ffffff",
    ...generateMatchingColors("#9abadd", 10)
  } as BackgroundColors,
  action: {
    active: "rgba(0, 0, 0, 0.54)",
    hover: "rgba(0, 0, 0, 0.04)",
    selected: "rgba(0, 0, 0, 0.08)",
    disabled: "rgba(0, 0, 0, 0.26)",
    disabledBackground: "rgba(0, 0, 0, 0.12)",
  },
};

export default colorSchema;