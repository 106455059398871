import axios from "axios";
import {appSession, selectedLanguage} from "../managers/generalManager";
import config from "../config.json";
import logger from "../utils/logging_services";

const service_url = config.mode === 'dev' ? config.server_url_dev : config.server_url_prod;

export interface CbItem {
    uuid: string;
    type: string;
    title_uuid: string;
    title_text: string;
    description_uuid: string;
    description_text: string;
    action_start_uuid: string;
    action_start_type: string;
    action_start: string;
    action_end_uuid: string;
    action_end_type: string;
    action_end: string;
    default_language: string;
    sequence: number;
    child_list: CbItem[];
}

export interface CbProject {
    uuid: string;
    project_name: string;
    description: string;
    props: string;
}

export interface ExecTaskInput {
    service_request: string;
    service_data: string;
    language: string
}

export interface RegisterUser {
    first_name: string;
    last_name: string;
    email: string;
    country: string;
}

export interface ResetPassword {
    link: string;
    password: string;
}

export function getMenus(projectId: string): Promise<{ data: CbItem[] | null; error: any | null }> {
    const headers = {Authorization: `Bearer ${appSession.accessToken}`, Accept: 'application/json'};

    let url = service_url + `/modules/chatboostr/${projectId}/menu`
    if (projectId === 'free') {
        url = service_url + `/modules/chatboostr/menu`
    }

    return axios.get(url, {headers})
        .then(response => {
            return {data: response.data as CbItem[], error: null};
        })
        .catch(error => {
            if ( error instanceof Error )
                logger.error("GetMenus Request Failed", error);
            let errorMessage = "Unknown error occurred.";
            if (error.response) {
                const {data, status, statusText} = error.response;
                errorMessage = `Request failed with status ${status} (${statusText}): ${data.detail}`;
            } else if (error.message) {
                errorMessage = error.message;
            }
            return {data: null, error: errorMessage};
        });
}


export function getMenuWithTopicsAndServices(projectId: string, menuId: string): Promise<{
    data: CbItem | null;
    error: any | null
}> {
    const headers = {Authorization: `Bearer ${appSession.accessToken}`, Accept: 'application/json'};

    // Retrieve selected language
    const language = selectedLanguage;

    let url = service_url + `/modules/chatboostr/${projectId}/menu/${menuId}?language=${language}`
    if (projectId === 'free') {
        url = service_url + `/modules/chatboostr/menu/${menuId}?language=${language}`
    }

    return axios.get(url, {headers})
        .then(response => {
            return {data: response.data as CbItem, error: null};
        })
        .catch(error => {
            if ( error instanceof Error )
                logger.error("GetMenuWithTopicsAndServices Request Failed", error);
            let errorMessage = "Unknown error occurred.";
            if (error.response) {
                const {data, status, statusText} = error.response;
                errorMessage = `Request failed with status ${status} (${statusText}): ${data.detail}`;
            } else if (error.message) {
                errorMessage = error.message;
            }
            return {data: null, error: errorMessage};
        });
}

export function getProjects(): Promise<{ data: CbProject[] | null; error: any | null }> {

    const headers = {Authorization: `Bearer ${appSession.accessToken}`, Accept: 'application/json'};
    const url = service_url + `/main/application/ChatBoostr/projects`

    return axios.get(url, {headers})
        .then(response => {
            return {data: response.data as CbProject[], error: null};
        })
        .catch(error => {
            if ( error instanceof Error )
                logger.error("GetProjects Request Failed", error);
            let errorMessage = "Unknown error occurred.";
            if (error.response) {
                const {data, status, statusText} = error.response;
                errorMessage = `Request failed with status ${status} (${statusText}): ${data.detail}`;
            } else if (error.message) {
                errorMessage = error.message;
            }
            return {data: null, error: errorMessage};
        });
}


export function execute_task(projectId: string, task: string, task_data: string): Promise<{
    data: string | null;
    error: any | null
}> {
    const headers = {Authorization: `Bearer ${appSession.accessToken}`, Accept: 'application/json'};

    let url = service_url + `/modules/chatboostr/${projectId}/task/execute`

    const execTaskInput: ExecTaskInput = {
        service_request: task,
        service_data: task_data,
        language: "en",
    };

    return axios.post(url, execTaskInput, {headers})
        .then(response => {
            return {data: response.data as string, error: null};
        })
        .catch(error => {
            if ( error instanceof Error )
                logger.error("ExecuteTask Request Failed", error);
            let errorMessage = "Unknown error occurred.";
            if (error.response) {
                const {data, status, statusText} = error.response;
                errorMessage = `Request failed with status ${status} (${statusText}): ${data.detail}`;
            } else if (error.message) {
                errorMessage = error.message;
            }
            return {data: null, error: errorMessage};
        });
}

export function retrieve_chunks(projectId: string, record_uuid: string, from_chunk: number, to_chunk: number): Promise<{
    data: string | null;
    error: any | null
}> {
    const headers = {Authorization: `Bearer ${appSession.accessToken}`, Accept: 'application/json'};

    let url = service_url + `/modules/chatboostr/${projectId}/query_data/${record_uuid}?from_chunk=${from_chunk}&to_chunk=${to_chunk}`

    return axios.get(url, {headers})
        .then(response => {
            return {data: response.data as string, error: null};
        })
        .catch(error => {
            if ( error instanceof Error )
                logger.error("RetrieveChunks Request Failed", error);

            let errorMessage = "Unknown error occurred.";
            if (error.response) {
                const {data, status, statusText} = error.response;
                errorMessage = `Request failed with status ${status} (${statusText}): ${data.detail}`;
            } else if (error.message) {
                errorMessage = error.message;
            }
            return {data: null, error: errorMessage};
        });
}

export function tts(projectId: string, text: string): Promise<{
    data: string | null;
    error: any | null
}> {

    let url = service_url + `/modules/chatboostr/${projectId}/text_to_speech/`
    const headers = {Authorization: `Bearer ${appSession.accessToken}`, Accept: 'application/json'};

    return axios({
        method: 'post',
        url: url,
        data: {
            text: text,
            quality: "tts-1-hd",
            voice: "shimmer"
        },
        responseType: 'blob', // Expect a blob (binary app_theme) as response
        headers: headers
    })
        .then(response => {
            const contentType = response.headers['content-type'];
            if (contentType && contentType.includes("audio/mpeg")) {
                const audioElement = new Audio();
                audioElement.src = URL.createObjectURL(response.data);
                audioElement.play();
                return {data: null, error: null};
            } else {
                logger.error("TTS service request : Unexpected content type:", contentType);
                return {data: null, error: 'Unexpected workspace folder'};
            }
        })
        .catch(error => {
            if ( error instanceof Error )
                logger.error("TTS Request Failed", error);
            return {data: null, error: error};
        });
}