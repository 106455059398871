import {debugMode, Option, personaDefinitionList, setPersonaDefinitionList} from "./generalManager";
import {default_promptDefinitionList} from "./promptManager";

export const getPersona = (name: string) => {

    debugMode && console.log(`Looking for persona ${name}`);

    const personaDefinition = personaDefinitionList.find(p => p.name === name);
    if (personaDefinition) {
        return personaDefinition;
    } else {
        console.log(`Persona with name ${name}, not found`);
        return default_promptDefinitionList[0];
    }
}

export type PersonaDefinition = {
    name: string;
    image: string;
    about: string;
    extraInstructions: string;
};

export const convertPersonaIntoOptionList = (): Option[] => {
    const personaOptionList: Option[] = [];
    const personaOption: Option = {
        label: "None",
        value: "None",
        image: "",
    };
    personaOptionList.push(personaOption);

    // Iterate through personaDefinitionList to create new options
    for (let persona of personaDefinitionList) {
        const personaOption: Option = {
            label: persona.name,
            value: persona.name,
            image: persona.image,
        };
        personaOptionList.push(personaOption);
    }
    return personaOptionList;
};


export const savePersonaSettings = (personaList: PersonaDefinition[]) => {
    // store the promptList in local storage
    localStorage.setItem('BoostrFoundation_PersonaDefinitionList', JSON.stringify(personaList));
}

export const loadPersonaSettings = () => {
    // load personaDefinitionList
    const local_personaDefinitionList = localStorage.getItem('BoostrFoundation_PersonaDefinitionList');
    if (local_personaDefinitionList) {
        try {
            setPersonaDefinitionList(JSON.parse(local_personaDefinitionList));
        } catch (e) {
            console.log("Error parsing personaDefinitionList", e);
            setPersonaDefinitionList(default_personaDefinitionList);
        }
    }
}


export const default_personaDefinitionList: PersonaDefinition[] = [
    {name: "Persona 1", image: "p1", about: "Default persona 1", extraInstructions: ""},
];

