import { createTheme, ThemeOptions } from '@mui/material/styles';
import colorSchema from './theme_support/colorSchema';
import typography from './theme_support/typography';
import components from './theme_support/components';
import globalStyles from './theme_support/globalStyles';

const zaiaThemeOptions: ThemeOptions = {
  palette: colorSchema,
  typography: typography,
  components: {
    ...components,
    MuiCssBaseline: {
      styleOverrides: globalStyles,
    },
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
};

export const ZAIATheme = createTheme(zaiaThemeOptions);

export default ZAIATheme;

// // zaiaTheme.ts
// import { createTheme, ThemeOptions } from '@mui/material/styles';
// import colorSchema from './theme_support/colorSchema';
// import typography from './theme_support/typography';
// import components from './theme_support/components';
//
// const zaiaThemeOptions: ThemeOptions = {
//   palette: colorSchema,
//   typography: typography,
//   components: components,
//   shape: {
//     borderRadius: 4,
//   },
//   spacing: 8,
// };
//
// export const ZAIATheme = createTheme(zaiaThemeOptions);
//
// export default ZAIATheme;


// import { createTheme } from "@mui/material";
//
// // Color schema definition
// const colorSchema = {
//     color1: "#003c54",
//     color2: "#0055FE",
//     color3: '#32E0A1',
//     color4: '#1D82A1',
//     color5: "#49B1D1",
//     default_light: "#fafafa",
//
//     bg_titles: "#fafafa",
//     tekst: "rgb(0 0 0 / 60%)",
//     info: "#0055FE",
//     warning: "#FF5722",
//     success: "#32E0A1",
//     error: "#E0324A",
//
//     primary: "#3E4D5C",
//     secondary: "#E7E8D1",
//     primary_support: "#A7BEAE",
//
//     primary1: "#C06010",
//     primary2: "#D5781E",
//     primary3: "#D5781E",
//
//     gray1: "#203040",
//     gray2: "#304050",
//     gray3: "#405060",
//     gray4: "#506070",
//     gray5: "#607080",
//     gray6: "#708090",
//     gray7: "#8090A0",
//     gray8: "#90A0B0",
//     gray9: "#A0B0C0",
//     gray10: "#B0C0D0",
//     gray11: "#C0D0E0",
//     gray12: "#D0E0F0",
//     gray: "#E0E0E0",
//
//     // ChatViewer
//     outgoing: "#fafafa",
//     outgoing_text: '#3E4D5C',
//     incoming: '#3E4D5C',
//     incoming_text: '#fafafa',
//     inputbox: "#fafafa",
//
//     app_background_start: "#90A0B0",
//     app_background: "#fafafa",
//
//     dialog_header_background: "#0055FE",
//     dialog_header_text: "#fafafa",
//     dialog_content_background: "#fafafa",
//     dialog_content_text: "#003c54",
//
//     options_content_background: "#fafafa",
//     options_content_text: "#003c54",
//     options_content_unselected: "#003c54",
//     options_content_selected: "#b6d0ee",
//     options_content_hover: '#32E0A1',
//
//     select_background: '#32E0A1',
//     select_text: "#003c54",
//     light: "#fafafa",
//
//     // Left Menu
//     menu_background: "#fafafa",
//     viewer_action_background: '#ffffff',
//     assistant_background: "#fafafa",
//     assistant_topics_background: '#708090',
//     assistant_topics_background_hover: '#8090A0',
//     assistant_topics_border: '#8090A0',
//     assistant_topics_text: '#ffffff',
//     assistant_text: 'rgb(0, 60, 84)',
//     assistant_hover: '#32E0A1',
//
//     // Icons
//     iconColor: '#3E4D5C'
// };
//
// const fontSizeBase = 16; // Base font size in pixels
// const scaleRatio = 1.125; // Major second scale
//
// const calcFontSize = (step: number) => `${(fontSizeBase * Math.pow(scaleRatio, step)).toFixed(2)}px`;
//
// export const ZAIATheme = createTheme({
//     typography: {
//         fontFamily: ['Inter', 'sans-serif'].join(','),
//         fontWeightLight: 300,
//         fontWeightRegular: 400,
//         fontWeightMedium: 500,
//         fontWeightBold: 700,
//         h1: {
//             fontSize: calcFontSize(3), // 22.78px
//             fontWeight: 500,
//             lineHeight: 1.2,
//         },
//         h2: {
//             fontSize: calcFontSize(2), // 20.25px
//             fontWeight: 500,
//             lineHeight: 1.3,
//         },
//         h3: {
//             fontSize: calcFontSize(1), // 18.00px
//             fontWeight: 400,
//             lineHeight: 1.3,
//         },
//         h4: {
//             fontSize: calcFontSize(0), // 16.00px
//             fontWeight: 400,
//             lineHeight: 1.4,
//         },
//         h5: {
//             fontSize: calcFontSize(-1), // 14.22px
//             fontWeight: 400,
//             lineHeight: 1.4,
//         },
//         h6: {
//             fontSize: calcFontSize(-2), // 12.64px
//             fontWeight: 400,
//             lineHeight: 1.5,
//         },
//         body1: {
//             fontSize: calcFontSize(-2), // 12.64px
//             fontWeight: 400,
//             lineHeight: 1.5,
//         },
//         body2: {
//             fontSize: calcFontSize(-3), // 11.24px
//             fontWeight: 400,
//             lineHeight: 1.5,
//         },
//         subtitle1: {
//             fontSize: calcFontSize(-2), // 12.64px
//             fontWeight: 400,
//             lineHeight: 1.75,
//         },
//         subtitle2: {
//             fontSize: calcFontSize(-3), // 11.24px
//             fontWeight: 500,
//             lineHeight: 1.57,
//         },
//         button: {
//             fontSize: calcFontSize(-3), // 11.24px
//             fontWeight: 500,
//             textTransform: 'uppercase',
//             lineHeight: 1.75,
//         },
//         caption: {
//             fontSize: calcFontSize(-4), // 9.99px
//             fontWeight: 400,
//             lineHeight: 1.66,
//         },
//         overline: {
//             fontSize: calcFontSize(-4), // 9.99px
//             fontWeight: 400,
//             textTransform: 'uppercase',
//             lineHeight: 2.66,
//             letterSpacing: '1px',
//         },
//     },
//     palette: {
//         primary: {
//             main: colorSchema.primary,
//             contrastText: colorSchema.default_light,
//         },
//         secondary: {
//             main: colorSchema.secondary,
//             contrastText: colorSchema.primary,
//         },
//         info: {
//             main: colorSchema.info,
//             contrastText: colorSchema.default_light,
//         },
//         warning: {
//             main: colorSchema.warning,
//             contrastText: colorSchema.default_light,
//         },
//         success: {
//             main: colorSchema.success,
//             contrastText: colorSchema.primary,
//         },
//         error: {
//             main: colorSchema.error,
//             contrastText: colorSchema.default_light,
//         },
//         text: {
//             primary: colorSchema.tekst,
//             secondary: colorSchema.assistant_text,
//         },
//         background: {
//             default: colorSchema.app_background,
//             paper: colorSchema.default_light,
//         },
//     },
//     spacing: 8, // Base spacing unit in pixels
//     shape: {
//         borderRadius: 4,
//     },
//     components: {
//         MuiMenuItem: {
//             styleOverrides: {
//                 root: {
//                     whiteSpace: 'normal',
//                     lineHeight: 1.5,
//                     padding: '8px 16px',
//                 },
//             },
//         },
//         MuiButton: {
//             styleOverrides: {
//                 root: {
//                     textTransform: 'none',
//                     fontWeight: 500,
//                     padding: '8px 16px',
//                 },
//                 contained: {
//                     boxShadow: 'none',
//                     '&:hover': {
//                         boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
//                     },
//                 },
//             },
//         },
//         MuiTextField: {
//             styleOverrides: {
//                 root: {
//                     '& .MuiInputBase-root': {
//                         borderRadius: 4,
//                     },
//                 },
//             },
//         },
//         MuiCard: {
//             styleOverrides: {
//                 root: {
//                     borderRadius: 8,
//                     // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
//                 },
//             },
//         },
//         MuiAppBar: {
//             styleOverrides: {
//                 root: {
//                     boxShadow: 'none',
//                     borderBottom: `1px solid ${colorSchema.gray6}`,
//                 },
//             },
//         },
//         MuiIcon: {
//             styleOverrides: {
//                 root: {
//                     color: colorSchema.iconColor,
//                 },
//             },
//         },
//     },
// });
//
// export { colorSchema };
//
// // import {createTheme, rgbToHex} from "@mui/material";
// // import {light} from "@mui/material/styles/createPalette";
// //
// //
// // // color1: #FF6B35 - Vibrant Orange
// // // color2: #0055FE - Bright Blue (kept from original)
// // // color3: #32E0A1 - Vibrant Teal (kept from original)
// // // color4: #9B5DE5 - Lavender Purple
// // // color5: #A8A8A8 - Warm Grey
// //
// // const color1 = "#003c54";
// // const color2 = "#0055FE";
// // const color3 = '#32E0A1';
// // const color4 = '#1D82A1';
// // const color5 = "#49B1D1";
// // const default_light = "#fafafa";
// //
// // // const color1 = "#FF6B35";
// // // const color2 = "#0055FE";
// // // const color3 = '#32E0A1';
// // // const color4 = '#9B5DE5';
// // // const color5 = "#A8A8A8";
// //
// //
// // export const colorSchema = {
// //     color1: color1,
// //     color2: color2,
// //     color3: color3,
// //     color4: color4,
// //     color5: color5,
// //
// //     bg_titles: "#fafafa",
// //     tekst: "rgb(0 0 0 / 60%)",
// //     info: "#0055FE",
// //     warning: "#FF5722",
// //     success: "#32E0A1",
// //     error: "#E0324A",
// //
// //     primary: "#3E4D5C",
// //     secondary: "#E7E8D1",
// //     primary_support: "#A7BEAE",
// //
// //     primary1: "#C06010",
// //     primary2: "#D5781E",
// //     primary3: "#D5781E",
// //
// //     gray1: "#203040",
// //     gray2: "#304050",
// //     gray3: "#405060",
// //     gray4: "#506070",
// //     gray5: "#607080",
// //     gray6: "#708090",
// //     gray7: "#8090A0",
// //     gray8: "#90A0B0",
// //     gray9: "#A0B0C0",
// //     gray10: "#B0C0D0",
// //     gray11: "#C0D0E0",
// //     gray12: "#D0E0F0",
// //     gray: "#E0E0E0",
// //
// //     // ChatViewer
// //     outgoing: default_light,
// //     outgoing_text: '#3E4D5C',
// //     incoming: '#3E4D5C',
// //     incoming_text: '#fafafa',
// //     inputbox: default_light,
// //
// //     app_background_start: "#90A0B0",
// //     app_background: default_light,
// //
// //     dialog_header_background: color2,
// //     dialog_header_text: default_light,
// //     dialog_content_background: default_light,
// //     dialog_content_text: color1,
// //
// //     options_content_background: default_light,
// //     options_content_text: color1,
// //     options_content_unselected: color1,
// //     options_content_selected: "#b6d0ee",
// //     options_content_hover: color3,
// //
// //     select_background: color3,
// //     select_text: color1,
// //     light: default_light,
// //
// //     // Left Menu
// //     menu_background: default_light,
// //     viewer_action_background: '#ffffff',
// //     assistant_background: default_light,
// //     assistant_topics_background: '#708090',
// //     assistant_topics_background_hover: '#8090A0',
// //     assistant_topics_border: '#8090A0',
// //     assistant_topics_text: '#ffffff',
// //     assistant_text: 'rgb(0, 60, 84)',
// //     assistant_hover: '#32E0A1',
// //
// //     // Icons
// //     iconColor: '#3E4D5C'
// //
// // };
// //
// // // colorSchema.warning = colorSchema.color2;
// // export const ZAIATheme = createTheme({
// //    typography: {
// //     fontWeightRegular: 400,
// //     fontFamily: [
// //         'Inter',
// //         'sans-serif',
// //     ].join(','),
// //
// //     h1: {
// //         fontSize: '20px', // Larger for main titles
// //         fontWeight: 500, // Slightly bolder for prominence
// //     },
// //     h2: {
// //         fontSize: '19px', // Distinctly smaller than h1
// //         fontWeight: 500,
// //     },
// //     h3: {
// //         fontSize: '18px', // Gradual decrease for subheadings
// //         fontWeight: 400,
// //     },
// //     h4: {
// //         fontSize: '17px', // Ideal for section titles
// //         fontWeight: 400,
// //     },
// //     h5: {
// //         fontSize: '16px', // Smaller for minor headings
// //         fontWeight: 400,
// //     },
// //     h6: {
// //         fontSize: '15px', // Smallest heading size
// //         fontWeight: 400,
// //     },
// //     body1: {
// //         fontSize: '13px', // Standard size for main body text
// //         fontWeight: 400,
// //         lineHeight: 1.5, // Good for readability
// //     },
// //     body2: {
// //         fontSize: '12px', // Slightly smaller for less prominent text
// //         fontWeight: 400,
// //         lineHeight: 1.43,
// //     },
// //     subtitle1: {
// //         fontSize: '13px', // Can be used for secondary text
// //         fontWeight: 400,
// //         lineHeight: 1.75,
// //     },
// //     subtitle2: {
// //         fontSize: '11px',
// //         fontWeight: 500, // Bolder for emphasis
// //         lineHeight: 1.57,
// //     },
// //     button: {
// //         fontSize: '0.875rem',
// //         fontWeight: 500, // Bold to stand out
// //         textTransform: 'uppercase', // Stylistic choice for buttons
// //     },
// //     // Add other styles like caption, overline as per your need
// // },
// //
// //     palette: {
// //         primary: {
// //             main: colorSchema.primary,
// //             contrastText: '#fff',
// //         },
// //         secondary: {
// //             main: colorSchema.secondary,
// //             contrastText: '#fff',
// //         },
// //         info: {
// //             main: colorSchema.info,
// //             contrastText: '#fff',
// //         },
// //         warning: {
// //             main: colorSchema.warning,
// //             contrastText: '#fff',
// //         },
// //         success: {
// //             main: colorSchema.success,
// //             contrastText: '#fff',
// //         },
// //         error: {
// //             main: colorSchema.error,
// //             contrastText: '#fff',
// //         },
// //         // You can add other color definitions as needed
// //     },
// //     components: {
// //         MuiMenuItem: {
// //             styleOverrides: {
// //                 root: {
// //                     whiteSpace: 'normal',
// //                 },
// //             },
// //         },
// //     },
// //     // Add other theme customizations as needed
// // });
//
// export const ChatBoostrTheme = createTheme({
//     palette: {
//         primary: {
//             main: colorSchema.primary,
//             light: colorSchema.primary,
//             dark: colorSchema.primary,
//             contrastText: '#fff',
//         },
//         secondary: {
//             main: colorSchema.secondary,
//             light: colorSchema.secondary,
//             dark: colorSchema.secondary,
//             contrastText: '#fff',
//         },
//         warning: {
//             main: colorSchema.warning,
//             light: colorSchema.warning,
//             dark: colorSchema.warning,
//             contrastText: '#fff',
//         },
//         success: {
//             main: colorSchema.success,
//             light: colorSchema.success,
//             dark: colorSchema.success,
//             contrastText: '#fff',
//         },
//     },
//     direction: 'rtl',
//     typography: {
//         fontWeightRegular: 400,
//         fontFamily: [
//             'sans-serif',
//         ].join(','),
//         h6: {
//             fontSize: '1.2rem',
//             fontWeight: 300,
//         },
//     },
//     components: {
//         MuiMenuItem: {
//             styleOverrides: {
//                 root: {
//                     whiteSpace: 'normal',
//                 },
//             },
//         },
//     },
//
// });
//
//
//
//
