import React, { useEffect, useState } from "react";
import {getLanguageCode} from "../../managers/generalManager";
import logger from "../../utils/logging_services";

interface SpeechToTextProps {
  language: string;
  isActive: boolean;
  onTranscriptChange: (transcript: string) => void;
}

const SpeechToTextService: React.FC<SpeechToTextProps> = ({ language, isActive, onTranscriptChange }) => {
  const [, setInterimTranscript] = useState<string>("");
  const [finalTranscript, setFinalTranscript] = useState<string>("");

  useEffect(() => {
    if (!("webkitSpeechRecognition" in window)) {
      alert("Web Speech API is not supported by this browser. Please use Chrome browser.");
      return;
    }

    const recognition = new (window as any).webkitSpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    logger.info("Language: ", language);
    recognition.lang = getLanguageCode(language);

    recognition.onresult = (event: any) => {
      let interim = "";
      let final = finalTranscript;

      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          final += (final ? " " : "") + event.results[i][0].transcript;
        } else {
          interim += event.results[i][0].transcript;
        }
      }

      setInterimTranscript(interim);
      setFinalTranscript(final);
      onTranscriptChange(final + " " + interim);
    };

    recognition.onerror = (event: any) => {
      console.error("Error occurred in recognition:", event.error);
    };

    if (isActive) {
      recognition.start();
    } else {
      recognition.stop();
    }

    return () => {
      recognition.stop();
    };
  }, [isActive, finalTranscript]);

  return null; // Rendering nothing as this component's main job is to handle side-effects.
};

export default SpeechToTextService;
