import {multiLangContent, t} from "./generalManager";


// FORM LIST
// ---------------------------------------------------------------------------------------------

export class FormList {

    form_definition_list: FormDefinition[];
    selected_language: string;

    constructor() {
        this.form_definition_list = [];
        this.selected_language = 'nl'
    }

    add_form_definition(data: FormDefinitionInterface): FormDefinition | null {
        const existingForm = this.findFormDefintion(data.id)
        if (!existingForm) {
            const form = new FormDefinition(data)
            this.form_definition_list.push(form);
            return form;
        } else {
            return null;
        }
    }

    findFormDefintion(form_id: string): FormDefinition | undefined {
        return this.form_definition_list.find((p) => p.id === form_id);
    }

    findFormNameAndDescription(form_id: string): { title: string, description: string } {
        let response = {'title': '', 'description': ''};

        const form_definition = this.findFormDefintion(form_id);
        if (form_definition) {
            response =
                {
                    'title': t(form_definition.template.title, this.selected_language),
                    'description': t(form_definition.template.description, this.selected_language)
                }
        }

        return response;
    }

    findForm(form_id: string): string {
        let response = "";

        const form_definition = this.findFormDefintion(form_id);
        if (form_definition) {
            response = t(form_definition.template.form, this.selected_language);
        }
        return response
    }

}


// FORM DEFINITION
// ---------------------------------------------------------------------------------------------

class FormDefinition {

    id: string
    template: TemplateInterface

    constructor(data: FormDefinitionInterface) {
        this.id = data.id;
        this.template = data.template;
    }
}


// -----------------------------------------------------------------------------------------------------------------
// INTERFACES
// -----------------------------------------------------------------------------------------------------------------
export interface FormDefinitionInterface {
    id: string
    template: TemplateInterface
}

export interface TemplateInterface {
    title: multiLangContent;
    description: multiLangContent;
    form: multiLangContent;
    prompt_id: string;
}


// ---------------------------------------------------------------------------------------------
// RUNTIME DATA
// ---------------------------------------------------------------------------------------------
export let appFormList: FormList = new FormList();

