import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";
import React, { useRef } from "react";

const draggablePaperStyle = {
  minHeight: '60vh',
  bgcolor: 'background.paper',
  minWidth: '60vw',
  border: '2px solid #000',
  boxShadow: 24,
  resize: "both",
  p: 2,
};

export const DraggablePaper = (props: PaperProps): JSX.Element => {
  const paperRef = useRef<HTMLDivElement|null>(null);

  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={paperRef}
    >
      <Paper {...props} sx={draggablePaperStyle} ref={paperRef} />
    </Draggable>
  );
};
