import React, {useEffect, useState} from 'react';
import {
    Divider,
    IconButton,
    Typography,
    Tooltip, Box, Grid, TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useDispatch, useSelector} from "react-redux";
import AppDialog from "../general/app_dialog";
import {EditorViewer} from "../viewers/editor_viewer";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import AddIcon from "@mui/icons-material/Add";
import {SaveToWorkspace} from "./save_to_workspace";

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {WorkspaceManager} from "./workspace_manager";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {appSession, snippetTypes} from "../../managers/generalManager";
import draftToHtml from "draftjs-to-html";
import {saveContentToDisk} from "../../utils/common_tools";
import logger from "../../utils/logging_services";
import {SnippetViewer} from "./snippet_viewer";
import {getWorkspaceItemData} from "../../utils/snippet_tools";
import {DrawMermaid} from "../mermaid/draw_mermaid";
import {UnfoldLess, UnfoldMore} from "@mui/icons-material";
import DropdownSelector from "../chat/dropdownSelector";
import {zaiaPart} from "../../app/app-styling";
import colorSchema from "../../app_theme/theme_support/colorSchema";
import {Workspace, WorkspaceItem} from "../../store/types";
import {AppDispatch, RootState} from "../../store/store";
import {
    deleteWorkspaceItemService,
    fetchWorkspaceItems,
    fetchWorkspaces,
    updateWorkspaceItemService
} from "../../store/thunks";
import {removeWorkspaceItem, resetSelectedWorkspaceItems} from "../../store/workspaceItemSlice";
import {
    setCompactSnippets, setSelectedFolder,
    setSelectedWorkspace,
    setSelectedWorkspaceItem,
    setSelectedWorkspaceItemAction
} from "../../store/dataSlice";
import {setNotification} from "../../store/uiSlice";

export enum WorkspaceType {
    WORKSPACE = 'workspaces',
    LINKEDIN = 'linkedin'
}

interface WorkspaceViewerProps {
    open: boolean;
    workspace: Workspace | undefined;
    onShowWorkSpaceItem: (workspaceItem: WorkspaceItem) => void;

    onSendRequest(request: string): void;

    onClose: () => void;
}


export const WorkspaceViewer = ({
                                    open,
                                    onClose,
                                    onShowWorkSpaceItem,
                                    onSendRequest,
                                    workspace
                                }: WorkspaceViewerProps) => {

    const dispatch = useDispatch<AppDispatch>();

    const [showWorkspaceItemEditor, setShowWorkspaceItemEditor] = useState(false);
    const [showMermaid, setShowMermaid] = useState(false);
    const [showCsvTable, setShowCsvTable] = useState(false);
    const [showHtml, setShowHtml] = useState(false);
    const [showWorkspaceManager, setShowWorkspaceManager] = useState(false);

    const [mermaidMarkup, setMermaidMarkup] = useState<string>("");

    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

    const [selectedSubtype, setSelectedSubtype] = useState<string | null>(null);
    const [snippetData, setSnippetData] = useState<string>("");
    const [filteredItems, setFilteredItems] = useState<WorkspaceItem[]>([]);
    const [uniqueSubtypes, setUniqueSubtypes] = useState<string[]>([]);
    const [name, setName] = useState<string>("");

    const selectedWorkspace: Workspace | undefined = useSelector((state: RootState) => state.data.selectedWorkspace);
    // const [selectedWorkspaceItem, setSelectedWorkspaceItem] = useState<WorkspaceItem | undefined>(undefined);
    const [addSnippet, setAddSnippet] = useState(false);

    const [currentWorkspace, setCurrentWorkspace] = useState<Workspace | undefined>(workspace);
    const [currentFolder, setCurrentFolder] = useState<string>("");
    const [currentType, setCurrentType] = useState<string>("");

    // const workspaceItems = useSelector((state: AppState) => state.temp.workspaceItems);
    const compactMode = useSelector((state: RootState) => state.data.compactSnippets);
    const selectedFolder = useSelector((state: RootState) => state.data.selectedFolder);

    const workspaces = useSelector((state: RootState) => state.workspaces.workspaces || []);
    const workspaceItems = useSelector((state: RootState) => state.workspaceItems.items);
    const selectedWorkspaceSubtype = useSelector((state: RootState) => state.data.selectedWorkspaceCategory);
    const selectedWorkspaceItem = useSelector((state: RootState) => state.data.selectedWorkspaceItem);


    const [reload, setReload] = useState<boolean>(true);

    // Load the workspace items for the selected workspace
    useEffect(() => {

        if (!selectedWorkspace) return;
        const user_scope = selectedWorkspace.user_scope;
        const type = selectedWorkspace.name;
        const subtype = '';

        setUniqueSubtypes([]);
        dispatch(fetchWorkspaceItems({
            user_scope,
            type,
            subtype,
            currentProject: appSession.currentProject,
            accessToken: appSession.accessToken
        }));

    }, [selectedWorkspace]);

    useEffect(() => {
        dispatch(fetchWorkspaces({user_scope: true}));
    }, [dispatch])

    // Filter and sort the workspace items based on the workspace type
    useEffect(() => {
        loadData();
    }, [workspaceItems])

    useEffect(() => {
        if (!reload) return;
        loadData();
    }, [reload]);

    const loadData = () => {

        if (!workspaceItems || !selectedWorkspace) return;

        const filteredItems = workspaceItems
            .filter(item => item.type === 'wi_' + selectedWorkspace?.name)
            .sort((a, b) => a.name.localeCompare(b.name));

        setFilteredItems(filteredItems);
        const newSubTypes = selectedWorkspace?.folder_list.map(ct => ct.name) || [];
        if (selectedWorkspace.folder_list) {
            setUniqueSubtypes(newSubTypes);
        } else {
            setUniqueSubtypes([])
        }

        // if selectedWorkspaceSubtype is not in the list of unique subtypes, reset the selected subtype
        if (selectedWorkspaceSubtype && !newSubTypes.includes(selectedWorkspaceSubtype)) {
            if (newSubTypes.length > 0) {
                setSelectedSubtype(newSubTypes[0]);
            } else {
                setSelectedSubtype(null);
            }
        } else {
            setSelectedSubtype(selectedWorkspaceSubtype);
        }

        setReload(false);
    }

    // Load snippet app_theme based on the type of app_theme
    useEffect(() => {

        if (!selectedWorkspace) return;

        const user_scope = selectedWorkspace.user_scope;
        const type = selectedWorkspace.name;
        const subtype = '';
        dispatch(fetchWorkspaceItems({
            user_scope: user_scope,
            type: type,
            subtype: subtype,
            currentProject: appSession.currentProject,
            accessToken: appSession.accessToken
        }));

    }, [selectedWorkspace])

    function deleteWorkspaceItem(id: string) {
        logger.info("Deleting workspace item", id);
        dispatch(removeWorkspaceItem(id));
        dispatch(deleteWorkspaceItemService(id));
    }

    function sendWorkspaceItemToChat(id: string) {
        const selectedItem = workspaceItems.find(ws => ws.uuid === id)
        if (selectedItem)
            onShowWorkSpaceItem(selectedItem);
    }

    function handleMergeSelectedWorkspaceItems() {
        logger.info("Merging all workspace items")

        if (!workspaceItems) return;

        const selectedWorkspaceItems = workspaceItems.filter(ws => ws.selected).map(ws => ws.content) || [];
        const mergedContent = selectedWorkspaceItems.join("\n");

        logger.info("Selected app_theme", mergedContent);
        setSnippetData(mergedContent);
        setAddSnippet(true);
        dispatch(resetSelectedWorkspaceItems());
    }

    // Mark the selected item for editing
    const editWorkspaceItem = (id: string) => {
        const selectedItem = workspaceItems.find(ws => ws.uuid === id);
        if (!selectedItem) return;

        loadEditorState(getWorkspaceItemData(selectedItem));
        setName(selectedItem.name);

        setCurrentFolder(selectedFolder);
        setCurrentType(selectedItem.tags[0]);
        setCurrentWorkspace(selectedWorkspace);

        setShowWorkspaceItemEditor(true);
    }

    // Update the selected item
    const handleUpdateWorkspaceItem = () => {
        if (selectedWorkspaceItem && currentWorkspace ) {

            if (name.trim() === '') {
                return;
            }

            const updatedItem: WorkspaceItem = {
                ...selectedWorkspaceItem,
                tags: [currentType],
                type: `wi_${currentWorkspace.name}`,
                subtype: currentFolder,
                name: name,
                content: draftToHtml(convertToRaw(editorState.getCurrentContent()))
            };
            dispatch(updateWorkspaceItemService(updatedItem));
        }
        setName("");
        setShowWorkspaceItemEditor(false);
    }

    // Load the editor state from the HTML content
    const loadEditorState = (value: string) => {
        let {contentBlocks, entityMap} = htmlToDraft(value || "");

        // Create a new content state from the HTML content
        const newContentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        // Create a new EditorState with the new content
        const newEditorState = EditorState.createWithContent(newContentState);

        // Set the new EditorState
        setEditorState(newEditorState);
    }

    function handleEditorStateChange(newState: EditorState) {
        setEditorState(newState);
    }

    function handleSwitchCompactMode() {
        dispatch(setCompactSnippets(!compactMode));
    }

    function handleAddSnippet() {
        handleMergeSelectedWorkspaceItems();
        setAddSnippet(true);
    }

    const handleRequestWrapper = (action: string, key: string) => {

        const currentWorkSpaceItem = workspaceItems.find(ws => ws.uuid === key);
        if (!currentWorkSpaceItem) return;

        // setSelectedWorkspaceItem(currentWorkSpaceItem);
        dispatch(setSelectedWorkspaceItem(currentWorkSpaceItem));

        if (action === 'path') {
            // path = selected workspace name + selected folder name + selected item name
            const path = `${selectedWorkspace?.name}/${selectedFolder}/${currentWorkSpaceItem.name}`;
            const id = currentWorkSpaceItem.uuid;
            if (id !== undefined) {

                // send path to clipboard
                navigator.clipboard.writeText(`{**ws=${id}||${path}**}`);

                // create a notification
                logger.info("Path copied to clipboard:", path);

                // create mui snackbar notification
                dispatch(setNotification({message: `Path copied to clipboard: ${path}`, type: 'info'}));
            }
        }
        if (action === 'delete') {
            deleteWorkspaceItem(key);
        }
        if (action === 'use') {
            sendWorkspaceItemToChat(key);
        }
        if (action === 'view') {

            dispatch(setSelectedWorkspaceItemAction(action));

        }
        if (action === 'edit') {
            editWorkspaceItem(key);
        }
        if (action === 'save') {
            saveContentToDisk(`${currentWorkSpaceItem.name}.html`, getWorkspaceItemData(currentWorkSpaceItem))
                .then(() => {
                    logger.info('Content saved successfully.');
                })
                .catch((error) => {
                    console.error('Failed to save content:', error);
                });
        }
    };

    const handleCloseMermaidViewer = () => {
        setShowMermaid(false);
    }

    const handleWorkspaceSelected = (value: string) => {
        dispatch(setSelectedWorkspace(workspaces.find(ws => ws.name === value)));
        dispatch(setSelectedFolder(workspaces.find(ws => ws.name === value)?.folder_list[0].name || ""));
    }

    const handleResetSelectedItems = () => {
        dispatch(resetSelectedWorkspaceItems());
    }

    const handleWorkspaceManagerClosed = () => {
        setShowWorkspaceManager(false);
        setReload(true);
    }

    // if (workspaces.length === 0) {
    //     return (
    //         <PleaseWaitDialog open={workspaces.length === 0} onCancel={() => {
    //         }} title={"Loading Workspaces"} message={"Please wait while the workspaces are being loaded"}/>
    //     );
    // }

    return (
        <div style={zaiaPart({})}>
            {open && (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    // maxWidth: appSettings.maxWidth,
                    // minWidth: appSettings.maxWidth,
                    height: '100%'
                }}>
                    {/* Title section */}
                    <div style={{
                        height: '50px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Typography variant={"h5"} marginLeft={1}>Workspace</Typography>
                        <IconButton onClick={onClose} size={"small"}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Divider/>

                    {/* Main section */}
                    <SnippetViewer
                        open={true}
                        onHandleRequest={handleRequestWrapper}
                        itemType={WorkspaceType.WORKSPACE}
                        initialCategory={selectedWorkspaceSubtype || ""}
                        uniqueCategories={uniqueSubtypes}
                        snippetList={filteredItems}
                    />

                    {/* Button section */}
                    <Box sx={{
                        height: '50px',
                        textAlign: 'center',
                        alignContent: 'center',
                        borderRadius: '10px',
                        backgroundColor: colorSchema.background.default,
                        color: colorSchema.primary.main,
                    }}>

                        <Divider style={{marginBottom: '10px'}}/>

                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>
                                <Tooltip title={"Reset"}>
                                    <IconButton onClick={() => handleResetSelectedItems()}
                                                name="reset selected items"
                                                size={"small"}>
                                        <RestartAltIcon style={{color: colorSchema.primary.main}}/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div>
                                <DropdownSelector
                                    defaultValue={selectedWorkspace ? selectedWorkspace.name : "None"}
                                    options={workspaces?.map((ws) => ({
                                        label: ws.name.substring(0, 15),
                                        value: ws.name
                                    })) || []}
                                    label=""
                                    onChange={handleWorkspaceSelected}
                                />

                            </div>

                            <div>
                                <Tooltip title={"Show/Hide content"}>
                                    <IconButton onClick={() => handleSwitchCompactMode()} name="compact_mode"
                                                size={"small"}>
                                        {compactMode ? (
                                            <UnfoldMore style={{color: colorSchema.primary.main}}/>
                                        ) : (
                                            <UnfoldLess style={{color: colorSchema.primary.main}}/>

                                        )}
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title={"Create (empty/merged)"}>
                                    <IconButton onClick={() => handleAddSnippet()} name="add snippet"
                                                size={"small"}>
                                        <AddIcon style={{color: colorSchema.primary.main}}/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={"Manage Projects"}>
                                    <IconButton onClick={() => setShowWorkspaceManager(true)} name="manage projects"
                                                size={"small"}>
                                        <ManageAccountsIcon style={{color: colorSchema.primary.main}}/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </Box>
                </div>
            )}

            {
                showWorkspaceManager && (
                    <WorkspaceManager onClose={handleWorkspaceManagerClosed}/>
                )
            }
            {
                addSnippet && (
                    <SaveToWorkspace onSubmit={() => setAddSnippet(false)} onCancel={() => setAddSnippet(false)}
                                     workspace={workspace!} workspaceFolder={selectedFolder}
                                     type={"html"}
                                     context_data={snippetData}/>
                )
            }

            {
                showMermaid && (
                    <DrawMermaid markup={mermaidMarkup} onClose={handleCloseMermaidViewer}/>
                )
            }

            {/*{*/}
            {/*    showHtml && (*/}
            {/*        <HTMLViewer htmlContent={dataToView} title={"HTML Viewer"} onClose={() => setShowHtml(false)}/>*/}
            {/*    )*/}
            {/*}*/}

            {
                showCsvTable && (
                    <></>
                    // <DynamicCsvTable
                    //     viewMode={"embedded"}
                    //     initialData={csvRecords}
                    //     onClose={() => setShowCsvTable(false)}
                    //     onSelection={(selectedRecords: DynamicDataItem[]) => {
                    //         const selectedKeys = selectedRecords.map(record => record.key);
                    //         fileSelection.setSelectedCsvRecordList(selectedKeys);
                    //     }}
                    //     selectedKeys={fileSelection.selectedCsvRecordList}
                    // />


                    // <DynamicCsvTable
                    //
                    //     viewMode={'modal'}
                    //     initialData={csvRecords}
                    //                  onClose={() => {
                    //                      setShowCsvTable(false)
                    //                  }}
                    //
                    //                  selectedKeys={[]}/>
                )
            }

            {
                showWorkspaceItemEditor && (

                    <AppDialog
                        open={true}
                        size={"xl"}
                        showSubmit={true}
                        showCancel={true}
                        title={`Edit Workspace Item`}
                        message={""}
                        onSubmit={handleUpdateWorkspaceItem}
                        onCancel={() => setShowWorkspaceItemEditor(false)}
                    >
                        <Grid container display={"flex"} flexDirection={"row"} spacing={2} marginTop={"5px"}
                              sx={{height: '800px'}} overflow={"hidden"}>


                            <TextField
                                id="workspace_name"
                                label="Workspace Name"
                                fullWidth
                                error={name.trim() === ''}
                                margin="normal"
                                variant="outlined"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />


                            <div style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                                height: '100%',
                                backgroundColor: colorSchema.background.default,
                            }}>
                                <EditorViewer editorState={editorState}
                                              showActions={false}
                                              onEditorStateChange={handleEditorStateChange}
                                              onSendRequest={() => {
                                              }}
                                              onUpdateEditorState={setEditorState}/>
                            </div>
                        </Grid>
                        {/* select workspace, folder and type of content */}
                        <Grid container spacing={2} sx={{mt: 1, mb: 2}}>
                            <Grid item xs={12} sm={6} md={4}>
                                <DropdownSelector
                                    defaultValue={currentWorkspace?.name || "None"}
                                    options={workspaces.map((ws) => ({label: ws.name, value: ws.name}))}
                                    label="Workspace"
                                    onChange={(value) => setCurrentWorkspace(workspaces.find((ws) => ws.name === value))}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <DropdownSelector
                                    defaultValue={currentFolder}
                                    options={currentWorkspace?.folder_list.map((folder) => ({
                                        label: folder.name,
                                        value: folder.name
                                    })) || []}
                                    label="Folder"
                                    onChange={(value) => setCurrentFolder(value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <DropdownSelector
                                    defaultValue={currentType}
                                    options={snippetTypes.map((contentType) => ({label: contentType, value: contentType}))}
                                    label="Content Type"
                                    onChange={(value) => setCurrentType(value)}
                                />
                            </Grid>
                        </Grid>
                    </AppDialog>
                )
            }
        </div>
    );
};