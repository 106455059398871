import logger from "./logging_services";

export interface KnowledgeResult {
    text: string;
    metadata: string;
}

export const replaceVariables = (
    prompt: string,
    variables: { [key: string]: string }
): string => {
    const regex = /{([>=<])(\w+)}(.*?)({=\2}.*?)?{<\2}/gs;

    return prompt.replace(regex, (match, condition, key, ifTrue, ifFalse) => {
        const lowerCaseKey = key.toLowerCase();
        const value = variables[key] || '';

        logger.info(`Processing variable ${lowerCaseKey} with condition ${condition}`);

        if (condition === '>') {
            // If condition
            if (value !== '' && value !== 'none') {
                return ifTrue + (ifFalse ? ifFalse.slice(ifFalse.indexOf('}') + 1) : '');
            } else {
                return ifFalse ? ifFalse.slice(ifFalse.indexOf('}') + 1) : '';
            }
        } else if (condition === '=') {
            // Else if condition (optional)
            return value !== '' && value !== 'none' ? ifTrue : '';
        }

        // If we reach here, it means the syntax is incorrect
        logger.error(`Incorrect syntax for variable ${lowerCaseKey}`);
        return match;
    }).replace(/{(\w+)}/g, (match, key) => {
        const value = variables[key] || '';

        // if value is of type array, concatenate the values
        if (Array.isArray(value)) {
            return value.join(', ');
        }

        return value.replace(/,/g, '.');
    });
};

export const capitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

// import {debugMode} from "../managers/generalManager";
// import logger from "./logging_services";
//
// export interface KnowledgeResult {
//     text: string;
//     metadata: string;
// }
//
// export const replaceVariables = (
//     prompt: string,
//     variables: { [key: string]: string }
// ) => {
//     let newPrompt = prompt;
//     for (const key in variables) {
//
//         const lowerCaseKey = key.toLowerCase();
//         const lowerCaseKeyIfStart = '>' + lowerCaseKey;
//         const lowerCaseKeyIfEnd = '<' + lowerCaseKey;
//
//         logger.info(`Looking for variable ${lowerCaseKey} in prompt ${newPrompt}`);
//
//         // Check if lowerCaseKeyIfStart and End are in the prompt
//         if (newPrompt.includes(lowerCaseKeyIfStart) && newPrompt.includes(lowerCaseKeyIfEnd)) {
//
//             // Check if the variable is empty or none
//             if (variables[key] === '' || variables[key] === 'none' ) {
//                 const start = newPrompt.indexOf(lowerCaseKeyIfStart);
//                 const end = newPrompt.indexOf(lowerCaseKeyIfEnd) + lowerCaseKeyIfEnd.length;
//                 newPrompt = newPrompt.substring(0, start - 1) + newPrompt.substring(end + 2);
//                 continue;
//             } else {
//                 // remove lowerCaseKeyIfStart and End from the prompt
//                 newPrompt = newPrompt.replace(new RegExp(`{${lowerCaseKeyIfStart}}`, 'g'), "");
//                 newPrompt = newPrompt.replace(new RegExp(`{${lowerCaseKeyIfEnd}}`, 'g'), "");
//             }
//         }
//
//         try {
//             // newPrompt = newPrompt.replace(new RegExp(`{${lowerCaseKey}}`, 'g'), variables[key]);
//             newPrompt = newPrompt.replace(new RegExp(`{${lowerCaseKey}}`, 'g'), variables[key].replace(/,/g, '.'));
//
//         } catch (error) {
//             if ( error instanceof Error )
//                 logger.error("Error --> API error: ", error.message);
//         }
//
//     }
//     return newPrompt;
// };
//
// export const capitalizeFirstLetter = (str: string) => {
//   return str.charAt(0).toUpperCase() + str.slice(1);
// };
//
