import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ItemState {
    selectedCSVRecords: string[];
    selectedCSVFile: string;
}

const initialState: ItemState = {
    selectedCSVRecords: [],
    selectedCSVFile: '',
};

const itemSlice = createSlice({
    name: 'item',
    initialState,
    reducers: {
        setSelectedCSVRecords: (state, action: PayloadAction<string[]>) => {
            state.selectedCSVRecords = action.payload;
        },
        setSelectedCSVFile: (state, action: PayloadAction<string>) => {
            state.selectedCSVFile = action.payload;
        }
    },
});

export const {
    setSelectedCSVRecords,
    setSelectedCSVFile,
} = itemSlice.actions;

export default itemSlice.reducer;