import axios from 'axios';
import config from '../config.json';
import logger from "../utils/logging_services";

const service_url = (config.mode === 'dev' ? config.server_url_dev : config.server_url_prod) + '/modules/extdata/snippets'

// ----------------------------------------------------------------------------------------------------------------
export type SnippetDataCore = {
    uuid?: string;
    name: string;
    reference: string;
    type: string;
    subtype: string;
    tags: string[];
    chunks?: number;
};

export interface SnippetData extends SnippetDataCore {
    content: string;
}

export type LinkedInProfile = {
    data: string;
    description: string;
}

export type LinkedInJob = {
    aboutTheVacancy: string;
}


export interface SnippetDataLinkedIn extends SnippetDataCore {
    content: LinkedInProfile | LinkedInJob;
}

export interface SnippetDataIn extends SnippetData {
    user_scope: boolean;
}

export type AddSnippetData = {
    message: string;
    uuid: string;
}

// ----------------------------------------------------------------------------------------------------------------
export async function addSnippet(data: SnippetData, projectId: string, server_token: string, user_scope: boolean = true): Promise<AddSnippetData | undefined> {
    const url = `${service_url}/${projectId}?user_scope=${user_scope}`;

    const headers = {
        Authorization: `Bearer ${server_token}`,
        Accept: "text/event-stream",
        "Content-Type": "application/json",
    };

    try {
        const response = await axios.post(url, data, {headers});
        logger.info('AddSnippet Service Request Response:', response.data);
        return response.data as AddSnippetData;
    } catch (error) {
        if (error instanceof Error)
            logger.error('AddSnippet Service Request Error:', error);
        return undefined;
    }
}

// ----------------------------------------------------------------------------------------------------------------
export async function updateSnippet(data: SnippetData, projectId: string, server_token: string) {
    const url = `${service_url}/${projectId}/${data.uuid}`;

    const headers = {
        Authorization: `Bearer ${server_token}`,
        'Accept': 'application/json',
        "Content-Type": "application/json",
    };

    try {
        const response = await axios.put(url, data, {headers});
        logger.info('UpdateSnippet Service Request Response:', response.data);
    } catch (error) {
        if (error instanceof Error)
            logger.error('UpdateSnippet Service Request Error:', error);
    }
}

export async function searchSnippets(
    user_scope: boolean, type: string, subType: string, projectUuid: string, serverToken: string
): Promise<SnippetDataIn[]> {

    const url = `${service_url}/${projectUuid}/search` +
        `?user_scope=${user_scope}&type=${encodeURIComponent(type)}&subtype=${encodeURIComponent(subType)}`;

    const headers = {
        'Authorization': `Bearer ${serverToken}`,
        'Accept': 'application/json'
    };

    try {
        const response = await axios.get<SnippetDataIn[]>(url, {headers});
        logger.info('SearchSnippets Service Request Response:', response.data);
        return response.data;
    } catch (error) {
        if (error instanceof Error)
            logger.error('SearchSnippets Service Request Error:', error);
        return [];
    }
}

export async function searchSnippetsByName(
    user_scope: boolean, name: string, type: string, subType: string, projectUuid: string, serverToken: string
): Promise<SnippetDataIn[]> {

    const url = `${service_url}/${projectUuid}/search_by_name` +
        `?user_scope=${user_scope}` +
        `&name=${encodeURIComponent(name)}` +
        `&type=${encodeURIComponent(type)}&subtype=${encodeURIComponent(subType)}`

    const headers = {
        'Authorization': `Bearer ${serverToken}`,
        'Accept': 'application/json'
    };

    try {
        const response = await axios.get<SnippetDataIn[]>(url, {headers});
        logger.info('SearchSnippetsByName Service Request Response:', response.data);
        return response.data;
    } catch (error) {
        if (error instanceof Error)
            logger.error('SearchSnippetsByName Service Request Error:', error);
        return [];
    }
}

export async function addRemoveSnippetToKb(
    uuid: string, projectUuid: string, serverToken: string): Promise<SnippetData | undefined> {

    const url = `${service_url}/${projectUuid}/add_remove_kb/${uuid}`;
    const headers = {
        'Authorization': `Bearer ${serverToken}`,
        'Accept': 'application/json'
    };

    try {
        // response is a json string
        const response = await axios.put<SnippetData>(url, "",{headers});
        logger.info('GetSnippet Service Request Response:', response.data);
        return response.data;
    } catch (error) {
        if (error instanceof Error)
            logger.error('GetSnippet Service Request Error:', error);
        return undefined;
    }
}

// ----------------------------------------------------------------------------------------------------------------
export async function getSnippet(
    uuid: string, projectUuid: string, serverToken: string
): Promise<SnippetDataIn | undefined> {

    const url = `${service_url}/${projectUuid}/${uuid}`
    const headers = {
        'Authorization': `Bearer ${serverToken}`,
        'Accept': 'application/json'
    };

    try {
        const response = await axios.get<SnippetDataIn>(url, {headers});
        logger.info('GetSnippet Service Request Response:', response.data);
        return response.data;
    } catch (error) {
        if (error instanceof Error)
            logger.error('GetSnippet Service Request Error:', error);
        return undefined;
    }
}

// encoded_file_path = urllib.parse.quote(file_path)
// ----------------------------------------------------------------------------------------------------------------
export async function deleteSnippet(uuid: string, projectId: string, serverToken: string): Promise<boolean> {
    const url = `${service_url}/${projectId}/${uuid}`;

    const headers = {
        'Authorization': `Bearer ${serverToken}`,
        'Accept': 'application/json'
    };

    try {
        const response = await axios.delete(url, {headers});
        logger.info('DeleteSnippet Service Request Response:', response.data);
        return true;
    } catch (error) {
        if (error instanceof Error)
            logger.error('DeleteSnippet Service Request Error:', error);
        return false;
    }
}