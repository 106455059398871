import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Settings from '@mui/icons-material/Settings';
import {Logout as LogoutIcon} from '@mui/icons-material';
import {LogOut} from "../authorisation/LogOutComponent";
import React, {useState} from "react";
import {TaskGroupsManager} from "../task_groups/task_groups_manager";
import {appSettings} from "../../managers/generalManager";
import logger from "../../utils/logging_services";

interface AppMenuProps {
    anchorEl: HTMLElement | null;
    user: string;
    initials: string;
    open: boolean;
    onSelection: (selection: string) => void;
    onClose: () => void;
}

const menuStyles = {
    elevation: 0,
    sx: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 0,
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
        },
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 100,
            right: 40,
            width: 10,
            height: 60,
            bgcolor: 'background.paper',
            transform: 'translateY(0%) rotate(-45deg)',
            zIndex: 0,
        },
    },
};

export default function AppMenu({anchorEl, user, initials, open, onClose, onSelection}: AppMenuProps) {

    const [showLogOut, setShowLogOut] = useState<boolean>(false);
    const [showLocalStorageStore, setShowLocalStorageStore] = useState<boolean>(false)


    const handleClose = () => {
        onClose(); // Close the menu by calling the passed-in onClose function
    };

    const handleLogOut = () => {
        setShowLogOut(true);
    }

    const handleShowLocalContextData = () => {
        setShowLocalStorageStore(true);
    }

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={menuStyles}
                transformOrigin={{horizontal: 'right', vertical: 'bottom'}}
                anchorOrigin={{horizontal: 'right', vertical: 'top'}}
            >

                <MenuItem onClick={handleClose}>
                    <Avatar>{initials}</Avatar>{user}
                </MenuItem>
                <Divider/>
                 <MenuItem onClick={() => handleShowLocalContextData()}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small"/>
                    </ListItemIcon>
                    Task Group Management
                </MenuItem>
                <MenuItem onClick={() => onSelection("settings")}>
                    <ListItemIcon>
                        <Settings fontSize="small"/>
                    </ListItemIcon>
                    Settings
                </MenuItem>
                <MenuItem onClick={() => handleLogOut()}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small"/>
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>

            {showLogOut && (
                <LogOut
                    onSucces={() => setShowLogOut(false)}
                    onCancel={() => setShowLogOut(false)}
                    isOpen={showLogOut}
                />
            )}

            {
                showLocalStorageStore && (
                    <TaskGroupsManager
                        isOpen={true}
                        mode={"manage"}
                        mode_data={""}
                        filter={""}
                        dbName={appSettings.database}
                        onClose={() => {
                            setShowLocalStorageStore(false)
                            onSelection("task_group_management")
                        }}
                        onSelect={(data: string) => {
                            logger.info(data);
                            setShowLocalStorageStore(false);
                        }}
                    />
                )
            }

        </>

    );
}