import React from "react";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import {DraggablePaper} from "../general/Styling.snippets";
import {removeSession} from "../../managers/generalManager";

export interface LogOutProps {
    isOpen: boolean;
    onSucces: () => void;
    onCancel: () => void;
}

export const LogOut = ({isOpen, onSucces, onCancel}: LogOutProps ) => {

    function handleSubmit() {
         removeSession();
         isOpen = false;
         window.location.reload();
         onSucces()
    }

    return (

        <Dialog
            open={isOpen}
            onClose={onCancel}
            PaperComponent={DraggablePaper}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle sx={{cursor: 'move'}} id="draggable-dialog-title">
                Preparing to Log Out: Almost Done!
            </DialogTitle>

            <DialogContentText sx={{mx: 5, my: 3}}>
                You have initiated the logout process and the system is now preparing to log you out. Please confirm.
            </DialogContentText>
            <DialogContent>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onCancel}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit}>LogOut</Button>
            </DialogActions>
        </Dialog>
    )
}
