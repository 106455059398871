import { appSession } from "./generalManager";
import {
  KnowledgeBaseItemOut,
  listDataBoostrItems,
  listTalentBoostrItems
} from "../backend/extdata.services";
import logger from "../utils/logging_services";
import {searchSnippets} from "../backend/snippet.services";

// Types
type SegmentType = "text" | "parameter";

interface Segment {
  type: SegmentType;
  value: string;
  props: Prop[];
}

interface Prop {
  key: string;
  value: string;
}

// Form Page and Field classes
export class FormPage {
  constructor(public id: string, public field_list: FormField[] = []) {}

  add_field(field: FormField): FormField {
    this.field_list.push(field);
    return field;
  }
}

export abstract class FormField {
  constructor(public id: string, public tooltip: string = "") {}
}

export class FormFieldTypograph extends FormField {
  constructor(
    public id: string,
    public variant: string,
    public text?: string,
    public component?: string,
    tooltip?: string
  ) {
    super(id, tooltip);
  }
}

export class FormFieldTextArea extends FormField {
  constructor(
    public id: string,
    public label: string,
    public variant: 'filled' | 'standard' | 'outlined' = 'standard',
    public default_value: string = '',
    public type: 'password' | 'number' | 'string' | 'search' = 'string',
    public helpertext: string = '',
    public isDisabled: boolean = false,
    public isFullWidth: boolean = false,
    public isRequired: boolean = false,
    public isError: boolean = false,
    public isMultiline: boolean = false,
    public rows: number = 1,
    public category: string = "",
    tooltip?: string
  ) {
    super(id, tooltip);
  }
}

export class FormFieldDivider extends FormField {}


export class FormFieldSelect extends FormField {
  constructor(
    public id: string,
    public defaultValue: string,
    public label: string,
    public values: string[],
    tooltip?: string,
    public allowFileUpload: boolean = true,
    public fileTypes: string[] = []
  ) {
    super(id, tooltip);
  }
}

// Utility functions
const removePunctuation = (text: string): string => {
  const punctuationRegex = /^[!.,;?]+/u;
  return text.replace(punctuationRegex, "").trim();
};

const extractProps = (propsText: string): Prop[] => {
  return propsText.split(",").map(prop => {
    const [key, value] = prop.split(":");
    return {
      key: key?.replace(/[{()}]/g, "")?.trim() ?? "",
      value: value?.replace(/[{()}]/g, "").trim() ?? ""
    };
  });
};

const convertTemplateToSegments = (template: string): Segment[] => {
  const segments: Segment[] = [];
  const regex = /\[(.*?)\]|\((.*?)\)/gu;
  let lastIndex = 0;
  let match: RegExpExecArray | null;

  while ((match = regex.exec(template))) {
    if (match.index > lastIndex) {
      segments.push({
        type: "text",
        value: removePunctuation(template.slice(lastIndex, match.index)),
        props: [{ key: "type", value: "body" }],
      });
    }

    if (match[1]) {
      const [parameterName, parameterProps] = match[1].split("(");
      segments.push({
        type: "parameter",
        value: parameterName.trim(),
        props: parameterProps ? extractProps(parameterProps.replace(/[{}]/g, "")) : [],
      });
    } else {
      const textProps = match[2];
      segments[segments.length - 1].props = extractProps(textProps?.replace(/[{}]/g, "") ?? "");
    }

    lastIndex = regex.lastIndex;
  }

  if (lastIndex < template.length) {
    segments.push({
      type: "text",
      value: removePunctuation(template.slice(lastIndex)),
      props: [{ key: "type", value: "body" }],
    });
  }

  return segments;
};

// Main function
export async function createFormPage(template_form: string, selectedFileCategory: string): Promise<FormPage> {
  logger.info("createFormPage", template_form);

  const page = new FormPage(template_form);
  let label_counter = 1;

  const segments = convertTemplateToSegments(template_form);

  for (const segment of segments) {
    switch (segment.type) {
      case "text":
        const typeProp = segment.props.find(prop => prop.key === "type");
        const variant = typeProp?.value ?? "text";
        page.add_field(
          new FormFieldTypograph(
            `label${label_counter++}`,
            variant,
            segment.value,
            "Typography"
          )
        );
        break;
      case "parameter":
        await handleParameterSegment(page, segment, selectedFileCategory);
        break;
      default:
        logger.error("Invalid option selected");
    }
  }

  return page;
}

async function handleParameterSegment(page: FormPage, segment: Segment, selectedFileCategory: string) {
  const choiceProp = segment.props.find(prop => prop.key === 'choice');
  const filesProp = segment.props.find(prop => prop.key === 'files');
  const talentBoostrProp = segment.props.find(prop => prop.key === 'talentboostr_items');
  const snippetProps = segment.props.find(prop => prop.key === 'snippet');

  if (choiceProp) {
    page.add_field(
      new FormFieldSelect(
        segment.value,
        segment.value,
        segment.value,
        choiceProp.value.split('|').map(value => value.trim())
      )
    );
  } else if (filesProp) {
    await handleFilesProp(page, segment, filesProp, selectedFileCategory);
  } else if (talentBoostrProp) {
    await handleTalentBoostrProp(page, segment, talentBoostrProp);
  } else if (snippetProps ) {
    await handleSnippetProp(page, segment, snippetProps);
  } else {
    handleDefaultParameter(page, segment);
  }
}

async function handleSnippetProp(page: FormPage, segment: Segment, snippetProp: Prop) {

  const [workspaceName = "", folderName = "", objectType = ""] = (snippetProp.value || "|").split('|');

  try {

    let snippets = await searchSnippets(true, "wi_" + workspaceName, folderName, appSession.currentProject, appSession.accessToken);
    if ( snippets.length === 0 ) {
      snippets = await searchSnippets(false, "wi_" + workspaceName, folderName, appSession.currentProject, appSession.accessToken);
    }

    let templates = snippets.map(snippet => `snippet=${snippet.uuid}||${snippet.tags}&${snippet.name}`);
    templates = ["none", ...templates];

    page.add_field(
      new FormFieldSelect(
        segment.value,
        segment.value,
        segment.value,
        templates
      )
    );
  } catch (error: any) {
    logger.error("Error while fetching app_theme snippet items", error);
    page.add_field(
      new FormFieldTypograph(
        `label${Date.now()}`,
        "error",
        "Error while fetching snippets, please check the selected definition",
        "Error while fetching snippets, please check the selected definition",
        "Typography"
      )
    );
  }
}

async function handleFilesProp(page: FormPage, segment: Segment, filesProp: Prop, selectedFileCategory: string) {

  const [folderNamePart = "", fileTypePart = ""] = (filesProp.value || "|").split('|');
  const folderName = folderNamePart || selectedFileCategory;
  const fileType = fileTypePart || "all";

  try {
    const response: KnowledgeBaseItemOut[] = await listDataBoostrItems(appSession.currentProject, folderName);
    let templates = filterTemplates(response, fileType);

    page.add_field(
      new FormFieldSelect(
        segment.value,
        segment.value,
        segment.value,
        templates
      )
    );
  } catch (error: any) {
    logger.error("Error while fetching app_theme boostr items", error);
    page.add_field(
      new FormFieldTypograph(
        `label${Date.now()}`,
        "error",
        "Error while fetching files, please check the selected goal definition",
        "Typography"
      )
    );
  }
}

function filterTemplates(response: KnowledgeBaseItemOut[], fileType: string): string[] {
  if (fileType === "all") {
    return response.map(template => `${template.uuid}&${template.title}`);
  }

  const fileExtensions = {
    image: ['.jpg', '.jpeg', '.png', '.gif', '.webp'],
    pdf: ['.pdf']
  };

  return response
    .filter(template => fileExtensions[fileType as keyof typeof fileExtensions]?.some(ext => template.metadata_resource.includes(ext)))
    .map(template => `${template.uuid}&${template.title}`);
}

async function handleTalentBoostrProp(page: FormPage, segment: Segment, talentBoostrProp: Prop) {
  try {
    const response = await listTalentBoostrItems(appSession.currentProject, talentBoostrProp.value);
    const templates = response ? response.map(template => `${template.uuid}&${template.title}`) : [];

    page.add_field(
      new FormFieldSelect(
        segment.value,
        segment.value,
        segment.value,
        templates
      )
    );
  } catch (error: any) {
    logger.error("Error while fetching talent boostr items", error);
  }
}

function handleDefaultParameter(page: FormPage, segment: Segment) {
  const tooltipProp = segment.props.find(prop => prop.key === "tooltip");
  const rowsProp = segment.props.find(prop => prop.key === "rows");
  const categoryProp = segment.props.find(prop => prop.key === "category");
  const defaultValueProp = segment.props.find(prop => prop.key === "default");

  page.add_field(
    new FormFieldTextArea(
      segment.value,
      segment.value,
      "outlined",
      defaultValueProp?.value || "",
      "string",
      "",
      false,
      true,
      true,
      false,
      Boolean(rowsProp),
      rowsProp?.value ? parseInt(rowsProp.value) : 1,
      categoryProp?.value || "",
      tooltipProp?.value
    )
  );
}