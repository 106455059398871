import React, {useEffect, useState} from 'react';
import {
    Box,
    TextField,
    MenuItem,
    Typography,
    FormControl,
    InputLabel,
    Select,
    IconButton,
    CardContent, Card, Divider
} from '@mui/material';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import LabelIcon from '@mui/icons-material/Label';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from "@mui/material/Tooltip";
import colorSchema from "../../app_theme/theme_support/colorSchema";

export interface Filter {
    id: string;
    type: 'file' | 'tag';
    value: string;
    query?: string;
}

export interface FileOption {
    value: string;
    label: string;
}

interface FilterManagerProps {
    fileList: FileOption[];
    onFilterUpdate: (filters: Filter[]) => void;
}

const FilterManager: React.FC<FilterManagerProps> = ({fileList, onFilterUpdate}) => {
    const [filters, setFilters] = useState<Filter[]>([]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        onFilterUpdate(filters);
    }, [filters]);

    const handleAddFilter = (type: 'file' | 'tag') => {
        setFilters([...filters, {id: count.toString(), type, value: ''}]);
        setCount(count + 1);
    };

    const handleDeleteFilter = (id: string) => {
        setFilters(filters.filter(filter => filter.id !== id));
    };

    const handleUpdateFilter = (id: string, value: string) => {
        setFilters(filters.map(filter => filter.id === id ? {...filter, value} : filter));
    };

    const handleUpdateFilterQuery = (id: string, query: string) => {
        setFilters(filters.map(filter => filter.id === id ? {...filter, query} : filter));
    }

    return (
        <Card>

            <Box
                display="flex"
                justifyContent="space-between" // Adjusts children to opposite ends
                alignItems="center"
                height={'15px'}
                sx={{
                    backgroundColor: colorSchema.background.default, // Adjusts background color
                    color: colorSchema.primary.main, // Adjusts text color
                    padding: '10px',
                }}
            >
                {/* Left-aligned part */}
                <Typography variant="body1">
                    {filters.length > 0 ? "Filter" : "Add Filter"}
                </Typography>

                {/* Right-aligned part */}
                {filters.length < 3 && (
                    <Box display="flex">
                        <IconButton onClick={() => handleAddFilter('file')} color="primary"
                                    aria-label="add file filter">
                            <Tooltip title="Filter on File">
                                <FolderOpenIcon sx={{color: colorSchema.primary.main}}/>
                            </Tooltip>
                        </IconButton>
                        <IconButton onClick={() => handleAddFilter('tag')} color="primary" aria-label="add tag filter">
                            <Tooltip title="Filter based on tag(s)">
                                <LabelIcon sx={{color: colorSchema.primary.main}}/>
                            </Tooltip>
                        </IconButton>
                    </Box>
                )}
            </Box>
            {filters.length > 0 && (
                <CardContent>
                    <Box display={"flex"} flex={'1, 0, 0px'} flexDirection={"column"} overflow={"auto"}>
                        {filters.length === 0 && (
                            <Typography variant="body2" color="textSecondary" align="center">
                                No filters applied
                            </Typography>
                        )}
                        {filters.map((filter, index) => (
                            <Box key={filter.id}
                                 sx={{marginTop: '5px', display: 'flex', flexDirection: 'column', gap: 1}}>
                                <Box sx={{display: 'flex', alignItems: 'center', gap: 0}}>
                                    {filter.type === 'file' ? (
                                        <FormControl fullWidth size="small">
                                            <InputLabel>Filter on File</InputLabel>
                                            <Select
                                                value={filter.value}
                                                label="Filter on File"
                                                margin={"none"}
                                                onChange={(e) => handleUpdateFilter(filter.id, e.target.value as string)}
                                            >
                                                {fileList.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <TextField
                                            size="small"
                                            label="Filter on Tags"
                                            value={filter.value}
                                            margin={"none"}
                                            fullWidth={true}
                                            onChange={(e) => handleUpdateFilter(filter.id, e.target.value)}
                                            sx={{marginRight: '10px'}}
                                        />
                                    )}
                                    <IconButton onClick={() => handleDeleteFilter(filter.id)} aria-label="delete"
                                                color={"error"}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </Box>
                                <TextField
                                    label="Filter Question"
                                    variant="outlined"
                                    fullWidth
                                    value={filter.query}
                                    onChange={(e) => handleUpdateFilterQuery(filter.id, e.target.value)}
                                    size="small"
                                />
                                {index < filters.length - 1 && (
                                    <Divider sx={{padding: '5px'}}/>
                                )}
                            </Box>
                        ))}
                    </Box>
                </CardContent>
            )}
        </Card>
    );
}

export default FilterManager;
