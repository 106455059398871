import {configureStore, combineReducers, ThunkAction, Action, AnyAction} from '@reduxjs/toolkit';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessionReducer from "./sessionSlice";
import dataReducer from "./dataSlice";
import uiReducer from './uiSlice';
import workspacesReducer from './workspacesSlice';
import workspaceItemsReducer from './workspaceItemSlice';
import linkedInItemsReducer from './linkedinItemSlice';
import itemReducer from './itemSlice';
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from "redux-persist/es/constants";

const rootPersistConfig = {
    key: 'root',
    storage,
    blacklist: ['workspaces', 'workspaceItems', 'linkedInItems']
};

const rootReducer = combineReducers({
    session: sessionReducer,
    data: dataReducer,
    selectedItems: itemReducer,
    workspaces: workspacesReducer,
    workspaceItems: workspaceItemsReducer,
    linkedInItems: linkedInItemsReducer,
    ui: uiReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});


export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  undefined,
  Action
>;

export default store;