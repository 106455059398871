import React from 'react';
import reactCSS from 'reactcss';
import { ColorResult, SketchPicker } from 'react-color';

export interface Color {
  r: number;
  g: number;
  b: number;
  a: number;
}

export function hexToRgbA(hex: string): Color {
  let c: any;
  if (/^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3 || c.length === 4) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2], c[3], c[3]];
    }
    if (c.length === 6) {
      c.push('F', 'F');
    }
    c = '0x' + c.join('');
    return {
      r: (c >> 24) & 255,
      g: (c >> 16) & 255,
      b: (c >> 8) & 255,
      a: +(c & 255) / 255
    };
  }
  throw new Error('Bad Hex');
}

export function rgbAToHex(color: Color): string {
  const { r, g, b, a } = color;
  const toHex = (n: number) => n.toString(16).padStart(2, '0');
  const alpha = Math.round(a * 255);
  return `#${toHex(r)}${toHex(g)}${toHex(b)}${toHex(alpha)}`;
}

interface ColorPickButtonProps {
  color: Color;
  onChange: (color: ColorResult) => void;
}

const ColorPickButton: React.FC<ColorPickButtonProps> = ({ color, onChange }) => {
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const styles = reactCSS({
    'default': {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute' as 'absolute',
        zIndex: 2 as 2,
      },
      cover: {
        position: 'fixed' as 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  return (
    <div>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={color} onChange={onChange} />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPickButton;
