import {appSession} from "../managers/generalManager";
import axios, {AxiosResponse} from "axios";
import config from "../config.json";
import logger from "../utils/logging_services";


const service_url = config.mode === 'dev' ? config.server_url_dev : config.server_url_prod;

export interface RequestStateOut {
    uuid: string; // The uuid of the request.
    action: string; // The action of the request.
    action_name: string; // The action name of the request.
    input_data: string; // The input app_theme of the request.
    input_type: string; // The input type of the request.
    output_data: string; // The output app_theme of the request.
    output_type: string; // The output type of the request.
    meta_data: Record<string, any>; // The metadata of the request. Using Record type for a dictionary object.
    status: string; // The status of the request.
    log_data: string; // The log app_theme of the request.
    created_at: Date; // The creation time of the request. Using Date type for datetime.
    updated_at: Date; // The last update time of the request. Using Date type for datetime.
    cost: Record<string, any>; // The cost of the request. Using Record type for a dictionary object.
}

export function get_action_state_list(request_id: string, from_date: string, to_date: string): Promise<RequestStateOut[]> {
    const headers = {Authorization: `Bearer ${appSession.accessToken}`, Accept: 'application/json'};
    let url = `${service_url}/modules/zaia_services_state/project/${appSession.currentProject}/state/${request_id}`;

    if (from_date !== '' && to_date !== '') {
        url += `?from_date=${from_date}&to_date=${to_date}`;
    }

    return axios.get(url, {headers})
        .then(response => {
            // Assuming the response app_theme is of type RequestStateOut[]
            return response.data as RequestStateOut[];
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error('Get Action State List Service Request Error:', error);
            let errorMessage = "Unknown error occurred.";
            if (error.response) {
                const {status, statusText} = error.response;
                errorMessage = `Request failed with status ${status} (${statusText})`;
            } else if (error.request) {
                errorMessage = `No response was received: ${error.message}`;
            } else {
                errorMessage = error.message;
            }
            // Throw an error or return a rejected promise
            return Promise.reject(new Error(errorMessage));
        });
}


// new service to obtain the dates as a list of distict values. Url = project/f7b689e5-bbc0-4c84-be2a-7c78a88ba1f4/request/dates
export function get_action_request_dates(): Promise<string[]> {
    const headers = {Authorization: `Bearer ${appSession.accessToken}`, Accept: 'application/json'};
    let url = `${service_url}/modules/zaia_services_state/project/${appSession.currentProject}/request/dates`;

    return axios.get(url, {headers})
        .then(response => {
            // Assuming the response app_theme is of type string[]
            return response.data as string[];
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error('Get Action Request Dates Service Request Error:', error);
            let errorMessage = "Unknown error occurred.";
            if (error.response) {
                const {status, statusText} = error.response;
                errorMessage = `Request failed with status ${status} (${statusText})`;
            } else if (error.request) {
                errorMessage = `No response was received: ${error.message}`;
            } else {
                errorMessage = error.message;
            }
            // Throw an error or return a rejected promise
            return Promise.reject(new Error(errorMessage));
        });
}


export function delete_action_request(request_id: string): Promise<{ message: string }> {
    const headers = {Authorization: `Bearer ${appSession.accessToken}`, Accept: 'application/json'};
    let url = `${service_url}/modules/zaia_services_state/project/${appSession.currentProject}/request/${request_id}`;

    return axios.delete(url, {headers})
        .then(response => {
            // Assuming the response is an object with a message property
            return response.data as { message: string };
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error('Delete Action Request Service Request Error:', error);
            let errorMessage = "Unknown error occurred.";
            if (error.response) {
                const {status, statusText} = error.response;
                errorMessage = `Request failed with status ${status} (${statusText})`;
            } else if (error.request) {
                errorMessage = `No response was received: ${error.message}`;
            } else {
                errorMessage = error.message;
            }
            // Throw an error or return a rejected promise
            return Promise.reject(new Error(errorMessage));
        });
}

function doubleEncodeURIComponent(str: string): string {
    return encodeURIComponent(encodeURIComponent(str));
}

export function fetch_document(containerId: string, documentId: string, headerOnly: boolean): Promise<{ data: any }> {
    const headers = {Authorization: `Bearer ${appSession.accessToken}`, Accept: 'application/json'};

    logger.info("Fetch Document Reqeust Response:", containerId, documentId, service_url, appSession.currentProject)
    let url = `${service_url}/modules/zaia_services_state/project/${appSession.currentProject}/container/${containerId}/document/${doubleEncodeURIComponent(documentId)}`;

    url += headerOnly ? '?get_header_only=true' : '';

    return axios.get(url, {headers})
        .then(response => {
            // Assuming the response returns the document app_theme
            return response;
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error('Fetch Document Service Request Error:', error);
            let errorMessage = "Unknown error occurred.";
            if (error.response) {
                const {status, statusText} = error.response;
                errorMessage = `Request failed with status ${status} (${statusText})`;
            } else if (error.request) {
                errorMessage = `No response was received: ${error.message}`;
            } else {
                errorMessage = error.message;
            }
            // Throw an error or return a rejected promise
            return Promise.reject(new Error(errorMessage));
        });
}


export function fetch_document_as_blob(containerId: string, documentId: string): Promise<Blob> {

    const headers = {
        Authorization: `Bearer ${appSession.accessToken}`,
        Accept: 'application/json'
    };

    let url = `${service_url}/modules/zaia_services_state/project/${appSession.currentProject}/container/${containerId}/document/${doubleEncodeURIComponent(documentId)}`;

    return axios.get(url, {headers, responseType: 'blob'})  // Set responseType to 'blob' to handle binary app_theme
        .then(response => {
            // Check the content type from headers to handle app_theme accordingly
            const contentType = response.headers['content-type'];
            return response.data;
            // if (contentType.startsWith('image/')) {
            //     return response.app_theme; // Return blob if image
            // } else {
            //     // Handle other file types or throw error
            //     throw new Error('Document is not an image');
            // }
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error('Fetch Document as Blob Service Request Error:', error);
            let errorMessage = "Unknown error occurred.";
            if (error.response) {
                const {status, statusText} = error.response;
                errorMessage = `Request failed with status ${status} (${statusText})`;
            } else if (error.request) {
                errorMessage = `No response was received: ${error.message}`;
            } else {
                errorMessage = error.message;
            }
            // Throw an error or return a rejected promise
            return Promise.reject(new Error(errorMessage));
        });
}

export function update_document(containerId: string, documentId: string, dataStream: string): Promise<{ data: any }> {
    const headers = {
        Authorization: `Bearer ${appSession.accessToken}`,
        Accept: 'application/json'
    };

    console.log("Update Document Request Response:", containerId, documentId, service_url, appSession.currentProject);
    logger.info("Update Document Request Response:", containerId, documentId, service_url, appSession.currentProject);

    // Double encode the documentId
    const encodedDocumentId = encodeURIComponent(encodeURIComponent(documentId));
    const url = `${service_url}/modules/zaia_services_state/${appSession.currentProject}/container/${containerId}/document/${encodedDocumentId}/update`;

    // Determine the MIME type based on the document_name suffix
    const getMimeType = (fileName: string): string => {
        const extension = fileName.split('.').pop()?.toLowerCase();
        switch (extension) {
            case 'csv':
                return 'text/csv';
            case 'json':
                return 'application/json';
            case 'xml':
                return 'application/xml';
            default:
                return 'application/octet-stream'; // default for binary files
        }
    };

    // Get the MIME type for the file
    const mimeType = getMimeType(documentId);
    // Create a File object from the dataStream
    const file = new File([dataStream], documentId, {type: mimeType});

    // Create a FormData object to hold the file app_theme
    const formData = new FormData();
    formData.append('file', file);

    // Log the final URL and headers for debugging
    logger.info(`Request URL: ${url}`);
    logger.info(`Headers: ${JSON.stringify(headers)}`);
    logger.info(`MIME Type: ${mimeType}`);

    return axios.post(url, formData, {headers})
        .then((response: AxiosResponse) => {
            // Assuming the response returns the updated document app_theme
            return response.data;
        })
        .catch(error => {
            if (error instanceof Error) {
                logger.error('Update Document Service Request Error:', error);
            }
            let errorMessage = "Unknown error occurred.";
            if (error.response) {
                const {status, statusText} = error.response;
                errorMessage = `Request failed with status ${status} (${statusText})`;
            } else if (error.request) {
                errorMessage = `No response was received: ${error.message}`;
            } else {
                errorMessage = error.message;
            }
            // Throw an error or return a rejected promise
            return Promise.reject(new Error(errorMessage));
        }).finally(
            () => {

                // cleanup temp resources
                file.arrayBuffer().then((buffer) => {
                    window.URL.revokeObjectURL(URL.createObjectURL(new Blob([buffer])));
                });

            }
        );
}




