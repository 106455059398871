import {pdfjs} from 'react-pdf';
import {appSettings, llmModelProvider} from "../../managers/generalManager";
import {runLLMRequest} from "../../backend/extdata.services";
import logger from "../../utils/logging_services";
import {removeHtmlWhitespace} from "../chat/chat_helpers/chat_helpers";

// Ensure the worker is set up
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const getTextFromPage = async (pdfUrl: string, pageNum: number): Promise<string> => {
    const pdf = await pdfjs.getDocument(pdfUrl).promise;
    const page = await pdf.getPage(pageNum);
    const textContent = await page.getTextContent();
    return textContent.items.map((item: any) => item.str).join(' ').trim();
};

export const getSelectedPagesText = async (pdfUrl: string, selectedPages: number[], fileName: string): Promise<string> => {
    const sortedSelectedPages = [...selectedPages].sort((a, b) => a - b);
    const texts = await Promise.all(sortedSelectedPages.map(async (page) => {
        const pageText = await getTextFromPage(pdfUrl, page);
        return `\n\n<h5>Page ${page} - ${fileName}</h5>\n<p>${pageText}</p>`;
    }));
    return texts.join('');
};


export function runZAIARequest(
    project_id: string,
    system_prompt: string,
    user_prompt: string,
    context_data: string,
    url: string = ""
): Promise<{ actionResult: string; internalResult: string }> {
    return new Promise((resolve, reject) => {
        let llmProvider = 'openai';
        let llmVersion = 'standard';
        let selectedLanguage = appSettings.selectedLanguage;

        let isFirstDataReceived = true;
        let wordReceived = 0;
        let isInternal = false;
        let internalResult = "";
        let isIgnore = false;
        let ignoreDataResult = "";
        let actionResult = "";

        const onDataReceived = (word: string) => {
            if (isFirstDataReceived) {
                wordReceived = 0;
                appSettings.isStreaming = true;
                appSettings.quitStreaming = false;
                isFirstDataReceived = false;
            } else {
                wordReceived = wordReceived + 1;
            }

            if (isInternal) {
                internalResult = internalResult + word;
                return;
            }

            if (isIgnore) {
                ignoreDataResult = ignoreDataResult + word;
                if (ignoreDataResult.includes("</analysis>")) {
                    logger.info("Ignore data result", ignoreDataResult);
                    isIgnore = false;
                }
                return;
            }

            actionResult = actionResult + word;
            actionResult = removeHtmlWhitespace(actionResult);

            if (actionResult.includes("@@INTERNAL@@")) {
                isInternal = true;
                internalResult = actionResult.substring(actionResult.indexOf("@@INTERNAL@@") + 12);
                actionResult = actionResult.substring(0, actionResult.indexOf("@@INTERNAL@@"));
            }

            if (actionResult.includes("<analysis>")) {
                isIgnore = true;
                ignoreDataResult = actionResult.substring(actionResult.indexOf("<analysis>") + 10);
                actionResult = actionResult.substring(0, actionResult.indexOf("<analysis>"));
            }

            // Remove the HTML intro tags and other unexpected characters from the actionResult
            if (actionResult.startsWith("```")) {
                actionResult = actionResult.substring(3);
            }
            if (actionResult.startsWith("html")) {
                actionResult = actionResult.substring(4);
            }
            if (actionResult.endsWith("```")) {
                actionResult = actionResult.substring(0, actionResult.length - 3);
            }
        };

        runLLMRequest(
            project_id,
            system_prompt,
            user_prompt,
            "",
            context_data,
            "",
            url,
            appSettings.temperature,
            selectedLanguage,
            llmProvider,
            llmVersion,
            onDataReceived
        ).then(
            () => {
                logger.info("actionResultString", actionResult);
                logger.info("internalResultString", internalResult);

                if (internalResult !== "") {
                    internalResult = internalResult.replace("@@INTERNAL@@", "").trim();
                    const questions = internalResult.split("||").map((item) => item.replace(/"/g, "").trim());
                    // Note: We're not clearing internalResult here as we want to return it
                    // internalResult = "";
                    isInternal = false;
                    logger.info("Extracted questions", questions);
                }
                resolve({ actionResult, internalResult });
            },
            (error) => {
                reject(error);
            }
        );
    });
}

export const convertTextToStenoPrompt = 'You are tasked with extracting and condensing the essential information from a given text using stenographic techniques. ' +
    'Your goal is to capture the core meaning and important details while significantly reducing the overall length of the text.\n' +
    'Follow these guidelines when extracting information:\n' +
    '1. Identify the main topic or theme\n' +
    '2. Capture key facts, figures, and dates\n' +
    '3. Retain important names and locations\n' +
    '4. Preserve crucial quotes or statements\n' +
    '5. Maintain the logical flow of ideas\n' +
    'Use the following stenographic techniques to reduce text length:\n' +
    '1. Abbreviate common words (e.g., "w/" for "with", "govt" for "government")\n' +
    '2. Use acronyms for frequently mentioned terms (define on first use)\n' +
    '3. Omit articles (a, an, the) when meaning remains clear\n' +
    '4. Replace phrases with symbols (e.g., "&" for "and", "+" for "plus")\n' +
    '5. Use numerals instead of spelling out numbers\n' +
    '6. Employ shorthand for common word endings (e.g., "-ing" as "g", "-tion" as "tn")\n' +
    'Provide your condensed version of the text within <condensed_text> tags. Strive to reduce the length by at least 50% while retaining the essential meaning and important details.\n' +
    'Remember to balance conciseness with clarity. The condensed text should still be understandable to someone familiar with stenographic techniques.\n' +
    'Directly start with the response without introduction and other indications. '

