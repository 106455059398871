import React, {useEffect, useState} from "react";
import {
    Button,
    IconButton,
    Menu,
    MenuItem, Tab, Tabs,
    Typography
} from "@mui/material";
import AppDialog from "../general/app_dialog";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {useDispatch, useSelector} from "react-redux";
import {ContentTypeDialog, WorkspaceDialog} from "./workspace_dialog";
import {TabPanel} from "../general/TabelPanel.snippets";
import {WorkspaceList} from "./components/workspace_list";
import logger from "../../utils/logging_services";
import colorSchema from "../../app_theme/theme_support/colorSchema";
import {AppDispatch, RootState} from "../../store/store";
import {FolderDef, Workspace} from "../../store/types";
import {deleteWorkspaceItemService, fetchWorkspaces, updateWorkspaces} from "../../store/thunks";
import {setWorkspaces} from "../../store/workspacesSlice";
import {setSelectedWorkspace} from "../../store/dataSlice";

interface WorkspaceManagerProps {
    onClose: () => void;
}

export const WorkspaceManager = ({onClose}: WorkspaceManagerProps) => {
    const [selectedWorkspaceIndex, setSelectedWorkspaceIndex] = useState<number>(-1);
    const [mode, setMode] = useState<string>('');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const [workspaceToAlter, setWorkspaceToAlter] = useState<Workspace | null>(null);
    const [contentTypeToAlter, setContentTypeToAlter] = useState<FolderDef | null>(null);
    const [contentTypeToDelete, setContentTypeToDelete] = useState<FolderDef | null>(null);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [workspacesWithUserScope, setWorkspacesWithUserScope] = useState<Workspace[]>([]);
    const [workspacesWithPublicScope, SetWorkspacesWithPublicScope] = useState<Workspace[]>([]);
    const [tabIndex, setTabIndex] = useState(0);

    const dispatch = useDispatch<AppDispatch>();
    const workspaces = useSelector((state: RootState) => state.workspaces.workspaces || []);
    const selectedWorkspace = useSelector((state: RootState) => state.data.selectedWorkspace);
    const workspaceItems = useSelector((state: RootState) => state.workspaceItems.items);
    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {
        logger.info('Fetching workspaces')
        dispatch(fetchWorkspaces({user_scope: true}));
        setIsLoaded(true);
    }, [dispatch]);

    useEffect(() => {
        logger.info('Split Workspaces:', workspaces)
        setWorkspacesWithUserScope(workspaces.filter(workspace => workspace.user_scope));
        SetWorkspacesWithPublicScope(workspaces.filter(workspace => !workspace.user_scope));
    }, [workspaces]);

    const handleWorkspaceSubmit = (mode: string, workspace: Workspace) => {
        if (workspace) {

            logger.info('Saving workspace:', workspace.name)
            workspace.user_scope = tabIndex === 0;

            const updatedWorkspaces = mode === 'edit'
                ? workspaces.map(p => (p.name === workspace.name ? workspace : p))
                : [...workspaces, workspace];
            dispatch(setWorkspaces(updatedWorkspaces));
        }
        resetAlterState();
    };

    const handleContentTypeSubmit = (mode: string, contentType: FolderDef) => {

        if (!workspaceToAlter || !contentType) {
            console.error('Error: workspaceToAlter or contentType is null');
            return;
        }

        const updatedWorkspace = {
            ...workspaceToAlter,
            folder_list: mode === 'edit'
                ? workspaceToAlter.folder_list.map(ct => (ct.name === contentType.name ? contentType : ct))
                : [...workspaceToAlter.folder_list, contentType]
        };

        const updatedWorkspaces = workspaces.map(p => (p.name === updatedWorkspace.name ? updatedWorkspace : p));
        dispatch(setWorkspaces(updatedWorkspaces));
        resetAlterState();
    };

    const handleOpenWorkspaceDialog = (workspace?: Workspace) => {
        setWorkspaceToAlter(workspace || {name: '', description: '', folder_list: [], user_scope: true});
        setMode(workspace ? 'edit' : 'create');
    };

    const handleOpenContentTypeDialog = (workspace: Workspace, contentType?: FolderDef) => {
        setWorkspaceToAlter(workspace);
        setContentTypeToAlter(contentType || {name: '', description: ''});
        setMode(contentType ? 'edit' : 'create');
    };

    const handleSaveUpdates = () => {
        // save workspaces to server
        dispatch(updateWorkspaces());
        onClose();
    }

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, key: number) => {
        setAnchorEl(event.currentTarget);
        setSelectedWorkspaceIndex(key);
        setMenuOpen(true);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const handleRequestWrapper = (action: string, key: number) => {
        handleMenuClose();
        if (selectedWorkspaceIndex !== -1) {

            let workspace: Workspace;
            if (tabIndex === 0) {
                workspace = workspacesWithUserScope[key];
            } else {
                workspace = workspacesWithPublicScope[key];
            }

            if (action === 'delete') {
                setWorkspaceToAlter(workspace);
                setMode('delete');
            } else if (action === 'add type') {
                handleOpenContentTypeDialog(workspace);
            } else if (action === 'edit') {
                handleOpenWorkspaceDialog(workspace);
            }
        }
    };

    const ConfirmDeleteDialog: React.FC<{
        type: string;
        name: string;
        description: string;
        onClose: () => void;
        onConfirm: () => void;
    }> = ({type, name, description, onClose, onConfirm}) => (
        <AppDialog
            open={true}
            size={"sm"}
            showSubmit={true}
            showCancel={true}
            title={`Delete ${name}`}
            message={"Are you sure you want to delete the selected object?"}
            onSubmit={onConfirm}
            onCancel={onClose}
        >
            <div style={{textAlign: 'center'}}>
                <Typography variant="h6" sx={{marginTop: '10px', marginLeft: '10px'}}>
                    You're about to delete the {type} <b>{name}</b>.
                </Typography>
                <Typography variant="body1" sx={{marginTop: '10px', marginLeft: '10px'}}>
                    Please be aware that this action is permanent and cannot be undone.
                </Typography>
                <Typography variant="body1" sx={{marginTop: '10px', marginLeft: '10px'}}>
                    Deleting the {type} will permanently remove all data contained within it.
                </Typography>
                <Typography variant="body1" sx={{marginTop: '10px', marginLeft: '10px'}}>
                    To confirm, click Confirm.
                </Typography>
            </div>
        </AppDialog>
    );

    const ShowErrorDialog: React.FC<{
        error: string;
        onClose: () => void;
    }> = ({error, onClose}) => (
        <AppDialog
            open={true}
            size={"sm"}
            showSubmit={false}
            showCancel={true}
            title={`Error`}
            message={"The request you've triggered is not allowed"}
            onCancel={onClose}
            onSubmit={()=>{}}
        >
            <div style={{textAlign: 'center'}}>
                <Typography variant="h6" sx={{marginTop: '10px', marginLeft: '10px'}}>
                    {error}
                </Typography>
            </div>
        </AppDialog>
    );



    const uiAddMenu = (index: number) => (
        <>
            <IconButton
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setSelectedWorkspaceIndex(index);
                    handleMenuOpen(event, index);
                }}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={menuOpen && index === selectedWorkspaceIndex}
                onClose={handleMenuClose}
                sx={{zIndex: 9999}}
            >
                <MenuItem
                    key={`project-edit-${index}`}
                    onClick={(event) => {
                        event.stopPropagation();
                        handleRequestWrapper('edit', index);
                    }}
                >
                    <EditIcon color="primary"/>Edit
                </MenuItem>
                <MenuItem
                    key={`project-addtype-${index}`}
                    onClick={(event) => {
                        event.stopPropagation();
                        handleRequestWrapper('add type', index);
                    }}
                >
                    <EditIcon color="primary"/>Add Workspace Folder
                </MenuItem>
                <MenuItem
                    key={`project-delete-${index}`}
                    onClick={(event) => {
                        event.stopPropagation();
                        handleRequestWrapper('delete', index);
                    }}
                >
                    <DeleteIcon color="primary"/>Delete
                </MenuItem>
            </Menu>
        </>
    );

    // Effectively delete the selected workspace. Also delete all snippets inside the workspace
    const onDeleteWorkspaceConfirmed = () => {
        if (workspaceToAlter) {
            logger.info('Deleting workspace:', workspaceToAlter.name)
            const snippetsToDelete = workspaceItems.filter(p => p.type === 'wi_' + workspaceToAlter.name);
            for (const snippet of snippetsToDelete) {
                dispatch(deleteWorkspaceItemService(snippet.uuid!));
            }

            const remainingWorkspaces = workspaces.filter(p => p.name !== workspaceToAlter.name);
            dispatch(setWorkspaces(remainingWorkspaces));
            resetAlterState();
            if (remainingWorkspaces.length > 0)
                dispatch(setSelectedWorkspace(remainingWorkspaces[0]));
            else
                dispatch(setSelectedWorkspace(undefined));

            // update workspace configuration state on server
            dispatch(updateWorkspaces())
        }

    };

    // Effectively delete the selected folder.
    const onDeleteFolderConfirmed = () => {

        console.log("vincent");
        if (workspaceToAlter && contentTypeToDelete) {

            const updatedWorkspace = {
                ...workspaceToAlter,
                folder_list: workspaceToAlter.folder_list.filter(ct => ct.name !== contentTypeToDelete.name)
            };

            const updatedWorkspaces = workspaces.map(p => (p.name === updatedWorkspace.name ? updatedWorkspace : p));

            // update workspace configuration state on server
            dispatch(setWorkspaces(updatedWorkspaces));
            resetAlterState();
        }
    };

    // Initialize the delete process for a workspace. Do not allow the user to delete a folder when there are still items inside. (just for testing, because you can delete a workspace with items )
    const initializeDeleteContentType = (workspace: Workspace, contentType: FolderDef) => {

        const snippetsToDelete = workspaceItems.filter(p => p.type === 'wi_' + workspace.name && p.subtype === contentType.name);
        if (snippetsToDelete.length === 0) {
            setWorkspaceToAlter(workspace);
            setContentTypeToDelete(contentType);
            setMode('delete');
        } else {
            setErrorMessage(`The folder "${contentType.name}" contains items. Please delete the items before deleting the folder.`);
        }
    };

    const handleWorkspaceSelected = (workspace: Workspace) => {
        dispatch(setSelectedWorkspace(workspace));
    };

    const resetAlterState = () => {
        setWorkspaceToAlter(null);
        setContentTypeToDelete(null);
        setContentTypeToAlter(null);
        setMode('');
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
    };

    if (!isLoaded) {
        return null;
    }

    return (
        <>
            <AppDialog
                open={true}
                size={"lg"}
                showSubmit={false}
                showCancel={true}
                title="Workspace Management"
                message=""
                onCancel={handleSaveUpdates}
                onSubmit={handleSaveUpdates}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    height: '600px',
                    justifyContent: 'start',
                }}>
                    <Tabs value={tabIndex} onChange={handleTabChange} aria-label="workspace tabs" variant={"fullWidth"}
                          sx={{
                              backgroundColor: colorSchema.background.default,
                              border: 1,
                              borderRadius: '6px',
                              // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                              '& .MuiTab-root': {
                                  textTransform: 'none',
                                  fontWeight: 'bold',
                                  fontSize: '16px',
                                  color: colorSchema.primary.main ,
                                  padding: '12px 16px',
                                  transition: 'color 0.3s ease, background-color 0.3s ease',
                                  '&:hover': {
                                      color: colorSchema.action.hover ,
                                      backgroundColor: colorSchema.background.default,
                                  },
                              },
                              '& .Mui-selected': {
                                  color: colorSchema.primary.main,
                                  backgroundColor: colorSchema.background.selected,
                              },
                              '& .MuiTabs-indicator': {
                                  display: 'none',
                              },
                          }}>
                        <Tab label="Personal"/>
                        <Tab label="Shared"/>
                    </Tabs>

                    <TabPanel value={tabIndex} index={0}>
                        <WorkspaceList
                            workspaces={workspacesWithUserScope}
                            selectedWorkspace={selectedWorkspace}
                            onWorkspaceSelect={handleWorkspaceSelected}
                            onOpenContentTypeDialog={handleOpenContentTypeDialog}
                            onInitializeDeleteContentType={initializeDeleteContentType}
                            onUiAddMenu={uiAddMenu}
                            colorSchema={colorSchema}
                        />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <WorkspaceList
                            workspaces={workspacesWithPublicScope}
                            selectedWorkspace={selectedWorkspace}
                            onWorkspaceSelect={handleWorkspaceSelected}
                            onOpenContentTypeDialog={handleOpenContentTypeDialog}
                            onInitializeDeleteContentType={initializeDeleteContentType}
                            onUiAddMenu={uiAddMenu}
                            colorSchema={colorSchema}
                        />
                    </TabPanel>
                </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>

                        <Button onClick={() => handleOpenWorkspaceDialog()}>Add Workspace</Button>
                    </div>
            </AppDialog>

            {workspaceToAlter && contentTypeToAlter === null && mode !== 'delete' && (
                <WorkspaceDialog
                    open={true}
                    existingNames={workspaces.map(p => p.name) || []}
                    workspace_original={workspaceToAlter}
                    mode={mode}
                    onClose={() => setWorkspaceToAlter(null)}
                    onSave={handleWorkspaceSubmit}
                />
            )}

            { (errorMessage !== "" ) && (
                <ShowErrorDialog error={errorMessage} onClose={() => setErrorMessage("")} />
            )}

            {workspaceToAlter && contentTypeToAlter === null && mode === 'delete' && (
                <ConfirmDeleteDialog
                    type="workspace"
                    name={workspaceToAlter.name}
                    description={workspaceToAlter.description}
                    onClose={resetAlterState}
                    onConfirm={onDeleteWorkspaceConfirmed}
                />
            )}
            {contentTypeToAlter && (
                <ContentTypeDialog
                    open={true}
                    existingNames={workspaceToAlter?.folder_list.map(ct => ct.name) || []}
                    mode={mode}
                    contentType={contentTypeToAlter}
                    onClose={resetAlterState}
                    onSave={handleContentTypeSubmit}
                />
            )}
            {contentTypeToDelete && (
                <ConfirmDeleteDialog
                    type="workspace folder"
                    name={contentTypeToDelete.name}
                    description={contentTypeToDelete.description}
                    onClose={resetAlterState}
                    onConfirm={onDeleteFolderConfirmed}
                />
            )}

        </>
    );
};


