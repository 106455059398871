import {DynamicFormBuilder} from "../../generators/formBuilder";
import React, {useEffect, useState} from "react";
import {replaceVariables} from "../../utils";
import {CbItem} from "../../backend/lexicalia_api";
import {
    appSettings, DefaultCategories,
    findItem, languageOptions, selectedLanguage,
} from "../../managers/generalManager";
import {extractTaskRequests} from "../../backend/executeTask";
import {Alert, Snackbar} from "@mui/material";
import {DBItem, getItemsByCategory} from "../../utils/IndexedDB.utils";
import logger from "../../utils/logging_services";
import {useDispatch, useSelector} from "react-redux";
// import {AppState} from "../../store/old/store";
import PleaseWaitDialog from "../general/please_wait";
import {zaiaPart} from "../../app/app-styling";
import {decodeHtmlEntities} from "../../utils/common_tools";
import {RootState, AppThunk, AppDispatch} from "../../store/store";
import {setSelectedTemplate} from "../../store/uiSlice";
import {Action, AnyAction} from "@reduxjs/toolkit";

interface AssistantTemplateProps {
    selected_menu: CbItem | undefined;
    selected_mode: string;
    selected_service_id: string;
    selected_goal_item?: DBItem;
    onHandleRequest: (request: string, template: string, persona: string) => void;
    onClose: (trigger: string) => void;
    onError: (error: string) => void;
}

function processTemplate(param: any[], request: string, b: boolean) {
    // log param, request and b
    logger.info("processTemplate", param, request, b);
}

export function AssistantTemplate({
                                      selected_menu,
                                      selected_mode,
                                      selected_service_id,
                                      selected_goal_item,
                                      onHandleRequest,
                                      onClose,
                                  }: AssistantTemplateProps) {
    const [selectedMenu,] = useState<CbItem | undefined>(selected_menu);
    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [selectedMode, setSelectedMode] = useState(selected_mode);

    const [selectedServiceId, setSelectedServiceId] = useState(selected_service_id);
    const [, setSelectedServiceTitle] = useState("");
    const [showPleaseWait, setShowPleaseWait] = useState(false);

    const [availableGoals, setAvailableGoals] = useState<DBItem[]>([]);
    const [availableTemplates, setAvailableTemplates] = useState<DBItem[]>([]);

    const dispatch = useDispatch<AppDispatch>();

    const selectedTemplate = useSelector((state: RootState) => state.ui.selectedTemplate);
    const selectedStore = useSelector((state: RootState) => state.data.activeStore);

    // const fileSelection = useFileSelection();
    // const itemSelection = useItemSelection();


    useEffect(() => {
        setSelectedServiceId(prevState => selected_service_id);
    }, [selected_service_id]);

    useEffect(() => {
        setSelectedMode(selected_mode);
    }, [selectedMode]);

    // -------------------------------------- SERVICE TEMPLATE -------------------------------------
    function extractItemsFromRunRequest(request: string): string[] {
        // Match the content inside the run request
        const match = request.match(/run=([^}]+)/);

        if (!match) {
            return []; // Return an empty array if no match is found
        }

        const runValue = match[1]; // Extract the value after 'run='
        const items = runValue.split('||'); // Split by '||' to get individual items

        return items.map(item => item.trim()); // Trim whitespace from each item
    }

    const handleFormSubmit = (formData: any): AppThunk => {
        return async (dispatch, getState) => {
            try {
                let template = "";
                if (selected_goal_item) {
                    const current_goals = await getItemsByCategory(DefaultCategories.GOALS, selectedStore);
                    let goals_to_run = extractItemsFromRunRequest(selected_goal_item.value);
                    if (goals_to_run.length === 0) {
                        logger.info("Single goal to run", goals_to_run);
                        await runGoal(selected_goal_item, template, formData, dispatch, getState);
                        return;
                    }

                    logger.info("Multiple Goals to run", goals_to_run);
                    for (const goal of goals_to_run) {
                        const goal_item_to_run = current_goals.find(item => item.name === goal);

                        if (goal_item_to_run) {
                            if (goal_item_to_run.template) {
                                const template_items = await getItemsByCategory(DefaultCategories.TEMPLATES, selectedStore);
                                const template_item = template_items.find(item => item.name === goal_item_to_run.template);
                                logger.info("Template item", template_item);
                                if (template_item) {
                                    template = template_item.value;
                                }
                            }

                            logger.info("Template to use", goal_item_to_run.template || "");
                            dispatch(setSelectedTemplate(goal_item_to_run.template || "") as unknown as Action);

                            // Wait for the state to update
                            await new Promise(resolve => setTimeout(resolve, 0));

                            await runGoal(goal_item_to_run, template, formData, dispatch, getState);

                            // Wait between runs
                            await new Promise(resolve => setTimeout(resolve, 1000));

                            // Wait for streaming to complete with a timeout
                            await waitForStreamingToComplete(30000); // 30 seconds timeout
                        } else {
                            logger.error("Goal item not found", goal);
                        }
                    }
                }
            } catch (error: any) {
                logger.error("Error in handleFormSubmit", error);
                // Handle error (e.g., show error message to user)
            }
        };
    };

    // Helper function to wait for streaming to complete
    const waitForStreamingToComplete = (timeout: number) => {
        return new Promise<void>((resolve, reject) => {
            const startTime = Date.now();
            const checkStreaming = () => {
                if (!appSettings.isStreaming) {
                    resolve();
                } else if (Date.now() - startTime > timeout) {
                    reject(new Error("Streaming timeout"));
                } else {
                    setTimeout(checkStreaming, 1000);
                }
            };
            checkStreaming();
        });
    };

    // Separate function for running a single goal
    const runGoal = async (
        goal_item: DBItem,
        template: string,
        formData: any,
        dispatch: AppDispatch,
        getState: () => RootState
    ) => {
        let request = "";
        const default_prompt = decodeHtmlEntities(goal_item.value);

        // Add application-specific variables to the form data
        const updatedFormData = {
            ...formData,
            target_language: languageOptions.find(option => option.value === selectedLanguage)?.label || "",
            output_format: 'html',
            zaia_template: getState().ui.selectedTemplate,
        };

        request = replaceVariables(default_prompt, updatedFormData);

        setShowPleaseWait(true);
        request = await extractTaskRequests(request);
        setShowPleaseWait(false);

        logger.info("Request", request);
        if (request !== "**STOP**") {
            processTemplate([], request, false);
            onHandleRequest(request, template, "");
        } else {
            setAlertMessage("Your task has been submitted. Please check the status of your task in the Task Manager.");
            setShowAlertMessage(true);
            handleFormClose("tasks");
        }
    };

    // const handleFormSubmit = async (formData: any) => {
    //
    //     async function runGoal(selected_goal_item: DBItem) {
    //         let request = "";
    //         let default_prompt = "";
    //
    //         if (selected_goal_item) {
    //             default_prompt = decodeHtmlEntities(selected_goal_item.value);
    //         } else {
    //             const service = selectedMenu ? findItem(selectedMenu.child_list, selectedServiceId) : null;
    //             if (service?.action_end_type === 'prompt') {
    //                 default_prompt = service.action_end
    //             } else {
    //                 return;
    //             }
    //         }
    //
    //         // Add application-specific variables to the form app_theme
    //         const selectedLanguageOption = languageOptions.find(option => option.value === selectedLanguage);
    //         formData.target_language = selectedLanguageOption?.label || "";
    //         formData.output_format = 'html';
    //         formData.zaia_template = selectedTemplate;
    //
    //         request = replaceVariables(default_prompt, formData);
    //
    //         setShowPleaseWait(true);
    //         request = await extractTaskRequests(request);
    //         setShowPleaseWait(false);
    //
    //         logger.info("Request", request);
    //         if (request !== "**STOP**") {
    //             processTemplate([], request, false);
    //             onHandleRequest(request);
    //         } else {
    //             setAlertMessage("Your task has been submitted. Please check the status of your task in the Task Manager.");
    //             setShowAlertMessage(true);
    //             handleFormClose("tasks");
    //         }
    //     }
    //
    //     if (selected_goal_item) {
    //         const current_goals = await getItemsByCategory(DefaultCategories.GOALS, selectedStore);
    //         const goals_to_run = extractItemsFromRunRequest(selected_goal_item.value);
    //         logger.info("Goals to run", goals_to_run);
    //
    //         if (goals_to_run.length > 0) {
    //             for (const goal of goals_to_run) {
    //                 const goal_item_to_run = current_goals.find(item => item.name === goal);
    //                 logger.info("Step", goal_item_to_run);
    //                 if (goal_item_to_run) {
    //
    //                     // change template to use based on the goal
    //                     const template_to_use = goal_item_to_run.template;
    //                     dispatch(setSelectedTemplate(template_to_use || ""));
    //
    //                     await runGoal(goal_item_to_run);
    //                     // Wait 10 seconds between each run
    //                     await new Promise(resolve => setTimeout(resolve, 10000));
    //
    //                     // wait till appsettings.isProcessing is false
    //                     while (appSettings.isStreaming) {
    //                         await new Promise(resolve => setTimeout(resolve, 1000));
    //                     }
    //
    //                 } else {
    //                     logger.error("Goal item not found", goal);
    //                 }
    //             }
    //         } else {
    //             await runGoal(selected_goal_item);
    //         }
    //     }
    // };

    const handleFormClose = (trigger: string) => {
        localStorage.removeItem('active_service');
        setSelectedServiceId("")
        setSelectedServiceTitle("");
        onClose(trigger);
    }

    // Create a wrapper function to handle form submission
    const createFormSubmitHandler = (dispatch: AppDispatch) => {
        return (formData: any) => {
            dispatch(handleFormSubmit(formData));
        };
    };
    const formSubmitHandler = createFormSubmitHandler(dispatch);

    return (
        <div style={zaiaPart({maxWidth: appSettings.maxWidth * 1.5})}>
            {showPleaseWait && (
                <PleaseWaitDialog open={showPleaseWait} onCancel={() => setShowPleaseWait(false)}
                                  title={"Calling ZAIA backend service"}
                                  message={"Please wait. I am gathering app_theme as context for the next step in your request"}/>
            )}
            {/*Show message*/}
            <Snackbar open={showAlertMessage} autoHideDuration={6000} onClose={() => setShowAlertMessage(false)}>
                <Alert onClose={() => setShowAlertMessage(false)} severity="success" sx={{width: '100%'}}>
                    {alertMessage}
                </Alert>
            </Snackbar>

            {(selectedServiceId !== "" || selected_goal_item) && (
                <DynamicFormBuilder
                    service_item={selected_goal_item}
                    service_id={selectedServiceId}
                    onSubmit={formSubmitHandler}
                    onClose={handleFormClose}
                />
            )}
        </div>
    )
}