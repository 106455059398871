import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box, ButtonGroup, Divider, Typography} from "@mui/material";
import Paper, {PaperProps} from "@mui/material/Paper";
import Draggable from "react-draggable";
import DOMPurify from 'dompurify';

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}


interface MessageDialogsProps {
    title: string;
    primary_text: string;
    secondary_text: string;
    dialogType: "yes_no" | "confirm" | "action";
    onClose: () => void;
    onButtonClicked: (button: string) => void;
}

export default function MessageDialog(props: MessageDialogsProps) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.onClose();
    };

    const createMarkup = (htmlContent: string) => {
        if (htmlContent === undefined || htmlContent === "" || htmlContent.trim() === '<p></p>') {
            return {__html: "<p>There is no help available for this topic.</p>"};
        } else {
            return {__html: DOMPurify.sanitize(htmlContent)};
        }
    };

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            sx={{
                '& .MuiDialog-paper': {minWidth: '800px'} // Set the minimum width of the dialog
            }}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle variant={"h5"} sx={{cursor: 'move', textTransform: 'capitalize'}}
                         alignSelf={"center"}
                         id="draggable-dialog-title">
                {props.title}
            </DialogTitle>

            <Divider/>
            <DialogContentText
                sx={{textAlign: 'justify'}}
                style={{
                    margin: 5,
                    padding: 5,
                    textAlign: "start",
                }}
                 dangerouslySetInnerHTML={createMarkup(props.primary_text)}/>

            <DialogContentText sx={{textAlign: 'justify'}}>
                <>
                    {props.secondary_text.trim() !== "" && props.secondary_text.split('\n').map((line, index) => (
                        <Box key={index}>
                            {line.trim() === "" && <Typography marginTop='5px'/>}
                            <Typography key={index}>{line}</Typography>
                        </Box>
                    ))}
                </>
            </DialogContentText>

            <DialogActions>
                {props.dialogType === "yes_no" && (
                    <ButtonGroup>
                        <Button variant={"contained"} color={"primary"} onClick={() => props.onButtonClicked("yes")}>Yes</Button>
                        <Button variant={"contained"} color={"secondary"} onClick={() => props.onButtonClicked("no")}>No</Button>
                    </ButtonGroup>
                )}
                {props.dialogType === "confirm" && (
                    <ButtonGroup>
                        <Button variant={"contained"} color={"primary"} onClick={() => props.onButtonClicked("close")}>Close</Button>
                    </ButtonGroup>
                )}
                {props.dialogType === "action" && (
                    <ButtonGroup>
                        <Button variant={"outlined"} color={"primary"} onClick={() => props.onButtonClicked("copy")}>Copy</Button>
                        <Button variant={"outlined"} color={"primary"} onClick={() => props.onButtonClicked("insert")}>Insert</Button>
                        <Button variant={"outlined"} color={"primary"} onClick={() => props.onButtonClicked("replace")}>Replace</Button>
                        <Button variant={"contained"} color={"warning"} onClick={() => props.onButtonClicked("close")}>Close</Button>
                    </ButtonGroup>
                )}
            </DialogActions>
        </Dialog>

    );
}