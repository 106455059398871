import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { WorkspaceViewer } from "../../components/workspace/workspace_viewer";
import { FileNavigator } from "../../components/files/file_navigator";
// import { WorkspaceItem } from "../../store/old/temp-slice";
import { KnowledgeBaseItem } from "../../backend/extdata.services";
import {appSettings} from "../../managers/generalManager";
import {WorkspaceItem} from "../../store/types";


interface FileWorkspaceManagerProps {
    showWorkspace: boolean;
    showFiles: boolean;
    onClose: () => void;
    onShowWorkSpaceItem: (workspaceItem: WorkspaceItem) => void;
    onFileSelected: (file: KnowledgeBaseItem | undefined) => void;
    onSendRequest: (request: any) => void;
    workspace: any;
    selectedCategory: string;
}

const FileWorkspaceManager = ({
  showWorkspace,
  showFiles,
  onClose,
  onShowWorkSpaceItem,
  onSendRequest,
  workspace,
  selectedCategory,
  onFileSelected
} : FileWorkspaceManagerProps) => {
  const [isFlipped, setIsFlipped] = useState(false);

  useEffect(() => {
    setIsFlipped(showFiles);
  }, [showFiles, showWorkspace]);

  return (
    <Box
      sx={{
        perspective: '1000px',
        width: appSettings.maxWidth,
        height: '100%',  // Set a fixed height or adjust as needed
        position: 'relative',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          transition: 'transform 0.6s',
          transformStyle: 'preserve-3d',
          transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backfaceVisibility: 'hidden',
            WebkitBackfaceVisibility: 'hidden',  // For Safari support
            // overflowY: 'auto',  // Add scrolling if content exceeds box size
          }}
        >
          <WorkspaceViewer
            open={showWorkspace && !showFiles}
            onClose={onClose}
            onShowWorkSpaceItem={onShowWorkSpaceItem}
            onSendRequest={onSendRequest}
            workspace={workspace}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            width: appSettings.maxWidth,
            height: '100%',
            backfaceVisibility: 'hidden',
            WebkitBackfaceVisibility: 'hidden',  // For Safari support
            transform: 'rotateY(180deg)',
            // overflowY: 'auto',  // Add scrolling if content exceeds box size
          }}
        >
          <FileNavigator
            selectedCategory={selectedCategory}
            onFileSelected={onFileSelected}
            onClose={onClose}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FileWorkspaceManager;