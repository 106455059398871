import {colorSchema} from "../app_theme/theme_support/colorSchema";
import React from "react";
import {appSettings} from "../managers/generalManager";

interface ZaiaPartProps {
    maxWidth?: number;
}

export const zaiaPart = ({ maxWidth = appSettings.maxWidth }: ZaiaPartProps): React.CSSProperties => ({
    display: 'flex',
    flex: '1',
    padding: '10px',
    maxWidth: `${maxWidth}px`,
    backgroundColor: colorSchema.background.default,
    marginLeft: '10px',
    flexDirection: 'column',
    boxShadow: 'rgba(9, 30, 66, 0.31) 0 0 1px 0, rgba(9, 30, 66, 0.25) 0 1px 1px 0',
    borderRadius: '10px',
    height: 'calc(100% - 0px)'
});
