import { useState, useEffect, useCallback } from 'react';
import {
    getOrganisations,
    getApplications,
    getProjects,
    getProjectByApplicationCode,
    Organisation,
} from "../backend/core.services";
import {
    loadSettings,
    removeSession,
    appSession,
    loadSession,
    saveSession,
} from "../managers/generalManager";
import logger from "./../utils/logging_services";
import { useDispatch } from "react-redux";
import {setSelectedFile} from "../store/dataSlice";
// import { setSelectedFile } from "../store/old/data-slice";

export const useAppInitialization = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const dispatch = useDispatch();

    const initializeApp = useCallback(async () => {
        try {
            loadSettings();
            const organisations = await getOrganisations();

            if (organisations.length === 0) {
                throw new Error("No organizations found");
            }

            const selectedOrganisation = organisations.find((org: Organisation) => org.is_default) || organisations[0];
            appSession.organisationName = selectedOrganisation.company_name;
            appSession.organisationUuid = selectedOrganisation.uuid;

            if ( appSession.currentProject !== "") {
                setIsLoaded(true);
                return;
            }

            const applications = await getApplications(selectedOrganisation.uuid);

            for (const application of applications) {
                switch (application.application_code) {
                    case 'ExtData':
                        const extDataProjects = await getProjects(selectedOrganisation.uuid, application.uuid);
                        if (extDataProjects.length > 0) {
                            appSession.currentKnowledgeBaseProject = extDataProjects[0].uuid;
                        }
                        break;
                    case 'Recruitment':
                        const hrProjects = await getProjectByApplicationCode("Recruitment");
                        if (hrProjects.length > 0) {
                            appSession.currentHrProject = hrProjects[0].uuid;
                        }
                        break;
                    case 'ChatBoostr':
                        const chatProjects = await getProjects(selectedOrganisation.uuid, application.uuid);
                        if (chatProjects.length > 0) {
                            appSession.currentProject = chatProjects[0].uuid;
                        }
                        break;
                }
            }

            saveSession();
            setIsLoaded(true);

        } catch (error: any) {
            logger.error("Error during app initialization:", error);
            removeSession();
            setShowLogin(true);
            throw error;
        }
    }, []);

    useEffect(() => {
        loadSession();

        if (!appSession.accessToken) {
            setShowLogin(true);
            return;
        }

        dispatch(setSelectedFile(undefined));
        initializeApp();
    }, [dispatch, initializeApp]);

    return {
        isLoaded,
        showLogin,
        projectId: appSession.currentProject,
        knowledgeBaseApplicationId: appSession.currentKnowledgeBaseProject
    };
};