import React, {useEffect, useState, useCallback, useMemo} from 'react';
import {
    Box, Button, Divider, FormControl, Grid, IconButton, MenuItem, Select, SelectChangeEvent,
    TextField, Typography, Tooltip, Paper, Chip,
} from '@mui/material';
import {styled} from '@mui/system';
import MicTwoToneIcon from '@mui/icons-material/MicTwoTone';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import CachedIcon from '@mui/icons-material/Cached';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import {useDispatch, useSelector} from 'react-redux';
import colorSchema from "../app_theme/theme_support/colorSchema";
import {
    createFormPage, FormField, FormFieldDivider, FormFieldSelect, FormFieldTextArea, FormFieldTypograph, FormPage,
} from "../managers";
import {findItem, selectedLanguage, appState, appSession} from "../managers/generalManager";
import {CbItem} from "../backend/lexicalia_api";
import SpeechToTextService from '../components/speech/speech-to-text.service';
import MessageDialog from "../components/general/messageDialog";
import logger from "../utils/logging_services";
import {DBItem} from "../utils/IndexedDB.utils";
import {removeHTMLTags} from "../utils/common_tools";
import ZAIATheme from "../app_theme/theme";
import {RootState} from "../store/store";
import {setFollowupQuestions} from "../store/uiSlice";
import {getSnippet} from "../backend/snippet.services";
import {processSnippetData} from "../components/workspace/workspace_services";

interface DynamicFormBuilderProps {
    service_id: string;
    service_item?: DBItem;
    onSubmit: (formData: any) => void;  // Change back to void
    onClose: (trigger: string) => void;
}

interface InputValues {
    [key: string]: string;
}

const StyledPaper = styled(Paper)(({theme}) => ({
    background_color: 'inherit',
    padding: theme.spacing(2),
    margin: '0 auto',
    // height: '80vp',
    display: 'flex',
    flex: '1 1 0px',
    overflowY: 'auto',
    width: '-webkit-fill-available',
    flexDirection: 'column',
}));

const StyledSelect = styled(Select)({
    '& .MuiSelect-select': {
        paddingRight: '32px',
    },
});

const FollowUpChip = styled(Chip)(({theme}) => ({
    margin: theme.spacing(0.5),
    maxWidth: '100%',
    height: 'auto',
    '& .MuiChip-label': {
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
    },
}));

export const DynamicFormBuilder: React.FC<DynamicFormBuilderProps> = ({
                                                                          service_id,
                                                                          service_item,
                                                                          onClose,
                                                                          onSubmit
                                                                      }) => {
    const [inputValues, setInputValues] = useState<InputValues>({});
    const [pageList, setPageList] = useState<FormPage[]>([]);
    const [showHelp, setShowHelp] = useState(false);
    const [activeField, setActiveField] = useState<string>("");
    const [speechMode, setSpeechMode] = useState<boolean>(false);
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [uuid, setUuid] = useState<string>("");

    const selectedFolder = useSelector((state: RootState) => state.data.selectedFolder);
    const followupQuestions = useSelector((state: RootState) => state.ui.followupQuestions);

    const dispatch = useDispatch();

    const initializeFormFields = useCallback(() => {
        const key = `formdata_${uuid}`;
        const storedInputValues = localStorage.getItem(key);
        if (storedInputValues && pageList.length > 0) {
            const loadedInputValues = JSON.parse(storedInputValues);
            setInputValues(prevValues => ({
                ...prevValues,
                ...loadedInputValues
            }));
        }
    }, [uuid, pageList]);

    useEffect(() => {
        const loadForm = async () => {
            if (!service_id) return;

            const selectedMenu = appState.getSelectedMenu();
            if (!selectedMenu) {
                logger.info("DynamicFormBuilder: selectedMenu is undefined");
                return;
            }

            const service = findItem(selectedMenu.child_list, service_id) as CbItem;
            if (!service) {
                logger.info("DynamicFormBuilder: service is undefined");
                return;
            }

            setTitle(service.title_text || "");
            setDescription(service.description_text || "");
            setUuid(service.uuid || "");

            const formDefinition = service.action_start;
            const page = await createFormPage(formDefinition, selectedFolder);
            setPageList([page]);
        };

        loadForm();
    }, [service_id, selectedFolder]);

    useEffect(() => {
        if (!service_item || !service_item.form) return;

        const loadServiceItem = async () => {
            setTitle(service_item.name || "");
            setDescription(service_item.description || "");
            setUuid(service_item.name || "");

            let service_template = removeHTMLTags(service_item.form || "");
            const page = await createFormPage(service_template, selectedFolder);
            dispatch(setFollowupQuestions([]))
            setPageList([page]);
        };

        loadServiceItem();
    }, [service_item, selectedFolder]);

    useEffect(() => {
        if (pageList.length === 0) return;

        const newInputValues: InputValues = {};
        pageList[0].field_list.forEach((field) => {
            logger.info("Initialize Form Field", field);
            if (field instanceof FormFieldSelect && field.values.length > 0) {
                const [id] = field.values[0].split('&');
                newInputValues[field.id] = id;
            } else if (field instanceof FormFieldTextArea) {
                newInputValues[field.id] = field.default_value;
            }
        });

        setInputValues(prevValues => ({
            ...prevValues,
            ...newInputValues
        }));
    }, [pageList]);

    const handleFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = event.target;
        setInputValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));
    }, []);


    const handleSelectChange = useCallback((event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
        const {name, value} = event.target;
        logger.info("handleSelectChange", name, value || "");

        if (value && value.toString().includes('=')) {
            const [type, properties] = value.toString().split('=');
            if (type === 'snippet') {
                const [id, snippetType] = properties.split('||');
                if (id !== '') {
                    getSnippet(id, appSession.currentProject, appSession.accessToken).then((snippet) => {

                        if (!snippet) return;

                        const snippetData = processSnippetData(snippet.content);
                        if (snippetData.success && snippetData.type === 'csv') {
                            logger.info("Snippet records", snippetData.data);
                            setInputValues(prevValues => ({
                                ...prevValues,
                                [name + '_records' as string]: JSON.stringify(snippetData.data),
                            }));
                            setInputValues(prevValues => ({
                                ...prevValues,
                                [name + '_data' as string]: "",
                            }));

                            // search select field
                            const selectField = memoizedPageList[0].field_list.find((field) => field.id === name + "_record");

                            if (selectField instanceof FormFieldSelect && snippetData.data) {
                                selectField.values = snippetData.data.map((record, index) => {
                                    return `${JSON.stringify(record)}&${record[Object.keys(record)[0]]}`;
                                });
                            }

                        } else if (snippetData.success && snippetData.type === 'html') {
                            logger.info("Snippet data", snippetData.data);
                            // remove html tags
                            setInputValues(prevValues => ({
                                ...prevValues,
                                [name + '_data' as string]: removeHTMLTags(snippet.content),
                            }));
                            setInputValues(prevValues => ({
                                ...prevValues,
                                [name + '_records' as string]: "",
                            }));

                            const selectField = memoizedPageList[0].field_list.find((field) => field.id === name + "_record");
                            if (selectField instanceof FormFieldSelect ) {
                                selectField.values = ['None'];
                            }
                        }
                    });
                }
            }
        }
        setInputValues(prevValues => ({
            ...prevValues,
            [name as string]: value as string,
        }));

    }, [pageList]);

    const handleSpeechButton = useCallback((id: string) => {
        setActiveField(id);
        setSpeechMode(prevMode => !prevMode);
    }, []);

    const handleCopyPasteSelectedQuestion = useCallback((question: string) => {
        setInputValues(prevValues => ({
            ...prevValues,
            [activeField]: question.toLowerCase(),
        }));
    }, [activeField]);

    const saveToLocalStorage = useCallback(() => {
        const key = `formdata_${uuid}`;
        localStorage.setItem(key, JSON.stringify(inputValues));
    }, [inputValues, uuid]);

    const handleSubmit = useCallback(() => {
        saveToLocalStorage();
        onSubmit(inputValues);
    }, [inputValues, onSubmit, saveToLocalStorage]);

    const renderField = useCallback((field: FormField, index: number) => {
        if (field instanceof FormFieldTypograph) {
            return (
                <Typography
                    variant={field.variant === 'header' ? 'h6' : 'body1'}
                    sx={{
                        marginTop: '2px',
                        marginBottom: '0px',
                        padding: '0px',
                        ...(field.variant === 'header' && {
                            backgroundColor: colorSchema.background.default,
                            border: `1px solid ${colorSchema.grey["400"]}`,
                            color: colorSchema.text.primary,
                            borderRadius: '5px',
                            padding: '5px',
                            textAlign: 'center',
                        }),
                        ...(field.variant === 'example' && {variant: 'body2', fontWeight: '300'}),
                        ...(field.variant === 'error' && {color: 'red'}),
                    }}
                >
                    {field.text}
                </Typography>
            );
        }

        if (field instanceof FormFieldTextArea) {
            return (
                <Tooltip title={field.tooltip} arrow>
                    <TextField
                        fullWidth
                        size="small"
                        color="primary"
                        key={field.id}
                        id={field.id}
                        name={field.id}
                        value={inputValues[field.id] || ''}
                        variant="outlined"
                        onChange={handleFieldChange}
                        onFocus={() => setActiveField(field.id)}
                        multiline={field.rows > 1}
                        rows={field.rows}
                        sx={{marginBottom: '8px'}}
                    />
                </Tooltip>
            );
        }

        if (field instanceof FormFieldSelect) {
            return (
                <Tooltip title={field.tooltip} arrow>
                    <FormControl fullWidth sx={{marginBottom: '8px'}}>
                        <StyledSelect
                            displayEmpty
                            size="small"
                            id={field.id}
                            name={field.id}
                            value={inputValues[field.id] || ''}
                            onChange={handleSelectChange}
                        >
                            {field.values.map((value) => {
                                const [actualValue, label] = value.includes('&') ? value.split('&') : [value, value];
                                return (
                                    <MenuItem key={actualValue} value={actualValue}>
                                        {label}
                                    </MenuItem>
                                );
                            })}
                        </StyledSelect>
                    </FormControl>
                </Tooltip>
            );
        }

        if (field instanceof FormFieldDivider) {
            return <Divider sx={{my: 1}}/>;
        }

        return null;
    }, [inputValues, speechMode, activeField, handleFieldChange, handleSelectChange, handleSpeechButton]);

    const memoizedPageList = useMemo(() => pageList, [pageList]);

    return (
        <StyledPaper elevation={0} theme={ZAIATheme}>
            {speechMode && (
                <SpeechToTextService
                    language={selectedLanguage}
                    isActive={speechMode}
                    onTranscriptChange={(transcript: string) => {
                        setInputValues(prevValues => ({
                            ...prevValues,
                            [activeField]: transcript,
                        }));
                    }}
                />
            )}

            {showHelp && (
                <MessageDialog
                    title={`HELP - ${title}`}
                    primary_text={description}
                    secondary_text=""
                    dialogType="confirm"
                    onButtonClicked={() => setShowHelp(false)}
                    onClose={() => setShowHelp(false)}
                />
            )}

            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2}}>
                <Typography variant="h5" sx={{color: colorSchema.text.primary}}>{title}</Typography>
                <Box>
                    <IconButton onClick={() => setShowHelp(true)} aria-label="help">
                        <HelpOutlineIcon sx={{color: colorSchema.primary.main}}/>
                    </IconButton>
                    <IconButton onClick={() => onClose("close_button")} aria-label="close">
                        <CloseIcon sx={{color: colorSchema.primary.main}}/>
                    </IconButton>
                </Box>
            </Box>

            <Divider sx={{borderColor: colorSchema.grey["400"], mb: 2}}/>

            <Box sx={{flexGrow: 1, overflowY: 'auto', pr: 1}}>
                <Grid container spacing={1}>
                    {memoizedPageList[0]?.field_list.map((field, index) => (
                        <Grid item xs={12} key={`field-${index}`}>
                            {renderField(field, index)}
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {followupQuestions.length > 0 && (
                <Box sx={{mt: 2, mb: 1}}>
                    <Typography variant="body1" align="center" sx={{fontWeight: 'bold', mb: 1}}>
                        Follow-up Questions
                    </Typography>
                    <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                        {followupQuestions.map((question, index) => (
                            <>
                                {question !== "" && (
                                    <FollowUpChip
                                        key={`question-${index}`}
                                        label={question.toLowerCase()}
                                        onClick={() => handleCopyPasteSelectedQuestion(question)}
                                        color="primary"
                                        variant="outlined"
                                        icon={<QuestionAnswerIcon/>}
                                    />
                                )}
                            </>
                        ))}
                    </Box>
                </Box>
            )}

            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2}}>
                <Tooltip title="Load Previous Values" arrow>
                    <IconButton size="small" onClick={initializeFormFields}>
                        <CachedIcon sx={{color: colorSchema.primary.main}}/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Active Speech Input" arrow>
                    <IconButton size="small" onClick={() => handleSpeechButton(activeField)}>
                        <MicTwoToneIcon color={speechMode ? "warning" : "primary"}/>
                    </IconButton>
                </Tooltip>
                <Button
                    onClick={handleSubmit}
                    color="success"
                    variant="contained"
                    sx={{
                        fontWeight: 'bold',
                        boxShadow: '0 3px 5px 2px rgba(76, 175, 80, .3)',
                        '&:hover': {
                            boxShadow: '0 6px 10px 4px rgba(76, 175, 80, .3)',
                        }
                    }}
                >
                    Submit
                </Button>
            </Box>
        </StyledPaper>
    );
};