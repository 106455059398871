import React, {useState} from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Box,
    Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import JsonViewer from "../viewers/json_viewer";
import colorSchema from "../../app_theme/theme_support/colorSchema";

interface InfoAccordionProps {
    infoText: string;
    infoTextJson: string;
    useDataAsContext: boolean;
    onSelectData: () => void;
    onFlipCard: () => void;
    selectState: boolean;
    fileName: string;
}

const InfoAccordion: React.FC<InfoAccordionProps> = ({
                                                         infoText,
                                                         infoTextJson,
                                                         useDataAsContext,
                                                         onSelectData,
                                                         onFlipCard,
                                                         selectState,
                                                         fileName
                                                     }) => {
    const [expanded, setExpanded] = useState<'description' | 'json' | false>('description');

    const handleChange = (panel: 'description' | 'json') => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const formatInfoText = (text: string) => {
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br/>
            </React.Fragment>
        ));
    };

    return (
        <>
            <Box sx={{
                border: selectState ? '2px solid red' : 'none',
                borderRadius: '4px',
                padding: selectState ? '16px' : '0',
                position: 'relative'
            }}>
                {selectState && (
                    <Typography
                        variant="caption"
                        sx={{
                            position: 'absolute',
                            top: '-10px',
                            left: '10px',
                            backgroundColor: 'white',
                            padding: '0 5px',
                            color: 'red'
                        }}
                    >
                        Selected as Context Data
                    </Typography>
                )}

                <Typography variant="h6" sx={{color: colorSchema.primary.main, mb: 2}}>{fileName}</Typography>

                <Accordion expanded={expanded === 'description'} onChange={handleChange('description')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography>Description</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{
                            mb: 2,
                            overflow: 'auto',
                            maxHeight: '400px',
                            whiteSpace: 'pre-wrap',
                            // fontFamily: 'monospace',
                        }}>
                            {formatInfoText(infoText)}
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'json'} onChange={handleChange('json')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography>JSON Data</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{mb: 2, overflow: 'auto', maxHeight: '300px'}}>
                            <JsonViewer jsonString={infoTextJson}/>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 2, mt: 2}}>
                <Button
                    variant="contained"
                    onClick={onSelectData}
                    sx={{
                        backgroundColor: useDataAsContext ? colorSchema.warning.main : colorSchema.primary.main,
                    }}
                >
                    {useDataAsContext ? 'Reset Context' : 'Select as Context'}
                </Button>
                <Button variant="contained" onClick={onFlipCard}>
                    Show Image
                </Button>
            </Box>
        </>
    );
};

export default InfoAccordion;