import axios from 'axios';

import config from '../config.json';
import logger from "../utils/logging_services";
import {appSession} from "../managers/generalManager";
const service_url = config.mode === 'dev' ? config.server_url_dev : config.server_url_prod;

export type Organisation = {
    uuid: string;
    company_name: string;
    vat_number: string;
    address: string;
    zip_code: string;
    location: string;
    country: string;
    is_default: boolean;
    description: string;
}

export type Application = {
    uuid: string;
    application_name: string;
    application_domain: string;
    application_code: string;
    description: string;
}

export type Project = {
    uuid: string;
    project_name: string;
    description: string;
    props: string;
}

export type Feedback = {
    reference: string;
    context: string;
    context_data: string;
    meta_application: string;
    meta_version: string;
    meta_other: string;
}

export function getOrganisations(): Promise<Organisation[]> {
    const accessToken = getAccessToken();

    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    const url = service_url + `/main/organisation/all`;

    return axios.get(url, {headers})
        .then(response => {
            logger.info("Organizations loaded: ", response.data);
            return response.data as Organisation[];
        })
        .catch(error => {
            logger.error("Get Organisation Request Failed", error);
            return [] as Organisation[];
        });
}

export function getProjects(organisationId?: string, applicationId?: string): Promise<Project[]> {
    const accessToken = getAccessToken();
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    let url = service_url + `/main/organisation/${organisationId}/application/${applicationId}/project/all`;

    return axios.get(url, {headers})
        .then(response => {
            logger.info("Projects loaded: ", response.data);
            return response.data as Project[];
        })
        .catch(error => {
            logger.error("GetProject Request Failed", error);
            return [] as Project[];
        });
}

export function getProjectByApplicationCode(applicationCode?: string): Promise<Project[]> {
    const accessToken = getAccessToken();
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    let url = service_url + `/main/application/${applicationCode}/projects`;

    return axios.get(url, {headers})
        .then(response => {
            logger.info("Projects loaded: ", response.data);
            return response.data as Project[];
        })
        .catch(error => {
            logger.error("GetProject Request Failed", error);
            return [] as Project[];
        });
}


export function getApplications(organisationId?: string): Promise<Application[]> {
    const accessToken = getAccessToken();
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    let url = service_url + `/main/application/all`;

    return axios.get(url, {headers})
        .then(response => {
            logger.info("Applications loaded: ", response.data);
            return response.data as Application[];
        })
        .catch(error => {
            logger.error("GetApplication request", error);
            throw error;
        });
}

export async function registerFeedback(organisationId: string, data: Feedback): Promise<void> {
    const accessToken = getAccessToken();
    const headers = {Authorization: `Bearer ${accessToken}`, Accept: 'application/json'};
    let url = service_url + `/main/organisation/${organisationId}/feedback`;

    try {
        const response = await axios.post(url, data, {headers});
        logger.info(response.data);
    } catch (error) {
        if ( error instanceof Error)
            logger.error("registerFeedback request failed:", error);
        throw error;
    }
}

export function getAccessToken(): string {
    const accessToken = appSession.accessToken;
    if (!accessToken) {
        logger.error("Access token not found");
        throw new Error("Access token not found");
    }
    return accessToken;
}


