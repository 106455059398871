import {MessageModel} from "@chatscope/chat-ui-kit-react";
import {Filter} from "../../filter/filter_component";
import {appSettings, selectedLanguage} from "../../../managers/generalManager";
import {runLLMRequest} from "../../../backend/extdata.services";
import logger from "../../../utils/logging_services";
import {execute_databoostr} from "../../../backend/executeTask";
import {MessageDirection} from "@chatscope/chat-ui-kit-react/src/types/unions";

export const addMessage = (
    setMessages: React.Dispatch<React.SetStateAction<MessageModel[]>>,
    message: string,
    direction: MessageDirection = "outgoing",
    sender: string = "Zaia"
): void => {
    const newMessage: MessageModel = {
        message,
        direction,
        position: "normal",
        type: "html",
        sender,
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
};

export const updateMessage = (
    setMessages: React.Dispatch<React.SetStateAction<MessageModel[]>>,
    word: string
): void => {
    setMessages((prevMessages) => {
        const lastMessageIndex = prevMessages.length - 1;
        if (lastMessageIndex >= 0 && prevMessages[lastMessageIndex].direction === 'incoming') {
            const updatedMessage = {
                ...prevMessages[lastMessageIndex],
                message: (prevMessages[lastMessageIndex].message || '') + word,
            };
            return [...prevMessages.slice(0, -1), updatedMessage];
        } else {
            const newMessage: MessageModel = {
                message: word,
                direction: 'incoming',
                position: 'normal',
                sender: 'Assistant',
            };
            return [...prevMessages, newMessage];
        }
    });
};

export const sendRequest = async (
    message: string,
    url: string,
    projectId: string,
    mode: string,
    filterList: Filter[],
    systemPrompt: string,
    contextData: string,
    onDataReceived: (word: string) => void
): Promise<void> => {
    let requestContext = contextData;

    if (!mode.startsWith("chat")) {
        const response = await executeDataboostrInSequence(message, mode, filterList);
        requestContext += response;
    }

    const request = `${message}, Write in ${selectedLanguage}`;

    try {
        await runLLMRequest(
            projectId,
            systemPrompt,
            request,
            "",
            requestContext,
            "",
            url,
            appSettings.temperature,
            selectedLanguage,
            appSettings.selectedLLMModelProvider,
            appSettings.selectedLLMModelVersion,
            onDataReceived
        );
    } catch (error: any) {
        logger.error("Error in sendRequest:", error);
        throw error;
    }
};

export const executeDataboostrInSequence = async (
    query: string,
    mode: string,
    filterList: Filter[]
): Promise<string> => {
    const responses: string[] = [];

    if (filterList.length === 0) {
        filterList.push({ id: '0', type: "file", value: "", query: "" });
    }

    const filterCount = filterList.length;
    const chunksRequested = filterCount > 1 ? 3 : appSettings.requestedChunks;

    for (const filter of filterList) {
        const filterFile = filter.type === "file" ? filter.value : "";
        const filterTag = filter.type === "tag" ? filter.value : "";
        const filterQuery = filter.query || query;

        try {
            logger.info("Searching knowledge Database for:", filterQuery, mode, filterFile, filterTag);
            const response = await execute_databoostr(filterQuery, mode, filterFile, filterTag, chunksRequested);
            responses.push(
                `<<DATA>><<FILTER= ${filterFile}, ${filterTag}>>` +
                response +
                `<<END DATA - ${filterFile}, ${filterTag}>>\n`
            );
        } catch (error: any) {
            logger.error("Knowledge database request failed:", filterQuery, error);
            responses.push(`Error: Unable to fetch data for ${filterFile}, ${filterTag}`);
        }
    }

    return responses.join("\n");
};

export const extractUrlFromMessage = (message: string): { modifiedMessage: string; url: string } => {
    const urlRegex = /https?:\/\/[^\s]+/g;
    const found = message.match(urlRegex);

    if (found && found.length > 0) {
        const url = found[0];
        const modifiedMessage = message.replace(url, "").trim();
        return { modifiedMessage, url };
    } else {
        return { modifiedMessage: message, url: "" };
    }
};

export const removeHtmlWhitespace = (htmlString: string): string => {
    const pattern = />\s+</g;
    return htmlString.replace(pattern, '><');
};