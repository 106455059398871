import React, {useState, useEffect} from 'react';
import {FormControl, InputLabel, Select, MenuItem, Tooltip, ListItemIcon, SelectChangeEvent} from '@mui/material';
import {Option} from "../../managers/generalManager";

interface DropdownSelectorWithImageProps {
    options: Option[];
    label: string;
    showLabel: boolean;
    defaultValue?: string;
    onChange?: (value: string) => void;
}

const DropdownSelectorWithImage = ({options, defaultValue, label, showLabel, onChange}: DropdownSelectorWithImageProps) => {
    const [selectedOption, setSelectedOption] = useState<string>(defaultValue || '');

    useEffect(() => {
        if (defaultValue) {
            setSelectedOption(defaultValue);
        }
    }, [defaultValue]);

    const handleChange = (event: SelectChangeEvent) => {
        const value = event.target.value as string;
        setSelectedOption(value);
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                value={selectedOption}
                label={label}
                onChange={handleChange}
                displayEmpty
                renderValue={(selected) => {
                    if (selected) {
                        const selectedOpt = options.find(opt => opt.value === selected);
                        return (
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {selectedOpt?.image && (
                                    <img src={selectedOpt.image} alt={selectedOpt.label}
                                         style={{width: '24px', height: '24px', marginRight: '10px'}}/>
                                )}
                                {selectedOpt?.label}
                            </div>
                        );
                    }
                    return <em>None selected</em>;
                }}
                variant="outlined"
                size="small"
            >
                {options.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                        <Tooltip title={option.label}>
                            <ListItemIcon>
                                {option.image && (
                                    <img src={option.image} alt={option.label} style={{width: '24px', height: '24px'}}/>
                                )}
                            </ListItemIcon>
                        </Tooltip>
                        {showLabel && option.label ? option.label : null}
                    </MenuItem>
                ))}

            </Select>
        </FormControl>
    );
};

export default DropdownSelectorWithImage;