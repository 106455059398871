import React, {useState, useRef, useEffect} from "react";
import mermaid from "mermaid";
import AppDialog from "../general/app_dialog";
import colorSchema from "../../app_theme/theme_support/colorSchema";
const html2canvas = require('html2canvas') as (element: HTMLElement, options?: any) => Promise<HTMLCanvasElement>;
const tinycolor = require('tinycolor2') as any;

interface DrawMermaidProps {
    markup: string;
    onClose: () => void;
}

interface ThemeColors {
    primary: string;
    secondary: string;
    tertiary: string;
    quaternary: string;
    background: string;
    text: string;
    textBackground: string;
    button: string;
}

const generateTheme = (baseColor: string): ThemeColors => {
    const base = tinycolor(baseColor);
    const isDark = base.isDark();

    const primary = base.toHexString();
    const secondary = base.spin(30).saturate(10).toHexString();
    const tertiary = base.spin(-30).saturate(10).toHexString();
    const quaternary = base.complement().toHexString();

    return {
        primary,
        secondary,
        tertiary,
        quaternary,
        background: isDark ? base.lighten(45).toHexString() : base.darken(45).toHexString(),
        text: isDark ? "#ffffff" : "#000000",
        textBackground: isDark ? base.lighten(10).toHexString() : base.darken(10).toHexString(),
        button: isDark ? base.lighten(20).toHexString() : base.darken(20).toHexString(),
    };
};

const baseThemes: Record<'light' | 'dark', ThemeColors> = {
    light: generateTheme(colorSchema.primary.main),
    dark: generateTheme(tinycolor(colorSchema.primary.main).darken(20).toHexString()),
};

mermaid.initialize({
    startOnLoad: false,
    theme: 'neutral',
});

export const DrawMermaid: React.FC<DrawMermaidProps> = ({markup, onClose}) => {
    const [mermaidText, setMermaidText] = useState<string>(markup);
    const [scale, setScale] = useState<number>(1);
    const [position, setPosition] = useState<{ x: number; y: number }>({x: 0, y: 0});
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [showFullImage, setShowFullImage] = useState<boolean>(false);
    const [theme, setTheme] = useState<'light' | 'dark'>('light');
    const [customColor, setCustomColor] = useState<string>(colorSchema.primary.main);
    const containerRef = useRef<HTMLDivElement>(null);
    const mermaidRef = useRef<HTMLDivElement>(null);

    const currentTheme = generateTheme(customColor);

    useEffect(() => {
        updateMermaidColors();
    }, [theme, markup, customColor]);

    useEffect(() => {
        if (mermaidRef.current) {
            renderMermaid();
        }
    }, [mermaidText]);

    const updateMermaidColors = () => {
        const {primary, secondary, tertiary, quaternary, background, text, textBackground} = currentTheme;

        const colorConfig = `
      %%{init: {'theme': 'base', 'themeVariables': { 
        'primaryColor': '${primary}', 
        'primaryTextColor': '${text}', 
        'primaryBorderColor': '${secondary}', 
        'lineColor': '${tertiary}', 
        'secondaryColor': '${secondary}', 
        'tertiaryColor': '${tertiary}',
        'noteTextColor': '${text}',
        'noteBkgColor': '${quaternary}',
        'titleColor': '${primary}',
        'actorBkg': '${secondary}',
        'actorTextColor': '${text}',
        'actorLineColor': '${tertiary}',
        'signalColor': '${quaternary}',
        'signalTextColor': '${background}',
        'labelBoxBkgColor': '${textBackground}',
        'labelBoxBorderColor': '${primary}',
        'labelTextColor': '${text}',
        'loopTextColor': '${text}',
        'activationBorderColor': '${tertiary}',
        'activationBkgColor': '${secondary}',
        'sequenceNumberColor': '${background}',
        'sectionBkgColor': '${quaternary}',
        'altSectionBkgColor': '${tertiary}',
        'sectionBkgColor2': '${secondary}',
        'taskBorderColor': '${tertiary}',
        'taskBkgColor': '${secondary}',
        'taskTextColor': '${text}',
        'taskTextLightColor': '${background}',
        'taskTextOutsideColor': '${text}',
        'taskTextClickableColor': '${primary}',
        'activeTaskBorderColor': '${quaternary}',
        'activeTaskBkgColor': '${primary}',
        'gridColor': '${tertiary}',
        'doneTaskBkgColor': '${tertiary}',
        'doneTaskBorderColor': '${quaternary}',
        'critBorderColor': '${quaternary}',
        'critBkgColor': '${tertiary}',
        'todayLineColor': '${primary}'
      }}}%%
    `;
        setMermaidText(colorConfig + markup);
    };

    const renderMermaid = async () => {
        if (mermaidRef.current) {
            try {
                mermaidRef.current.innerHTML = '';
                const {svg} = await mermaid.render('mermaid-svg', mermaidText);
                mermaidRef.current.innerHTML = svg;
            } catch (error) {
                console.error("Error rendering mermaid diagram:", error);
            }
        }
    };

    // ... (keep the existing event handlers: handleClose, handleWheel, handleMouseDown, handleMouseMove, handleMouseUp, handleResetZoom, toggleFullImage, handlePrint, handleSave)
    const handleClose = () => {
        onClose();
    };

    const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
        e.preventDefault();
        const newScale = scale + (e.deltaY > 0 ? -0.1 : 0.1);
        setScale(Math.max(0.1, Math.min(newScale, 3)));
    };

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsDragging(true);
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (isDragging) {
            setPosition({
                x: position.x + e.movementX,
                y: position.y + e.movementY,
            });
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleResetZoom = () => {
        setScale(1);
        setPosition({x: 0, y: 0});
    };

    const toggleFullImage = () => {
        setShowFullImage(!showFullImage);
        if (!showFullImage) {
            handleResetZoom();
        }
    };

    const handlePrint = () => {
        const printWindow = window.open('', '_blank');
        if (printWindow) {
            printWindow.document.write('<html><head><title>Print Mermaid Diagram</title></head><body>');
            printWindow.document.write(`<div style="background-color: ${currentTheme.background};">`);
            printWindow.document.write(mermaidRef.current?.innerHTML || '');
            printWindow.document.write('</div>');
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.print();
        }
    };

    const handleSave = async () => {
        if (mermaidRef.current) {
            try {
                const canvas = await html2canvas(mermaidRef.current, {
                    backgroundColor: currentTheme.background,
                });
                const image = canvas.toDataURL("image/png");
                const link = document.createElement('a');
                link.href = image;
                link.download = 'mermaid-diagram.png';
                link.click();
            } catch (error) {
                console.error("Error saving image:", error);
                alert("There was an error saving the image. Please try again.");
            }
        }
    };


    const toggleTheme = () => {
        setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
        setCustomColor(baseThemes[theme === 'light' ? 'dark' : 'light'].primary);
    };

    const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCustomColor(e.target.value);
    };

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener("mouseleave", handleMouseUp);
            return () => {
                container.removeEventListener("mouseleave", handleMouseUp);
            };
        }
    }, []);

    const {background, text, button, primary, secondary, tertiary, quaternary} = currentTheme;

    const buttonStyle = {
        padding: '0.5rem 1rem',
        borderRadius: '0.25rem',
        border: 'none',
        cursor: 'pointer',
        marginRight: '0.5rem',
        fontWeight: 'bold',
    };

    return (
        <AppDialog
            onCancel={handleClose}
            open={true}
            size="xl"
            title="Show Mermaid Diagram"
            message=""
            showCancel={true}
            showSubmit={false}
            onSubmit={() => {
            }}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: text,
                backgroundColor: background
            }}>
                <div style={{marginBottom: '1rem', display: 'flex', flexWrap: 'wrap'}}>
                    <button
                        onClick={handleResetZoom}
                        style={{...buttonStyle, backgroundColor: primary, color: text}}
                    >
                        Reset Zoom
                    </button>
                    <button
                        onClick={toggleFullImage}
                        style={{...buttonStyle, backgroundColor: secondary, color: text}}
                    >
                        {showFullImage ? "Enable Zoom" : "Show Full Image"}
                    </button>
                    <button
                        onClick={handlePrint}
                        style={{...buttonStyle, backgroundColor: tertiary, color: text}}
                    >
                        Print
                    </button>
                    <button
                        onClick={handleSave}
                        style={{...buttonStyle, backgroundColor: quaternary, color: background}}
                    >
                        Save as PNG
                    </button>
                    <button
                        onClick={toggleTheme}
                        style={{...buttonStyle, backgroundColor: primary, color: text}}
                    >
                        Toggle {theme === 'light' ? 'Dark' : 'Light'} Mode
                    </button>
                    <input
                        type="color"
                        value={customColor}
                        onChange={handleColorChange}
                        style={{
                            width: '2rem',
                            height: '2rem',
                            borderRadius: '0.25rem',
                            cursor: 'pointer',
                            marginLeft: '0.5rem'
                        }}
                    />
                </div>
                <div
                    ref={containerRef}
                    style={{
                        width: "100%",
                        height: "800px",
                        overflow: "hidden",
                        cursor: isDragging ? "grabbing" : "grab",
                        backgroundColor: background,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    onWheel={handleWheel}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                >
                    <div
                        ref={mermaidRef}
                        style={{
                            transform: `scale(${showFullImage ? 1 : scale}) translate(${position.x}px, ${position.y}px)`,
                            transformOrigin: "center",
                            transition: showFullImage ? "transform 0.3s ease" : "none",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                        }}
                    />
                </div>
            </div>
        </AppDialog>
    );
};