import React, {useEffect, useRef, useState} from "react";
// import {FolderDef, Workspace} from "../../store/old/data-slice";
import AppDialog from "../general/app_dialog";
import {TextField} from "@mui/material";
import {FolderDef, Workspace} from "../../store/types";


interface WorkspaceDialogProps {
    open: boolean;
    workspace_original: Workspace;
    mode: string;
    onClose: () => void;
    onSave: (mode: string, object: Workspace) => void;
    existingNames: string[];
}

const WorkspaceDialog: React.FC<WorkspaceDialogProps> = ({ open, workspace_original, mode, onClose, onSave, existingNames }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [workspace, setWorkspace] = useState<Workspace>({ ...workspace_original });
    const [error, setError] = useState<string>('');

    useEffect(() => {
        if (open && mode === 'create' && inputRef.current) {
            inputRef.current.focus();
        }
    }, [open, mode]);

    const handleSave = () => {

        if ( mode === 'create' ) {
            if (workspace.name.trim() === '') {
                setError('Name cannot be empty');
                return;
            }
            if (existingNames.includes(workspace.name)) {
                setError('Name must be unique');
                return;
            }
        }
        onSave(mode, workspace);
    };

    const handleChange = (field: keyof Workspace, value: string) => {
        setWorkspace(prev => ({ ...prev, [field]: value }));
        setError(''); // Reset error on change
    };

    return (
        <AppDialog
            open={open}
            size="sm"
            message={""}
            showSubmit={true}
            showCancel={true}
            title={mode === 'edit' ? "Edit Workspace" : "Create Workspace"}
            onSubmit={handleSave}
            onCancel={onClose}
        >
            <TextField
                id="workspace_name"
                label="Workspace Name"
                fullWidth
                ref={inputRef}
                autoFocus={mode === 'create'}
                margin="normal"
                variant="outlined"
                error={!!error}
                helperText={error}
                disabled={mode === 'edit'}
                value={workspace.name}
                onChange={(e) => handleChange('name', e.target.value)}
            />
            <TextField
                id="workspace_description"
                label="Workspace Description"
                fullWidth
                multiline
                rows={3}
                margin="normal"
                variant="outlined"
                value={workspace.description}
                onChange={(e) => handleChange('description', e.target.value)}
            />
        </AppDialog>
    );
};

interface ContentTypeDialogProps {
    open: boolean;
    contentType: FolderDef;
    mode: string;
    onClose: () => void;
    onSave: (mode: string, object: FolderDef) => void;
    existingNames: string[];
}

const ContentTypeDialog: React.FC<ContentTypeDialogProps> = ({ open, contentType, mode, onClose, onSave, existingNames }) => {
    const [localContentType, setLocalContentType] = useState<FolderDef>({ ...contentType });
    const [error, setError] = useState<string>('');

    useEffect(() => {
        setLocalContentType({ ...contentType });
    }, [contentType]);

    const handleSave = () => {

        if ( mode === 'create' ) {
            if (localContentType.name.trim() === '') {
                setError('Name cannot be empty');
                return;
            }
            if (existingNames.includes(localContentType.name)) {
                setError('Name must be unique');
                return;
            }
        }
        onSave(mode, localContentType);
    };

    const handleChange = (field: keyof FolderDef, value: string) => {
        setLocalContentType(prev => ({ ...prev, [field]: value }));
        setError(''); // Reset error on change
    };

    return (
        <AppDialog
            open={open}
            size="sm"
            message={""}
            showSubmit={true}
            showCancel={true}
            title={mode === 'edit' ? "Edit Workspace Folders" : "Create Workspace Folders"}
            onSubmit={handleSave}
            onCancel={onClose}
        >
            <TextField
                id="content_type_name"
                label="Workspace Folder Name"
                fullWidth
                autoFocus={mode === 'create'}
                margin="normal"
                variant="outlined"
                error={!!error}
                helperText={error}
                disabled={mode === 'edit'}
                value={localContentType.name}
                onChange={(e) => handleChange('name', e.target.value)}
            />
            <TextField
                id="content_type_description"
                label="Description"
                fullWidth
                multiline
                rows={3}
                margin="normal"
                variant="outlined"
                value={localContentType.description}
                onChange={(e) => handleChange('description', e.target.value)}
            />
        </AppDialog>
    );
};

export { WorkspaceDialog, ContentTypeDialog };