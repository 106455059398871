import React, {useEffect, useState} from "react";
import DropdownSelector from "../chat/dropdownSelector";
import {categoryOptions, appSession} from "../../managers/generalManager";
import {Divider, Typography} from "@mui/material";
import FilterManager, {FileOption, Filter} from "../filter/filter_component";
import {getExtDataRecordList, KnowledgeBaseItem} from "../../backend/extdata.services";

interface ComponentSelectZaiaServicesProps {
    onFileSelect: (file: string) => void;
    onFilterUpdate: (filters: Filter[]) => void;
}

const ComponentSelectZaiaServices: React.FC<ComponentSelectZaiaServicesProps> = (props) => {
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [recordList, setRecordList] = useState<KnowledgeBaseItem[]>([]);
    const [filterList, setFilterList] = useState<Filter[]>([]);
    const [fileList, setFileList] = useState<FileOption[]>([{value: "None", label: "Loading please wait ....."}]);

    const [createFollowupQuestions, setCreateFollowupQuestions] = useState<string>('No');
    const yesnoOptions = [{value: "Yes", label: "Yes"}, {value: "No", label: "No"}];

    useEffect(() => {
        const fetchData = async () => {
            const data = await getExtDataRecordList(appSession.currentKnowledgeBaseProject, selectedCategory);
            setRecordList(data);
            const newFileList = data
                .filter(record => record.meta_data.resource.endsWith("pdf"))
                .filter(record => record.chunks_original > 0)
                .map((record) => ({
                    value: record.uuid,
                    label: record.title.substring(0, 50) + (record.meta_data.resource.length > 50 ? "..." : ""),
                }));

            newFileList.unshift({value: "All", label: "All"});
            setFileList(newFileList);
        };

        fetchData();
    }, [selectedCategory]);

    useEffect(() => {
        props.onFilterUpdate(filterList);
    }, [filterList]);

    const handleCategoryChange = (selected: string) => {
        setSelectedCategory(selected);
    };

    const handleFollowUpChange = (selected: string) => {
        setCreateFollowupQuestions(selected);
    }

    return (
        <>
            {/*Show the dropdown selector for the category and the filter components*/}
            <div style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
                flexDirection: 'column',
                alignItems: 'flex-start'
            }}>
                <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                    <div style={{flex: '1', display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="body1" sx={{fontWeight: 'bold'}}>Knowledge Domain</Typography>
                        <Typography variant="subtitle2">
                            Select the category for the knowledge domain.
                        </Typography>
                    </div>
                    <div style={{flex: '2', display: 'flex', flexDirection: 'column'}}>
                        <DropdownSelector
                            variant={"outlined"}
                            defaultValue={categoryOptions.find(option => option.value === selectedCategory)?.value}
                            options={categoryOptions}
                            label="Knowledge Domain"
                            onChange={handleCategoryChange}
                        />
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginTop: '10px'}}>
                    <div style={{flex: '1', display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="subtitle2">
                            Select filter and define the variable that contains the question
                        </Typography>
                    </div>

                    <div style={{flex: '2', display: 'flex', flexDirection: 'column'}}>
                        <FilterManager
                            fileList={fileList}
                            onFilterUpdate={setFilterList}
                        />
                        <Divider
                            sx={{
                                marginTop: '10px',
                                marginBottom: '10px'
                            }}
                        />
                    </div>
                </div>
            </div>
            <div style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
                flexDirection: 'column',
                alignItems: 'flex-start'
            }}>
                <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                    <div style={{flex: '1', display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="body1" sx={{fontWeight: 'bold'}}>Follow-up questions</Typography>
                        <Typography variant="subtitle2">
                            Do you want the system create following up question?
                        </Typography>
                    </div>
                    <div style={{flex: '2', display: 'flex', flexDirection: 'column'}}>
                        <DropdownSelector
                            variant={"outlined"}
                            defaultValue={yesnoOptions.find(option => option.value === createFollowupQuestions)?.value}
                            options={yesnoOptions}
                            label="Follow-up Questions"
                            onChange={handleFollowUpChange}
                        />
                    </div>
                </div>
            </div>
        </>

    );
};

export default ComponentSelectZaiaServices;
