import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useEffect, useState} from "react";
import {Divider} from "@mui/material";
import colorSchema from "../../app_theme/theme_support/colorSchema";

interface AppDialogProps {
    open: boolean
    size: "xs" | "sm" | "md" | "lg" | "xl";
    title: string;
    header?: React.ReactElement;
    children?: React.ReactNode;
    message: string;
    height?: string;
    showSubmit?: boolean;
    showCancel?: boolean;
    onSubmit: () => void;
    onCancel: () => void;
    fullHeight?: boolean;
}

export default function AppDialog(props: AppDialogProps) {
    const [open, setOpen] = useState<boolean>(props.open);
    const [size ] = useState(props.size);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    function handleSubmit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        props.onSubmit();
    }

    function handleCancel(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        props.onCancel();
    }

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={size}
            PaperProps={{
                sx: props.height ? { minHeight: props.height } : {},
            }}
            onClose={handleCancel}
        >
            <DialogTitle
                variant={"h5"}
                id="dialog_title"
                align={"center"}
                sx={{backgroundImage: colorSchema.background.gradient, padding: '5px', letterSpacing: '1px'}}
                color={colorSchema.primary.main}
            >
                { props.header ? props.header : ( props.title?.charAt(0).toUpperCase() + props.title?.slice(1) ) || ''}
            </DialogTitle>
            <Divider/>
            {/*<DialogContent sx={{background: `linear-gradient(to bottom, ${colorSchema.app_background_start}, ${colorSchema.app_background})`}}>*/}
            <DialogContent
                sx={{background: colorSchema.background.default, color: colorSchema.primary.main, height: props.fullHeight ? 'calc(100% - 64px)' : 'auto'}}>
                    {props.children}
            </DialogContent>
            <DialogActions sx={{marginRight: '1px', marginBottom: '1px'}}>
                {props.showCancel && (
                    <Button color={"warning"} size={"small"} variant={"contained"} onClick={handleCancel}>
                         { props.showSubmit ? "Cancel" : "Close" }
                    </Button>
                )}
                {props.showSubmit && (
                    <Button color={"primary"} size={"small"} variant={"contained"} onClick={handleSubmit} autoFocus>Confirm</Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

// import * as React from 'react';
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
// import {colorSchema} from "../../app_theme/theme";
// import {useEffect, useState} from "react";
// import CloseIcon from '@mui/icons-material/Close';
// import {Divider} from "@mui/material";
//
// interface SimpleDialogProps {
//     open: boolean
//     size: "xs" | "sm" | "md" | "lg" | "xl";
//     title: string;
//     children?: React.ReactNode;
//     message: string;
//     showSubmit?: boolean;
//     showCancel?: boolean;
//     onSubmit: () => void;
//     onCancel: () => void;
// }
//
// export default function AppDialog(props: SimpleDialogProps) {
//     const [open, setOpen] = useState<boolean>(props.open);
//     const [size ] = useState(props.size);
//
//     useEffect(() => {
//         setOpen(props.open);
//     }, [props.open]);
//
//     function handleSubmit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
//         props.onSubmit();
//     }
//
//     function handleCancel(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
//         props.onCancel();
//     }
//
//     return (
//         <Dialog
//             open={open}
//             fullWidth={true}
//             maxWidth={size}
//             onClose={handleCancel}
//         >
//             <DialogTitle
//                 variant={"h5"}
//                 id="simple_dialog"
//                 align={"center"}
//                 color={colorSchema.color1}
//
//                 sx={{
//                     height: "20px",
//                 }}
//             >
//                 {props.title.charAt(0).toUpperCase() + props.title.slice(1)}
//             </DialogTitle>
//             <Divider/>
//             <DialogContent>
//                 {props.children}
//                 <CloseIcon
//                     onClick={props.onCancel}
//                     sx={{
//                         color: colorSchema.color1,
//                         position: 'absolute', // this makes it absolute positioned
//                         top: 8, // adjust this to move it vertically
//                         right: 8, // adjust this to move it horizontally
//                     }}/>
//             </DialogContent>
//             <DialogActions sx={{marginRight: '10px', marginBottom: '10px'}}>
//                 {props.showCancel && (
//                     <Button color={"warning"} size={"small"} variant={"contained"} onClick={handleCancel}>
//                          { props.showSubmit ? "Cancel" : "Close" }
//                     </Button>
//                 )}
//                 {props.showSubmit && (
//                     <Button color={"primary"} size={"small"} variant={"contained"} onClick={handleSubmit} autoFocus>Confirm</Button>
//                 )}
//             </DialogActions>
//         </Dialog>
//     );
// }