// StoreList.tsx
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { AppState } from '../../../store/old/store';
//import { setActiveStore } from '../../../store/old/data-slice';
import { List, ListItem, ListItemText, IconButton, Divider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import StorageIcon from '@mui/icons-material/Storage';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AppDrawer from '../../general/app_drawer';
import colorSchema from "../../../app_theme/theme_support/colorSchema";
import {RootState} from "../../../store/store";
import {setActiveStore} from "../../../store/dataSlice";

interface StoreListProps {
    storeList: string[];
    onAddStore: () => void;
    onDeleteStore: () => void;
    onSaveStore: () => void;
    onLoadStore: () => void;
}

const GroupManager: React.FC<StoreListProps> = ({ storeList, onAddStore, onDeleteStore, onSaveStore, onLoadStore }) => {
    const dispatch = useDispatch();
    const selectedStore = useSelector((state: RootState) => state.data.activeStore);

    const handleListItemClick = (store: string) => {
        dispatch(setActiveStore(store));
    };

    return (
        <AppDrawer title={selectedStore} initialState={false} showLock={false}>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'space-between' }}>
                    <div style={{ display: 'flex' }}>
                        <Typography variant="subtitle1" sx={{ marginTop: '10px', marginLeft: '10px' }}>
                            <StorageIcon sx={{ marginRight: '5px', color: colorSchema.primary.main }} />
                        </Typography>
                    </div>
                    <div style={{ minHeight: '50px', justifyContent: 'end', alignItems: 'center', display: 'flex' }}>
                        <IconButton size={"small"} onClick={onAddStore} name="add">
                            <AddIcon />
                        </IconButton>
                        <IconButton onClick={onDeleteStore} name="delete" size={"small"}>
                            <DeleteIcon style={{ color: colorSchema.warning.main }} />
                        </IconButton>
                        <IconButton onClick={onSaveStore} name="save_data" size={"small"} sx={{ ml: '5px' }}>
                            <CloudDownloadIcon style={{ color: colorSchema.primary.main }} />
                        </IconButton>
                        <IconButton onClick={onLoadStore} name="load_data" size={"small"}>
                            <CloudUploadIcon style={{ color: colorSchema.primary.main }} />
                        </IconButton>
                    </div>
                </div>
                <Divider />
                <div style={{ display: 'flex', flexDirection: 'column', flex: '1, 0, 0px', overflowY: 'auto', marginTop: '5px' }}>
                    {storeList.length > 0 && (
                        <List component="nav" aria-label="store list" sx={{ flex: 1, paddingX: 2 }}>
                            {storeList.map((store, index) => (
                                <ListItem
                                    key={index}
                                    onClick={() => handleListItemClick(store)}
                                    sx={{
                                        borderRadius: '5px',
                                        height: '40px',
                                        textAlign: 'center',
                                        marginBottom: '5px',
                                        backgroundColor: selectedStore === store ? colorSchema.grey["400"] : "inherit",
                                        color: selectedStore === store ? 'white' : "inherit",
                                        fontWeight: selectedStore === store ? 'bold' : "inherit",
                                        '&:hover': {
                                            backgroundColor: colorSchema.action.hover,
                                        },
                                    }}
                                >
                                    <ListItemText primary={store} />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </div>
            </div>
        </AppDrawer>
    );
};

export default GroupManager;
