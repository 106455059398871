import config from '../config.json';

type Mode = 'dev' | 'prod';

interface Config {
  mode: Mode;
}

type Loggable = string | number | boolean | Record<string, any> | void | Error;

const formatObject = (obj: Loggable): string => {
  if (obj === undefined) {
    return ''; // Handle void or undefined by returning an empty string
  } else if (typeof obj === 'object') {
    return JSON.stringify(obj, null, 2);
  }
  return obj.toString();
};


const logger = {
  info: (message: string, ...objs: Loggable[]) => {
    log('INFO', message, ...objs);
  },
  error: (message: string, ...objs: Loggable[]) => {
    log('ERROR', message, ...objs);
  }
};

const log = (level: string, message: string, ...objs: Loggable[]) => {
  const mode: Mode = (config as Config).mode;

  if (mode === 'dev' || level === 'ERROR') {
    console.log(`[${level}] ${message}`);
    objs.forEach(obj => {
      if (obj !== undefined) { // Skip logging if the object is void or undefined
        console.log(formatObject(obj));
      }
    });
  } else if (mode === 'prod') {
    // In production, handle logging appropriately, e.g., send to a remote server
  }
};

export default logger;
