import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch} from 'react-redux';
// import {AppDispatch} from '../../store/old/store';
import {fetch_document_as_blob} from '../../backend/zaia_state';
import {Button, CircularProgress, Box, TextField} from '@mui/material';
// import {SelectedFile, setSelectedFile} from '../../store/old/data-slice';
import SendIcon from '@mui/icons-material/Send';
import {processImage} from '../../backend/special.services';
import logger from '../../utils/logging_services';
import ComponentModalOrEmbedded from '../general/component_modal_embedded';
import {appSettings, appSession} from '../../managers/generalManager';
import {getServiceData, getServiceRecords, KnowledgeBaseItemOut} from '../../backend/extdata.services';
import {getServiceInfo} from '../files/file_services';
import colorSchema from "../../app_theme/theme_support/colorSchema";
import InfoAccordion from "./info_viewer";
import {SelectedFile} from "../../store/types";
import {AppDispatch} from "../../store/store";
import {setSelectedFile} from "../../store/dataSlice";

interface ShowImageProps {
    file: SelectedFile;
    selectedGoal: string;
    onClose: () => void;
    onResponse: (request: string) => void;
    onDataSelected: (request: string) => void;
}

const ShowImage: React.FC<ShowImageProps> = ({file, selectedGoal, onClose, onResponse, onDataSelected}) => {
    const [imageUrl, setImageUrl] = useState<string>('');
    const [imageRequest, setImageRequest] = useState<string>('');
    const [showPleaseWait, setShowPleaseWait] = useState<boolean>(false);
    const [viewMode] = useState<'modal' | 'embedded'>('embedded');
    const [infoText, setInfoText] = useState<string>('');
    const [infoTextJson, setInfoTextJson] = useState<string>('');
    const [infoStatus, setInfoStatus] = useState<boolean>(false);
    const [serviceRecords, setServiceRecords] = useState<KnowledgeBaseItemOut[]>([]);
    const [useDataAsContext, setUseDataAsContext] = useState<boolean>(false);
    const [isFlipped, setIsFlipped] = useState<boolean>(false);
    const [infoLoaded, setInfoLoaded] = useState<boolean>(false);

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        logger.info('ShowImage: ', file);
        fetch_document_as_blob(file.metadata.container_name, file.metadata.blob_name).then((blob) => {
            const url = URL.createObjectURL(blob);
            setImageUrl(url);
            logger.info('URL: ', url);
            loadServiceInfo();
        });
    }, [file]);

    const loadServiceInfo = useCallback(async () => {
        if (!file.uuid) return;

        const {
            service_records,
            infoStatus
        } = await getServiceInfo(appSession.currentKnowledgeBaseProject, file.uuid, getServiceRecords);
        setServiceRecords(service_records);
        setInfoStatus(infoStatus);
    }, [file.uuid]);

    const handleSendRequest = () => {
        if (imageRequest.trim() === '' && selectedGoal.trim() === '') return;

        const myRequest = `${imageRequest.trim()}\n${selectedGoal.trim()}\nFormat your response as formatted HTML with titles, subtitles, paragraphs, lists, etc. Focus on readability`;

        setShowPleaseWait(true);
        processImage(file.metadata.container_name, file.metadata.blob_name, myRequest)
            .then((response) => {
                onResponse(response);
                setShowPleaseWait(false);
            })
            .catch(() => {
                setShowPleaseWait(false);
            });
    };

    const handleOnClose = () => {
        dispatch(setSelectedFile(undefined));
        onClose();
    };

    const handleShowServiceData = async () => {
        if (!file.uuid || infoLoaded) {
            setIsFlipped(true);
            return;
        }

        setShowPleaseWait(true);
        try {
            const service_record = serviceRecords.find(record => record.metadata_type === 'info');
            if (!service_record) {
                setShowPleaseWait(false);
                return;
            }

            const data = await getServiceData(appSession.currentKnowledgeBaseProject, service_record.uuid);

            const extractJsonAndText = (data: string): { jsonText: string; remainingText: string } => {
                const jsonRegex = /```([\s\S]*?)```/;
                const match = data.match(jsonRegex);

                if (match && match[1]) {
                    const jsonText = match[1].trim();
                    const remainingText = data.replace(jsonRegex, '').trim();
                    return {jsonText, remainingText};
                }

                return {jsonText: '', remainingText: data.trim()};
            };

            try {
                const data = await getServiceData(appSession.currentKnowledgeBaseProject, service_record.uuid);
                const {jsonText, remainingText} = extractJsonAndText(data);

                setInfoTextJson(jsonText || data); // If no JSON found, use the entire data
                setInfoText(remainingText);
            } catch (error) {
                console.error('Error fetching or processing service data:', error);
                // Handle the error appropriately, e.g., show an error message to the user
                setInfoTextJson('');
                setInfoText('Error fetching data');
            }
            extractJsonAndText(data);

            setInfoLoaded(true);
            setIsFlipped(true);
        } catch (error: any) {
            logger.error('Error fetching info app_theme:', error);
        }
        setShowPleaseWait(false);
    };

    const handleSelectData = () => {
        const newState = !useDataAsContext;
        setUseDataAsContext(newState);
        if (newState) {
            onDataSelected(infoText);
        } else {
            onDataSelected('');
        }
    };

    const flipCard = () => {
        setIsFlipped(!isFlipped);
    };

    return (
        <ComponentModalOrEmbedded onClose={handleOnClose} title={"Image"} mode={viewMode}
                                  maxWidth={appSettings.maxWidth * 2}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                height: appSettings.dialogHeight,
                position: 'relative',
                overflow: 'hidden'
            }}>
                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    transition: 'transform 0.8s',
                    transformStyle: 'preserve-3d',
                    transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0)',
                }}>
                    {/* Front of the card (Image) */}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backfaceVisibility: 'hidden',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    }}>
                        {imageUrl && (
                            <img
                                src={imageUrl}
                                style={{
                                    maxWidth: '80%',
                                    maxHeight: '80%',
                                    objectFit: 'contain',
                                    borderRadius: '8px',
                                }}
                                alt="Fetched Document"
                            />
                        )}
                        {infoStatus && (
                            <Button variant="contained" onClick={handleShowServiceData} sx={{mt: 2}}>
                                {infoLoaded ? 'Show Information' : 'Load Information'}
                            </Button>
                        )}
                    </Box>

                    {/* Back of the card (Info) */}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        backfaceVisibility: 'hidden',
                        transform: 'rotateY(180deg)',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        padding: '5px',
                        boxSizing: 'border-box',
                        height: '100%',
                        backgroundColor: colorSchema.background.default,
                    }}>
                        <InfoAccordion
                            fileName={file.fileName}
                            infoText={infoText}
                            infoTextJson={infoTextJson}
                            useDataAsContext={useDataAsContext}
                            onSelectData={handleSelectData}
                            onFlipCard={flipCard}
                            selectState={useDataAsContext}
                        />
                    </Box>
                </Box>

                {viewMode === 'modal' && (
                    <Box sx={{display: 'flex', alignItems: 'center', mt: 2, p: 2}}>
                        <TextField
                            fullWidth
                            multiline
                            maxRows={4}
                            value={imageRequest}
                            onChange={(e) => setImageRequest(e.target.value)}
                            placeholder="Type a message..."
                            disabled={showPleaseWait}
                            sx={{mr: 1}}
                        />
                        <Button
                            variant="contained"
                            endIcon={<SendIcon/>}
                            onClick={handleSendRequest}
                            disabled={showPleaseWait}
                        >
                            Send
                        </Button>
                    </Box>
                )}

                {showPleaseWait && (
                    <Box sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1000,
                    }}>
                        <CircularProgress/>
                    </Box>
                )}
            </Box>
        </ComponentModalOrEmbedded>
    );
};

export default ShowImage;