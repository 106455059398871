import React from 'react';
import {Card, CardContent, Box, Typography, Tooltip, Divider} from '@mui/material';
// import {WorkspaceItem} from "../../store/old/temp-slice";
import colorSchema from "../../app_theme/theme_support/colorSchema";
import {WorkspaceItem} from "../../store/types";

interface WorkspaceItemCardProps {
    item: WorkspaceItem;
    itemData: string;
    index: number;
    isSelected: boolean;
    isTarget: boolean;
    openTooltip: boolean;
    currentCardId: string;
    onSelect: (uuid: string) => void;
    onMouseEnter: (uuid: string) => void;
    onMouseLeave: () => void;
    addMenu: (ws: WorkspaceItem, index: number) => React.ReactNode;
}

const WorkspaceItemCard: React.FC<WorkspaceItemCardProps> = ({
                                                                 item,
                                                                 itemData,
                                                                 index,
                                                                 isSelected,
                                                                 isTarget,
                                                                 openTooltip,
                                                                 currentCardId,
                                                                 onSelect,
                                                                 onMouseEnter,
                                                                 onMouseLeave,
                                                                 addMenu
                                                             }) => {
    if (!item) return null;

    const stripHtmlTags = (html: string) => {
        const tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    };

    return (
        <React.Fragment key={item.uuid}>
            {isTarget && <Divider sx={{my: 1, backgroundColor: 'primary.main'}}/>}
            <Card
                variant="elevation"
                sx={{
                    marginBottom: '5px',
                    backgroundColor: isSelected ? colorSchema.action.selected : 'white'
                }}
                onClick={(event) => {
                    event.preventDefault();
                    onSelect(item.uuid || "");
                }}
            >
                <CardContent
                    onMouseEnter={() => onMouseEnter(item.uuid || "")}
                    onMouseLeave={onMouseLeave}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Tooltip
                            title={<span dangerouslySetInnerHTML={{__html: itemData.slice(1, 500)}}/>}
                            arrow
                            open={openTooltip && currentCardId === item.uuid}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                        >
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <Typography variant={"h6"} textAlign={"start"} fontWeight={600}>{item.name}</Typography>
                                <Typography variant={"body2"}
                                            dangerouslySetInnerHTML={{__html: stripHtmlTags(itemData).substring(0, 50) + '...'}}/>
                            </div>
                        </Tooltip>
                        {addMenu(item, index)}
                    </Box>
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default React.memo(WorkspaceItemCard);
