import React, {useState, useMemo, useEffect} from 'react';
import {TableColumn, TableItem} from "../general/app_table";
import {fetch_document, fetch_document_as_blob, update_document} from "../../backend/zaia_state";
import AppDialog from "../general/app_dialog";
import {Button} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import {useDispatch, useSelector} from "react-redux";
// import {setSelectedFileColumns} from "../../store/old/data-slice";
import PleaseWaitDialog from "../general/please_wait";
import logger from "../../utils/logging_services";
import RecordViewer from "./viewers/view_records";
import {appSettings} from "../../managers/generalManager";
import ComponentModalOrEmbedded from "../general/component_modal_embedded";
import {setSelectedFileColumns} from "../../store/dataSlice";
import {WorkspaceItem} from "../../store/types";
import {RootState} from "../../store/store";

// Define a generic interface for items, extending with TableItem for general table properties
export interface DynamicDataItem extends TableItem {
    [key: string]: any;  // Allows any key with any type of value
}

interface DynamicCsvTableProps {
    viewMode: 'modal' | 'embedded';
    initialData: DynamicDataItem[];
    itemRef: string;
    title: string;
    selectedKeys: string[];
    onClose: () => void;
    onSelectRecord: (record: DynamicDataItem | undefined) => void;
    onSelection: (records: DynamicDataItem[]) => void;
}

// Component that uses AppTable with dynamic app_theme structure
const DynamicCsvTable = ( props : DynamicCsvTableProps) => {
    const [data, setData] = useState<DynamicDataItem[]>([]);
    const [imageUrls, setImageUrls] = useState<string[]>([]);
    const [currentRecordIndex, setCurrentRecordIndex] = useState<number>(0);
    const [showImageViewer, setShowImageViewer] = useState<boolean>(false);
    const [selectedDescription, setSelectedDescription] = useState<string>('');
    const [selectedRecords, setSelectedRecords] = useState<DynamicDataItem[]>([]);
    const [imageCache, setImageCache] = useState<{ [key: string]: string }>({}); // Cache for images
    const [showBusy, setShowBusy] = useState<boolean>(false);
    const [viewMode, setViewMode] = useState<'modal' | 'embedded'>(props.viewMode);

    const [itemRef, setItemRef] = useState<WorkspaceItem|undefined>(undefined);

    const [recordInEditMode, setRecordInEditMode] = useState<DynamicDataItem | null>(null); // Record currently being edited

    const [isUpdated, setIsUpdated] = useState<boolean>(false);

    const selectedfile = useSelector((state: any) => state.data.selectedFile);

    const [ titleToUse, setTitleToUse] = useState<string>(selectedfile ? selectedfile.metadata.blob_name.substring(0, 10) : props.title);

    const dispatch = useDispatch();
    const workspaceItems: WorkspaceItem[] = useSelector((state: RootState) => state.workspaceItems.items);

    useEffect(() => {
        if (props.initialData.length === 0) return;
        logger.info('Initial CSV data:', props.initialData)

        const clonedData = JSON.parse(JSON.stringify(props.initialData)) as DynamicDataItem[];
        setData(clonedData);

        const clonedDataForSelectedRecords = JSON.parse(JSON.stringify(props.initialData)) as DynamicDataItem[];
        setSelectedRecords(clonedDataForSelectedRecords.filter(record => props.selectedKeys.includes(record.key)));

    }, [props.initialData]);


    useEffect(() => {

        if (props.itemRef === "") return;
        const workspaceItem = workspaceItems.filter(item => item.uuid === props.itemRef);
        if (workspaceItem && workspaceItem[0]) {
            setItemRef(workspaceItem[0]);
            setTitleToUse(workspaceItem[0].name);
        }

    }, [props.itemRef]);

    useEffect(() => {
        logger.info('Selected records in csv_table:', selectedRecords);
        props.onSelection(selectedRecords);
    }, [selectedRecords]);

    // Dynamically create column definitions based on the first item keys in the app_theme array
    const columns: TableColumn<DynamicDataItem>[] = useMemo(() => {
        logger.info("", data);
        if (data.length > 0) {
            return Object.keys(data[0]).filter(key => key !== 'key' && !key.startsWith('@Z_')).map(key => {
                return {
                    title: key.charAt(0).toUpperCase() + key.toLowerCase().slice(1), // Capitalize the first letter of the key for the title
                    dataIndex: key as keyof DynamicDataItem
                }
            });
        }
        return [];
    }, [data]);

    // Define menu items within the component function
    const menuItem = [
        {label: "View", action: "view"},
        // {label: "Show Image", action: "image"},
        {label: "Show Description", action: "description"},
        {label: "Edit", action: "edit"}
    ];

    // Use useMemo to memoize the menuTable based on isUpdated
    const menuTable = useMemo(() => [
        // {label: "Show Image", action: "image_all"},
        // {label: "Download Images as Zip", action: "download_zip"},
        ...(selectedRecords.length > 0 ? [{label: "Deselect all", action: "deselect_all"}] : []),
        ...(isUpdated ? [{label: "Save updates", action: "save"}] : [])
    ], [isUpdated, selectedRecords]);


    const extractZaiaLink = (link: string) => {
        let metadata = "";
        let documentId = "";
        let containerId = "";
        let isZaiaLink = false;

        // remove quotes if present
        link = link.replace(/['"]+/g, '');

        if (link.startsWith("@Z_")) {
            isZaiaLink = true;
            metadata = link.substring(0, 7);
            link = link.substring(7);
        } else {
            return {isZaiaLink, metadata, containerId, documentId};
        }

        const parts = link.split('/');
        containerId = parts[0];
        documentId = parts.slice(1).join('/'); // Assuming documentId could be the rest of the path

        return {isZaiaLink, metadata, containerId, documentId};
    };


    const handleRequest = async (action: string, key: string, selectedColumns: string[]) => {

        const filteredRecord = (record: DynamicDataItem) => {

            logger.info('Selected Columns:', selectedColumns)
            const recordToFilter = JSON.parse(JSON.stringify(record)) as DynamicDataItem;

            for (let column of Object.keys(recordToFilter)) {
                if (column === 'key' || column.startsWith('@Z_'))
                    continue;
                column = key.charAt(0).toUpperCase() + key.toLowerCase().slice(1)
                if (!selectedColumns.includes(column))
                    recordToFilter[column] = "";
            }
            return recordToFilter;
        }

        logger.info(`Action: ${action}, Key: ${key}`);

        if (action === 'save') {

            if (!selectedfile) return;

            const blob_name = selectedfile.metadata.blob_name;
            const container_name = selectedfile.metadata.container_name;

            const csvData = convertToCsv(data);
            update_document(container_name, blob_name, csvData).then().catch((error) => {
                logger.error('Failed to update document:', error);
            });
            setIsUpdated(false);
        }

        if (action === 'image_all') {
            const imageUrls: string[] = [];

            setShowBusy(true);

            const fetchImages = async () => {
                for (const record of selectedRecords) {
                    const imageUrl = Object.values(record).find(value => typeof value === 'string' && value.includes('.png'));
                    if (imageUrl) {
                        const {isZaiaLink, metadata, containerId, documentId} = extractZaiaLink(imageUrl);
                        if (isZaiaLink) {
                            if (imageCache[documentId]) {
                                logger.info('Using cached image:', imageCache[documentId])
                                imageUrls.push(imageCache[documentId]);
                            } else {
                                try {
                                    const blob = await fetch_document_as_blob(containerId, documentId);
                                    const url = URL.createObjectURL(blob);
                                    logger.info('Fetched image:', url)
                                    setImageCache(prevCache => ({...prevCache, [documentId]: url}));
                                    imageUrls.push(url);
                                } catch (error) {
                                    console.error('Failed to fetch document:', error);
                                }
                            }
                        }
                    }
                }
                setImageUrls(imageUrls);
                setShowImageViewer(true);
                setShowBusy(false);

            };

            fetchImages();
            return;
        }

        if (action === 'download_zip') {
            downloadImagesAsZip();
            return;
        }

        if (action === 'set_columns') {
            dispatch(setSelectedFileColumns(selectedColumns));
            const newSelectedRecords: DynamicDataItem[] = [];
            logger.info('Selected Columns:', selectedColumns);

            for (let record of selectedRecords) {
                const record_original = data.map(item => item.key === record.key ? item : record)[0];
                const record_original_duplicate = JSON.parse(JSON.stringify(record_original));
                record = filteredRecord(record_original_duplicate);
                newSelectedRecords.push(record);
            }
            setSelectedRecords(newSelectedRecords);
            logger.info('Filtered selected records:', newSelectedRecords);
            return;
        }

        if (action === 'select_all') {

            // create copy of selected records
            const newSelectedRecords: DynamicDataItem[] = []

            const keys = key.split(',');
            for (const key of keys) {
                let record = data.find(item => item.key === key);
                if (record)
                    record = filteredRecord(record);
                newSelectedRecords.push(record!);
            }
            setSelectedRecords(newSelectedRecords);
            return;
        }

        if (action === 'deselect_all') {
            setSelectedRecords([]);
            return;
        }

        let item = data.find(item => item.key === key);
        if (!item) {
            logger.error('Item not found:', key)
            return;
        }

        if (action === 'select') {
            // check if item is already selected, if so unselect otherwise select
            if (selectedRecords.filter(record => record.key === item!.key).length > 0) {
                logger.info('Unselecting item:', item);
                setSelectedRecords(selectedRecords.filter(record => record.key !== item!.key));
                return;
            } else {
                logger.info('Selecting item:', item);
                item = filteredRecord(item);
                setSelectedRecords([...selectedRecords, item]);
            }
        }

        if (action === 'edit') {
            setRecordInEditMode(item)
        }

        if (action === 'view') {
        }

        if (action === 'image') {
            logger.info('Fetching image for item:', item);
            // search all values in item for image urls
            // let imageUrl = Object.values(item).find(value => typeof value === 'string' && value.includes('.png'));
            let imageUrl = Object.values(item).find(value => typeof value === 'string' && /\.(png|jpg|webp)$/i.test(value));
            if (!imageUrl) {
                return;
            }

            const {isZaiaLink, metadata, containerId, documentId} = extractZaiaLink(imageUrl);
            if (!isZaiaLink) {
                return;
            }

            if (imageCache[documentId]) {
                setImageUrls([imageCache[documentId]]);
                setShowImageViewer(true);
            } else {
                try {
                    const blob = await fetch_document_as_blob(containerId, documentId);
                    const url = URL.createObjectURL(blob);
                    setImageCache(prevCache => ({...prevCache, [documentId]: url}));
                    setImageUrls([url]);
                    setShowImageViewer(true);
                } catch (error) {
                    console.error('Failed to fetch document:', error);
                }
            }
        }

        if (action === 'description') {
            fetchDescription(item, () => {
            });
        }
    };

    const handleNextRecord = () => {
        setCurrentRecordIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
    };

    const handlePreviousRecord = () => {
        setCurrentRecordIndex((prevIndex) => (prevIndex - 1 + imageUrls.length) % imageUrls.length);
    };

    function fetchDescription(item: DynamicDataItem, callback: (description: string) => void) {
        logger.info('Fetching description for item:', item);
        const descriptionUrl = Object.values(item).find(value => typeof value === 'string' && value.includes('.html'));
        if (!descriptionUrl) {
            return;
        }

        const {isZaiaLink, metadata, containerId, documentId} = extractZaiaLink(descriptionUrl);
        if (!isZaiaLink) {
            return;
        }

        fetch_document(containerId, documentId, false)
            .then(blob => {
                logger.info('Fetched document:', blob);
                setSelectedDescription(blob.data);
                callback(blob.data);
            })
            .catch(error => {
                logger.error('Failed to fetch document:', error);
            });
    }

    const downloadImagesAsZip = async () => {
        const zip = new JSZip();
        const imgFolder = zip.folder("images");
        if (selectedRecords.length === 0) {
            return
        }

        for (const record of selectedRecords) {
            setShowBusy(true);
            const imageUrl = Object.values(record).find(value => typeof value === 'string' && value.includes('.png'));
            if (imageUrl) {
                const {isZaiaLink, metadata, containerId, documentId} = extractZaiaLink(imageUrl);
                if (isZaiaLink) {
                    try {
                        let imgBlob;
                        if (imageCache[documentId]) {
                            const response = await fetch(imageCache[documentId]);
                            imgBlob = await response.blob();
                        } else {
                            const blob = await fetch_document_as_blob(containerId, documentId);
                            const url = URL.createObjectURL(blob);
                            setImageCache(prevCache => ({...prevCache, [documentId]: url}));
                            const response = await fetch(url);
                            imgBlob = await response.blob();
                            URL.revokeObjectURL(url); // Revoke Object URL after use
                        }
                        const fileName = `${documentId}.png`;
                        imgFolder?.file(fileName, imgBlob);
                    } catch (error) {
                        console.error('Failed to fetch document:', error);
                    }
                }
            }
        }

        zip.generateAsync({type: "blob"}).then((content) => {
            saveAs(content, "images.zip");
        });
        setShowBusy(false);

    };

    const handleClose = () => {
        clearImageCache();
        props.onClose();
    }

    const clearImageCache = () => {
        Object.values(imageCache).forEach(URL.revokeObjectURL);
        setImageCache({});
    }

    const handleItemFieldChange = (key: string, value: string) => {
        if (recordInEditMode) {
            const updatedRecord = {...recordInEditMode, [key]: value};
            setRecordInEditMode(updatedRecord);
        }
    }

    const handleEditItemSubmit = () => {

        // recordInEditMode needs to be updated in state variable app_theme based on the key
        if (!recordInEditMode) {
            return;
        }

        const updatedData = data.map(item => item.key === recordInEditMode.key ? recordInEditMode : item);
        setIsUpdated(true);
        console.log('Updated Data:', updatedData);
        setData(updatedData);
        setRecordInEditMode(null);
    }

    const handleUpdateRecord = async (record: DynamicDataItem) => {
        const updatedData = data.map(item => item.key === record.key ? record : item);
        setData(updatedData);
        setSelectedRecords(selectedRecords.map(item => item.key === record.key ? record : item));
    }


    return (
        <ComponentModalOrEmbedded onClose={handleClose}
                                  title={titleToUse}
                                  mode={viewMode}
                                  color={ itemRef ? itemRef.selected_color : ""}
                                  maxWidth={appSettings.maxWidth * 2} onModeChange={setViewMode} size={"xl"}>

            <div style={{marginBottom: '10px'}}></div>
            <RecordViewer records={data}
                          open={true}
                          isModalMode={viewMode === 'modal'}
                          onSave={handleUpdateRecord}
                          menuItem={menuItem} menuTable={menuTable}
                          selectedFile={selectedfile}
                          selectedKeys={props.selectedKeys}
                          handleRequest={handleRequest}
                          columns={columns}
                          onRecordViewed={(record) => props.onSelectRecord(record)}
                          onClose={() => handleClose()}
            />


            {showImageViewer && imageUrls.length > 0 && (
                <AppDialog
                    open={showImageViewer}
                    message={""}
                    size="md"
                    showSubmit={true}
                    showCancel={true}
                    title={"Image Viewer"}
                    onSubmit={() => {
                        setShowImageViewer(false);
                        // imageUrls.forEach(URL.revokeObjectURL); // Clean up Object URLs
                        // setImageUrls([]); // Clear image URLs
                    }}
                    onCancel={() => {
                        setShowImageViewer(false);
                        // imageUrls.forEach(URL.revokeObjectURL); // Clean up Object URLs
                        // setImageUrls([]); // Clear image URLs
                    }}
                >
                    <div style={{
                        display: "flex",
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                        <img src={imageUrls[currentRecordIndex]} style={{maxWidth: '100%'}} alt="Fetched Document"/>
                        <div style={{
                            marginTop: '10px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}>
                            <Button onClick={handlePreviousRecord} disabled={imageUrls.length <= 1}>
                                <ArrowBackIcon/>
                            </Button>
                            <Button onClick={handleNextRecord} disabled={imageUrls.length <= 1}>
                                <ArrowForwardIcon/>
                            </Button>
                        </div>
                    </div>
                </AppDialog>
            )}

            {showBusy && (
                <PleaseWaitDialog open={showBusy} title={"Please Wait"} message={"Fetching images..."}
                                  onCancel={() => setShowBusy(false)}/>
            )}

            {selectedDescription !== "" && (
                <AppDialog
                    open={selectedDescription !== ""}
                    message={""}
                    size="md"
                    showSubmit={true}
                    showCancel={true}
                    title={"Description"}
                    onSubmit={() => setSelectedDescription("")}
                    onCancel={() => setSelectedDescription("")}
                >
                    <div style={{display: "flex", justifyContent: 'center'}}>
                        <div dangerouslySetInnerHTML={{__html: selectedDescription}}/>
                    </div>
                </AppDialog>
            )}
       </ComponentModalOrEmbedded>
    );
};

export default DynamicCsvTable;


function convertToCsv(records: DynamicDataItem[]) {
    if (!records || records.length === 0) {
        return '';
    }

    const CSV_SEPARATOR = ';';
    const columns = Object.keys(records[0]).filter(key => key !== 'key');
    const csvHeader = columns.join(CSV_SEPARATOR);

    const csvRows = records.map(record => {
        return columns.map(column => {
            let value = record[column] || "";
            if (value.includes(CSV_SEPARATOR) || value.includes('\n') || value.includes('"')) {
                value = `"${value.replace(/"/g, '""')}"`; // Escape double quotes by doubling them
            }
            return value;
        }).join(CSV_SEPARATOR);
    });

    return [csvHeader, ...csvRows].join('\n');
}

