// import {WorkspaceItem} from "../store/old/temp-slice";
import {LinkedInJob, LinkedInProfile} from "../backend/snippet.services";
import {WorkspaceItem} from "../store/types";
import {useCallback} from "react";
import {setSelectedFile} from "../store/dataSlice";
import {DynamicDataItem} from "../components/csvData/csv_table";
import logger from "./logging_services";

export function getWorkspaceItemData(ws: WorkspaceItem) {
    if (ws.type === 'LinkedIn_Profile') {
        const content = ws.content as LinkedInProfile;
        return content.data;
    }
    if (ws.type === 'LinkedIn_Data') {
        const content = ws.content as LinkedInJob;
        return content.aboutTheVacancy
    }
    if (ws.type.startsWith('wi_'))
        return ws.content as string;

    return '';
}

export function updateWorkspaceItemData(ws: WorkspaceItem, data: string): WorkspaceItem {
    if (ws.type === 'LinkedIn_Profile') {
        const content = { ...(ws.content as LinkedInProfile), data };
        return { ...ws, content };
    }
    if (ws.type === 'LinkedIn_Data') {
        const content = { ...(ws.content as LinkedInJob), aboutTheVacancy: data };
        return { ...ws, content };
    }
    if (ws.type.startsWith('wi_')) {
        return { ...ws, content: data };
    }

    return ws;
}



