// typography.ts
import { TypographyOptions } from '@mui/material/styles/createTypography';

const typography: TypographyOptions = {
  fontFamily: "'Inter', sans-serif",
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontSize: "2.5rem",
    fontWeight: 700,
    lineHeight: 1.2,
    "@media (max-width:600px)": {
      fontSize: "2rem",
    },
  },
  h2: {
    fontSize: "2rem",
    fontWeight: 600,
    lineHeight: 1.3,
    "@media (max-width:600px)": {
      fontSize: "1.75rem",
    },
  },
  h3: {
    fontSize: "1.75rem",
    fontWeight: 600,
    lineHeight: 1.3,
    "@media (max-width:600px)": {
      fontSize: "1.5rem",
    },
  },
  h4: {
    fontSize: "1.5rem",
    fontWeight: 500,
    lineHeight: 1.4,
    "@media (max-width:600px)": {
      fontSize: "1.25rem",
    },
  },
  h5: {
    fontSize: "1.25rem",
    fontWeight: 500,
    lineHeight: 1.4,
    "@media (max-width:600px)": {
      fontSize: "1.1rem",
    },
  },
  h6: {
    fontSize: "1.1rem",
    fontWeight: 500,
    lineHeight: 1.5,
    "@media (max-width:600px)": {
      fontSize: "1rem",
    },
  },
  body1: {
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.43,
  },
  body2: {
    fontSize: "0.8rem",
    fontWeight: 400,
    lineHeight: 1.35,
  },
  button: {
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: 1.75,
    textTransform: "uppercase",
  },
  caption: {
    fontSize: "0.75rem",
    fontWeight: 400,
    lineHeight: 1.66,
  },
  overline: {
    fontSize: "0.75rem",
    fontWeight: 400,
    lineHeight: 2.66,
    textTransform: "uppercase",
    letterSpacing: "0.08333em",
  },
};

export default typography;