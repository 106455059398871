import {useEffect, useMemo, useState} from 'react';
import {ObjectItem} from "../files/file_navigator";
import {ExtDataRecordMeta} from "../../backend/extdata.services";
import {fetch_document} from "../../backend/zaia_state";
import logger from "../../utils/logging_services";
import {Grid, TextField, Typography} from "@mui/material";

// --------------------------------------------------------------------------------------------------------
// This component contains
//  - The definition of a state variable CSVHeaderFields
//  - A component CSVHeaderFieldsForm to allow the user to enter instructions about the header fields found.
// --------------------------------------------------------------------------------------------------------

//------------------------------------ CVSHeaderFields ----------------------------------------
export interface CVSHeaderFieldsProps {
    CSVFile: ObjectItem | undefined;
    initialFields?: {[key: string]: string};
}

export const CVSHeaderFields = ({ CSVFile, initialFields }: CVSHeaderFieldsProps): {[key: string]: string} => {
    const [headerFields, setHeaderFields] = useState<{[key: string]: string}>({});
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (!CSVFile) {
            return;
        }

        const metadata: ExtDataRecordMeta | undefined = CSVFile.value?.meta_data;
        if (!metadata) {
            return;
        }

        const containerName = metadata.container_name || "";
        const documentName = metadata.blob_name || "";

        if (containerName === "" || documentName === "") {
            return;
        }

        const fetchHeaderFields = async () => {
            try {
                setIsLoaded(false);
                const blob = await fetch_document(containerName, documentName, true);
                logger.info('Fetched document:', blob);
                const fieldsArray = blob.data.split(';');
                console.log('fieldsArray:', fieldsArray)
                const fieldsObject: {[key: string]: string} = fieldsArray.reduce((acc: {[key: string]: string}, field: string) => {
                     acc[field.trim()] = "";
                    return acc;
                }, {});
                setHeaderFields(fieldsObject);
                setIsLoaded(true);
            } catch (error: any) {
                logger.error('Failed to fetch document:', error);
            }
        };

        fetchHeaderFields().then();
    }, [CSVFile]);


    // useEffect(() => {
    //
    //     if (isLoaded && initialFields) {
    //         // merge field values of initialField to headerFields
    //         const newHeaderFields = {...headerFields};
    //         Object.keys(initialFields).forEach((field) => {
    //             newHeaderFields[field] = initialFields[field];
    //         });
    //         setHeaderFields(newHeaderFields);
    //     }
    //
    // }, [initialFields]);

    return useMemo(() => headerFields, [headerFields]);
};



//------------------------------------ CSVHeaderFieldsForm ----------------------------------------
interface CSVHeaderFieldsFormProps {
    CSVHeaderFields: {[key: string]: string};
    onUpdateFields: (fields: {[key: string]: string}) => void;
}

export const CSVHeaderFieldsForm = ({ CSVHeaderFields, onUpdateFields }: CSVHeaderFieldsFormProps) => {
    const [fields, setFields] = useState<{ [key: string]: string }>({});
    const [isDirty, setIsDirty] = useState<boolean>(false);

    useEffect(() => {
        console.log('CSVHeaderFields:', CSVHeaderFields)
        setFields(CSVHeaderFields);
    }, [CSVHeaderFields]);

    useEffect(() => {
        if ( isDirty ) {
            console.log('fields:', fields)
            onUpdateFields(fields);
            setIsDirty(false);
        }
    }, [fields]);

    const handleFieldChange = (field: string, value: string) => {
        setFields(prevFields => {
            setIsDirty(true);
            return {
                ...prevFields,
                [field]: value
            };
        });
    };

    return (
        <div style={{ marginTop: '10px', display: 'flex', flex: '1 1 0px', flexDirection: 'column' }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>CSV Fields</Typography>
            <Typography variant="subtitle2">Please enter the instructions for each field</Typography>
            <div style={{ margin: '10px', display: 'flex', flex: '1 1 0px' }}>
                <Grid container spacing={2} sx={{ maxHeight: '500px', overflowY: 'auto', flex: '1 1 auto', alignItems: 'flex-start' }}>
                    {Object.keys(CSVHeaderFields).map((field) => (
                        <Grid item xs={6} key={field} container alignItems="center">
                            <Typography variant="subtitle2" sx={{ flex: 1 }}>{field}</Typography>
                            <TextField
                                sx={{ flex: '2', display: 'flex', minWidth: '400px' }}
                                multiline={true}

                                rows={2}
                                value={fields[field] || ''}
                                onChange={(e) => handleFieldChange(field, e.target.value)}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    );
};
