import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { setSelectedFile, setSelectedWorkspaceItemAction } from "../store/old/data-slice";
// import { SelectedFile } from "../store/old/data-slice";
import logger from "../utils/logging_services";
import { htmlToPlainText } from "../utils/common_tools";
import { getWorkspaceItemData } from "../utils/snippet_tools";
import { processSnippetData } from "../components/workspace/workspace_services";
import { DynamicDataItem } from "../components/csvData/csv_table";
import {SelectedFile, WorkspaceItem} from "../store/types";
import {updateWorkspaceItem} from "../store/workspaceItemSlice";
import {setSelectedFile, setSelectedWorkspaceItemAction} from "../store/dataSlice";
import {RootState} from "../store/store";
// import { updateWorkspaceItem, WorkspaceItem } from "../store/old/temp-slice";

interface CsvRecord {
    key: string;
    [key: string]: string;
}

export const useItemSelection = () => {
    const [csvData, setCsvData] = useState("");
    const [csvRecords, setCsvRecords] = useState<CsvRecord[]>([]);
    const [csvFolder, setCsvFolder] = useState("");
    const [csvItemRef, setCsvItemRef] = useState("");
    const [selectedCsvRecordList, setSelectedCsvRecordList] = useState<string[]>([]);
    const [showCsvTable, setShowCsvTable] = useState(false);
    const [selectedCsvChatContext, setSelectedCsvChatContext] = useState<string[]>([]);

    const [selectedContextData, setSelectedContextData] = useState("");
    const [selectedImage, setSelectedImage] = useState<SelectedFile | undefined>(undefined);
    const [selectedPdf, setSelectedPdf] = useState<SelectedFile | undefined>(undefined);
    const [selectedAudio, setSelectedAudio] = useState<SelectedFile | undefined>(undefined);
    const [showSelectedImage, setShowSelectedImage] = useState(false);
    const [showSelectedPdf, setShowSelectedPdf] = useState(false);
    const [showSelectedAudio, setShowSelectedAudio] = useState(false);

    const [mermaidMarkup, setMermaidMarkup] = useState("");
    const [showMermaid, setShowMermaid] = useState(false);

    const [htmlData, setHtmlData] = useState("");
    const [htmlFolder, setHtmlFolder] = useState("");

    const [htmlItemRef, setHtmlItemRef] = useState("");
    const [showHtml, setShowHtml] = useState(false);

    const dispatch = useDispatch();

    const selectedWorkspaceItem = useSelector((state: RootState) => state.data.selectedWorkspaceItem);
    const selectedWorkspaceItemAction = useSelector((state: RootState) => state.data.selectedWorkspaceItemAction);
    const workspaceItems: WorkspaceItem[] = useSelector((state: RootState) => state.workspaceItems.items);

    const processedItemsRef = useRef(new Set<string>());

    const deselectItem = useCallback((itemRef: string) => {
        const item = workspaceItems.find(item => item.uuid === itemRef);
        if (item && item.selected) {
            dispatch(updateWorkspaceItem({ ...item, selected: false }));
        }
    }, [workspaceItems, dispatch]);


     useEffect(() => {

        const selectedContext = selectedCsvRecordList.map((record) => {
            // Initialize result string
            let result = "";

            // Find the record in the list of records
            const selectedRecord = csvRecords.find((rec) => rec.key === record);

            // If the record is found, iterate over each key in the record
            if (selectedRecord) {
                Object.keys(selectedRecord).forEach((key) => {
                    if (key !== 'key' && !key.startsWith('@Z_')) {
                        const keyValue = selectedRecord[key];  // Ensure value is converted to string
                        if (keyValue !== "") {
                            result += `${key}: ${keyValue}\n`;
                        }
                    }
                });
            }

            // Return the result string for this record
            return result;
        });

        // Set the selected context app_theme
        const contextData = selectedContext.join("\n");
        logger.info("Selected context app_theme: ", contextData);

        setSelectedCsvChatContext(selectedContext);

    }, [selectedCsvRecordList]);


    const processItem = useCallback((workspaceItem: WorkspaceItem) => {
        if ( !workspaceItem || ! workspaceItem.uuid ) return;

        if (processedItemsRef.current.has(workspaceItem.uuid)) {
            return;
        }
        processedItemsRef.current.add(workspaceItem.uuid);

        if (workspaceItem.tags?.includes('mermaid')) {
            const data = htmlToPlainText(getWorkspaceItemData(workspaceItem));
            setMermaidMarkup(data);
            setShowMermaid(true);
            return;
        }

        if (workspaceItem.content) {
            const response = processSnippetData(getWorkspaceItemData(workspaceItem));
            if (response.success) {
                switch (response.type) {
                    case 'html':
                        if (htmlItemRef !== workspaceItem.uuid) {
                            deselectItem(htmlItemRef);
                        }
                        const itemData = response.data?.[0] || "";
                        if (itemData) {
                            setHtmlData(itemData);
                            setHtmlFolder(workspaceItem.subtype);
                            setHtmlItemRef(workspaceItem.uuid || '');
                            setShowHtml(true);
                        }
                        break;
                    case 'csv':
                        if (csvItemRef !== workspaceItem.uuid) {
                            deselectItem(csvItemRef);
                        }

                        const csvData = JSON.stringify(response.data);
                        setCsvItemRef(workspaceItem.uuid || "");
                        convertJsonToCSV(csvData, workspaceItem.subtype);
                        break;
                    case 'mermaid':
                        if (response.data) {
                            setMermaidMarkup(response.data[0]);
                            setShowMermaid(true);
                        }
                        break;
                }
            }
        }
    }, [htmlItemRef, deselectItem]);

    const convertJsonToCSV = useCallback((jsonData: string, folder: string): void => {
        try {
            const jsonObjects: Record<string, any>[] = JSON.parse(jsonData);

            if (jsonObjects.length === 0) {
                setCsvRecords([]);
                setShowCsvTable(false);
                return;
            }

            dispatch(setSelectedFile(undefined));

            const columns = Object.keys(jsonObjects[0]);
            const newRecords = jsonObjects.map((item, idx) => {
                const record: DynamicDataItem = { key: `record_${idx}` };
                columns.forEach(column => {
                    let value = item[column]?.toString().trim() || "";
                    if (value.startsWith('"') && value.endsWith('"')) {
                        value = value.substring(1, value.length - 1);
                    }
                    record[column] = value;
                });
                return record;
            });

            setCsvRecords(newRecords);
            setCsvFolder(folder);
            setShowCsvTable(true);
            logger.info("CSV records:", newRecords);
        } catch (error: any) {
            logger.error("Invalid JSON data:", error);
            setCsvRecords([]);
            setShowCsvTable(false);
        }
    }, [dispatch]);

    useEffect(() => {
        if (selectedWorkspaceItemAction === "view" && selectedWorkspaceItem) {
            processItem(selectedWorkspaceItem);
            dispatch(setSelectedWorkspaceItemAction(undefined));
        }
    }, [selectedWorkspaceItemAction, selectedWorkspaceItem, dispatch, processItem]);

    useEffect(() => {
        const checkItemSelection = (itemRef: string, setShow: (show: boolean) => void, clearData: () => void) => {
            const item = workspaceItems.find(item => item.uuid === itemRef);
            if (item && !item.selected) {
                setShow(false);
                clearData();
            }
        };

        checkItemSelection(htmlItemRef, setShowHtml, () => setHtmlData(""));
        checkItemSelection(csvItemRef, setShowCsvTable, () => setCsvRecords([]));

        workspaceItems.filter(item => item.selected).forEach(processItem);

        processedItemsRef.current.clear();
    }, [workspaceItems, htmlItemRef, csvItemRef, processItem]);

    useEffect(() => {
        if (!showHtml && htmlItemRef !== "") {
            deselectItem(htmlItemRef);
        }

        if ( !showCsvTable && csvItemRef !== "" ) {
            deselectItem(csvItemRef);
        }

    }, [showHtml, showCsvTable]);

    return {
        csvData,
        csvItemRef,
        csvFolder,
        htmlData,
        htmlItemRef,
        htmlFolder,
        csvRecords,
        mermaidMarkup,
        selectedCsvRecordList,
        selectedContextData,
        showCsvTable,
        selectedCsvChatContext,
        selectedImage,
        selectedPdf,
        selectedAudio,
        showSelectedImage,
        showSelectedPdf,
        showSelectedAudio,
        showMermaid,
        showHtml,
        setSelectedContextData,
        setSelectedCsvRecordList,
        setSelectedCsvChatContext,
        setShowCsvTable,
        setShowHtml,
        setShowSelectedImage,
        setShowSelectedPdf,
        setShowSelectedAudio,
        setShowMermaid,
        setMermaidMarkup,
        setCsvRecords,
        setHtmlData,
        setHtmlFolder,
        setCsvData,
        setSelectedImage,
        setSelectedPdf,
        setSelectedAudio,
    };
};

// import {useState, useEffect} from 'react';
// import {useDispatch, useSelector} from 'react-redux';
// import {setSelectedFile, setSelectedWorkspaceItemAction} from "../store/data-slice";
// import {SelectedFile} from "../store/data-slice";
// import logger from "../utils/logging_services";
// import {htmlToPlainText} from "../utils/common_tools";
// import {getWorkspaceItemData} from "../utils/snippet_tools";
// import {processSnippetData} from "../components/workspace/workspace_services";
// import {DynamicDataItem} from "../components/csvData/csv_table";
// import {updateWorkspaceItem, WorkspaceItem} from "../store/temp-slice";
//
// interface CsvRecord {
//     key: string;
//
//     [key: string]: string; // Allow dynamic keys
// }
//
// export const useItemSelection = () => {
//
//     const [csvData, setCsvData] = useState("");
//     const [csvRecords, setCsvRecords] = useState<CsvRecord[]>([]);
//     const [csvItemRef, setCsvItemRef] = useState<string>("");
//     const [selectedCsvRecordList, setSelectedCsvRecordList] = useState<string[]>([]);
//     const [showCsvTable, setShowCsvTable] = useState(false);
//     const [selectedCsvChatContext, setSelectedCsvChatContext] = useState<string[]>([]);
//
//     const [selectedContextData, setSelectedContextData] = useState<string>("");
//     const [selectedImage, setSelectedImage] = useState<SelectedFile | undefined>(undefined);
//     const [selectedPdf, setSelectedPdf] = useState<SelectedFile | undefined>(undefined);
//     const [selectedAudio, setSelectedAudio] = useState<SelectedFile | undefined>(undefined);
//     const [showSelectedImage, setShowSelectedImage] = useState(false);
//     const [showSelectedPdf, setShowSelectedPdf] = useState(false);
//     const [showSelectedAudio, setShowSelectedAudio] = useState(false);
//
//     const [mermaidMarkup, setMermaidMarkup] = useState<string>("");
//     const [showMermaid, setShowMermaid] = useState(false);
//
//     const [htmlData, setHtmlData] = useState<string>("");
//     const [htmlItemRef, setHtmlItemRef] = useState<string>("");
//
//     const [showHtml, setShowHtml] = useState(false);
//
//     const dispatch = useDispatch();
//
//     const selectedWorkspaceItem = useSelector((state: any) => state.data.selectedWorkspaceItem);
//     const selectedWorkspaceItemAction = useSelector((state: any) => state.data.selectedWorkspaceItemAction);
//     const workspaceItems: WorkspaceItem[] = useSelector((state: any) => state.temp.workspaceItems);
//
//     // Action performed on selected workspace item
//     useEffect(() => {
//
//         if (selectedWorkspaceItemAction === undefined) return;
//
//         if (selectedWorkspaceItemAction === "view") {
//             processItem(selectedWorkspaceItem)
//             dispatch(setSelectedWorkspaceItemAction(undefined));
//         }
//     }, [selectedWorkspaceItemAction]);
//
//
//     // Show the corresponding data for the selected workspace item when triggered.
//     // In addition, reset the data view for all unselected workspace items.
//     useEffect(() => {
//
//         // part 1 : Check if selected items are still selected, if not deselect them
//         let item = workspaceItems.find((item: WorkspaceItem) => item.uuid === htmlItemRef);
//         if (item?.selected === false) {
//             setShowHtml(false);
//             setHtmlData("");
//         }
//
//         item = workspaceItems.find((item: WorkspaceItem) => item.uuid === csvItemRef);
//         if (item?.selected === false) {
//             setShowCsvTable(false);
//             setCsvRecords([]);
//         }
//
//         // part 2 : Check if selected items are selected and process them
//         const selectedItems = workspaceItems.filter((item: any) => item.selected === true);
//         for (let i = 0; i < selectedItems.length; i++) {
//             const item = selectedItems[i];
//             processItem(item);
//         }
//
//     }, [workspaceItems]);
//
//     const deselectItem = (itemRef: string) => {
//         const item = workspaceItems.find((item: any) => item.uuid === itemRef);
//         if (item) {
//             const updatedItem = {...item, selected: false};
//             dispatch(updateWorkspaceItem(updatedItem));
//         }
//     }
//
//     useEffect(() => {
//
//         if (!showHtml && htmlItemRef !== "") {
//             deselectItem(htmlItemRef);
//         }
//
//     }, [showMermaid, showHtml, showCsvTable]);
//
//         // useEffect(() => {
//         //
//         //     const selectedContext = selectedCsvRecordList.map((record) => {
//         //         // Initialize result string
//         //         let result = "";
//         //
//         //         // Find the record in the list of records
//         //         const selectedRecord = csvRecords.find((rec) => rec.key === record);
//         //
//         //         // If the record is found, iterate over each key in the record
//         //         if (selectedRecord) {
//         //             Object.keys(selectedRecord).forEach((key) => {
//         //                 if (key !== 'key' && !key.startsWith('@Z_')) {
//         //                     const keyValue = selectedRecord[key];  // Ensure value is converted to string
//         //                     if (keyValue !== "") {
//         //                         result += `${key}: ${keyValue}\n`;
//         //                     }
//         //                 }
//         //             });
//         //         }
//         //
//         //         // Return the result string for this record
//         //         return result;
//         //     });
//         //
//         //     // Set the selected context app_theme
//         //     const contextData = selectedContext.join("\n");
//         //     logger.info("Selected context app_theme: ", contextData);
//         //
//         //     setSelectedCsvChatContext(selectedContext);
//         //
//         // }, [selectedCsvRecordList]);
//         //
//         // const isImageFile = (filename: string) => {
//         //     const imageExtensions = ['jpg', 'jpeg', 'png', 'webp'];
//         //     const extension = filename.split('.').pop()?.toLowerCase();
//         //     return imageExtensions.includes(extension || "");
//         // };
//         //
//         // const isAudioFile = (filename: string) => {
//         //     const audioExtensions = ['mp3', 'wav', 'ogg', 'm4a'];
//         //     const extension = filename.split('.').pop()?.toLowerCase();
//         //     return audioExtensions.includes(extension || "");
//         // };
//         //
//         // useEffect(() => {
//         //     if (csvData !== "") {
//         //         const rows = csvData.trim().split('\n').filter(row => row.length > 0);
//         //         const columns = rows[0].split(';').map(column => column.trim());
//         //         const newRecords = rows.slice(1).map((row, idx) => {
//         //             const values = row.split(';');
//         //             let record: CsvRecord = {key: `record_${idx}`};
//         //             columns.forEach((column, index) => {
//         //                 let value = values[index]?.trim() || "";
//         //                 if (value.startsWith('"') && value.endsWith('"')) {
//         //                     value = value.substring(1, value.length - 1);
//         //                 }
//         //                 record[column] = value;
//         //             });
//         //             return record;
//         //         });
//         //         setCsvRecords(newRecords);
//         //     } else {
//         //         setCsvRecords([]);
//         //         setShowCsvTable(false);
//         //     }
//         // }, [csvData]);
//
//
//         const processItem = (workspaceItem: WorkspaceItem) => {
//
//             if (workspaceItem.tags && workspaceItem.tags.includes('mermaid')) {
//                 const data = htmlToPlainText(getWorkspaceItemData(workspaceItem));
//                 setMermaidMarkup(data);
//                 setShowMermaid(true);
//                 return;
//             }
//
//             if (workspaceItem.tags) {
//
//                 if (workspaceItem.content) {
//                     const response = processSnippetData(getWorkspaceItemData(workspaceItem));
//                     if (response.success) {
//                         switch (response.type) {
//                             case 'html':
//                                 if (htmlItemRef !== workspaceItem.uuid) {
//                                     deselectItem(htmlItemRef);
//                                 }
//
//                                 const itemData = response?.data ? response.data[0] : ""
//                                 if (itemData !== "") {
//                                     setHtmlData(itemData);
//                                     setHtmlItemRef(workspaceItem.uuid || '');
//                                     setShowHtml(true);
//                                 }
//                                 break;
//                             case 'text':
//                                 break;
//                             case 'json':
//                                 break;
//                             case 'csv':
//                                 const csvData = JSON.stringify(response.data)
//                                 setHtmlItemRef(workspaceItem.uuid || "");
//                                 convertJsonToCSV(csvData);
//                                 break;
//                             case 'mermaid':
//                                 if (response.data) {
//                                     setMermaidMarkup(response.data[0]);
//                                     setShowMermaid(true);
//                                 }
//                                 break;
//                             default:
//                                 break;
//                         }
//                     }
//                 }
//             }
//         }
//
//         const convertJsonToCSV = (jsonData: string): void => {
//             try {
//                 if (jsonData && jsonData.length > 0) {
//                     const jsonObjects: Record<string, any>[] = JSON.parse(jsonData);
//
//                     if (jsonObjects.length === 0) {
//                         setCsvRecords([]);
//                         setShowCsvTable(false);
//                         return;
//                     }
//
//                     // Clear the selected file, this could interfere the CSV table viewer
//                     dispatch(setSelectedFile(undefined));
//
//                     // Extract columns
//                     const columns = Object.keys(jsonObjects[0]);
//
//                     const newRecords = jsonObjects.map((item, idx) => {
//                         let record: DynamicDataItem = {key: `record_${idx}`};
//                         columns.forEach(column => {
//                             let value = item[column]?.toString().trim() || "";
//                             if (value.startsWith('"') && value.endsWith('"')) {
//                                 value = value.substring(1, value.length - 1);
//                             }
//                             record[column] = value;
//                         });
//                         return record;
//                     });
//
//                     setCsvRecords(newRecords);
//                     setShowCsvTable(true);
//                     logger.info("CSV records:", newRecords);
//                 } else {
//                     setCsvRecords([]);
//                     setShowCsvTable(false);
//                 }
//             } catch (error: any) {
//                 logger.error("Invalid JSON app_theme:", error);
//                 setCsvRecords([]);
//                 setShowCsvTable(false);
//             }
//         }
//
//         return {
//             csvData,
//             htmlData,
//             csvRecords,
//             mermaidMarkup,
//             selectedCsvRecordList,
//             selectedContextData,
//             showCsvTable,
//             selectedCsvChatContext,
//             selectedImage,
//             selectedPdf,
//             selectedAudio,
//             showSelectedImage,
//             showSelectedPdf,
//             showSelectedAudio,
//             showMermaid,
//             showHtml,
//             setSelectedContextData,
//             setSelectedCsvRecordList,
//             setSelectedCsvChatContext,
//             setShowCsvTable,
//             setShowHtml,
//             setShowSelectedImage,
//             setShowSelectedPdf,
//             setShowSelectedAudio,
//             setShowMermaid,
//             setMermaidMarkup,
//             setCsvRecords,
//             setHtmlData,
//             setCsvData,
//             setSelectedImage,
//             setSelectedPdf,
//             setSelectedAudio,
//             // isImageFile,
//             // isAudioFile,
//         };
//     };
//
