import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetch_document } from "../backend/zaia_state";
import { KnowledgeBaseItem } from "../backend/extdata.services";
// import { setSelectedFileRecords } from "../store/old/data-slice";
// import { SelectedFile } from "../store/old/data-slice";
import logger from "../utils/logging_services";
import {SelectedFile} from "../store/types";
import {setSelectedFileRecords} from "../store/dataSlice";

interface CsvRecord {
    key: string;
    [key: string]: string; // Allow dynamic keys
}

export const useFileSelection = () => {
    const [csvData, setCsvData] = useState("");
    const [csvRecords, setCsvRecords] = useState<CsvRecord[]>([]);
    const [selectedCsvRecordList, setSelectedCsvRecordList] = useState<string[]>([]);
    const [showCsvTable, setShowCsvTable] = useState(false);
    const [selectedCsvChatContext, setSelectedCsvChatContext] = useState<string[]>([]);
    const [selectedContextData, setSelectedContextData] = useState<string>("");
    const [selectedImage, setSelectedImage] = useState<SelectedFile | undefined>(undefined);
    const [selectedPdf, setSelectedPdf] = useState<SelectedFile | undefined>(undefined);
    const [selectedAudio, setSelectedAudio] = useState<SelectedFile | undefined>(undefined);
    const [showSelectedImage, setShowSelectedImage] = useState(false);
    const [showSelectedPdf, setShowSelectedPdf] = useState(false);
    const [showSelectedAudio, setShowSelectedAudio] = useState(false);

    const dispatch = useDispatch();

    const handleFileSelected = (record: KnowledgeBaseItem | undefined) => {
        if (record === undefined) {
            setSelectedCsvRecordList([]);
            setSelectedContextData("");
            setCsvData("");
            setShowCsvTable(false);
            setSelectedCsvChatContext([]);
            setShowSelectedImage(false);
            setShowSelectedPdf(false);
            setShowSelectedAudio(false);
            dispatch(setSelectedFileRecords([]));
            return;
        }

        if (record.meta_data.resource.endsWith("csv")) {
            setShowCsvTable(true);
        } else if (isImageFile(record.meta_data.resource)) {
            setShowSelectedImage(true);
        } else if (record.meta_data.resource.endsWith("pdf")) {
            setShowSelectedPdf(true);
        } else if (isAudioFile(record.meta_data.resource)) {
            setShowSelectedAudio(true);
        } else {
            setShowCsvTable(false);
            setShowSelectedImage(false);
            setShowSelectedPdf(false);
            setShowSelectedAudio(false);
        }
    };

    const fetchDocument = async (container_name: string, blob_name: string, isBase64: boolean) => {
        return await fetch_document(container_name, blob_name, isBase64);
    }

    const loadSelectedFileData = (selectedFile: SelectedFile) => {
        if (selectedFile.fileName.endsWith("csv")) {
            if (selectedFile.metadata.container_name && selectedFile.metadata.blob_name) {
                fetch_document(selectedFile.metadata.container_name, selectedFile.metadata.blob_name, false).then((data) => {
                    setCsvData(data.data);
                    setSelectedCsvRecordList(selectedFile.records);
                    setShowCsvTable(true);
                });
            }
        } else if (selectedFile.fileName.endsWith("pdf")) {
            setSelectedPdf(selectedFile);
            setShowSelectedPdf(true);
        } else if (isImageFile(selectedFile.fileName)) {
            setSelectedImage(selectedFile);
            setShowSelectedImage(true);
        } else if (isAudioFile(selectedFile.fileName)) {
            setSelectedAudio(selectedFile);
            setShowSelectedAudio(true);
        }
    };

    useEffect(() => {

        const selectedContext = selectedCsvRecordList.map((record) => {
            // Initialize result string
            let result = "";

            // Find the record in the list of records
            const selectedRecord = csvRecords.find((rec) => rec.key === record);

            // If the record is found, iterate over each key in the record
            if (selectedRecord) {
                Object.keys(selectedRecord).forEach((key) => {
                    if (key !== 'key' && !key.startsWith('@Z_')) {
                        const keyValue = selectedRecord[key];  // Ensure value is converted to string
                        if (keyValue !== "") {
                            result += `${key}: ${keyValue}\n`;
                        }
                    }
                });
            }

            // Return the result string for this record
            return result;
        });

        // Set the selected context app_theme
        const contextData = selectedContext.join("\n");
        logger.info("Selected context app_theme: ", contextData);

        setSelectedCsvChatContext(selectedContext);

    }, [selectedCsvRecordList]);

    const isImageFile = (filename: string) => {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'webp'];
        const extension = filename.split('.').pop()?.toLowerCase();
        return imageExtensions.includes(extension || "");
    };

    const isAudioFile = (filename: string) => {
        const audioExtensions = ['mp3', 'wav', 'ogg', 'm4a', "mp4"];
        const extension = filename.split('.').pop()?.toLowerCase();
        return audioExtensions.includes(extension || "");
    };

    useEffect(() => {
        if (csvData !== "") {
            const rows = csvData.trim().split('\n').filter(row => row.length > 0);
            const columns = rows[0].split(';').map(column => column.trim());
            const newRecords = rows.slice(1).map((row, idx) => {
                const values = row.split(';');
                let record: CsvRecord = {key: `record_${idx}`};
                columns.forEach((column, index) => {
                    let value = values[index]?.trim() || "";
                    if (value.startsWith('"') && value.endsWith('"')) {
                        value = value.substring(1, value.length - 1);
                    }
                    record[column] = value;
                });
                return record;
            });
            setCsvRecords(newRecords);
        } else {
            setCsvRecords([]);
            setShowCsvTable(false);
        }
    }, [csvData]);



    return {
        csvData,
        csvRecords,
        selectedCsvRecordList,
        selectedContextData,
        showCsvTable,
        selectedCsvChatContext,
        selectedImage,
        selectedPdf,
        selectedAudio,
        showSelectedImage,
        showSelectedPdf,
        showSelectedAudio,
        handleFileSelected,
        loadSelectedFileData,
        setSelectedContextData,
        setSelectedCsvRecordList,
        setSelectedCsvChatContext,
        setShowCsvTable,
        setShowSelectedImage,
        setShowSelectedPdf,
        setShowSelectedAudio,
        setCsvRecords,
        setCsvData,
        setSelectedImage,
        setSelectedPdf,
        setSelectedAudio,
        isImageFile,
        isAudioFile,
        fetchDocument
    };
};