import {appSession} from "../managers/generalManager";
import axios from "axios";
import config from "../config.json";
import logger from "../utils/logging_services";

const service_url = config.mode === 'dev' ? config.server_url_dev : config.server_url_prod;

interface ActionRequestBase {
    name: string;
    context_data: string;
    context_type: string;
    language: string;
    version: string;
    temperature: number;
    provider: string
}

export interface BookRequest extends ActionRequestBase {
    title: string;
    number_of_chapters: number;
}

export interface TaskRequest extends ActionRequestBase {
    task: string;
}

export interface ImageRequest extends ActionRequestBase {
    image_prompt: string;
    image_size: string;
}

interface ActionResponse {
    uuid: string| null;
    message: string;
    status: string;
    data: string | null;
}

export function action_create_book(bookRequest: BookRequest): Promise<ActionResponse> {
    const headers = { Authorization: `Bearer ${appSession.accessToken}`, Accept: 'application/json' };
    let url = `${service_url}/modules/zaia_services/create_book/${appSession.currentProject}`;

    return axios.post(url, bookRequest, { headers })
        .then(response => {
            // Assuming the response has a uuid field, else you will need to adjust this
            return response.data as ActionResponse;
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error('Create Book Service Request Error:', error);
            let errorMessage = "Unknown error occurred.";
            let errorStatus = 'error';
            if (error.response) {
                const { status, statusText } = error.response;
                errorMessage = `Request failed with status ${status} (${statusText})`;
                errorStatus = status.toString();
            } else if (error.message) {
                errorMessage = error.message;
            }
            return { uuid: null, status: errorStatus, message: errorMessage, data: null };
        });
}

//

export function action_create_image(imageRequest: ImageRequest): Promise<ActionResponse> {
    const headers = { Authorization: `Bearer ${appSession.accessToken}`, Accept: 'application/json' };
    let url = `${service_url}/modules/zaia_services/create_image/${appSession.currentProject}`;

    return axios.post(url, imageRequest, { headers })
        .then(response => {
            // Assuming the response has a uuid field, else you will need to adjust this
            return response.data as ActionResponse;
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error('Create Image Service Request Error:', error);
            let errorMessage = "Unknown error occurred.";
            let errorStatus = 'error';
            if (error.response) {
                const { status, statusText } = error.response;
                errorMessage = `Request failed with status ${status} (${statusText})`;
                errorStatus = status.toString();
            } else if (error.message) {
                errorMessage = error.message;
            }
            return { uuid: null, status: errorStatus, message: errorMessage, data: null };
        });
}

export function action_run_task(taskRequest: TaskRequest): Promise<ActionResponse> {
    const headers = { Authorization: `Bearer ${appSession.accessToken}`, Accept: 'application/json' };
    let url = `${service_url}/modules/zaia_services/run_task/${appSession.currentProject}`;

    return axios.post(url, taskRequest, { headers })
        .then(response => {
            // Assuming the response has a uuid field, else you will need to adjust this
            return response.data as ActionResponse;
        })
        .catch(error => {
            if (error instanceof Error)
                logger.error('Run Task Service Request Error:', error);
            let errorMessage = "Unknown error occurred.";
            let errorStatus = 'error';
            if (error.response) {
                const { status, statusText } = error.response;
                errorMessage = `Request failed with status ${status} (${statusText})`;
                errorStatus = status.toString();
            } else if (error.message) {
                errorMessage = error.message;
            }
            return { uuid: null, status: errorStatus, message: errorMessage, data: null };
        });
}