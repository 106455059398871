import React, {useState, useEffect} from 'react';
import {
    Button, Dialog, DialogContent, DialogContentText, DialogTitle, Divider,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField, Typography
} from "@mui/material";
import {PersonaDefinition, savePersonaSettings} from "../../managers/personaManager";
import {personaDefinitionList} from "../../managers/generalManager";
import DialogActions from "@mui/material/DialogActions";
import DropdownSelectorWithImage from "../chat/dropdownSelectorWithImage";
import logger from "../../utils/logging_services";
import colorSchema from "../../app_theme/theme_support/colorSchema";

type AddNewPersonaDialogProps = {
    openDialog: boolean;
    setOpenDialog: (open: boolean) => void;
    addNewPersona: (persona: PersonaDefinition) => void;
};

export const AddNewPersonaDialog: React.FC<AddNewPersonaDialogProps> = ({openDialog, setOpenDialog, addNewPersona}) => {
    const [newPersonaName, setNewPersonaName] = useState('');

    const handleDialogClose = () => {
        setOpenDialog(false);
        setNewPersonaName('');
    };

    const handleCreateNewPersona = () => {
        if (newPersonaName) {
            const newPersona: PersonaDefinition = {
                name: newPersonaName,
                image: 'p1',
                about: '',
                extraInstructions: '',
            };
            addNewPersona(newPersona);
            handleDialogClose();
        }
    };

    return (
        <Dialog open={openDialog} onClose={handleDialogClose}>
            <DialogTitle>Add New Persona</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter a name for the new persona.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Persona Name"
                    fullWidth
                    variant="outlined"
                    value={newPersonaName}
                    onChange={(e) => setNewPersonaName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button onClick={handleCreateNewPersona}>Create</Button>
            </DialogActions>
        </Dialog>
    );
};

export const PersonaEditor = () => {
    const [selectedPersona, setSelectedPersona] = useState<PersonaDefinition | null>(null);
    const [personaList, setPersonaList] = useState<PersonaDefinition[]>(personaDefinitionList);

    const [name, setName] = useState<string>('');
    const [image, setImage] = useState<string>('p1');
    const [about, setAbout] = useState<string>('');
    const [extraInstructions, setExtraInstructions] = useState<string>('');
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if (personaList.length > 0) {
            setSelectedPersona(personaList[0]);
            updateForm(personaList[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const updatedPersona = personaList.find(p => p.name === selectedPersona?.name);
        if (updatedPersona) {
            setSelectedPersona(updatedPersona);
        }
    }, [personaList]);

    const handleSelectChange = (event: SelectChangeEvent) => {
        const selected = personaList.find(persona => persona.name === event.target.value) || null;
        if (selected) {
            setSelectedPersona(selected);
            updateForm(selected);
        } else {
            setSelectedPersona(null);
            setName('');
            setAbout('');
            setExtraInstructions('');
            setImage('');
        }
    };

    const handleSavePersona = () => {
        logger.info("handleUpdatePersona", personaList);
        savePersonaSettings(personaList);
        window.location.reload()
    };

    const handleUpdatePersona = () => {
        if (selectedPersona) {
            const updatedPersona: PersonaDefinition = {
                ...selectedPersona,
                name: name,
                about: about,
                image: image,
                extraInstructions: extraInstructions,
            };

            setPersonaList(currentList => {
                const index = currentList.findIndex(p => p.name === selectedPersona.name);
                if (index === -1) return currentList; // If not found, return the list as is

                const newList = [...currentList];
                newList[index] = updatedPersona;
                return newList;
            });
        }
    };

    const handleDeletePersona = () => {
        if (selectedPersona) {
            setPersonaList(currentList =>
                currentList.filter(p => p.name !== selectedPersona.name)
            );

            if (personaList.length > 0) {
                setSelectedPersona(personaList[0]);
                updateForm(personaList[0]);
            } else {
                setSelectedPersona(null);
                setName('');
                setAbout('');
                setExtraInstructions('');
                setImage('');
            }
        }
    };

    const imageOptions = [
        {value: 'p1', label: 'Person 1', image: require('../../assets/p1.png')},
        {value: 'p2', label: 'Person 2', image: require('../../assets/p2.png')},
        {value: 'p3', label: 'Person 3', image: require('../../assets/p3.png')},
        {value: 'p4', label: 'Person 4', image: require('../../assets/p4.png')},
        {value: 'p5', label: 'Person 5', image: require('../../assets/p5.png')},
        {value: 'p6', label: 'Person 6', image: require('../../assets/p6.png')},
        {value: 'p7', label: 'Person 7', image: require('../../assets/p7.png')},
        {value: 'p8', label: 'Person 8', image: require('../../assets/p8.png')},
        {value: 'p9', label: 'Person 9', image: require('../../assets/p9.png')},
        {value: 'p10', label: 'Person 10', image: require('../../assets/p10.png')},
    ];

    const handleImageChange = (value: string) => {
        setImage(value);
        handleUpdatePersona();
    }

    const updateForm = (persona: PersonaDefinition) => {
        setName(persona.name);
        setImage(persona.image)
        setAbout(persona.about);
        setExtraInstructions(persona.extraInstructions);
    }

    const addNewPersona = (newPersona: PersonaDefinition) => {
        setPersonaList([...personaList, newPersona]);
        setSelectedPersona(newPersona);
        updateForm(newPersona);
    };

    return (
        <div style={{padding: '10px', display: 'flex', flexDirection: 'column', flex: 1}}>
            <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px', marginBottom: '10px'}}>
                <Typography variant={'body1'} style={{marginBottom: '10px'}}>
                    Please select
                </Typography>
                <Select
                    value={selectedPersona?.name || ''}
                    onChange={handleSelectChange}
                    variant="outlined"
                    size="small"
                    style={{marginBottom: '10px'}}
                >
                    {personaList.map(persona => (
                        <MenuItem key={persona.name} value={persona.name}>
                            {persona.name}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <Divider></Divider>
            {selectedPersona && (
                <>
                    <div
                        style={{display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px', marginBottom: '10px'}}>
                        <img
                            src={require(`../../assets/${selectedPersona.image}.png`)}
                            style={{width: '100px', height: '100px'}}
                            alt={selectedPersona.name}
                        />
                        <TextField
                            size={'small'}
                            label="Name"
                            value={name}
                            fullWidth={true}
                            onChange={(e) => setName(e.target.value)}
                            style={{marginBottom: '10px', marginTop: '10px'}}
                        />
                        <div style={{maxWidth: "200px"}}>
                            <DropdownSelectorWithImage
                                options={imageOptions}
                                label={""}
                                showLabel={true}
                                onChange={handleImageChange}
                                defaultValue={selectedPersona.image}
                            />
                        </div>
                    </div>


                    {/*<Typography variant={'caption'} style={{marginBottom: '10px'}}>*/}
                    {/*    What would you like ZAIA to know about you to provide better responses?*/}
                    {/*</Typography>*/}
                    {/*<TextField*/}
                    {/*    size={'small'}*/}
                    {/*    multiline={true}*/}
                    {/*    rows={4}*/}
                    {/*    maxRows={4}*/}
                    {/*    value={about}*/}
                    {/*    onChange={(e) => setAbout(e.target.value)}*/}
                    {/*    style={{marginBottom: '10px'}}*/}
                    {/*/>*/}
                    <Typography variant={'caption'} style={{marginBottom: '10px'}}>
                        How would you like ZAIA to respond?
                    </Typography>
                    <TextField
                        size={'small'}
                        multiline={true}
                        rows={12}
                        maxRows={12}
                        value={extraInstructions}
                        onChange={(e) => setExtraInstructions(e.target.value)}
                        style={{marginBottom: '10px'}}
                    />
                </>
            )}
            <div style={{marginTop: '20px', textAlign: 'end'}}>
                {/* Add buttons for CRUD operations */}
                <Button variant="contained"
                        style={{marginRight: '2px', backgroundColor: colorSchema.primary.main}}
                        onClick={() => setOpenDialog(true)}>
                    Create New
                </Button>
                <Button variant="contained"
                        style={{marginRight: '2px', backgroundColor: colorSchema.primary.main}}
                        onClick={handleUpdatePersona}
                >
                    Update
                </Button>
                <Button variant="contained"
                        style={{marginRight: '2px', backgroundColor: colorSchema.warning.main}}
                        onClick={handleDeletePersona}
                >
                    Delete
                </Button>
                <Button variant="contained"
                        style={{marginRight: '2px', backgroundColor: colorSchema.warning.main}}
                        onClick={handleSavePersona}
                >
                    Save
                </Button>
            </div>
            <AddNewPersonaDialog
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                addNewPersona={addNewPersona}
            />
        </div>
    );
};
