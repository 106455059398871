import React, {useEffect, useState, useCallback} from "react";
import {Grid, TextField, Alert, useTheme, useMediaQuery, Box} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {EditorState, convertToRaw, ContentState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import AppDialog from "../general/app_dialog";
import {SnippetData} from "../../backend/snippet.services";
// import {AppDispatch, AppState} from "../../store/old/store";
import DropdownSelector from "../chat/dropdownSelector";
// import {addWorkspaceItemService, fetchWorkspaces} from "../../store/old/data-thunk";
import {EditorViewer} from "../viewers/editor_viewer";
import {AppState, snippetTypes} from "../../managers/generalManager";
// import {Workspace} from "../../store/old/data-slice";
import colorSchema from "../../app_theme/theme_support/colorSchema";
import {Workspace} from "../../store/types";
import {AppDispatch, RootState} from "../../store/store";
import {addWorkspaceItemService, fetchWorkspaces} from "../../store/thunks";

interface SaveToWorkspaceProps {
    workspace?: Workspace;
    workspaceFolder?: string;
    onSubmit: (data: SnippetData) => void;
    onCancel: () => void;
    type: string;
    context_data: string;
}

const loadEditorState = (value: string): EditorState => {
    const {contentBlocks, entityMap} = htmlToDraft(value || "");
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return EditorState.createWithContent(contentState);
};

export const SaveToWorkspace: React.FC<SaveToWorkspaceProps> = ({
                                                                    workspace,
                                                                    workspaceFolder,
                                                                    onSubmit,
                                                                    onCancel,
                                                                    type,
                                                                    context_data
                                                                }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [name, setName] = useState<string>("");
    const [selectedType, setSelectedType] = useState<string>(type);
    const [editorState, setEditorState] = useState<EditorState>(loadEditorState(context_data));
    const [error, setError] = useState<string | null>(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const workspaces = useSelector((state: RootState) => state.workspaces.workspaces || []);
    const app_selected_workspace = useSelector((state: RootState) => state.data.selectedWorkspace);
    const app_selected_folder = useSelector((state: RootState) => state.data.selectedFolder);

    const [currentWorkspace, setCurrentWorkspace] = useState<Workspace | undefined>(workspace || app_selected_workspace);
    const [selectedFolder, setSelectedFolder] = useState<string>(workspaceFolder || app_selected_folder);

    useEffect(() => {
        dispatch(fetchWorkspaces({user_scope: true}));
    }, [dispatch]);

    useEffect(() => {
        if (workspaces.length > 0 && !currentWorkspace) {
            setCurrentWorkspace(workspaces[0]);
        }
    }, [workspaces, currentWorkspace]);

    const handleSaveToWorkspace = useCallback(() => {
        if (!name.trim()) {
            setError("Name is required");
            return;
        }
        if (!currentWorkspace) {
            setError("No workspace selected");
            return;
        }

        setError(null);

        const newWorkspaceItem: SnippetData = {
            name,
            content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
            type: `wi_${currentWorkspace.name}`,
            subtype: selectedFolder,
            reference: "",
            tags: [selectedType],
        };

        dispatch(addWorkspaceItemService({snippet: newWorkspaceItem, user_scope: currentWorkspace.user_scope}));
        onSubmit(newWorkspaceItem);
    }, [name, currentWorkspace, selectedFolder, selectedType, editorState, dispatch, onSubmit]);

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
        if (e.target.value.trim()) {
            setError(null);
        }
    };

    return (
        <AppDialog
            open={true}
            size={isMobile ? "sm" : isTablet ? "md" : "xl"}
            showSubmit={true}
            showCancel={true}
            title="Save to workspace"
            message=""
            onSubmit={handleSaveToWorkspace}
            onCancel={onCancel}
        >
            <Box sx={{width: '100%', maxWidth: '100%', overflow: 'hidden'}}>
                {error && <Alert severity="error" sx={{mb: 2}}>{error}</Alert>}

                {/* item name */}
                <TextField
                    id="name"
                    fullWidth
                    required
                    type="text"
                    sx={{my: 1}}
                    variant="outlined"
                    onChange={handleNameChange}
                    value={name}
                    error={!!error}
                    helperText={error || ""}
                    label="Name"
                />

                {/* content editor */}
                <Box sx={{
                    height: isMobile ? '300px' : isTablet ? '500px' : '600px',
                    my: 1,
                    backgroundColor: colorSchema.background.default,
                    overflow: "hidden"
                }}>
                    <EditorViewer
                        editorState={editorState}
                        showActions={false}
                        onEditorStateChange={setEditorState}
                        onSendRequest={() => {
                        }}
                        onUpdateEditorState={setEditorState}
                    />
                </Box>

                {/* select workspace, folder and type of content */}
                <Grid container spacing={2} sx={{mt: 1, mb: 2}}>
                    <Grid item xs={12} sm={6} md={4}>
                        <DropdownSelector
                            defaultValue={currentWorkspace?.name || "None"}
                            options={workspaces.map((ws) => ({label: ws.name, value: ws.name}))}
                            label="Workspace"
                            onChange={(value) => setCurrentWorkspace(workspaces.find((ws) => ws.name === value))}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <DropdownSelector
                            defaultValue={selectedFolder}
                            options={currentWorkspace?.folder_list.map((folder) => ({
                                label: folder.name,
                                value: folder.name
                            })) || []}
                            label="Folder"
                            onChange={setSelectedFolder}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <DropdownSelector
                            defaultValue={selectedType}
                            options={snippetTypes.map((contentType) => ({label: contentType, value: contentType}))}
                            label="Content Type"
                            onChange={setSelectedType}
                        />
                    </Grid>
                </Grid>
            </Box>
        </AppDialog>
    );
};


