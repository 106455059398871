import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {Accordion, AccordionDetails, AccordionSummary, IconButton, Menu, MenuItem, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import {WorkspaceType} from "./linkedin_viewer";
import logger from "../../utils/logging_services";
// import {
//     updateLinkedInItem,
//     WorkspaceItem
// } from "../../store/old/temp-slice";
// import {AppDispatch} from "../../store/old/store";
import {LinkedInJob, LinkedInProfile} from "../../backend/snippet.services";
import WorkspaceItemCard from "./linkedin_item_card";
import colorSchema from "../../app_theme/theme_support/colorSchema";
import {WorkspaceItem} from "../../store/types";
import {AppDispatch} from "../../store/store";
import {updateLinkedInItem} from "../../store/linkedinItemSlice";

interface LinkedInSnippetViewerProps {
    open: boolean;
    itemType: string;
    uniqueCategories: string[];
    filteredItems: WorkspaceItem[];
    onHandleRequest: (action: string, key: string) => void;
}

export const LinkedInSnippetViewer = ({
                                  open,
                                  itemType,
                                  uniqueCategories,
                                  filteredItems,
                                  onHandleRequest,
                              }: LinkedInSnippetViewerProps) => {

    const dispatch = useDispatch<AppDispatch>();

    const [openTooltip, setOpenTooltip] = useState<boolean>(false);
    const [targetIndex,] = useState<number | null>(null); // New state to track target index
    const [currentCardId, setCurrentCardId] = useState<string>("");
    const tooltipTimeoutRef = useRef<number | null>(null);
    const [, setMenuOptionSelected] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<string | null>("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [itemsToShow, setItemsToShow] = useState<WorkspaceItem[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>("");

    // Load the workspace items for the selected workspace
    useEffect(() => {
    }, [dispatch]);

    useEffect(() => {

        if (filteredItems.length === 0) {
            return
        }
        setItemsToShow(filteredItems.filter((ws) => ws.type === selectedCategory));
    }, [filteredItems, selectedCategory]);

    const handleSelectCategory = (category: string) => {
        if ( selectedCategory === category) {
            setSelectedCategory("");
            return;
        }
        setSelectedCategory(category);
    }

    const handleMouseEnter = (id: string) => {
        setCurrentCardId(id);
        if (tooltipTimeoutRef.current !== null) {
            clearTimeout(tooltipTimeoutRef.current);
        }
        // `window.setTimeout` to emphasize it's the browser's function. The `window.` part is optional.
        tooltipTimeoutRef.current = window.setTimeout(() => {
            setOpenTooltip(true);
        }, 2000);
    };

    const handleMouseLeave = () => {
        if (tooltipTimeoutRef.current !== null) {
            clearTimeout(tooltipTimeoutRef.current);
        }
        setOpenTooltip(false);
        setCurrentCardId("");
    };

    function handleSelectWorkspaceItem(id: string) {
        logger.info("Selecting workspace item", id);
        const selectedItem = filteredItems.find(ws => ws.uuid === id)
        if (!selectedItem) return;

        logger.info("Selected item", selectedItem);
        const updatedItem: WorkspaceItem = {...selectedItem, selected: !selectedItem.selected};
        logger.info("Updated item", updatedItem);
        dispatch(updateLinkedInItem(updatedItem));
    }

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, key: string) => {
        setAnchorEl(event.currentTarget);
        setSelectedItem(key);
    };

    const getItemData = (ws: WorkspaceItem): string => {

        if ( WorkspaceType.LINKEDIN === itemType) {
            if (ws.type === 'LinkedIn_Profile') {
                const content = ws.content as LinkedInProfile;
                return content.data;
            }
            if ( ws.type === 'LinkedIn_Data') {
                const content = ws.content as LinkedInJob;
                return content.aboutTheVacancy
            }
        }

        return ""
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedItem(null);
        setMenuOptionSelected(false);
    };

    const handleRequestWrapper = (action: string, key: string) => {
        setOpenTooltip(false);
        setCurrentCardId("");
        handleMenuClose();
        onHandleRequest(action, key);
    }

    const addMenu = (ws: WorkspaceItem, index: number) => {
        return (
            <>
                <IconButton
                    onClick={(event) => {
                        setMenuOptionSelected(true);
                        setOpenTooltip(false);
                        setCurrentCardId("");
                        event.preventDefault(); // Voorkom de standaardactie
                        event.stopPropagation();
                        handleMenuOpen(event, ws.uuid!);
                    }}
                >
                    <MoreVertIcon/>
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={open && ws.uuid === selectedItem}
                    onClose={(event: React.MouseEvent<HTMLLIElement>) => {
                        event.preventDefault();
                        event.stopPropagation();
                        handleMenuClose();
                    }}
                    sx={{zIndex: 9999}}
                >
                    <MenuItem key={`${ws.uuid}-edit-${index}`}
                              onClick={(event) => {
                                  event.stopPropagation();
                                  event.preventDefault();
                                  handleRequestWrapper('edit', ws.uuid!)
                              }}>
                        <EditIcon color="primary"/>Edit
                    </MenuItem>
                    <MenuItem key={`${ws.uuid}-save-${index}`}
                              onClick={(event) => {
                                  event.stopPropagation();
                                  event.preventDefault();
                                  handleRequestWrapper('save', ws.uuid!)
                              }}>
                        <SaveIcon color="primary"/>Save
                    </MenuItem>
                    <MenuItem key={`${ws.uuid}-use-${index}`}
                              onClick={(event) => {
                                  event.stopPropagation();
                                  event.preventDefault();
                                  handleRequestWrapper('use', ws.uuid!)
                              }}>
                        <PreviewIcon color="primary"/>To Chat
                    </MenuItem>
                    <MenuItem key={`${ws.uuid}-delete-${index}`}
                              onClick={(event) => {
                                  event.stopPropagation();
                                  event.preventDefault();
                                  handleRequestWrapper('delete', ws.uuid!)
                              }}>
                        <DeleteIcon color="primary"/>Delete
                    </MenuItem>
                </Menu>
            </>
        )
    }

    return (
        <>
            <div style={{display: 'flex', overflow: 'auto', flex: 1, flexDirection: 'column'}}>
                {uniqueCategories.map((category, index) => (
                    <Accordion key={category} expanded={selectedCategory === category}
                               sx={{backgroundColor: colorSchema.background.default,}}
                               onChange={() => handleSelectCategory(category)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls={category}
                            id={category}
                            sx={{
                                backgroundColor: selectedCategory === category ? colorSchema.grey : 'inherit',
                                minHeight: '40px',
                                height: '40px',
                                '&.Mui-expanded': {
                                    minHeight: '40px',
                                    height: '40px',
                                }
                            }}
                        >
                            <Typography>
                                {category}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{overflowY: 'auto', flex: 1}}>
                                {itemsToShow && itemsToShow.map((ws, index) => (
                                    <WorkspaceItemCard
                                        key={ws.uuid}
                                        item={ws}
                                        itemData={getItemData(ws)}
                                        index={index}
                                        isSelected={ws.selected}
                                        isTarget={index === targetIndex}
                                        openTooltip={openTooltip}
                                        currentCardId={currentCardId}
                                        onSelect={handleSelectWorkspaceItem}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                        addMenu={addMenu}
                                    />
                                ))}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </>
    )
}


