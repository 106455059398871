import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WorkspaceItem } from './types';
import {fetchLinkedInItems, FetchWorkspaceItemsResult} from "./thunks";
// import { fetchLinkedInItems, FetchWorkspaceItemsResult } from './old/data-thunk';

interface LinkedInItemsState {
    items: WorkspaceItem[];
    error: string | null;
}

const initialState: LinkedInItemsState = {
    items: [],
    error: null,
};

const linkedInItemsSlice = createSlice({
    name: 'linkedInItems',
    initialState,
    reducers: {
        updateLinkedInItem: (state, action: PayloadAction<WorkspaceItem>) => {
            const index = state.items.findIndex(item => item.uuid === action.payload.uuid);
            if (index !== -1) {
                state.items[index] = action.payload;
            }
        },
        removeLinkedInItem: (state, action: PayloadAction<string>) => {
            state.items = state.items.filter(item => item.uuid !== action.payload);
        },
        selectLinkedInItem: (state, action: PayloadAction<string>) => {
            const item = state.items.find(item => item.uuid === action.payload);
            if (item) {
                item.selected = true;
            }
        },
        deselectLinkedInItem: (state, action: PayloadAction<string>) => {
            const item = state.items.find(item => item.uuid === action.payload);
            if (item) {
                item.selected = false;
            }
        },
        resetSelectedLinkedInItems: (state) => {
            state.items.forEach(item => {
                item.selected = false;
            });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLinkedInItems.fulfilled, (state, action: PayloadAction<FetchWorkspaceItemsResult>) => {
                // ... (implementation as before)
            })
            .addCase(fetchLinkedInItems.rejected, (state, action) => {
                state.items = [];
                state.error = action.payload as string;
            })
            .addCase(fetchLinkedInItems.pending, (state) => {
                state.error = null;
            });
    },
});

export const {
    updateLinkedInItem,
    removeLinkedInItem,
    selectLinkedInItem,
    deselectLinkedInItem,
    resetSelectedLinkedInItems,
} = linkedInItemsSlice.actions;

export default linkedInItemsSlice.reducer;