import React, {useEffect, useState} from "react";

// ---- App components and services -------------------------------------------------------------------
import {runLLMRequest} from "../../backend/extdata.services";
import {execute_databoostr} from "../../backend/executeTask";
import {
    appSettings,
    appSession,
    selectedLanguage,
    setQuitStreaming,
    showWatermark, AppState, ragData, Chunk
} from "../../managers/generalManager";

// ---- Mui components--------------------------------------------------------------------------------
import {Chip} from "@mui/material";

// ---- Editor component--------------------------------------------------------------------------------
import {MessageModel} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {MessageDirection} from "@chatscope/chat-ui-kit-react/src/types/unions";
import {ContentState, EditorState, convertToRaw, Modifier} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

// ---- Icons --------------------------------------------------------------------------------
import {Feedback, registerFeedback} from "../../backend/core.services";
import {PromptSections} from "../../managers/promptManager";
import {ChatViewer} from "../viewers/chat_viewer";
import {EditorViewer} from "../viewers/editor_viewer";
import {copyToClipboard, removeHTMLTags} from "../../utils/common_tools";
import MessageDialog from "../general/messageDialog";
import {tts} from "../../backend/lexicalia_api";
import {Filter} from "../filter/filter_component";
import {SaveToWorkspace} from "../workspace/save_to_workspace";
import {useDispatch, useSelector} from "react-redux";
// import {Workspace} from "../../store/old/data-slice";
// import {AppState} from "../../store/old/store";
// import {deselectWorkspaceItem, setFollowupQuestions, WorkspaceItem} from "../../store/old/temp-slice";
import {InteractOnMessage} from "./interact_on_message";
import AppDialog from "../general/app_dialog";
import {DBItem} from "../../utils/IndexedDB.utils";
import logger from "../../utils/logging_services";
import {promptManager} from "./prompt_manager";
import {getSelectedPagesText} from "../pdf/pdf_utils";
import PleaseWaitDialog from "../general/please_wait";
import colorSchema from "../../app_theme/theme_support/colorSchema";
import {Workspace, WorkspaceItem} from "../../store/types";
import {setFollowupQuestions} from "../../store/uiSlice";
import {deselectWorkspaceItem} from "../../store/workspaceItemSlice";
import {RootState} from "../../store/store";
import {createImage} from "../../backend/special.services";


export interface AutoRequest {
    request: string;
    template: string;
    persona: string;
}

// ---- Interfaces and variables -----------------------------------------------------------------------
export interface ChatProps {
    isOpen: boolean;
    projectId: string;
    mode: string;
    autoRequest: AutoRequest;
    filter: Filter[];
    allowUserInput: boolean;
    selectedWorkspaceItem?: WorkspaceItem;
    selectedCsvChatContext?: string[];
    selectedGoalItem?: DBItem;
    selectedTemplate: string;
    selectedContextData: string;
    showAssistant: boolean;
    defaultPrompt: PromptSections;
    onStreaming: (isStreaming: boolean) => void;
    onAssistantClose: () => void;
}

// Default functions
function removeHtmlWhitespace(htmlString: string): string {
    const pattern = />\s+</g;
    return htmlString.replace(pattern, '><');
}

// -----------------------------------------------------------------------------------------------------------------
// Chat Component
// -----------------------------------------------------------------------------------------------------------------
export const Chat = (props: ChatProps) => {

    // message list
    const [messages, setMessages] = useState<MessageModel[]>([{
        message: "Hi, my name is ZAIA, I am your personal assistant. How can I help you?",
        direction: "outgoing",
        position: "first",
        sender: "ZAIA",
    }])

    const [showFeedbackIndex, setShowFeedbackIndex] = useState<number>(-1)
    const [, setShowAssistant] = useState<boolean>(props.showAssistant)
    const [, setTyping] = useState<boolean>(false)
    const [, setActionResultComplete] = useState<boolean>(false);
    const [systemPrompt, setSystemPrompt] = useState<PromptSections>(props.defaultPrompt);
    const [mode, setMode] = useState<string>(props.mode);
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
    const [, setContent] = useState<string>("");
    const [documentMode,] = useState<string>("Append");
    const [logoName, setLogoName] = useState<string>("UQ");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [actionTriggered, setActionTriggered] = useState<boolean>(false);
    const [localClipboardData, setLocalClipboardData] = useState<string>("");
    const [showSave, setShowSave] = useState<boolean>(false);
    const [filterList, setFilterList] = useState<Filter[]>(props.filter);
    const [showInteraction, setShowInteraction] = useState<boolean>(false);
    const [showExplainData, setShowExplainData] = useState<boolean>(false);
    const [selectedCsvChatContext, setSelectedCsvChatContext] = useState<string[]>(props.selectedCsvChatContext || []);
    const [selectedConversation, setSelectedConversation] = useState<string>("");
    const [selectedContextData, setSelectedContextData] = useState<string>("");
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const [allowUserInputState, setAllowUserInputState] = useState<boolean>(props.allowUserInput);

    const dispatch = useDispatch();

    // =============================================================================================================

    let isFirstDataReceived: boolean = false;
    let isInternal: boolean = false;
    let isIgnore: boolean = false;
    let internalResult: string = "";
    let ignoreDataResult: string = "";
    let actionResult: string = "";
    let wordReceived = 0;

    // =============================================================================================================

    const workspaceItems: WorkspaceItem[] = useSelector((state: RootState) => state.workspaceItems.items);
    const selectedWorkspace: Workspace | undefined = useSelector((state: RootState) => state.data.selectedWorkspace);
    const selectedFolder: string = useSelector((state: RootState) => state.data.selectedFolder);

    const selectedPages = useSelector((state: RootState) => state.data.selectedPages);
    const currentPage = useSelector((state: RootState) => state.data.currentPage);
    const selectedFile = useSelector((state: RootState) => state.data.selectedFile);
    const selectedFileUrl = useSelector((state: RootState) => state.data.selectedFileUrl);

    // Synchronize component state when input properties are altered
    // --------------------------------------------------------------------------------------------------------------
    useEffect(() => {

        // When the users selects a goal item, update the system prompt
        if (props.selectedGoalItem !== undefined && props.selectedGoalItem.systemPrompt !== undefined && props.selectedGoalItem.systemPrompt !== "None") {

            // OUTPUT FORMAT = HTML
            let outputPrompt = "Format your response as HTML, using catchy titles, subtitles, and bullet points. Include links and images when relevant. Do not add fictive images to the response. For example <H1>title</H1><H2>Subtitle</H2><p>Line1</p><p>Line2</p><ul><li>list item</li></ul>"

            // OUTPUT FORMAT = CSV
            if (props.selectedGoalItem.outputFormat === "csv") {
                outputPrompt = props.selectedGoalItem.value;
                outputPrompt += "\nFormat your response as JSON with the following specifics:\n- " +
                    "The result is an array of records between square brackets [ ].\n- " +
                    "Each record is a JSON object between {} separated by comma except for the last one.\n" +
                    "Use the field names as JSON keys.\n- Ensure the values are properly formatted as JSON.\n- " +
                    "Enclose each key and value in double quotes.\n- Use proper JSON syntax and structure.\n- " +
                    "Do not add any additional text before or after the response.\n";

                if (props.selectedGoalItem.csvHeaderFields) {
                    const csvHeaderFieldsString = Object.entries(props.selectedGoalItem.csvHeaderFields)
                        .map(([field, content]) => `${field} {${content}}`)
                        .join(', ');

                    outputPrompt += `\nUse the following fields and instructions per field:\n${csvHeaderFieldsString}`;
                }
            }

            // OUTPUT FORMAT = JSON
            if (props.selectedGoalItem.outputFormat === "json") {
                outputPrompt = "Format your response as valid and validated JSON. Do not add intro and outro text to the response."
            }

            const goalPrompt: PromptSections = {
                prompt: removeHTMLTags(props.selectedGoalItem.systemPrompt),
                instructions: outputPrompt,
                persona_instructions: ""
            }
            setSystemPrompt(goalPrompt);
            logger.info("Current System Prompt", goalPrompt);
        } else {
            if (props.defaultPrompt.prompt !== "") {
                setSystemPrompt(props.defaultPrompt);
                logger.info("Current System Prompt", props.defaultPrompt);
            }
        }

    }, [props.defaultPrompt, props.selectedGoalItem]);

    useEffect(() => {
        setFilterList(props.filter);
    }, [props.filter]);

    useEffect(() => {
        setAllowUserInputState(props.allowUserInput)
    }, [props.allowUserInput]);

    // On user request to send the selected workspace item to the chat, add content as new message
    // --------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        if (props.selectedWorkspaceItem !== undefined) {
            const content = props.selectedWorkspaceItem.content;
            if (content !== undefined) {
                if (typeof content === 'string')
                    addMessage(content);
                else
                    addMessage(JSON.stringify(content));
            }
        }
    }, [props.selectedWorkspaceItem]);


    useEffect(() => {
        setSelectedContextData(props.selectedContextData)
    }, [props.selectedContextData]);

    // update the showAssistant parameter when the user changes the type of chat
    useEffect(() => {
        setShowAssistant(props.showAssistant)
    }, [props.showAssistant]);

    // update the mode parameter when the user changes the type of chat
    useEffect(() => {
        setMode(props.mode);
    }, [props.mode]);

    useEffect(() => {

        if (props.autoRequest.request !== "") {
            logger.info("Auto request (chat)", props.autoRequest);
            sendRequest(props.autoRequest.request, "", "", "", props.autoRequest.template, props.autoRequest.persona, true);
        }

    }, [props.autoRequest]);

    useEffect(() => {
    }, [isMobile]);

    useEffect(() => {
        logger.info(`project_uuid ${props.projectId}`);
    }, [props.projectId]);

    useEffect(() => {
        setSelectedCsvChatContext(props.selectedCsvChatContext || [])
    }, [props.selectedCsvChatContext]);


    // --------------------------------------------------------------------------------------------------------------
    function extractUrlFromMessage(message: string): { modifiedMessage: string; url: string } {
        // Regex om een URL te vinden
        const urlRegex = /https?:\/\/[^\s]+/g;
        const found = message.match(urlRegex);

        // Check of er een URL gevonden is
        if (found && found.length > 0) {
            // Haal de eerste gevonden URL eruit (aannemend dat er maximaal 1 URL is)
            const url = found[0];

            // Verwijder de URL uit het bericht
            const modifiedMessage = message.replace(url, "").trim();

            return {modifiedMessage, url};
        } else {
            // Geen URL gevonden, geef originele message en lege string voor URL
            return {modifiedMessage: message, url: ""};
        }
    }

    // function extractInstructionFromMessage(message: string): { modifiedMessage: string; instruction: string } {
    //     // check if the message starts with the following instruction : image
    //     const instructionRegex = /image/g;
    //     const found = message.match(instructionRegex);
    //     return {modifiedMessage: message, instruction: found ? found[0] : ""};
    // }

    function extractInstructionFromMessage(message: string): {
        modifiedMessage: string;
        instruction: string;
        dimensions?: string
    } {
        // Regular expression to match 'image' followed by optional dimensions
        const instructionRegex = /^image(\s+(\d+:\d+))?/i;
        const match = message.match(instructionRegex);

        if (match) {
            const fullInstruction = match[0];
            const dimensions = match[2]; // This will be undefined if no dimensions were specified
            const modifiedMessage = message.slice(fullInstruction.length).trim();

            return {
                modifiedMessage,
                instruction: 'image',
                dimensions: dimensions || undefined
            };
        }

        return {modifiedMessage: message, instruction: ''};
    }

    // --------------------------------------------------------------------------------------------------------------
    const handleProcessSelectedPdfPages = async () => {
        if (selectedFile && selectedFile.fileName.toLowerCase().endsWith(".pdf") && selectedFileUrl) {
            const pages = selectedPages?.length > 0 ? selectedPages : currentPage ? [currentPage] : [];
            try {
                const selectedPagesText = await getSelectedPagesText(
                    selectedFileUrl,
                    pages,
                    selectedFile.fileName
                );
                logger.info('Selected pages text:', selectedPagesText);
                return selectedPagesText;
            } catch (error: any) {
                logger.error('Error processing selected pages:', error);
            }
        }
        return "";
    };

    // --------------------------------------------------------------------------------------------------------------
    async function handleSend(message: string) {

        // check if message contains an url
        const message_url = extractUrlFromMessage(message);
        logger.info("result", message_url);
        let url_in_request = "";
        if (message_url.url !== "") {
            url_in_request = message_url.url;
            message = message_url.modifiedMessage;
        }

        const message_instruction = extractInstructionFromMessage(message);
        logger.info("result", message_instruction);
        let instruction_in_request = "";
        if (message_instruction.instruction !== "") {
            instruction_in_request = message_instruction.instruction;
            message = message_instruction.modifiedMessage;
        }

        if (instruction_in_request === "image") {

            let conversationContext = "";
            if (selectedConversation) {
                conversationContext = selectedConversation;
                message = message + `<context>${conversationContext}</context>`;
            }

            setIsProcessing(true);
            let format = "1:1";
            if (message_instruction.dimensions && (message_instruction.dimensions === "16:9" || message_instruction.dimensions === "9:16")) {
                format = message_instruction.dimensions;
            }

            const image_response = await createImage(message, format);
            setIsProcessing(false);
            if (image_response.imageUrl === "") {
                addMessage("Image creation failed, please try again later.");
                return;
            }

            const newMessage: MessageModel = {
                message: `<img src="${image_response.imageUrl}" alt="image" style="width: 100%; height: auto;"/><div>${image_response.revised_prompt}</div>`,
                direction: "outgoing",
                position: "normal",
                type: "html",
                sender: "User",
            };
            setMessages((prevMessages) => [...prevMessages, newMessage]);
            return;
        }

        // If the request is made using a goal
        if (props.selectedGoalItem !== undefined) {
            message = message.trim() + " " + props.selectedGoalItem.value;
        }

        let newMessage: MessageModel = {
            message: message,
            direction: "incoming",
            position: "normal",
            sender: "User",
        };

        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setTyping(true);

        logger.info("handleSend : ", message);
        sendRequest(message, url_in_request);
    }

    // Assuming `ragData` is already defined somewhere in your code
    const createRAGMessage = () => {
        // Transform the ragData chunks into HTML formatted string
        const message = ragData.chunks
            .map((item: Chunk) => {
                // Format each chunk with a background color and structured content
                return `<div style="background-color: #f0f0f0; padding: 10px; margin: 10px; border-radius: 10px;">
                        <strong>Reference:</strong> ${item.reference}<br/>
                        <p>${item.data}</p>
                    </div>`;
            })
            .join(""); // Join all the chunks into a single HTML string

        // Create a new message object
        const newMessage: MessageModel = {
            message: message, // Set the composed HTML message
            direction: "outgoing",
            position: "normal",
            sender: "System", // Or "User" based on your use case
            type: "html", // Set the message type to HTML
        };

        logger.info("createRAGMessage : ", newMessage);

        // Append the new message to the existing messages
        setMessages((prevMessages) => [...prevMessages, newMessage]);
    };

    // Background processing of the message received from the backend
    // --------------------------------------------------------------------------------------------------------------
    const onDataReceived = (word: string) => {

        if (isFirstDataReceived) {
            wordReceived = 0;
            props.onStreaming(true);
            appSettings.isStreaming = true;
            appSettings.quitStreaming = false;
            isFirstDataReceived = false;
            setIsProcessing(false);
        } else {
            wordReceived = wordReceived + 1;
        }

        if (isInternal) {
            internalResult = internalResult + word;
            return;
        }

        if (isIgnore) {

            ignoreDataResult = ignoreDataResult + word;
            if (ignoreDataResult.includes("</analysis>")) {
                logger.info("Ignore data result", ignoreDataResult);
                isIgnore = false;
            }
            return;
        }

        actionResult = actionResult + word;
        actionResult = removeHtmlWhitespace(actionResult);

        if (actionResult.includes("@@INTERNAL@@")) {
            isInternal = true;
            internalResult = actionResult.substring(actionResult.indexOf("@@INTERNAL@@") + 12);
            actionResult = actionResult.substring(0, actionResult.indexOf("@@INTERNAL@@"));
        }

        if (actionResult.includes("<analysis>")) {
            isIgnore = true;
            ignoreDataResult = actionResult.substring(actionResult.indexOf("<analysis>") + 10);
            actionResult = actionResult.substring(0, actionResult.indexOf("<analysis>"));
        }

        // Remove the HTML intro tags and other unexpected charachters from the actionResult
        if (actionResult.startsWith("```")) {
            actionResult = actionResult.substring(3);
        }
        if (actionResult.startsWith("html")) {
            actionResult = actionResult.substring(4);
        }
        if (actionResult.endsWith("```")) {
            actionResult = actionResult.substring(0, actionResult.length - 3);
        }

        updateMessage(actionResult);
    };

    // Post-processing of the message received from the backend
    // --------------------------------------------------------------------------------------------------------------
    function postprocess_runGTPRequest(message: string) {

        // trim message
        message = message.trim();

        // remove trailing "} from the message
        if (message.endsWith("}")) {
            message = message.substring(0, message.length - 1);
        }
        if (message.endsWith("\"")) {
            message = message.substring(0, message.length - 1);
        }

        updateMessage(message);
        setActionResultComplete(true)
        props.onStreaming(false);
    }

    async function executeDataboostrInSequence(query: string, mode: string, filter_list: Filter[], messageIsAutoRequest: boolean = false) {
        const responses = [];

        if (filter_list.length === 0) {
            if (messageIsAutoRequest) {
                return "";
            }
            filter_list.push({id: '0', type: "file", value: "", query: ""})
        }

        const filter_count = filter_list.length;
        let chunks_requested = appSettings.requestedChunks;
        if (filter_count > 1) {
            chunks_requested = 3;
        }

        for (const filter of filter_list) {

            const filter_file = filter.type === "file" ? filter.value : "";
            const filter_tag = filter.type === "tag" ? filter.value : "";
            const filter_query = filter.query || "";
            if (filter_query !== '') {
                query = filter_query;
            }

            try {
                logger.info("Searching knowledge Database for :", query, mode, filter_file, filter_tag);
                const response = await execute_databoostr(query, mode, filter_file, filter_tag, chunks_requested);
                responses.push(
                    `<<DATA>><<FILTER= ${filter_file}, ${filter_tag}>>` +
                    response +
                    `<<END DATA - ${filter_file}, ${filter_tag}>>\n`);
                logger.info("Response", response)
            } catch (error) {
                if (error instanceof Error) {
                    logger.error("Knowledge database request failed:", query, error);
                }
                responses.push(null); // Or handle the error as needed
            }
        }

        return responses.join("\n");
    }

    // Helper function to run the LLM request called by the sendRequest function
    // --------------------------------------------------------------------------------------------------------------
    function run(system_prompt: string, user_prompt: string, context_data: string, url: string = "") {

        addMessage("");

        // Bepaal LLM provides op basis van de goal of de standaard instellingen
        // -----------------------------------------------------------------------------------------------
        let llmProvider = appSettings.selectedLLMModelProvider
        let llmVersion = appSettings.selectedLLMModelVersion

        if (props.selectedGoalItem !== undefined) {
            if (props.selectedGoalItem.llmModelProvider !== undefined &&
                props.selectedGoalItem.llmModelProvider !== 'None') {
                llmProvider = props.selectedGoalItem.llmModelProvider;
            }

            if (props.selectedGoalItem.llmModelVersion !== undefined &&
                props.selectedGoalItem.llmModelVersion !== 'None') {
                llmVersion = props.selectedGoalItem.llmModelVersion;
            }
        }

        runLLMRequest(
            props.projectId,
            system_prompt,
            user_prompt,
            "",
            context_data,
            "",
            url,
            appSettings.temperature,
            selectedLanguage,
            llmProvider,
            llmVersion,
            onDataReceived).then(
            () => {
                logger.info("actionResultString", actionResult);
                logger.info("internalResultString", internalResult);

                if (internalResult !== "") {
                    internalResult = internalResult.replace("@@INTERNAL@@", "").trim();
                    const questions = internalResult.split("||").map((item) => item.replace("\"", "").trim());
                    dispatch(setFollowupQuestions(questions));
                    internalResult = "";
                    isInternal = false;
                }

                postprocess_runGTPRequest(actionResult);
                setActionResultComplete(true)
                props.onStreaming(false);
            });
    }

    // --------------------------------------------------------------------------------------------------------------
    async function sendRequest(message: string, url: string, context: string = "", system_prompt: string = "", template: string = "", persona: string = "", messageIsAutoRequest: boolean = false, useKnowledgeDomain: boolean = true) {

        if (mode === "chat+" && documentMode === "Replace") {
            logger.info("Clearing editor");
            setContent("");
            setEditorState(EditorState.createEmpty());
        }

        isFirstDataReceived = true;
        isInternal = false;
        actionResult = "";

        // reset optional followup questions.
        internalResult = "";
        dispatch(setFollowupQuestions([]));

        setIsProcessing(true);

        // Create default user request indicating the response language and the template to use
        // -----------------------------------------------------------------------------------------------------------
        let request = message + ", Write in {language} "

        if (template === "") {
            request += (props.selectedTemplate !== "" ? "<Output_Template> Use the following format and structure> :" + props.selectedTemplate + "</Output_Template>" : "");
        } else {
            request += "<Output_Template> Use the following format and structure> :" + template + "</Output_Template>";
        }

        logger.info("handleRequestSubmit : ", request);

        // Retrieve the content of the selected workspace items ( but only for HTML items )
        // -----------------------------------------------------------------------------------------------------------
        let workspaceContext = "";
        const selectedWorkspaceItems = workspaceItems.filter((item) => item.selected && item.tags.includes('html')).map((item) => item.content);
        if (selectedWorkspaceItems.length > 0) {
            workspaceContext = removeHTMLTags(selectedWorkspaceItems.join("\n"));
            logger.info("workspaceContext", workspaceContext);
        }

        // Retrieve context app_theme based on the selected CSV records
        // -----------------------------------------------------------------------------------------------------------
        let csvChatContext = "";
        if (selectedCsvChatContext !== undefined) {

            // find {selected_csv_data} into request, if found, replace it with the selectedCsvChatContext
            if (request.includes("<selected_csv_data>")) {
                request = request.replace("<selected_csv_data>", selectedCsvChatContext.join("\n"));
            } else
                csvChatContext = selectedCsvChatContext.join("\n");
        }

        // Retrieve context app_theme based on the selected file
        // -----------------------------------------------------------------------------------------------------------
        let fileContext = "";
        if (selectedFile) {
            fileContext = await handleProcessSelectedPdfPages();
        }

        // Retrieve context app_theme based on the selected conversation
        // -----------------------------------------------------------------------------------------------------------
        let conversationContext = "";
        if (selectedConversation !== "") {
            conversationContext = selectedConversation;
        } else {
            if (!messageIsAutoRequest) {
                let messages_outgoing = messages.filter((message) => message.direction === "outgoing");

                // Check if there is more than one outgoing message
                if (messages_outgoing.length > 1) {
                    let lastMessage = messages_outgoing[messages_outgoing.length - 1].message;
                    conversationContext = lastMessage || "";
                }
            }
        }

        // Create extra instructions based on the system prompt
        // -----------------------------------------------------------------------------------------------------------
        let extra_instructions = "";

        if (systemPrompt?.persona_instructions) {
            extra_instructions += `<Persona> Create your response based on this persona: ${systemPrompt.persona_instructions.trim()}</Persona>`;
        }

        if (systemPrompt?.instructions) {
            extra_instructions += `<Extra>${systemPrompt.instructions.trim()}</Extra>`;
        }

        // MODE = Knowledge domain ==> Get chunks based on the request, then send context and prompt to the backend
        // -----------------------------------------------------------------------------------------------------------
        if (!mode.startsWith("chat") && useKnowledgeDomain) {

            executeDataboostrInSequence(message, mode, filterList, messageIsAutoRequest).then((response) => {

                if (response.startsWith("Error")) {
                    addMessage(response);
                    return;
                }

                let requestContext = conversationContext + '\n' + workspaceContext + '\n' + csvChatContext + '\n' + response + '\n' + fileContext + '\n' + context;
                requestContext += selectedContextData;
                run(systemPrompt.prompt + "\n" + extra_instructions, request, requestContext, url);

            });
        } else {
            // MODE = DEFAULT CHAT ==> Send the request to the backend without context
            if (mode.startsWith("chat") || !useKnowledgeDomain) {

                if (mode === 'chat+') {
                    setActionTriggered(true);
                }

                let requestContext = conversationContext + '\n' + workspaceContext + '\n' +
                    csvChatContext + '\n' + fileContext + '\n' + context;

                requestContext += selectedContextData;

                run(systemPrompt.prompt + "\n" + extra_instructions, request, requestContext, url);
            }
        }
    }

    // Add message to the message list and remove the typing indicator
    function addMessage(message: string, direction: MessageDirection = "outgoing", sender: string = "Zaia") {
        let newMessage: MessageModel = {
            message: message,
            direction: direction,
            position: "normal",
            type: "html",
            sender: sender,
        };

        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setTyping(false);
    }

    // Update the last message in the message list
    function updateMessage(message: string) {
        setMessages((prevMessages) => {
            const lastMessageIndex = prevMessages.length - 1;
            if (lastMessageIndex >= 0) {
                setShowFeedbackIndex(lastMessageIndex);
                const updatedMessage = {
                    ...prevMessages[lastMessageIndex],
                    message: message,
                };
                const newMessages = [...prevMessages];
                newMessages[lastMessageIndex] = updatedMessage;
                return newMessages;
            } else {
                return prevMessages;
            }
        });
    }

    function handleEditorStateChange(newState: EditorState) {
        setEditorState(newState);
        setContent(draftToHtml(convertToRaw(newState.getCurrentContent())));
    }

    // Assistant functions
    // function handleEditorViewerRequest(request: string) {
    //     console.log("Assistent request: " + request);
    //     setAutoRequest(request);
    //     handleAssistentClose();
    // }
    //
    // function handleAssistentClose() {
    //     props.onAssistantClose();
    //     setShowAssistant(false);
    //     setSelectedServiceName("");
    // }

    function handleFeedbackOptionRequest(request: string, request_data: string, messageList: MessageModel[]) {

        // concatenate all messages in the messageList
        const message = messageList.map((message) => message.message).join("\n");
        const lastMessage = messageList[messageList.length - 1].message;

        const runPrompt = (prompt: string) => {
            const foundPrompt = promptManager.findPromptByName(request);
            if (foundPrompt) {
                logger.info("Found prompt: ", foundPrompt);
                sendRequest(foundPrompt.prompt, "", message, "", "", "", false, false);
            }
        }

        if (request === 'rag') {
            createRAGMessage();
            return;
        }

        if (request === 'tts') {
            if (message === undefined) {
                return;
            }

            const msg = message?.replace(/<[^>]*>?/gm, '')
            tts(props.projectId, msg).then(r => logger.info("TTS response:", r));
            return;
        }

        if (request === "copy") {
            copyToClipboard(message || "");
            return;
        }
        if (request === 'local_storage') {

            // convert message.message to plain text
            let msg = message || "";

            if (msg.includes("<html")) {
                const bodyStart = msg.indexOf("<html");
                const bodyEnd = msg.indexOf("</html>");
                if (bodyStart !== -1 && bodyEnd !== -1) {
                    msg = msg.substring(bodyStart, bodyEnd + 7);
                }
            }

            logger.info("msg", msg)
            setLocalClipboardData(msg)
            setShowSave(true);
            return;
        }

        // if ( request === 'remove') {
        //     setMessages(messages.filter((item) => item !== message));
        // }

        if (request === 'interact') {
            setShowInteraction(true);
            return;
        }

        switch (request) {
            case 'view':
                break;
            case 'shorter':
            case 'longer':
            case 'formal':
            case 'informal':
                runPrompt(request);
                break;
            case 'to_html':
            case 'to_table':
                runPrompt(request);
                break;
            default:
                logger.info("Feedback request: " + request);
                sendRequest(request, "", message, "", "", "", false, false).then();
                break;
        }
    }

    function handleSubmitFeedback(commentType: string, comment: string) {

        const meta = {
            user: appSession.currentUser,
            model: appSettings.selectedLLMModelVersion,
            model_provider: appSettings.selectedLLMModelProvider,
            language: selectedLanguage
        }

        const feedbackMessage: Feedback = {
            reference: commentType,
            context: comment,
            context_data: messages.map((message) => message.message).join("\n"),
            meta_application: "ZAIA-CHAT",
            meta_other: JSON.stringify(meta),
            meta_version: "1.0",
        }
        registerFeedback(appSession.organisationUuid, feedbackMessage).then((response) => {
            logger.info("Feedback Registration:", response);
        });
    }

    function handleActionFeedback(action: string) {
        logger.info("Action feedback: " + action);
        const lastMessageReceived = messages[messages.length - 1].message;

        setActionTriggered(false);
        if (action === "copy") {
            copyToClipboard(lastMessageReceived || "");
        }
        if (action === 'replace') {
            let {contentBlocks, entityMap} = htmlToDraft(lastMessageReceived || "");

            // Create a new content state from the HTML content
            const newContentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

            // Create a new EditorState with the new content
            const newEditorState = EditorState.createWithContent(newContentState);

            // Set the new EditorState
            setEditorState(newEditorState);
        }

        if (action === 'insert') {
            let {contentBlocks, entityMap} = htmlToDraft(lastMessageReceived || "");
            const currentSelection = editorState.getSelection();

            const newContent = Modifier.replaceWithFragment(
                editorState.getCurrentContent(),
                currentSelection,
                ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
            )

            const _editorState = EditorState.push(editorState, newContent, "insert-fragment");
            setEditorState(_editorState)
        }

    }

    function handleResetChat() {
        setQuitStreaming(true);
        setMessages([{
            message: "Hi, my name is ZAIA, I am your personal assistant. How can I help you?",
            direction: "outgoing",
            position: "first",
            sender: "Lexi",
        }]);
        setShowFeedbackIndex(-1);
        setSelectedConversation("");
    }

    function handleExplainData() {
        setShowExplainData(true);
    }

    const handleMessagesSelected = (messages: MessageModel[]) => {

        let newSelectedConversation = "";
        for (const message of messages) {
            let rawData = message.message || ""
            // find the content between <body> and </body)
            const bodyStart = rawData.indexOf("<body>");
            const bodyEnd = rawData.indexOf("</body>");
            if (bodyStart !== -1 && bodyEnd !== -1) {
                rawData = rawData.substring(bodyStart + 6, bodyEnd);
            }
            rawData = removeHTMLTags(rawData);
            newSelectedConversation = newSelectedConversation + rawData + "\n";
        }

        setSelectedConversation(newSelectedConversation);
        logger.info("selectedConversation", newSelectedConversation);
    }

    const handleInteractionOnMessageSubmit = (request: string) => {
        const lastMessage = messages[messages.length - 1];
        logger.info("Interaction request: " + request);
        sendRequest('++' + request, "", lastMessage.message, "", "", "", false, false);
        setShowInteraction(false);
    }


    function explainCurrentMode() {
        if (mode === "chat") {
            return `
                <p>This mode does not use your internal knowledge database. <br/>Instead, the question is sent directly to the large language model, and the accuracy of the reply depends on the model's training data. <br/>Please note that the chance of hallucinations (made-up stories) is highest in this mode.</p>
            `;
        }
        return `
            <p>In this mode, your question is semantically analyzed, and potential data that could answer the question is retrieved from the <b>${mode}</b> knowledge database.</p>
            <p>If relevant information is found, the top text segments are used to generate a response to your question. This mode is more accurate than the chat mode; however, complex or combined questions may still result in unreliable answers.</p>
            <p>For multiple questions, use the filter option and specify each question inomidually. Use the input field to ask a general question, such as "provide an answer to the given questions."</p>
        `;
    }

    function explain_pdf_pages() {
        if (selectedPages.length === 0 && currentPage === undefined) {
            return (
                <p>No PDF pages are currently selected. You can select PDF pages by clicking on them in the PDF
                    viewer.</p>
            );
        }

        return (
            <div>
                <p>Selected PDF file : {selectedFile?.fileName}</p>
                <p>You have selected the following PDF pages:</p>
                <div>
                    {selectedPages.length > 0 ? (
                        <>
                            {selectedPages.map((page, index) => (
                                <Chip
                                    key={'pdf_page' + index}
                                    label={`Page ${page}`}
                                    style={{margin: '5px'}}
                                />
                            ))}
                        </>
                    ) : (
                        <Chip
                            key={'pdf_page' + currentPage}
                            label={`Page ${currentPage}`}
                            style={{margin: '5px'}}
                        />

                    )}

                </div>
            </div>
        );

    }

    function explain_selected_csv_records() {

        if (props.selectedCsvChatContext === undefined || selectedCsvChatContext.length === 0) {
            return (
                <p>No CSV records are currently selected. You can select CSV records by clicking on them in the CSV
                    panel.</p>
            );
        }

        return (
            <div>
                {selectedCsvChatContext?.map((record, index) => (
                    <Chip
                        key={index}
                        label={record}
                        // onDelete remove the record from the selectedCsvChatContext array
                        onDelete={() => {
                            setSelectedCsvChatContext(selectedCsvChatContext.filter((item) => item !== record));
                        }}

                        style={{margin: '5px'}}/>
                ))}
            </div>
        )

    }

    function explain_selected_workspace() {
        if (selectedWorkspace !== undefined) {
            const selectedItems = workspaceItems.filter(item => item.selected && item.tags.includes('html'));
            if (selectedItems.length === 0) {
                return (
                    <p>No workspace items are currently selected. You can select workspace items by clicking on them in
                        the workspace panel.</p>
                );
            }

            return (
                <div>
                    <p>You have selected the following workspace items:</p>
                    <div>
                        {selectedItems.map((item, index) => (
                            <Chip
                                key={index}
                                label={item.name}
                                onDelete={() => dispatch(deselectWorkspaceItem(item))}
                                style={{margin: '5px'}}
                            />
                        ))}
                    </div>
                    <p>These workspace items will be used as context data by the large language model to create an
                        answer.</p>
                    <p>Be aware that irrelevant items could influence the answer. Reset your selection when you no
                        longer need these items.</p>
                </div>
            );
        }
        return "";
    }

    function explain_selected_conversation() {
        if (selectedConversation === "") {
            return (
                <p>No conversation context is currently selected. You can select a conversation by clicking on the
                    messages in
                    the chat history.</p>
            );
        }

        return (
            <div>
                <p>You have selected the following conversation context:</p>
                <div>
                    <Chip
                        label={selectedConversation}
                        style={{margin: '5px'}}
                    />
                </div>
            </div>
        );
    }

    function explain_context_data() {
        if (selectedContextData === "") {
            return (
                <p>No context data is currently selected. You can select a context data by selecting image of pdf
                    pages</p>
            );
        }

        return (
            <div>
                <p>You have selected the following context data:</p>
                <div>
                    <Chip
                        label={selectedContextData}
                        style={{margin: '5px'}}
                    />
                </div>
            </div>
        );
    }

    function explain_selected_goal() {
        if (props.selectedGoalItem !== undefined && props.selectedGoalItem.value === "None") {
            return (
                <p>No goal is currently selected. Please use the message field below to specify your question for
                    processing.</p>
            );
        }

        return (
            <div>
                <p>You have selected the following goal:</p>
                <Chip
                    label={props.selectedGoalItem !== undefined ? props.selectedGoalItem.value : "None"}
                    style={{margin: '5px'}}
                />
                <p>This goal will be used as the basis for your request. You can further extend this by adding more
                    details in the message field.</p>
                <p>Remember to reset your goal when it is no longer relevant.</p>
            </div>
        );
    }


    return (
        <div
            style={{display: "flex", flex: 1, minHeight: "100%", width: '100%', flexDirection: 'column'}}
            className="border"
        >
            {showWatermark && (
                <span style={{position: 'absolute', zIndex: 1, width: '100%'}}>
                        <img
                            src={process.env.PUBLIC_URL + `/Logo_${logoName}_bg.png`}
                            className="watermark-logo"
                            onDragStart={(e) => e.preventDefault()}
                            alt="Watermark"
                        />
                    </span>
            )}

            <PleaseWaitDialog open={isProcessing} onCancel={() => setIsProcessing(false)}/>

            {showInteraction && (
                <InteractOnMessage onCancel={() => setShowInteraction(false)}
                                   onSubmit={handleInteractionOnMessageSubmit} context_data={""}/>
            )}

            {showSave && selectedWorkspace !== undefined && (
                <SaveToWorkspace context_data={localClipboardData} onCancel={() => setShowSave(false)}
                                 workspace={selectedWorkspace} workspaceFolder={selectedFolder} type={"html"}
                                 onSubmit={() => setShowSave(false)}/>
            )}

            {/*{showAddToLocalStorageStore && (*/}
            {/*    <LocalDataStore*/}
            {/*        isOpen={true}*/}
            {/*        mode={"add"}*/}
            {/*        mode_data={localClipboardData}*/}
            {/*        filter={""}*/}
            {/*        dbName={appSettings.database}*/}
            {/*        onClose={() => setShowAddToLocalStorageStore(false)}*/}
            {/*        onSelect={(app_theme: string) => {*/}
            {/*            setShowAddToLocalStorageStore(false);*/}
            {/*        }}*/}
            {/*    />*/}


            {/*)}*/}

            {/* CHAT MODE */}
            {
                mode !== "chat+" && (

                    <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                        <ChatViewer
                            conversation={messages}
                            allowUserInput={allowUserInputState}
                            feedbackIndex={showFeedbackIndex}
                            goalSelected={props.selectedGoalItem !== undefined && props.selectedGoalItem.value !== ""}
                            onSend={handleSend}
                            onReset={handleResetChat}
                            onExplainData={handleExplainData}
                            onFeedbackOptionRequest={handleFeedbackOptionRequest}
                            onMessagesSelected={handleMessagesSelected}
                            onSubmittedFeedback={handleSubmitFeedback}
                        />
                    </div>
                )
            }

            {/* EDITOR MODE */}
            {
                (mode === "chat+") && (

                    <>
                        {actionTriggered && (
                            <MessageDialog title={"Action Feedback"}
                                           primary_text={messages[messages.length - 1].message || ""}
                                           secondary_text=""
                                           dialogType={"action"}
                                           onButtonClicked={handleActionFeedback}
                                           onClose={() => setActionTriggered(false)}/>
                        )}
                        <div style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            height: '100%',
                            backgroundColor: colorSchema.background.default,
                        }}>
                            <EditorViewer editorState={editorState}
                                          showActions={true}
                                          onEditorStateChange={handleEditorStateChange}
                                          onSendRequest={sendRequest}
                                          onUpdateEditorState={setEditorState}/>

                        </div>
                    </>
                )
            }

            {showExplainData && (
                <AppDialog open={showExplainData} size={"lg"} title={"Explain Data Used"} message={""}
                           showSubmit={false} showCancel={true} onSubmit={() => {
                }} onCancel={() => setShowExplainData(false)}>
                    <>
                        <h4>Current Mode : {mode}</h4>
                        <div dangerouslySetInnerHTML={{__html: explainCurrentMode()}}/>
                        <h4>Current Goal</h4>
                        {explain_selected_goal()}
                        <h4>Workspace Data</h4>
                        {explain_selected_workspace()}
                        <h4>Context Data</h4>
                        {explain_context_data()}
                        <h4>PDF Pages</h4>
                        {explain_pdf_pages()}
                        <h4>Selected Conversation</h4>
                        {explain_selected_conversation()}
                        <h4>Selected CSV records</h4>
                        {explain_selected_csv_records()}
                    </>

                </AppDialog>
            )}

            {/*{*/}
            {/*    showMermaid && (*/}
            {/*        <DrawMermaid markup={mermaidMarkup} onClose={handleCloseMermaidViewer}/>*/}
            {/*    )*/}
            {/*}*/}

            {/*{*/}
            {/*    showCsvTable && (*/}
            {/*        <DynamicCsvTable initialData={csvRecords}*/}
            {/*                         onClose={() => {*/}
            {/*                             setShowCsvTable(false)*/}
            {/*                         }}*/}
            {/*                         onSelection={() => {*/}
            {/*                         }}*/}
            {/*                         selectedKeys={[]}/>*/}
            {/*    )*/}
            {/*}*/}

        </div>
    )
}