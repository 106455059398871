import {Button, ButtonGroup, Typography} from "@mui/material";
import formImage from "../../assets/form.png";
import promptImage from "../../assets/prompt.png";
import settingsImage from "../../assets/settings.png";
import headerImage from "../../assets/header.png";
import textImage from "../../assets/text.png";
import inputImage from "../../assets/input.png";
import dropdownImage from "../../assets/dropdown.png";
import exampleImage from "../../assets/example.png";
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import {Input, Output, Settings} from "@mui/icons-material";
import React from "react";
import colorSchema from "../../app_theme/theme_support/colorSchema";

interface CategoryButtonGroupProps {
    categoryType: string;
    setCategoryType: (categoryType: string) => void;
}

// ================================================================================================================
// This component offers the options assigned to a GOAL. The options are: Form, Prompt and Settings
// ================================================================================================================
export const GoalDefinitionOptions = ({categoryType, setCategoryType}: CategoryButtonGroupProps) => {
    const buttonStyle = {display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5px'};

    return (
        <ButtonGroup orientation="vertical" variant="outlined" size={"small"} color={"primary"}>
            <Button
                sx={{
                    ...buttonStyle,
                    backgroundColor: categoryType === 'template' ? colorSchema.action.selected : 'inherit'
                }}
                onClick={() => setCategoryType("template")}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img src={formImage} style={{width: '20px', height: 'auto'}} alt="form"/>
                    <Typography variant="caption" sx={{textAlign: 'center'}}>Form</Typography>
                </div>
            </Button>
            <Button
                sx={{
                    ...buttonStyle,
                    backgroundColor: categoryType === 'prompt' ? colorSchema.action.selected : 'inherit'
                }}
                onClick={() => setCategoryType("prompt")}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img src={promptImage} style={{width: '20px', height: 'auto'}} alt="Prompt"/>
                    <Typography variant="caption" sx={{textAlign: 'center'}}>Prompt</Typography>
                </div>
            </Button>
            <Button
                sx={{
                    ...buttonStyle,
                    backgroundColor: categoryType === 'settings' ? colorSchema.action.selected : 'inherit'
                }}
                onClick={() => setCategoryType("settings")}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img src={settingsImage} style={{width: '20px', height: 'auto', alignSelf: 'center'}}
                         alt="Settings"/>
                    <Typography variant="caption" sx={{textAlign: 'center'}}>Settings</Typography>
                </div>
            </Button>
        </ButtonGroup>
    );
};


interface AssistButtonGroupProps {
    onHandleInsert: (type: string) => void;
}

// ================================================================================================================
// This component offers buttons to help the user to insert different types of elements in the form builder
// ================================================================================================================
export const GoalFormAssistant = ({onHandleInsert}: AssistButtonGroupProps) => {

    const handleInsert = (type: string) => {
        onHandleInsert(type);
    };

    const buttonStyle = {marginTop: '3px', display: 'flex', justifyContent: 'center'};

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <Typography variant="body1" marginTop={"10px"} sx={{
                textAlign: 'center',
                marginX: '5px'
            }}>
                ASSIST
            </Typography>
            <ButtonGroup orientation="vertical" sx={{marginTop: '5px'}}>
                <Button sx={buttonStyle} onClick={() => handleInsert("header")}>
                    <img src={headerImage} style={{width: '20px', height: 'auto'}} alt="Header"/>
                </Button>
                <Button sx={buttonStyle} onClick={() => handleInsert("text")}>
                    <img src={textImage} style={{width: '20px', height: 'auto'}} alt="Text"/>
                </Button>
                <Button sx={buttonStyle} onClick={() => handleInsert("field")}>
                    <img src={inputImage} style={{width: '20px', height: 'auto'}} alt="Field"/>
                </Button>
                <Button sx={buttonStyle} onClick={() => handleInsert("choice")}>
                    <img src={dropdownImage} style={{width: '20px', height: 'auto'}} alt="Choice"/>
                </Button>
                <Button sx={buttonStyle} onClick={() => handleInsert("example")}>
                    <img src={exampleImage} style={{width: '20px', height: 'auto'}} alt="Example"/>
                </Button>
            </ButtonGroup>
        </div>
    );
};


// ================================================================================================================
// This component offers the GOAL settings options. The options are: General, Input, Services and Output
// ================================================================================================================
interface SettingsButtonGroupProps {
    onHandleSettingsMode: (type: string) => void;
    selectedMode: string;
}

export const GoalDefinitionSettingsOptions = ({onHandleSettingsMode, selectedMode}: SettingsButtonGroupProps) => {
    const handleSettingsMode = (type: string) => {
        onHandleSettingsMode(type);
    };

    const buttonStyle = {display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5px'};

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <Typography variant="body1" marginTop={"10px"} sx={{
                textAlign: 'center',
                marginX: '5px'
            }}>
                SETTINGS
            </Typography>
            <ButtonGroup orientation="vertical" sx={{marginTop: '5px'}}>
                <Button
                    sx={{
                        ...buttonStyle,
                        backgroundColor: selectedMode === 'general' ? colorSchema.action.selected : 'inherit'
                    }}
                    onClick={() => handleSettingsMode("general")}
                >
                    <Settings fontSize="small"/>
                    <Typography variant="caption" sx={{textAlign: 'center'}}>General</Typography>
                </Button>
                <Button
                    sx={{
                        ...buttonStyle,
                        backgroundColor: selectedMode === 'input' ? colorSchema.action.selected : 'inherit'
                    }}
                    onClick={() => handleSettingsMode("input")}
                >
                    <Input fontSize="small"/>
                    <Typography variant="caption" sx={{textAlign: 'center'}}>Input</Typography>
                </Button>
                 <Button
                    sx={{
                        ...buttonStyle,
                        backgroundColor: selectedMode === 'services' ? colorSchema.action.selected : 'inherit'
                    }}
                    onClick={() => handleSettingsMode("services")}
                >
                    <Output fontSize="small"/>
                    <Typography variant="caption" sx={{textAlign: 'center'}}>Services</Typography>
                </Button>
                <Button
                    sx={{
                        ...buttonStyle,
                        backgroundColor: selectedMode === 'output' ? colorSchema.action.selected : 'inherit'
                    }}
                    onClick={() => handleSettingsMode("output")}
                >
                    <Output fontSize="small"/>
                    <Typography variant="caption" sx={{textAlign: 'center'}}>Output</Typography>
                </Button>
                <Button
                    sx={{
                        ...buttonStyle,
                        backgroundColor: selectedMode === 'workflow' ? colorSchema.action.selected : 'inherit'
                    }}
                    onClick={() => handleSettingsMode("workflow")}
                >
                    <AccountTreeOutlinedIcon fontSize="small"/>
                    <Typography variant="caption" sx={{textAlign: 'center'}}>Workflow</Typography>
                </Button>

            </ButtonGroup>
        </div>
    );
};