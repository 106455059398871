// import {FolderDef, Workspace} from "../../../store/old/data-slice";
import React from "react";
import {Chip, List, ListItem, ListItemText} from "@mui/material";
import {FolderDef, Workspace} from "../../../store/types";

export interface WorkspaceListProps {
    workspaces: Workspace[];
    selectedWorkspace: Workspace | undefined;
    onWorkspaceSelect: (workspace: Workspace) => void;
    onOpenContentTypeDialog: (workspace: Workspace, contentType: FolderDef) => void;
    onInitializeDeleteContentType: (workspace: Workspace, contentType: FolderDef) => void;
    onUiAddMenu: (index: number) => JSX.Element;
    colorSchema: any;
}

export const WorkspaceList: React.FC<WorkspaceListProps> = ({
                                                         workspaces,
                                                         selectedWorkspace,
                                                         onWorkspaceSelect,
                                                         onOpenContentTypeDialog,
                                                         onInitializeDeleteContentType,
                                                         onUiAddMenu,
                                                         colorSchema
                                                     }) => (
    <List>
        {workspaces.map((workspace, index) => (
            <ListItem
                key={workspace.name}

                onClick={() => onWorkspaceSelect(workspace)}
                sx={{
                    backgroundColor: selectedWorkspace?.name === workspace.name
                        ? colorSchema.options_content_selected
                        : 'inherit',
                    '& .MuiListItemText-primary': {
                            fontWeight: 'bold',
                            fontSize: '16px',
                        },
                }}
            >
                <ListItemText primary={workspace.name} secondary={workspace.description}/>
                {workspace.folder_list
                    .map(folder => (
                    <Chip
                        key={folder.name}
                        label={folder.name}
                        onClick={() => onOpenContentTypeDialog(workspace, folder)}
                        onDelete={() => onInitializeDeleteContentType(workspace, folder)}
                    />
                ))}
                {onUiAddMenu(index)}
            </ListItem>
        ))}
    </List>
);
