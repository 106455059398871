// components.ts
import { Components } from '@mui/material/styles';
import colorSchema from './colorSchema';

const components: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        scrollbarColor: "#6b6b6b #2b2b2b",
        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
          backgroundColor: colorSchema.background.default,
        },
        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
          borderRadius: 8,
          backgroundColor: colorSchema.grey[400],
          minHeight: 24,
          border: `3px solid ${colorSchema.background.default}`,
        },
        "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
          backgroundColor: colorSchema.grey[500],
        },
        "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
          backgroundColor: colorSchema.grey[500],
        },
        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
          backgroundColor: colorSchema.grey[500],
        },
        "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
          backgroundColor: colorSchema.background.default,
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '4px',
        textTransform: 'none',
        fontWeight: 500,
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)',
        transition: 'box-shadow 0.3s ease-in-out',
        '&:hover': {
          boxShadow: '0px 4px 8px -1px rgba(0,0,0,0.3)',
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          borderRadius: 4,
        },
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        borderBottom: `1px solid ${colorSchema.grey[200]}`,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        whiteSpace: 'normal',
        lineHeight: 1.5,
        padding: '8px 16px',
      },
    },
  },
  MuiIcon: {
    styleOverrides: {
      root: {
        color: colorSchema.primary.main,
      },
    },
  },
};

export default components;