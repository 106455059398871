import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Accordion, AccordionDetails, AccordionSummary, IconButton, Menu, MenuItem, Typography} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';

import {WorkspaceType} from './workspace_viewer';
import {getWorkspaceItemData} from '../../utils/snippet_tools';
import WorkspaceItemCard from './viewer/workspace_item_card';
import colorSchema, {BackgroundColors} from "../../app_theme/theme_support/colorSchema";
import {AppDispatch, RootState} from "../../store/store";
import {WorkspaceItem} from "../../store/types";
import {setSelectedFolder, setSelectedWorkspaceCategory} from "../../store/dataSlice";
import {updateWorkspaceItem} from "../../store/workspaceItemSlice";
import logger from "../../utils/logging_services";

interface SnippetViewerProps {
    open: boolean;
    itemType: string;
    uniqueCategories: string[];
    initialCategory: string;
    snippetList: WorkspaceItem[];
    onHandleRequest: (action: string, key: string) => void;
}

type BackgroundColorKey = keyof BackgroundColors;

const MAX_COLORS = 10; // Assuming we have 10 distinct colors


export const SnippetViewer: React.FC<SnippetViewerProps> = ({
                                                                open,
                                                                itemType,
                                                                uniqueCategories,
                                                                snippetList,
                                                                onHandleRequest,
                                                            }) => {
    const dispatch = useDispatch<AppDispatch>();
    const usedColorsRef = useRef<Set<string>>(new Set());

    const selectedWorkspaceCategory = useSelector((state: RootState) => state.data.selectedWorkspaceCategory);
    const compactMode = useSelector((state: RootState) => state.data.compactSnippets);

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

    const workspaceItems: WorkspaceItem[] = useSelector((state: RootState) => state.workspaceItems.items);

    const itemsToShow = useMemo(() => {
        if (itemType === WorkspaceType.WORKSPACE) {
            return snippetList.filter((ws) => ws.subtype === selectedWorkspaceCategory);
        }
        return [];
    }, [snippetList, selectedWorkspaceCategory, itemType]);

    const handleSelectCategory = useCallback((category: string) => {
        dispatch(setSelectedWorkspaceCategory(category));
        dispatch(setSelectedFolder(category));
    }, [dispatch]);

    const getUsedColors = (items: WorkspaceItem[]): Set<string> => {
        const usedColors = new Set<string>();
        items.forEach(item => {
            if (item.selected && item.selected_color) {
                usedColors.add(item.selected_color);
            }
        });
        return usedColors;
    };

    const getNextAvailableColor = (usedColors: Set<string>): string => {
        for (let i = 1; i <= MAX_COLORS; i++) {
            const colorKey = `selected${i}` as keyof BackgroundColors; // Use keyof for type safety
            const color = colorSchema.background[colorKey];
            if (!usedColors.has(color)) {
                return color;
            }
        }
        // If all colors are used, return the default selected color
        return colorSchema.background.selected;
    };

    const handleSelectWorkspaceItem = useCallback((event: React.MouseEvent, id: string) => {
        if (!(event.target instanceof HTMLElement) || !event.target.closest('.menu-button')) {
            const selectedItem = snippetList.find(ws => ws.uuid === id);
            if (!selectedItem) return;

            logger.info('Available background colors', colorSchema.background);

            const usedColors = getUsedColors(workspaceItems); // Get current set of used colors
            let updatedItem: WorkspaceItem;

            if (selectedItem.selected) {
                // Deselecting: no need to manually remove color since it's dynamically computed
                updatedItem = {
                    ...selectedItem,
                    selected: false,
                    selected_color: undefined
                };
            } else {
                // Selecting: assign the next available color
                const nextColor = getNextAvailableColor(usedColors);
                updatedItem = {
                    ...selectedItem,
                    selected: true,
                    selected_color: nextColor
                };
            }

            dispatch(updateWorkspaceItem(updatedItem));
        }
    }, [snippetList, workspaceItems, dispatch]);


    const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLElement>, id: string) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
        setSelectedItemId(id);
    }, []);

    const handleMenuClose = useCallback(() => {
        setMenuAnchorEl(null);
        setSelectedItemId(null);
    }, []);

    // Handle the action selected from the menu
    // The action can be viewed, edit, save, use, delete, path
    // The action is passed to the onHandleRequest function
    const handleMenuAction = useCallback((action: string, key: string) => {
        handleMenuClose();
        onHandleRequest(action, key);
    }, [onHandleRequest]);

    // Render the menu for each workspace item
    // The menu contains actions that can be performed on the workspace item
    const renderMenu = useCallback((ws: WorkspaceItem) => {
        return (
            <div className="menu-button" onClick={(e) => e.stopPropagation()}>
                <IconButton
                    onClick={(event) => handleMenuOpen(event, ws.uuid!)}
                >
                    <MoreVertIcon/>
                </IconButton>
                <Menu
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl) && selectedItemId === ws.uuid}
                    onClose={handleMenuClose}
                >
                    {/*{(ws.tags.includes('csv') || ws.tags.includes('mermaid')) && (*/}
                    <MenuItem onClick={() => handleMenuAction('view', ws.uuid!)}>
                        <EditIcon color="primary"/>View
                    </MenuItem>
                    {/*)}*/}
                    <MenuItem onClick={() => handleMenuAction('edit', ws.uuid!)}>
                        <EditIcon color="primary"/>Edit
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuAction('save', ws.uuid!)}>
                        <SaveIcon color="primary"/>Save
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuAction('use', ws.uuid!)}>
                        <PreviewIcon color="primary"/>To Chat
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuAction('delete', ws.uuid!)}>
                        <DeleteIcon color="primary"/>Delete
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuAction('path', ws.uuid!)}>
                        <RouteOutlinedIcon color="primary"/>Copy Path
                    </MenuItem>
                </Menu>
            </div>
        );
    }, [menuAnchorEl, selectedItemId, handleMenuOpen, handleMenuClose, handleMenuAction]);

    return (
        <div style={{
            display: 'flex', flex: '1 0 0', flexDirection: 'column', overflowY: 'auto',
        }}>
            {uniqueCategories.map((category) => (
                <Accordion
                    key={category}
                    expanded={selectedWorkspaceCategory === category}
                    onChange={() => handleSelectCategory(category)}
                    sx={{
                        backgroundColor: colorSchema.background.default,
                        '&.Mui-expanded': {
                            margin: '0px',
                            backgroundColor: colorSchema.grey["100"],
                        },
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        sx={{
                            backgroundColor: selectedWorkspaceCategory === category ? colorSchema.action.selected : 'inherit',
                            minHeight: '40px',
                            height: '40px',
                            '&.Mui-expanded': {
                                minHeight: '40px',
                            },
                        }}
                    >
                        <Typography>{category}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{padding: 1}}>
                        <div style={{overflowY: 'auto', flex: 1}}>
                            {itemsToShow.map((ws, index) => (
                                <WorkspaceItemCard
                                    key={ws.uuid}
                                    item={ws}
                                    itemData={getWorkspaceItemData(ws)}
                                    index={index}
                                    isSelected={ws.selected}
                                    isTarget={false}
                                    onSelect={(event) => handleSelectWorkspaceItem(event, ws.uuid!)}
                                    compactMode={compactMode}
                                    addMenu={renderMenu}
                                />
                            ))}
                        </div>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};