import React, {useEffect, useMemo, useState} from "react";
import {SelectWithData} from "../../general/Site.snippets";
import {appSession} from "../../../managers/generalManager";
import {Box} from "@mui/material";
import {ObjectItem} from "../file_navigator";
import {getExtDataRecordList, KnowledgeBaseItem} from "../../../backend/extdata.services";
import logger from "../../../utils/logging_services";
import colorSchema from "../../../app_theme/theme_support/colorSchema";

export interface SelectFileInFolderProps {
    selectedCategory: string;
    selectedFilter: string;
    selectedFile: string | undefined;
    onFileSelected: (file: ObjectItem) => void;
}

export const SelectFileInFolder: React.FC<SelectFileInFolderProps> = (props: SelectFileInFolderProps) => {
    const [recordList, setRecordList] = useState<KnowledgeBaseItem[]>([]);
    const [selectedFilename, setSelectedFilename] = useState<string | undefined>(undefined);

    useEffect(() => {
        try {
            getExtDataRecordList(appSession.currentKnowledgeBaseProject, props.selectedCategory).then(data => setRecordList(data));
        }
        catch (e: any) {
            logger.error('Error getting record list:', e);
        }
    }, [props.selectedCategory]);

    useEffect( () => {
        try {
            setSelectedFilename(props.selectedFile);
        } catch (e: any) {
            logger.error('Error setting selected file:', e);
        }
    }, [props.selectedFile])

    const sortedAndConvertedRecords: ObjectItem[] = useMemo(() => {
        return [...recordList]
            .sort((a, b) => a.title.localeCompare(b.title)) // Sort by title
            .filter((record) => record.meta_data.resource?.toLowerCase().endsWith(props.selectedFilter)) // Filter by selected filter
            .map((record) => ({
                key: record.uuid,
                category: "files",
                filename: truncateLongWords(record.title, 25),
                source: record.meta_data.resource,
                chunks: record.chunks_original,
                value: record,
            }));
    }, [recordList, props.selectedFilter]);

    const selectedFile = useMemo(() => {
        return sortedAndConvertedRecords.find((record) => record.filename === selectedFilename);
    }, [selectedFilename, sortedAndConvertedRecords]);

    useEffect(() => {

        logger.info('Selected file:', selectedFile);

        if (selectedFile) {
            props.onFileSelected(selectedFile);
        }
    }, [selectedFile]);

    return (
        <Box sx={{
            height: '55px',
            textAlign: 'center',
            alignContent: 'center',
            borderRadius: '10px',
            backgroundColor: colorSchema.background.default,
            color: colorSchema.text.primary,
        }}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <SelectWithData
                    data={sortedAndConvertedRecords.map((record) => ({value: record.filename, label: record.filename}))}
                    id="SelectFile"
                    onSelect={(option) => setSelectedFilename(option.value)}
                    selected={selectedFilename ? sortedAndConvertedRecords.findIndex((record) => record.filename === selectedFilename) : 0}
                    label={""}
                    isOpen={false}
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 0,
                        flexGrow: 0,
                        alignItems: "center",
                        justifyContent: "start",
                        m: 1,
                    }}
                />
            </div>
        </Box>
    );
}

function truncateLongWords(str: string, maxLength: number): string {
    if (str.length <= maxLength) return str;
    return str.substring(0, maxLength) + '...';
}
