import React from 'react';
import {Button, ButtonGroup, Box} from '@mui/material';

interface AudioServiceProps {
    filetype: string;
    transcriptionStatus: boolean;
    subtitlesStatus: boolean;
    handleAddRemoveTranscription: () => void;
    handleAddRemoveSubtitle: () => void;
    handleShowServiceData: (serviceType: string) => void;
}

export const renderAudioServices = ({
                                        filetype,
                                        transcriptionStatus,
                                        subtitlesStatus,
                                        handleAddRemoveTranscription,
                                        handleAddRemoveSubtitle,
                                        handleShowServiceData
                                    }: AudioServiceProps) => {
    if (filetype !== 'audio') return null;

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flex: 1,
            padding: '10px'
        }}>
            <ButtonGroup>
                <Button
                    variant="outlined"
                    size="small"
                    color={transcriptionStatus ? "warning" : "primary"}
                    onClick={handleAddRemoveTranscription}
                >
                    {transcriptionStatus ? "Remove Transcription" : "Create Transcription"}
                </Button>

                {transcriptionStatus && (
                    <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() => handleShowServiceData("transcribe")}
                    >
                        Show Transcription
                    </Button>
                )}
            </ButtonGroup>
            {/* Temp disable due to no purpose here */}
            {/*<ButtonGroup>*/}
            {/*    <Button*/}
            {/*        variant="outlined"*/}
            {/*        size="small"*/}
            {/*        color={subtitlesStatus ? "warning" : "primary"}*/}
            {/*        onClick={handleAddRemoveSubtitle}*/}
            {/*    >*/}
            {/*        {subtitlesStatus ? "Remove Subtitles" : "Create Subtitles"}*/}
            {/*    </Button>*/}

            {/*    {subtitlesStatus && (*/}
            {/*        <Button*/}
            {/*            variant="outlined"*/}
            {/*            size="small"*/}
            {/*            color="primary"*/}
            {/*            onClick={() => handleShowServiceData("subtitle")}*/}
            {/*        >*/}
            {/*            Show Subtitles*/}
            {/*        </Button>*/}
            {/*    )}*/}
            {/*</ButtonGroup>*/}
        </Box>
    );
};