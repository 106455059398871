import React, { useState } from 'react';
import { Popover, Button, ButtonGroup, Menu, MenuItem } from '@mui/material';

export interface MenuOption {
    value: string;
    label: string;
    image?: string;
    submenu?: MenuOption[];
}

interface PopoverMenuProps {
    anchorEl: HTMLElement | null;
    optionList: MenuOption[];
    onSelect: (value: MenuOption) => void;
    onClose: () => void;
}

export const PopoverMenu: React.FC<PopoverMenuProps> = ({ anchorEl, optionList, onSelect, onClose }) => {
    const [submenuAnchorEl, setSubmenuAnchorEl] = useState<HTMLElement | null>(null);
    const [currentSubmenu, setCurrentSubmenu] = useState<MenuOption[]>([]);

    const handleOptionClick = (event: React.MouseEvent<HTMLElement>, option: MenuOption) => {
        if (option.submenu) {
            setCurrentSubmenu(option.submenu);
            setSubmenuAnchorEl(event.currentTarget);
        } else {
            onSelect(option);
            onClose();
        }
    };

    const handleSubmenuClose = () => {
        setSubmenuAnchorEl(null);
        setCurrentSubmenu([]);
    };

    return (
        <>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                    {optionList.map((option) => (
                        <Button
                            key={option.value}
                            size={"small"}
                            sx={{ '&:hover': { backgroundColor: 'lightgray', color: 'black' } }}
                            onClick={(event) => handleOptionClick(event, option)}
                        >
                            {option.image ? (
                                <img src={option.image} alt={option.label} style={{ width: '24px', height: '24px' }} />
                            ) : (
                                option.label
                            )}
                        </Button>
                    ))}
                </ButtonGroup>
            </Popover>
            <Popover
                open={Boolean(submenuAnchorEl)}
                anchorEl={submenuAnchorEl}
                onClose={handleSubmenuClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <ButtonGroup variant="outlined" aria-label="outlined primary button group" orientation={"vertical"}>
                    {currentSubmenu.map((subOption) => (
                        <Button
                            key={subOption.value}
                            size={"small"}
                            sx={{ '&:hover': { backgroundColor: 'lightgray', color: 'black' } }}
                            onClick={(event) => handleOptionClick(event, subOption)}
                        >
                            {subOption.image ? (
                                <img src={subOption.image} alt={subOption.label} style={{ width: '24px', height: '24px' }} />
                            ) : (
                                subOption.label
                            )}
                        </Button>
                    ))}
                </ButtonGroup>
            </Popover>
        </>
    );
};
