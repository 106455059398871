// useDynamicFontSize.ts
import { useEffect } from 'react';

const useDynamicFontSize = () => {
  const adjustFontSize = () => {
    const width = window.innerWidth;
    let fontSize;
    console.log("vincent-useDynamicFontSize.tsx-adjustFontSize-width: ", width);

    if (width < 600) {
      fontSize = 12;
    } else if (width < 960) {
      fontSize = 13;
    } else if (width < 1280) {
      fontSize = 14;
    } else {
      fontSize = 14;
    }

    document.documentElement.style.fontSize = `${fontSize}px`;
  };

  useEffect(() => {
    adjustFontSize();
    window.addEventListener('resize', adjustFontSize);

    return () => {
      window.removeEventListener('resize', adjustFontSize);
    };
  }, []);
};

export default useDynamicFontSize;