
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ThumbUpAltTwoToneIcon from '@mui/icons-material/ThumbUpAltTwoTone';
import ThumbDownAltTwoToneIcon from '@mui/icons-material/ThumbDownAltTwoTone';
import ContentPasteTwoToneIcon from '@mui/icons-material/ContentPasteTwoTone';
import SmartButtonTwoToneIcon from '@mui/icons-material/SmartButtonTwoTone';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import { TextField } from "@mui/material";
import AppDialog from "../general/app_dialog";
import { MenuOption, PopoverMenu } from "./popover_menu";
import { getStoreItems } from "../task_groups/store_data_detail";
// import { AppState } from "../../store/old/store";
import logger from "../../utils/logging_services";
import {RootState} from "../../store/store";


// The rewrite and convert options are using references to prompts defined in promptmanager.
// The prompts submenu is filled with prompts found in the active store.
// The Save option stores the current content in the local storage.
const initialActionOptions: MenuOption[] = [
    {value: 'local_storage', label: 'Save'},
    {
        value: '', label: 'rewrite', submenu: [
            {value: 'shorter', label: 'Shorter'},
            {value: 'longer', label: 'Longer'},
            {value: 'formal', label: 'Formal'},
            {value: 'informal', label: 'Informal'},
        ]
    },
    {
        value: '', label: 'Convert', submenu: [
            {value: 'to_html', label: 'Format as html'},
            {value: 'to_table', label: 'Format as table'},
        ]
    },
    {
        value: '', label: 'Prompts', submenu: []
    },
];

type OptionItem = {
    text: string;
    icon: React.ReactNode;
    showText: boolean;
    action: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

type FeedbackOptionsProps = {
    onSubmit: (commentType: string, comment: string) => void;
    onRequest: (request: string, request_data: string) => void;
}

const FeedbackOptions: React.FC<FeedbackOptionsProps> = ({ onSubmit, onRequest }) => {
    const [showComment, setShowComment] = useState<boolean>(false);
    const [commentType, setCommentType] = useState<string>('');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [actionOptions, setActionOptions] = useState<MenuOption[]>(initialActionOptions);

    const activeStore = useSelector((state: RootState) => state.data.activeStore);

    useEffect(() => {
        const fetchPrompts = async () => {
            try {
                const data = await getStoreItems(activeStore, 'prompts');
                const promptOptions = data.filter(item => item.label.toLowerCase() !== 'none').map((item: { value: string, label: string }) => ({
                    value: item.value,
                    label: item.label
                }));

                setActionOptions(prevOptions => {
                    const newOptions = [...prevOptions];
                    const promptsIndex = newOptions.findIndex(option => option.label === 'Prompts');
                    if (promptsIndex !== -1) {
                        newOptions[promptsIndex].submenu = promptOptions;
                    }
                    return newOptions;
                });
            } catch (error: any) {
                logger.error('Error fetching prompts:', error);
            }
        };

        fetchPrompts().then();
    }, [activeStore]);

    const handleComment = (type: string) => {
        setShowComment(true);
        setCommentType(type);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCommentSubmit = (comment: string) => {
        setShowComment(false);
        onSubmit(commentType, comment);
    }

    const handleCommentCancel = () => {
        setShowComment(false);
        setCommentType('');
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOptionClick = (option: MenuOption) => {
        if (anchorEl) {
            const originId = anchorEl.id;
            logger.info(`Option clicked: ${option.value} for action: ${originId}`);
            if (option.value !== '0') {
                onRequest(option.value, "");
            }
            handleClose();
        }
    };

    const options: OptionItem[] = [
        {
            text: 'Like',
            showText: false,
            icon: <ThumbUpAltTwoToneIcon style={{height: '1rem', width: '1rem', marginRight: '0.25rem'}}/>,
            action: () => handleComment('like'),
        },
        {
            text: 'Dislike',
            showText: false,
            icon: <ThumbDownAltTwoToneIcon style={{height: '1rem', width: '1rem', marginRight: '0.25rem'}}/>,
            action: () => handleComment('dislike'),
        },
        {
            text: 'Copy',
            showText: false,
            icon: <ContentPasteTwoToneIcon style={{height: '1rem', width: '1rem', marginRight: '0.25rem'}}/>,
            action: () => onRequest('copy', '')
        },
        {
            text: 'Rag',
            showText: false,
            icon: <EmojiObjectsOutlinedIcon style={{height: '1rem', width: '1rem', marginRight: '0.25rem'}}/>,
            action: () => onRequest('rag', '')
        },
        {
            text: 'Options',
            showText: false,
            icon: <SmartButtonTwoToneIcon style={{height: '1rem', width: '1rem', marginRight: '0.25rem'}}/>,
            action: handleClick,
        },
    ];

    return (
        <>
            {showComment && (
                <FeedbackDialog isOpen={showComment} onCancel={handleCommentCancel} onSubmit={handleCommentSubmit}/>
            )}
            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '0.25rem',
                justifyContent: 'flex-end'
            }}>
                {options.map((option, index) => (
                    <button
                        key={index}
                        onClick={option.action}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#6b7280',
                            fontSize: '0.75rem',
                        }}
                    >
                        {option.icon}
                        {option.showText && option.text}
                    </button>
                ))}
            </div>
            <PopoverMenu anchorEl={anchorEl} optionList={actionOptions}
                         onSelect={handleOptionClick}
                         onClose={handleClose}/>
        </>
    );
};

export default FeedbackOptions;


type FeedbackDialogProps = {
    isOpen: boolean;
    onSubmit: (comment: string) => void;
    onCancel: () => void;
}

const FeedbackDialog = ({isOpen, onSubmit, onCancel}: FeedbackDialogProps) => {

    const [comment, setComment] = useState<string>('');

    return (
        <AppDialog
            open={isOpen}
            size={"md"}
            title={"Please motivate your feedback"}
            message={""}
            showSubmit={true}
            onSubmit={() => onSubmit(comment)}
            onCancel={() => onCancel()}
        >
            <TextField id="comment"
                       fullWidth
                       multiline={true}
                       rows={4}
                       sx={{my: 1}}
                       variant={"outlined"}
                       onChange={(event) => {
                           setComment(event.target.value)
                       }}
                       value={comment}
            />
        </AppDialog>
    )
}
