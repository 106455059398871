import {decodeHtmlEntities, removeHTMLTags} from "../../utils/common_tools";

interface ConversionResult {
  success: boolean;
  type: string;
  data?: any[];
  error?: string;
}

const VALID_MERMAID_STARTS = ['graph ', 'mindmap', 'sequenceDiagram', 'gantt', 'flowchart ', 'pie ', 'classDiagram', 'stateDiagram', 'erDiagram', 'journey', 'gitGraph', 'xychart-beta'];

export const processSnippetData = (text: string): ConversionResult => {

    const raw_data = removeHTMLTags(text, '||');
    const lines = raw_data.trim().split('||');

    // remove empty lines
    const filtered_lines = lines.filter(Boolean);

    // check if the first line is 'csv'
    if (filtered_lines[0].trim().toLowerCase() === 'csv') {
      const fields = filtered_lines[1].split(';');
      if (fields.length > 1) {
        const lines_to_process = filtered_lines.slice(2);
        return convertItemToCSV(fields, lines_to_process);
      }
    }

    const firstLineTrimmed = filtered_lines[0].trim();
    if (VALID_MERMAID_STARTS.some(start => firstLineTrimmed.startsWith(start))) {
      return { success: true, type: 'mermaid', data: [decodeHtmlEntities(removeHTMLTags(text))] };
    }

    const htmlElements = ['h1', 'p', 'li'];

    // check if any of these elements are inside the text
    if (htmlElements.some(element => text.includes(`<${element}>`))) {
        return { success: true, type: 'html', data: [text] };
    }

    return { success: false, type: 'unknown', error: 'unknown app_theme format' };
}

export const convertItemToCSV = (fields: string[], lines: string[]): ConversionResult => {
  try {

    const data = lines.map(line => {
      const values = line.split(';');
      const record: { [key: string]: string } = {};

      fields.forEach((field, index) => {
        record[field.trim()] = values[index]?.trim() || '';
      });

      return record;
    });

    return { success: true, type: 'csv', data: data };
  } catch (error) {
    return { success: false, type: 'csv', error: (error as Error).message };
  }
};