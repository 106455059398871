import {Box, Button, ButtonGroup} from "@mui/material";
import React from "react";

interface ImageServiceProps {
    filetype: string;
    infoStatus: boolean;
    handleAddRemoveInfo: () => void;
    handleShowServiceData: (serviceType: string) => void;
}

export const renderImageServices = ({
                                        filetype,
                                        infoStatus,
                                        handleAddRemoveInfo,
                                        handleShowServiceData
                                    }: ImageServiceProps) => {
    if (filetype !== 'image') return null;

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flex: 1,
            padding: '10px'
        }}>
            <ButtonGroup>
                <Button
                    variant="outlined"
                    size="small"
                    color={infoStatus ? "warning" : "primary"}
                    onClick={handleAddRemoveInfo}
                >
                    {infoStatus ? "Remove Info" : "Create Info"}
                </Button>

                {infoStatus && (
                    <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() => handleShowServiceData("info")}
                    >
                        Show Info
                    </Button>
                )}
            </ButtonGroup>
        </Box>
    );
};

export const infoServicePrompt = "You are an AI assistant tasked with extracting and organizing information from images. Your goal is to analyze the given image thoroughly and convert the information into a structured format that can be used for various purposes. The image will be described to you in text form. Follow these instructions carefully:\n" +
    "1. First, you will be provided with a detailed description of an image:\n" +
    "{{IMAGE_DESCRIPTION}}\n" +
    "2. Analyze the image description thoroughly, paying attention to all visual elements, text, objects, people, settings, and any other relevant details mentioned.\n" +
    "3. Extract and categorize the following information from the image description (if applicable):\n" +
    "   a. Main subject or focus\n" +
    "   b. People (number, appearance, actions)\n" +
    "   c. Objects (type, quantity, arrangement)\n" +
    "   d. Text or captions\n" +
    "   e. Colors and visual style\n" +
    "   f. Setting or background\n" +
    "   g. Emotions or mood conveyed\n" +
    "   h. Any notable symbols or logos\n" +
    "4. Organize the extracted information into a structured format using JSON. Use the following structure:\n" +
    "{\n" +
    "  \"main_subject\": \"\",\n" +
    "  \"people\": \"\",\n" +
    "  \"objects\": \"\",\n" +
    "  \"text\": \"\",\n" +
    "  \"visual_elements\": \"\",\n" +
    "  \"setting\": \"\",\n" +
    "  \"mood\": \"\",\n" +
    "  \"symbols\": \"\"\n" +
    "}\n" +
    "5. If any elements in the image description are unclear or ambiguous, mention this in your analysis and provide your best interpretation based on the available information.\n" +
    "6. If the image description contains sensitive or explicit content, provide a content warning before your analysis.\n" +
    "7. After organizing the extracted information, provide a detailed summary that describes the image comprehensively. This summary should paint a vivid picture of the image based on all the information you've extracted and analyzed.\n" +
    "Present your response in the following order:\n" +
    "1. Content warning (if applicable)\n" +
    "2. JSON structure with extracted information\n" +
    "3. Detailed summary of the image\n" +
    "Begin your analysis now."