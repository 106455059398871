import React, {useState, useCallback, useMemo} from 'react';
import {Card, CardContent, Box, Typography, Divider} from '@mui/material';
// import {WorkspaceItem} from '../../../store/old/temp-slice';
import colorSchema from "../../../app_theme/theme_support/colorSchema";
import {getItemIcon} from "../../files/file_services";
import {WorkspaceItem} from "../../../store/types";

interface WorkspaceItemCardProps {
    item: WorkspaceItem;
    itemData: string;
    index: number;
    isSelected: boolean;
    isTarget: boolean;
    onSelect: (event: React.MouseEvent, uuid: string) => void;
    compactMode: boolean;
    addMenu: (ws: WorkspaceItem, index: number) => React.ReactNode;
}

const WorkspaceItemCard: React.FC<WorkspaceItemCardProps> = ({
                                                                 item,
                                                                 itemData,
                                                                 index,
                                                                 isSelected,
                                                                 isTarget,
                                                                 onSelect,
                                                                 compactMode,
                                                                 addMenu
                                                             }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = useCallback(() => setIsHovered(true), []);
    const handleMouseLeave = useCallback(() => setIsHovered(false), []);

    const handleClick = useCallback((event: React.MouseEvent) => {
        event.preventDefault();
        onSelect(event, item.uuid || "");
    }, [item.uuid, onSelect]);

    const stripHtmlTags = useMemo(() => (html: string) => {
        const tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }, []);

    const truncatedItemData = useMemo(() => stripHtmlTags(itemData).substring(0, 50), [itemData, stripHtmlTags]);

    if (!item) return null;

    return (
        <React.Fragment key={item.uuid}>
            {isTarget && <Divider sx={{my: 1, backgroundColor: colorSchema.primary.main}}/>}
            <Card
                variant="outlined"
                sx={{
                    mb: 1,
                    backgroundColor: isSelected ? item.selected_color !== undefined ? item.selected_color : colorSchema.background.selected : colorSchema.background.paper,
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        backgroundColor: colorSchema.background.hover,
                        transform: 'translateY(-2px)',
                    },
                    cursor: 'pointer',
                }}
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <CardContent>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        {/*<Tooltip*/}
                        {/*    title={<span dangerouslySetInnerHTML={{__html: itemData.slice(1, 500)}}/>}*/}
                        {/*    arrow*/}
                        {/*    placement="right-end"*/}
                        {/*    enterDelay={5000}*/}
                        {/*    leaveDelay={200}*/}
                        {/*>*/}
                        <Box sx={{display: "flex", flexDirection: "column", flex: 1, mr: 2}}>

                            <div style={{display: "flex", flexDirection: "row", justifyContent: "start"}}>

                                <div style={{marginRight: '5px', color: (item.chunks && item.chunks > 0) ? colorSchema.success.dark : 'inherit'}}>
                                    {getItemIcon(item)}
                                </div>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        mb: !compactMode ? 0.5 : 0
                                    }}
                                >
                                    {item.name}
                                </Typography>
                            </div>
                            {!compactMode && (
                                <Typography
                                    variant="body2"
                                    sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: 'vertical',
                                    }}
                                >
                                    {truncatedItemData}...
                                </Typography>
                            )}
                        </Box>
                        {/*</Tooltip>*/}
                        <Box sx={{opacity: isHovered ? 1 : 0, transition: 'opacity 0.2s ease-in-out'}}>
                            {addMenu(item, index)}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default React.memo(WorkspaceItemCard);